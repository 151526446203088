import React, { useState, useEffect } from 'react';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Table from 'cccisd-graphql-table';
import style from './style.css';

import entityAdminsQuery from './entityAdmins.graphql';

const Boilerplate = window.cccisd.boilerplate;

const Component = props => {
    const { row } = props;
    const [loading, setLoading] = useState(true);
    const [entityGroups, setEntityGroups] = useState([]);
    useEffect(() => {
        (async () => {
            let response = await axios.get(
                Boilerplate.route('api.shape.group.entities', {
                    group: row['group.groupId'],
                })
            );
            if (response.data.data.current.length > 0) {
                setEntityGroups(response.data.data.current);
            }
            setLoading(false);
        })();
    }, []);
    if (loading) {
        return <Loader />;
    }
    return (
        <div>
            {entityGroups.length > 0 && (
                <>
                    {entityGroups.map(entity => (
                        <div>
                            <Modal trigger={<a>{entity.label}</a>} title={entity.label}>
                                <Table
                                    rowKey="user.email"
                                    query={entityAdminsQuery}
                                    graphqlVariables={{ groupId: entity.id }}
                                    columns={[
                                        { name: 'user.fullName', label: 'Name' },
                                        { name: 'user.email', label: 'Email' },
                                        { name: 'fields.position', label: 'Position' },
                                        { name: 'user.lastLogin', label: 'Last Login' },
                                    ]}
                                />
                            </Modal>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default settings => {
    return ({ value, row }) => {
        return (
            <div className={style.box}>
                <Component row={row} value={value} />
            </div>
        );
    };
};
