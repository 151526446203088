import React from 'react';
import { format } from 'date-fns';
import style from './style.css';
export default settings => {
    return ({ value, row }) => {
        const formattedDate = format(value, 'YYYY/MM/DD');

        return (
            <div key={row} className={`${style.container} text-nowrap`}>
                {formattedDate}
            </div>
        );
    };
};
