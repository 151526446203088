import { handleActions, createAction } from 'redux-actions';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;

export const persistedKeys = [];

// Initial state
export const initialState = {};

// Actions
const SET_MHQ_FIELDS = 'app/shape/SET_MHQ_FIELDS';
const SET_TRS_FIELDS = 'app/shape/SET_TRS_FIELDS';
const SET_OWBI_SCHOOL_FIELDS = 'app/shape/SET_OWBI_SCHOOL_FIELDS';
const SET_OWBI_DISTRICT_FIELDS = 'app/shape/SET_OWBI_DISTRICT_FIELDS';
const SET_OWBI_ENTITY_FIELDS = 'app/shape/SET_OWBI_ENTITY_FIELDS';
const SET_CARE_SCHOOL_FIELDS = 'app/shape/SET_CARE_SCHOOL_FIELDS';
const SET_CARE_DISTRICT_FIELDS = 'app/shape/SET_CARE_DISTRICT_FIELDS';
const SET_CARE_ENTITY_FIELDS = 'app/shape/SET_CARE_ENTITY_FIELDS';
const SET_SMHP_FIELDS = 'app/shape/SET_SMHP_FIELDS';
const SET_INSTRUCTION_FIELDS = 'app/shape/SET_INSTRUCTION_FIELDS';
const SET_EMAIL_FIELDS = 'app/shape/SET_EMAIL_FIELDS';
const SET_CSV_UPDATED = 'app/shape/SET_CSV_UPDATED';
const SET_PRESCHOOL_FIELDS = 'app/shape/SET_PRESCHOOL_FIELDS';
const SET_FAMILY_ENGAGEMENT_SCHOOL_FIELDS = 'app/shape/SET_FAMILY_ENGAGEMENT_SCHOOL_FIELDS';
const SET_FAMILY_ENGAGEMENT_DISTRICT_FIELDS = 'app/shape/SET_FAMILY_ENGAGEMENT_DISTRICT_FIELDS';
const SET_FAMILY_ENGAGEMENT_ENTITY_FIELDS = 'app/shape/SET_FAMILY_ENGAGEMENT_ENTITY_FIELDS';

// Action Creators
export const setMhqFields = createAction(SET_MHQ_FIELDS);
export const setTrsFields = createAction(SET_TRS_FIELDS);
export const setOwbiSchoolFields = createAction(SET_OWBI_SCHOOL_FIELDS);
export const setOwbiDistrictFields = createAction(SET_OWBI_DISTRICT_FIELDS);
export const setOwbiEntityFields = createAction(SET_OWBI_ENTITY_FIELDS);
export const setCareSchoolFields = createAction(SET_CARE_SCHOOL_FIELDS);
export const setCareDistrictFields = createAction(SET_CARE_DISTRICT_FIELDS);
export const setCareEntityFields = createAction(SET_CARE_ENTITY_FIELDS);
export const setSmhpFields = createAction(SET_SMHP_FIELDS);
export const setInstructionFields = createAction(SET_INSTRUCTION_FIELDS);
export const setEmailFields = createAction(SET_EMAIL_FIELDS);
export const setCsvUpdated = createAction(SET_CSV_UPDATED);
export const setPreschoolFields = createAction(SET_PRESCHOOL_FIELDS);
export const setFamilyEngagementSchoolFields = createAction(SET_FAMILY_ENGAGEMENT_SCHOOL_FIELDS);
export const setFamilyEngagementDistrictFields = createAction(SET_FAMILY_ENGAGEMENT_DISTRICT_FIELDS);
export const setFamilyEngagementEntityFields = createAction(SET_FAMILY_ENGAGEMENT_ENTITY_FIELDS);

export const updateParams = (params, key) => async () => {
    const response = await axios.post(Boilerplate.route('shape.set.data'), {
        data: JSON.stringify(params),
        data_key: key,
    });
    if (response.data.success) {
        notification({
            message: `Site fields have been updated.`,
            type: 'success',
            duration: 8000,
        });
    }
};

// fields: JSON object, contains the default text to be used.
// key: string, 'trs', 'smhp', 'mhq', 'emails', 'instructions', or 'csv'
export const getSavedParams = (fields, key) => {
    return async dispatch => {
        const response = await axios.get(Boilerplate.route('shape.get.data'), {
            data_key: key,
        });
        let params = {};

        if (fields) {
            Object.keys(fields.messages).forEach(handle => {
                params[handle] = fields.messages[handle].message;
            });
            if (response.data.data[key]) {
                params = {
                    ...params,
                    ...JSON.parse(response.data.data[key].data),
                };
            }
        }

        if (key === 'mhq') {
            await dispatch(setMhqFields(params));
        }
        if (key === 'trs') {
            await dispatch(setTrsFields(params));
        }
        if (key === 'owbiSchool') {
            await dispatch(setOwbiSchoolFields(params));
        }
        if (key === 'owbiDistrict') {
            await dispatch(setOwbiDistrictFields(params));
        }
        if (key === 'owbiEntity') {
            await dispatch(setOwbiEntityFields(params));
        }
        if (key === 'careSchool') {
            await dispatch(setCareSchoolFields(params));
        }
        if (key === 'careDistrict') {
            await dispatch(setCareDistrictFields(params));
        }
        if (key === 'careEntity') {
            await dispatch(setCareEntityFields(params));
        }
        if (key === 'instructions') {
            await dispatch(setInstructionFields(params));
        }
        if (key === 'smhp') {
            await dispatch(setSmhpFields(params));
        }
        if (key === 'emails') {
            await dispatch(setEmailFields(params));
        }
        if (key === 'csv') {
            params = response.data.data.csvUpdated;
            await dispatch(setCsvUpdated(params));
        }
        if (key === 'preschool') {
            await dispatch(setPreschoolFields(params));
        }
        if (key === 'familyEngagementSchool') {
            await dispatch(setFamilyEngagementSchoolFields(params));
        }
        if (key === 'familyEngagementDistrict') {
            await dispatch(setFamilyEngagementDistrictFields(params));
        }
        if (key === 'familyEngagementEntity') {
            await dispatch(setFamilyEngagementEntityFields(params));
        }
    };
};

// Reducer
export default handleActions(
    {
        [SET_MHQ_FIELDS]: (state, action) => ({
            ...state,
            mhqFields: action.payload,
        }),
        [SET_TRS_FIELDS]: (state, action) => ({
            ...state,
            trsFields: action.payload,
        }),
        [SET_OWBI_SCHOOL_FIELDS]: (state, action) => ({
            ...state,
            owbiSchoolFields: action.payload,
        }),
        [SET_OWBI_DISTRICT_FIELDS]: (state, action) => ({
            ...state,
            owbiDistrictFields: action.payload,
        }),
        [SET_OWBI_ENTITY_FIELDS]: (state, action) => ({
            ...state,
            owbiEntityFields: action.payload,
        }),
        [SET_CARE_SCHOOL_FIELDS]: (state, action) => ({
            ...state,
            careSchoolFields: action.payload,
        }),
        [SET_CARE_DISTRICT_FIELDS]: (state, action) => ({
            ...state,
            careDistrictFields: action.payload,
        }),
        [SET_CARE_ENTITY_FIELDS]: (state, action) => ({
            ...state,
            careEntityFields: action.payload,
        }),
        [SET_INSTRUCTION_FIELDS]: (state, action) => ({
            ...state,
            instructionFields: action.payload,
        }),
        [SET_SMHP_FIELDS]: (state, action) => ({
            ...state,
            smhpFields: action.payload,
        }),
        [SET_EMAIL_FIELDS]: (state, action) => ({
            ...state,
            emailFields: action.payload,
        }),
        [SET_CSV_UPDATED]: (state, action) => ({
            ...state,
            csvUpdated: action.payload,
        }),
        [SET_PRESCHOOL_FIELDS]: (state, action) => ({
            ...state,
            preschoolFields: action.payload,
        }),
        [SET_FAMILY_ENGAGEMENT_SCHOOL_FIELDS]: (state, action) => ({
            ...state,
            familyEngagementSchoolFields: action.payload,
        }),
        [SET_FAMILY_ENGAGEMENT_DISTRICT_FIELDS]: (state, action) => ({
            ...state,
            familyEngagementDistrictFields: action.payload,
        }),
        [SET_FAMILY_ENGAGEMENT_ENTITY_FIELDS]: (state, action) => ({
            ...state,
            familyEngagementEntityFields: action.payload,
        }),
    },
    initialState
);
