import React from 'react';

export default settings => {
    return ({ value, row }) => {
        if (value === null || typeof value === 'undefined') {
            return '';
        }

        // get the last one added
        value = JSON.parse(value);
        const lastContributor = value[value.length - 1];

        return (
            <div key={row} className="text-center">
                {lastContributor.completedByFullName}
            </div>
        );
    };
};
