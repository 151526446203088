import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class Checkbox extends React.Component {
    static propTypes = {
        value: PropTypes.bool,
        row: PropTypes.object,
        loadData: PropTypes.func,
    };

    handleCheckbox = async e => {
        const { row } = this.props;
        await axios.put(Boilerplate.route('api.nexus.pawn.update', { pawn: row['pawn.pawnId'] }), {
            inviteGatekeeper: !this.props.value,
            pawnHash: row['pawn.pawnHash'],
            pawnId: row['pawn.pawnId'],
        });
        await this.props.loadData();
    };

    render() {
        return (
            <input
                type="checkbox"
                name="gatekeeperCheckbox"
                checked={this.props.value}
                onChange={this.handleCheckbox}
            />
        );
    }
}
