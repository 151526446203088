import React from 'react';

export default settings => {
    return ({ value, row }) => {
        if (value > 0) {
            return <div>{value}</div>;
        }

        return '';
    };
};
