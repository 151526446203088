import React from 'react';
import PropTypes from 'prop-types';
import { Page, PrintOnly } from 'cccisd-laravel-report';
import Table from 'cccisd-table';
import SubHeading from '../SubHeading';
import style from '../ReportSection/style.css';
import { Html } from 'cccisd-wysiwyg';
import { CAREscoringDistrict, CAREscoringSchool, CAREscoringEntity } from 'js/selectors/scoringData.js';

export default class CarePrincipalReport extends React.Component {
    static propTypes = {
        pageNumber: PropTypes.number,
        totalPages: PropTypes.number,
        totalScores: PropTypes.object,
        flowType: PropTypes.string,
        getEnteredBy: PropTypes.func,
        selectedDates: PropTypes.object,
        siteFields: PropTypes.object,
    };

    // rows based on domains selected
    // get lowest and highest average with item label
    getData = totalScores => {
        const { flowType } = this.props;

        let rows = [];
        let scoringData;

        if (flowType === 'careDistrict') {
            scoringData = CAREscoringDistrict;
        }
        if (flowType === 'careSchool') {
            scoringData = CAREscoringSchool;
        }
        if (flowType === 'careEntity') {
            scoringData = CAREscoringEntity;
        }

        totalScores.map((domain, index) => {
            let row = [];

            // get lowest and highest item
            let indicators = domain.indicators.filter(item => typeof item.score === 'number');

            let lowItem =
                indicators.length > 0 ? indicators.reduce((prev, curr) => (prev.score < curr.score ? prev : curr)) : '';
            let highItem =
                indicators.length > 0 ? indicators.reduce((prev, curr) => (prev.score > curr.score ? prev : curr)) : '';

            if (domain.score) {
                row = {
                    id: index,
                    domain: scoringData[domain.handle].title,
                    domainAverage: domain.score,
                    highItem: highItem.title,
                    highAvg: highItem.score,
                    lowItem: lowItem.title,
                    lowAvg: lowItem.score,
                };
            } else {
                row = {
                    id: index,
                    domain: scoringData[domain.handle].title,
                    domainAverage: "Answers for all items were Don't Know",
                    highItem: '',
                    highAvg: '',
                    lowItem: '',
                    lowAvg: '',
                };
            }
            rows.push(row);
        });

        return rows;
    };

    pageOne = pageNumber => {
        const { totalScores, siteFields, totalPages } = this.props;
        let data = this.getData(totalScores);
        let tooltip = <Html content={siteFields.carePrincipalReportTableFooter} />;

        const columns = [
            { name: 'domain', label: 'Domain (Average)' },
            { name: 'domainAverage', label: 'Avg', class: 'text-center' },
            {
                name: 'highItem',
                label: 'Highest Rated Item in Domain',
                setStyle(value, row) {
                    return { borderLeft: '8px solid #fff' };
                },
            },
            { name: 'highAvg', label: 'Avg', class: 'text-center' },
            {
                name: 'lowItem',
                label: 'Lowest Rated Item in Domain',
                tooltip,
                setStyle(value, row) {
                    return { borderLeft: '8px solid #fff' };
                },
            },
            { name: 'lowAvg', label: 'Avg', class: 'text-center' },
        ];

        return (
            <Page key={`PrincipalReport ${pageNumber}`}>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo}>
                            <div className={style.triangle} />
                            <div className={style.logoCARE} />
                        </div>
                        <div className={style.headerTitle}>Principal Report</div>
                    </div>
                    <SubHeading getEnteredBy={this.props.getEnteredBy} selectedDates={this.props.selectedDates} />

                    <div className={style.principalContent}>
                        <Html content={siteFields.carePrincipalReportIntro} />
                        <Table
                            columns={columns}
                            data={data}
                            rowKey="id"
                            hideShowingRowsSummary
                            showPerPageOptions={false}
                        />
                        <PrintOnly>
                            <Html content={siteFields.carePrincipalReportTableFooter} />
                        </PrintOnly>
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        <div>
                            This progress report was developed by the National Center for School Mental Health for the
                            SHAPE System.
                        </div>
                        <div>
                            Page {pageNumber} of {totalPages}
                        </div>
                    </div>
                    <div className={style.footerLogo} />
                </div>
            </Page>
        );
    };

    pageTwo = pageNumber => {
        const { siteFields, totalPages } = this.props;
        return (
            <Page key={`PrincipalReport ${pageNumber}`}>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo}>
                            <div className={style.triangle} />
                            <div className={style.logoCARE} />
                        </div>
                        <div className={style.headerTitle}>Principal Report</div>
                    </div>
                    <SubHeading getEnteredBy={this.props.getEnteredBy} selectedDates={this.props.selectedDates} />

                    <div className={style.principalContent}>
                        <Html content={siteFields.carePrincipalReportReflectionQuestions} />
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        <div>
                            This progress report was developed by the National Center for School Mental Health for the
                            SHAPE System.
                        </div>
                        <div>
                            Page {pageNumber} of {totalPages}
                        </div>
                    </div>
                    <div className={style.footerLogo} />
                </div>
            </Page>
        );
    };

    pageThree = pageNumber => {
        const { siteFields, totalPages } = this.props;
        return (
            <Page key={`PrincipalReport ${pageNumber}`}>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo}>
                            <div className={style.triangle} />
                            <div className={style.logoCARE} />
                        </div>
                        <div className={style.headerTitle}>Principal Report</div>
                    </div>
                    <SubHeading getEnteredBy={this.props.getEnteredBy} selectedDates={this.props.selectedDates} />

                    <div className={style.principalContent}>
                        <Html content={siteFields.carePrincipalReportDistrictResources} />
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        <div>
                            This progress report was developed by the National Center for School Mental Health for the
                            SHAPE System.
                        </div>
                        <div>
                            Page {pageNumber} of {totalPages}
                        </div>
                    </div>
                    <div className={style.footerLogo} />
                </div>
            </Page>
        );
    };

    pageFour = pageNumber => {
        const { totalPages } = this.props;
        return (
            <Page key={`PrincipalReport ${pageNumber}`}>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo}>
                            <div className={style.triangle} />
                            <div className={style.logoCARE} />
                        </div>
                        <div className={style.headerTitle}>Principal Report</div>
                    </div>
                    <SubHeading getEnteredBy={this.props.getEnteredBy} selectedDates={this.props.selectedDates} />
                    <div className={style.principalContent}>
                        <div className={style.visitBlurb}>
                            Visit{' '}
                            <a
                                href="https://www.learningforjustice.org/professional-development/diversity-responsive-principals-tool"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <strong>Diversity Responsive Principal&rsquo;s Tool | Learning for Justice</strong>
                            </a>{' '}
                            for a self-assessment tool if interested!
                        </div>
                    </div>
                    <div className={style.principalContent}>
                        <div style={{ textAlign: 'center' }}>(blank for notes)</div>
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        <div>
                            This progress report was developed by the National Center for School Mental Health for the
                            SHAPE System.
                        </div>
                        <div>
                            Page {pageNumber} of {totalPages}
                        </div>
                    </div>
                    <div className={style.footerLogo} />
                </div>
            </Page>
        );
    };

    render() {
        const { pageNumber, totalScores } = this.props;

        // don't render report if domains and users are not selected yet
        if (totalScores.length === 0) {
            return null;
        }

        return (
            <div>
                {this.pageOne(pageNumber)}
                {this.pageTwo(pageNumber + 1)}
                {this.pageThree(pageNumber + 2)}
                {this.pageFour(pageNumber + 3)}
            </div>
        );
    }
}
