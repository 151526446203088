import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';

export default class Polices extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain3: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain3, stateTitle } = this.props;

        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo_stateLevel}>
                                <div className={style.triangleState_polices} />
                            </div>
                            <div className={style.headerTitle_state}>Policies</div>
                        </div>
                        <div className={style.reportPage}>
                            <div className={style.bodyContent} style={{ fontSize: '14px' }}>
                                <div className={style.bodyTopState}>
                                    <div className={style.firstHeading}>
                                        <p className={style.firstHeadingContent}>
                                            {stateTitle ? stateTitle : ''} school mental health policy landscape:
                                        </p>
                                    </div>

                                    <p className={style.topStateContent_page3_state_policy3}>
                                        {stateTitle ? stateTitle : ''} has policies related to:{' '}
                                    </p>
                                </div>
                                <div className={style.checkBoxes}>
                                    <div>
                                        <ul className={style.custom_ul_style}>
                                            {domain3.mental_health_profile_mtss === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Multi-tiered systems of support</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {domain3.mental_health_profile_d3_other1 === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>{domain3.mental_health_profile_d3_other1_text}</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {domain3.mental_health_profile_d3_other2 === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>{domain3.mental_health_profile_d3_other2_text}</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {domain3.mental_health_profile_schclim === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>School climate</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_schsafe === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>School safety</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_violprev === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Violence prevention</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_sel === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Social emotional learning</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_bullyprev === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Bullying prevention</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhsuscreen === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Mental health/substance use screening</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suicprev === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Suicide prevention</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_trauminform === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Trauma-informed schools/care</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhedustud === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Mental health education for students</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhedustaff === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Mental health education for school staff</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhedureq === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>
                                                                {' '}
                                                                Mental health education requirement for educator
                                                                certification/renewal
                                                            </p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_staffwell === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p> Staff wellness</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suedustud === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p> Substance use education for students</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suedustaff === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p> Substance use education for school staff</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suedufmly === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p> Substance use education for caregivers/families</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suprev === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>
                                                                {' '}
                                                                Substance use prevention (e.g. tobacco, opioid, alcohol)
                                                            </p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_datashare === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p> Data collection/sharing</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_telemhlth === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p> Telemedicine/telemental health</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_coverage === '1' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>
                                                                {' '}
                                                                Health care coverage for school mental health services
                                                                (e.g. Medicaid, CHIP, private insurance)
                                                            </p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                {/* // no polices (red div) */}

                                <div className={style.header_shape_page5_redBox} />
                                <p className={style.topStateContent_page3_state_policy}>
                                    {stateTitle ? stateTitle : ''} does not have policies related to:{' '}
                                </p>
                                <div className={style.checkBoxes_redBox}>
                                    <div>
                                        <ul className={style.custom_ul_style11}>
                                            {domain3.mental_health_profile_mtss === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Multi-tiered systems of support</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {domain3.mental_health_profile_d3_other1 === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p> {domain3.mental_health_profile_d3_other1_text}</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {domain3.mental_health_profile_d3_other2 === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>{domain3.mental_health_profile_d3_other2_text}</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {domain3.mental_health_profile_schclim === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>School climate</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_schsafe === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>School safety</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_violprev === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Violence prevention</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_sel === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Social emotional learning</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_bullyprev === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Bullying prevention</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhsuscreen === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Mental health/substance use screening</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suicprev === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Suicide prevention</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_trauminform === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Trauma-informed schools/care</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhedureq === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>
                                                                {' '}
                                                                Mental health education requirement for educator
                                                                certification/renewal
                                                            </p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhedustud === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Mental health education for students</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_mhedustaff === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Mental health education for school staff</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}

                                            {domain3.mental_health_profile_staffwell === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Staff wellness</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suedustud === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Substance use education for students</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suedustaff === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Substance use education for school staff</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suedufmly === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Substance use education for caregivers/families</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_suprev === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>
                                                                Substance use prevention (e.g. tobacco, opioid, alcohol)
                                                            </p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_datashare === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Data collection/sharing</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_telemhlth === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>Telemedicine/telemental health</p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain3.mental_health_profile_coverage === '0' ? (
                                                <li>
                                                    <div>
                                                        <span>
                                                            <p>
                                                                Health care coverage for school mental health services
                                                                (e.g. Medicaid, CHIP, private insurance)
                                                            </p>
                                                        </span>
                                                    </div>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                {/* // red box */}

                                <div className={style.mainCartHeader_unknBox}>
                                    <div className={style.cartContentHeader_unknwBox}>
                                        <h4>&nbsp;Unknown: </h4>
                                    </div>
                                    <div className={style.CartContent_unknBox}>
                                        <div className={style.OnOrderlist_MainContainer}>
                                            <div className={style.firstbox_checkedBox}>
                                                <ul>
                                                    {domain3.mental_health_profile_mtss === '777' ? (
                                                        <li>Multi-tiered systems of support</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain3.mental_health_profile_d3_other1 === '777' ? (
                                                        <li>{domain3.mental_health_profile_d3_other1_text}</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain3.mental_health_profile_d3_other2 === '777' ? (
                                                        <li>{domain3.mental_health_profile_d3_other2_text}</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_schclim === '777' ? (
                                                        <li>School climate</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_schsafe === '777' ? (
                                                        <li>School safety</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_violprev === '777' ? (
                                                        <li>Violence prevention</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_sel === '777' ? (
                                                        <li>Social emotional learning</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_bullyprev === '777' ? (
                                                        <li>Bullying prevention</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_mhsuscreen === '777' ? (
                                                        <li>Mental health/substance use screening</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_suicprev === '777' ? (
                                                        <li>Suicide prevention</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_trauminform === '777' ? (
                                                        <li>Trauma-informed schools/care</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_mhedustud === '777' ? (
                                                        <li>Mental health education for students</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_mhedustaff === '777' ? (
                                                        <li>Mental health education for school staff</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_mhedureq === '777' ? (
                                                        <li>
                                                            <div>
                                                                {' '}
                                                                Mental health education requirement for educator
                                                                certification/renewal
                                                            </div>
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_staffwell === '777' ? (
                                                        <li>Staff wellness</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_suedustud === '777' ? (
                                                        <li>Substance use education for students</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_suedustaff === '777' ? (
                                                        <li>Substance use education for school staff</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_suedufmly === '777' ? (
                                                        <li>Substance use education for caregivers/families</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_suprev === '777' ? (
                                                        <li>
                                                            <p>
                                                                {' '}
                                                                Substance use prevention (e.g. tobacco, opioid, alcohol)
                                                            </p>
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_datashare === '777' ? (
                                                        <li>Data collection/sharing</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_telemhlth === '777' ? (
                                                        <li>Telemedicine/telemental health</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {domain3.mental_health_profile_coverage === '777' ? (
                                                        <li>
                                                            <div>
                                                                {' '}
                                                                Health care coverage for school mental health services
                                                                (e.g. Medicaid, CHIP, private insurance)
                                                            </div>
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* // end unkn box */}
                                <div className={style.endBlueBox}>
                                    {stateTitle ? stateTitle : ''} may want to consider developing policies identified
                                    as not currently in place and to further explore policies identified as unknown.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 6 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
