import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

export default class DomainsDropdown extends React.Component {
    static propTypes = {
        label: PropTypes.node,
        field: PropTypes.object,
        options: PropTypes.array,
        onDomainsChange: PropTypes.func,
    };

    componentDidUpdate() {
        this.cleanField();
    }

    cleanField = () => {
        const { field, options } = this.props;
        const optionIds = options.map(option => option.id);
        let cleanedValue = [];
        if (field.value) {
            cleanedValue = field.value.filter(id => optionIds.includes(id));
        }

        if (cleanedValue.length !== field.value.length) {
            field.onChange(cleanedValue);
        }
    };

    onCheckBoxChange = e => {
        const { field } = this.props;
        const id = Number(e.target.value);
        const checked = e.target.checked;
        if (checked) {
            field.onChange([...field.value, id]);
        } else {
            field.onChange(field.value.filter(item => item !== id));
        }
        this.props.onDomainsChange();
    };

    onCheckAllBoxChange = e => {
        const checked = e.target.checked;

        if (checked) {
            this.props.field.onChange(this.props.options.map(item => item.id));
        } else {
            this.props.field.onChange([]);
        }

        this.props.onDomainsChange();
    };

    getName = () => {
        const { label, field, options } = this.props;

        if (options.length === 0) {
            return 'No domains found';
        }

        if (field.value.length === 0) {
            return `Select ${label}`;
        }

        if (field.value.length === 1) {
            const option = options.find(item => item.id === field.value[0]);
            if (option) {
                return option.name;
            }
        }

        return `${field.value.length} ${label}s`;
    };

    checkAllChecked = () => {
        const { field, options } = this.props;
        const optionIds = options.map(option => option.id);

        return field.value.length === optionIds.length;
    };

    render() {
        const { field, label, options } = this.props;
        // console.log('domains options', options);
        return (
            <div>
                <div className="dropdown">
                    <button
                        type="button"
                        className={`btn btn-default dropdown-toggle ${style.button}`}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                    >
                        <Tooltip title={this.getName()} placement="top">
                            <div className={style.buttonText}>
                                {this.getName()} <span className="caret" />
                            </div>
                        </Tooltip>
                    </button>

                    <ul
                        className="dropdown-menu"
                        style={{ padding: '0.5em', overflow: 'auto', width: '400px' }}
                    >
                        {options.length === 0 ? (
                            <li>No domains found</li>
                        ) : (
                            <form>
                                <li>
                                    <label className="dropdown-item">
                                        <div className={style.listItem}>
                                            <input
                                                type="checkbox"
                                                name="select-all"
                                                value="select-all"
                                                checked={this.checkAllChecked()}
                                                onChange={this.onCheckAllBoxChange}
                                            />
                                            <div>{`All ${label}s`}</div>
                                        </div>
                                    </label>
                                </li>
                                <li role="separator" className="divider" />
                                {options.map(option => (
                                    <li key={option.id}>
                                        <label className="dropdown-item">
                                            <div className={style.listItem}>
                                                <input
                                                    type="checkbox"
                                                    name={option.name}
                                                    value={option.id}
                                                    checked={field.value.includes(option.id)}
                                                    onChange={this.onCheckBoxChange}
                                                />
                                                <div>{option.name}</div>
                                            </div>
                                        </label>
                                    </li>
                                ))}
                            </form>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}
