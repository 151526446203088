import React from 'react';
// import IconEmail from 'cccisd-icons/envelop3';

export default settings => {
    return ({ value, row }) => {
        return (
            <div>
                <a href={`mailto:${value}`}>{value}</a>
            </div>
        );
    };
};
