export const familyEngagement = {
    activities: {
        'Teaming, Needs Assessment & Resource Mapping, and Screening': [
            'GetInvolved',
            'Participate',
            'Meetings',
            'Options',
            'Perspectives',
            'Feedback',
        ],
        'Tier 1': [
            'PositiveRel',
            'CommunicationLang',
            'PositiveComm',
            'DiffCultures',
            'Trainings',
            'Input',
            'ActiveInvolv',
        ],
        'Tier 2/3': ['Evaluate', 'Consistency'],
        Impact: ['ShareData', 'InvolveCaregivers', 'Collaborate'],
        Funding: ['Fundraising', 'SecureFunding', 'UseFunding'],
    },
    caregiver: {
        Training: ['TeachingSkills', 'ProvideTraining', 'OfferTraining', 'TeachingStudents'],
        'Services and Supports': [
            'Organizing',
            'CheckingIn',
            'SharingInfo',
            'UsingPrograms',
            'AvailableProfs',
            'ProfsComm',
            'Input',
            'ServicesRespect',
            'Accomodations',
        ],
        Evaluation: ['MonitoringStaff', 'MonitoringStudents', 'Transparent'],
        Funding: ['OrganizeEvents', 'SecureFunding'],
    },
};
