import React from 'react';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import ReportModal from './ReportModal';

export default settings => {
    return ({ value, row }) => {
        // console.log('table value', value, 'table row', row);
        // console.log('settings', settings);
        const isDisabled = () => {
            if (settings.flowType === 'mhpReportSchool' || settings.flowType === 'mhpReportDistrict') {
                if (!row[settings.sharing]) {
                    return true;
                }
                return !row['fields.starStatus'];
            }
            if (settings.flowType === 'mhqSchool' || settings.flowType === 'mhqDistrict') {
                if (!row[settings.sharing]) {
                    return true;
                }
                return !row['fields.pendingStarStatus'];
            }
            if (settings.flowType === 'trs') {
                if (!row[settings.sharing]) {
                    return true;
                }
                return !row['fields.traumaData'];
            }
            if (['owbiSchool', 'owbiDistrict'].includes(settings.flowType)) {
                if (!row[settings.sharing]) {
                    return true;
                }
                return !row['fields.owbiData'];
            }
            if (['careSchool', 'careDistrict'].includes(settings.flowType)) {
                if (!row[settings.sharing]) {
                    return true;
                }

                let allUsers = [...row[settings.adminList], ...row[settings.tmList]];

                let careAdminCount = [];
                careAdminCount = allUsers.filter(admin => {
                    return admin.careCount;
                });

                return careAdminCount.length === 0;
            }
            if (['familyEngagementSchool', 'familyEngagementDistrict'].includes(settings.flowType)) {
                if (!row[settings.sharing]) {
                    return true;
                }

                let allUsers = [...row[settings.adminList], ...row[settings.tmList]];

                let familyEngagementAdminCount = [];
                familyEngagementAdminCount = allUsers.filter(admin => {
                    return admin.familyEngagementCount;
                });

                return familyEngagementAdminCount.length === 0;
            }
        };
        return (
            <div>
                {row[settings.sharing] && (
                    <>
                        {isDisabled() ? (
                            <Tooltip title="No data entered" placement="top">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-xs"
                                    style={{ width: '70px' }}
                                    disabled={isDisabled()}
                                >
                                    View
                                </button>
                            </Tooltip>
                        ) : (
                            <Modal
                                trigger={
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-xs"
                                        style={{ width: '70px' }}
                                        disabled={isDisabled()}
                                    >
                                        View
                                    </button>
                                }
                                title={settings.title + ' - ' + row['group.label']}
                                size="72vw"
                            >
                                <ReportModal
                                    flowType={settings.flowType}
                                    groupId={row['group.groupId']}
                                    groupStartDate={row['fields.reportingPeriodStart']}
                                    groupEndDate={row['fields.reportingPeriodEnd']}
                                    groupName={row['group.label']}
                                    groupReportingPeriods={row['fields.reportingPeriods']}
                                />
                            </Modal>
                        )}
                    </>
                )}
            </div>
        );
    };
};
