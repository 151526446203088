import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import SubHeading from '../SubHeading';
import {
    TRSscoring,
    MHQscoringDistrict,
    MHQscoringSchool,
    MHQscoringPreschool,
    MHQscoringEntity,
    OWBIscoringSchool,
    OWBIscoringDistrict,
    OWBIscoringEntity,
    CAREscoringSchool,
    CAREscoringDistrict,
    CAREscoringEntity,
    FAMILYENGAGEMENTscoringSchool,
    FAMILYENGAGEMENTscoringEntity,
    FAMILYENGAGEMENTscoringDistrict,
} from 'js/selectors/scoringData.js';
import { getSavedParams } from 'js/reducers/params.js';
import { mhqFields } from 'js/reducers/siteFields/mhqFields.js';
import { trsFields } from 'js/reducers/siteFields/trsFields.js';
import { preschoolFields } from 'js/reducers/siteFields/preschoolFields.js';
import {
    school as owbiSchoolReportFields,
    district as owbiDistrictReportFields,
    entity as owbiEntityReportFields,
} from 'js/reducers/siteFields/owbiFields.js';
import {
    careSchool as careSchoolReportFields,
    careDistrict as careDistrictReportFields,
    careEntity as careEntityReportFields,
} from 'js/reducers/siteFields/careFields.js';
import {
    familyEngagementSchool as familyEngagementSchoolReportFields,
    familyEngagementDistrict as familyEngagementDistrictReportFields,
    familyEngagementEntity as familyEngagementEntityReportFields,
} from 'js/reducers/siteFields/familyEngagementFields.js';
import style from './style.css';

import _find from 'lodash/find';
import _mean from 'lodash/mean';
import _round from 'lodash/round';

const mapStateToProps = state => {
    return {
        mhqFields: state.app.params.mhqFields,
        trsFields: state.app.params.trsFields,
        owbiSchoolFields: state.app.params.owbiSchoolFields,
        owbiDistrictFields: state.app.params.owbiDistrictFields,
        owbiEntityFields: state.app.params.owbiEntityFields,
        preschoolFields: state.app.params.preschoolFields,
        careSchoolFields: state.app.params.careSchoolFields,
        careDistrictFields: state.app.params.careDistrictFields,
        careEntityFields: state.app.params.careEntityFields,
        familyEngagementSchoolFields: state.app.params.familyEngagementSchoolFields,
        familyEngagementDistrictFields: state.app.params.familyEngagementDistrictFields,
        familyEngagementEntityFields: state.app.params.familyEngagementEntityFields,
    };
};

export default connect(mapStateToProps, { getSavedParams })(
    class CoverPage extends React.Component {
        static propTypes = {
            reportLength: PropTypes.number,
            title: PropTypes.string,
            getEnteredBy: PropTypes.func,
            domains: PropTypes.array,
            groupName: PropTypes.string,
            data: PropTypes.object,
            flowType: PropTypes.string,
            mhqFields: PropTypes.object,
            trsFields: PropTypes.object,
            owbiSchoolFields: PropTypes.object,
            owbiDistrictFields: PropTypes.object,
            owbiEntityFields: PropTypes.object,
            preschoolFields: PropTypes.object,
            careSchoolFields: PropTypes.object,
            careDistrictFields: PropTypes.object,
            careEntityFields: PropTypes.object,
            familyEngagementSchoolFields: PropTypes.object,
            familyEngagementEntityFields: PropTypes.object,
            familyEngagementDistrictFields: PropTypes.object,
            getSavedParams: PropTypes.func,
            pageTitle: PropTypes.string,
            isAggregateReport: PropTypes.bool,
            selectedDates: PropTypes.object,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            const { flowType } = this.props;
            if (flowType === 'trs') {
                if (!this.props.trsFields) {
                    await this.props.getSavedParams(trsFields, 'trs');
                }
            }
            if (['mhq', 'mhqSchool', 'mhqDistrict', 'mhqEntity'].includes(flowType)) {
                if (!this.props.mhqFields) {
                    await this.props.getSavedParams(mhqFields, 'mhq');
                }
            }
            if (flowType === 'mhqPreschool') {
                if (!this.props.preschoolFields) {
                    await this.props.getSavedParams(preschoolFields, 'preschool');
                }
            }
            if (flowType === 'owbiSchool') {
                if (!this.props.owbiSchoolFields) {
                    await this.props.getSavedParams(owbiSchoolReportFields, 'owbiSchool');
                }
            }
            if (flowType === 'owbiDistrict') {
                if (!this.props.owbiDistrictFields) {
                    await this.props.getSavedParams(owbiDistrictReportFields, 'owbiDistrict');
                }
            }
            if (flowType === 'owbiEntity') {
                if (!this.props.owbiEntityFields) {
                    await this.props.getSavedParams(owbiEntityReportFields, 'owbiEntity');
                }
            }
            if (flowType === 'careSchool') {
                if (!this.props.careSchoolFields) {
                    await this.props.getSavedParams(careSchoolReportFields, 'careSchool');
                }
            }
            if (flowType === 'careDistrict') {
                if (!this.props.careDistrictFields) {
                    await this.props.getSavedParams(careDistrictReportFields, 'careDistrict');
                }
            }
            if (flowType === 'careEntity') {
                if (!this.props.careEntityFields) {
                    await this.props.getSavedParams(careEntityReportFields, 'careEntity');
                }
            }
            if (flowType === 'familyEngagementSchool') {
                if (!this.props.familyEngagementSchoolFields) {
                    await this.props.getSavedParams(familyEngagementSchoolReportFields, 'familyEngagementSchool');
                }
            }
            if (flowType === 'familyEngagementEntity') {
                if (!this.props.familyEngagementEntityFields) {
                    await this.props.getSavedParams(familyEngagementEntityReportFields, 'familyEngagementEntity');
                }
            }
            if (flowType === 'familyEngagementDistrict') {
                if (!this.props.familyEngagementDistrictFields) {
                    await this.props.getSavedParams(familyEngagementDistrictReportFields, 'familyEngagementDistrict');
                }
            }
            this.setState({ loading: false });
        };

        getScreeningIndicatorScores = indicatorHandle => {
            const { data, flowType } = this.props;
            let indicatorScores = { score: NaN, title: 'N/A' };

            if (data.totalScores) {
                if (flowType === 'mhqDistrict') {
                    indicatorScores = data.totalScores.find(
                        item => item.handle === 'district_quality_screening'
                    ).indicators;
                }
                if (flowType === 'mhqSchool') {
                    indicatorScores = data.totalScores.find(
                        item => item.handle === 'school_quality_screening'
                    ).indicators;
                }
                if (flowType === 'mhqPreschool') {
                    indicatorScores = data.totalScores.find(
                        item => item.handle === 'pre_mental_health_screening'
                    ).indicators;
                }
                if (flowType === 'mhqEntity') {
                    indicatorScores = data.totalScores.find(
                        item => item.handle === 'entity_quality_screening'
                    ).indicators;
                }
                const indicatorScore = indicatorScores.find(item => item.handle === indicatorHandle);
                if (indicatorScore) {
                    return indicatorScore.score;
                }
            }
            return NaN;
        };

        getSortedFlowScores = () => {
            const masteryScores = [];
            const progressingScores = [];
            const emergingScores = [];
            const notEstablishedScores = [];

            const { domains, data, flowType } = this.props;

            let scoringData;
            if (flowType === 'trs') {
                scoringData = TRSscoring;
            }
            if (flowType === 'mhqDistrict') {
                scoringData = MHQscoringDistrict;
            }
            if (flowType === 'mhqSchool') {
                scoringData = MHQscoringSchool;
            }
            if (flowType === 'mhqPreschool') {
                scoringData = MHQscoringPreschool;
            }
            if (flowType === 'mhqEntity') {
                scoringData = MHQscoringEntity;
            }
            if (flowType === 'owbiSchool') {
                scoringData = OWBIscoringSchool;
            }
            if (flowType === 'owbiDistrict') {
                scoringData = OWBIscoringDistrict;
            }
            if (flowType === 'owbiEntity') {
                scoringData = OWBIscoringEntity;
            }
            if (flowType === 'careSchool') {
                scoringData = CAREscoringSchool;
            }
            if (flowType === 'careDistrict') {
                scoringData = CAREscoringDistrict;
            }
            if (flowType === 'careEntity') {
                scoringData = CAREscoringEntity;
            }
            if (flowType === 'familyEngagementSchool') {
                scoringData = FAMILYENGAGEMENTscoringSchool;
            }
            if (flowType === 'familyEngagementDistrict') {
                scoringData = FAMILYENGAGEMENTscoringDistrict;
            }
            if (flowType === 'familyEngagementEntity') {
                scoringData = FAMILYENGAGEMENTscoringEntity;
            }

            const domainsMinusScreening =
                (flowType === 'mhqPreschool' && domains.filter(id => id !== 6)) || domains.filter(id => id !== 3);

            domainsMinusScreening
                .map(id => {
                    const domainInfo = _find(scoringData, { id });
                    const scoreInfo = data.totalScores.find(item => item.handle === domainInfo.handle);
                    const score = scoreInfo ? scoreInfo.score : '*';
                    return { score, title: domainInfo.title, handle: domainInfo.handle };
                })
                .forEach(s => {
                    const score = parseInt(s.score, 10);
                    if (['trs', 'owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                        if (score >= 3) {
                            masteryScores.push(s);
                            return;
                        }
                        if (score >= 2 && score < 4) {
                            progressingScores.push(s);
                            return;
                        }
                        emergingScores.push(s);
                    } else if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                        if (score >= 5) {
                            masteryScores.push(s);
                            return;
                        }
                        if (score >= 3 && score < 5) {
                            progressingScores.push(s);
                            return;
                        }
                        if (score >= 2 && score < 3) {
                            emergingScores.push(s);
                            return;
                        }
                        notEstablishedScores.push(s);
                    } else if (
                        ['familyEngagementSchool', 'familyEngagementDistrict', 'familyEngagementEntity'].includes(
                            flowType
                        )
                    ) {
                        if (score >= 5) {
                            masteryScores.push(s);
                            return;
                        }
                        if (score >= 3 && score < 5) {
                            progressingScores.push(s);
                            return;
                        }
                        if (score >= 2 && score < 3) {
                            emergingScores.push(s);
                            return;
                        }
                        notEstablishedScores.push(s);
                    } else {
                        if (score >= 5) {
                            masteryScores.push(s);
                            return;
                        }
                        if (score >= 3 && score < 5) {
                            progressingScores.push(s);
                            return;
                        }
                        emergingScores.push(s);
                    }
                });

            // District Support Scores
            if (['mhqDistrict', 'mhqEntity'].includes(flowType)) {
                let districtSupportScores = [];
                domainsMinusScreening
                    .map(id => {
                        const domainInfo = _find(scoringData, { id });
                        const scoreInfo = data.totalScores.find(item => item.handle === domainInfo.handle);
                        const score = scoreInfo ? scoreInfo.districtScore : '*';
                        const title =
                            flowType === 'mhqEntity'
                                ? domainInfo.title + ' Entity Support'
                                : domainInfo.title + ' District Support';
                        return { score, title, handle: domainInfo.handle };
                    })
                    .forEach(s => {
                        districtSupportScores.push(Number(s.score));

                        if (s.districtReportingScore) {
                            const districtReportingScore = parseInt(s.districtReportingScore, 10);
                            if (districtReportingScore >= 5) {
                                masteryScores.push(s);
                                return;
                            }
                            if (districtReportingScore >= 3 && districtReportingScore < 5) {
                                progressingScores.push(s);
                                return;
                            }
                            emergingScores.push(s);
                        }
                    });
                let districtScreening;
                if (flowType === 'mhqDistrict') {
                    districtScreening = data.totalScores.find(d => d.handle === 'district_quality_screening');
                }
                if (flowType === 'mhqEntity') {
                    districtScreening = data.totalScores.find(d => d.handle === 'entity_quality_screening');
                }
                if (districtScreening) {
                    districtSupportScores.push(Number(districtScreening.districtScore));
                }
                const sanitizedScores = districtSupportScores.filter(Boolean);
                const avgDistrictSupportScore = {
                    score: _round(_mean(sanitizedScores), 1),
                    title:
                        flowType === 'mhqEntity'
                            ? 'Entity Implementation Support Quality'
                            : 'District Implementation Support Quality',
                    handle: 'district_support_average',
                };

                const dsScore = parseInt(avgDistrictSupportScore.score, 10);
                if (dsScore >= 5) {
                    masteryScores.push(avgDistrictSupportScore);
                } else if (dsScore >= 3 && dsScore < 5) {
                    progressingScores.push(avgDistrictSupportScore);
                } else {
                    emergingScores.push(avgDistrictSupportScore);
                }

                domainsMinusScreening
                    .map(id => {
                        const domainInfo = _find(scoringData, { id });
                        const scoreInfo = data.totalScores.find(item => item.handle === domainInfo.handle);
                        const score = ['district_quality_impact', 'entity_quality_impact'].includes(scoreInfo.handle)
                            ? scoreInfo.districtReportingScore
                            : null;

                        const title =
                            flowType === 'mhqEntity'
                                ? domainInfo.title + ' Entity-Level Documenting and Reporting'
                                : domainInfo.title + ' District-Level Documenting and Reporting';
                        return { score, title, handle: domainInfo.handle };
                    })
                    .forEach(s => {
                        const score = parseInt(s.score, 10);
                        if (!Number.isNaN(score)) {
                            if (score >= 5) {
                                masteryScores.push(s);
                                return;
                            }
                            if (score >= 3 && score < 5) {
                                progressingScores.push(s);
                                return;
                            }
                            emergingScores.push(s);
                        }
                    });
            }

            return { masteryScores, progressingScores, emergingScores, notEstablishedScores };
        };

        setSummaryText = () => {
            const { flowType } = this.props;
            let summaryText;
            if (flowType === 'trs') {
                summaryText = this.props.trsFields.summaryReportIntro;
            }
            if (['mhq', 'mhqSchool', 'mhqDistrict', 'mhqEntity'].includes(flowType)) {
                summaryText = this.props.mhqFields.summaryReportIntro;
            }
            if (flowType === 'mhqPreschool') {
                summaryText = this.props.preschoolFields.summaryReportIntro;
            }
            if (flowType === 'owbiSchool') {
                summaryText = this.props.owbiSchoolFields.summaryReportIntroSchool;
            }
            if (flowType === 'owbiDistrict') {
                summaryText = this.props.owbiDistrictFields.summaryReportIntroDistrict;
            }
            if (flowType === 'owbiEntity') {
                summaryText = this.props.owbiEntityFields.summaryReportIntroEntity;
            }
            if (flowType === 'careSchool') {
                summaryText = this.props.careSchoolFields.summaryReportIntroSchool;
            }
            if (flowType === 'careDistrict') {
                summaryText = this.props.careDistrictFields.summaryReportIntroDistrict;
            }
            if (flowType === 'careEntity') {
                summaryText = this.props.careEntityFields.summaryReportIntroEntity;
            }
            if (flowType === 'familyEngagementSchool') {
                summaryText = this.props.familyEngagementSchoolFields.summaryReportIntroSchool;
            }
            if (flowType === 'familyEngagementEntity') {
                summaryText = this.props.familyEngagementEntityFields.summaryReportIntroEntity;
            }
            if (flowType === 'familyEngagementDistrict') {
                summaryText = this.props.familyEngagementDistrictFields.summaryReportIntroDistrict;
            }
            return summaryText;
        };

        // Additional text is intended to be shown on the bottom of the cover page.
        // Any summary text that doesn't fit along the left side of the cover page.
        // Since the cover page scoring elements are variable height, this text placed below can overflow onto the next page.
        setAdditionalSummaryText = () => {
            const { flowType } = this.props;
            let additionalSummaryText;
            if (flowType === 'careSchool') {
                additionalSummaryText = this.props.careSchoolFields.summaryReportAdditionalText;
            }
            if (flowType === 'careDistrict') {
                additionalSummaryText = this.props.careDistrictFields.summaryReportAdditionalText;
            }
            if (flowType === 'careEntity') {
                additionalSummaryText = this.props.careEntityFields.summaryReportAdditionalText;
            }
            if (flowType === 'familyEngagementSchool') {
                additionalSummaryText = this.props.familyEngagementSchoolFields.summaryReportAdditionalText;
            }
            if (flowType === 'familyEngagementEntity') {
                additionalSummaryText = this.props.familyEngagementEntityFields.summaryReportAdditionalText;
            }
            if (flowType === 'familyEngagementDistrict') {
                additionalSummaryText = this.props.familyEngagementDistrictFields.summaryReportAdditionalText;
            }
            return additionalSummaryText;
        };

        getLogo = () => {
            const { flowType } = this.props;
            if (['owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                return style.logoOWBI;
            }
            if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                return style.logoCARE;
            }
            if (['familyEngagementSchool', 'familyEngagementDistrict', 'familyEngagementEntity'].includes(flowType)) {
                return style.logoFE;
            }
            if (flowType === 'trs') {
                return style.logoTRS;
            }

            return style.logo;
        };

        getMasteryTitle = () => {
            const { flowType } = this.props;

            if (['owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                return 'PROFICIENT';
            }

            if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                return 'ADVANCING';
            }

            return 'MASTERY';
        };

        render() {
            const { masteryScores, progressingScores, emergingScores, notEstablishedScores } =
                this.getSortedFlowScores();
            const { reportLength, getEnteredBy, flowType, domains } = this.props;

            let screeningScore = '*';
            const summaryText = this.setSummaryText();
            const additionalSummaryText = this.setAdditionalSummaryText();
            let shapeLevelType = flowType === 'mhqPreschool' ? 'Preschool' : 'School';
            if (flowType.toLowerCase().includes('district')) {
                shapeLevelType = 'District';
            }
            if (flowType.toLowerCase().includes('entity')) {
                shapeLevelType = 'Entity';
            }
            if (
                (flowType !== 'mhqPreschool' && domains.indexOf(3) !== -1) ||
                (flowType === 'mhqPreschool' && domains.indexOf(6) !== -1)
            ) {
                if (
                    !Number.isNaN(
                        this.getScreeningIndicatorScores('Screened') / this.getScreeningIndicatorScores('Enrolled')
                    ) &&
                    this.getScreeningIndicatorScores('Screened')
                ) {
                    screeningScore =
                        _round(
                            (this.getScreeningIndicatorScores('Screened') /
                                this.getScreeningIndicatorScores('Enrolled')) *
                                100,
                            0
                        ) + '%';
                }
            }
            return (
                <Page>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo}>
                                <div className={style.triangle} />
                                <div className={this.getLogo()} />
                            </div>
                            <div className={style.headerTitle}>
                                {this.props.isAggregateReport ? (
                                    this.props.pageTitle
                                ) : (
                                    <>
                                        {shapeLevelType} {this.props.title} Progress Report
                                    </>
                                )}

                                <div className={style.headerSubtitle}>{this.props.groupName}</div>
                            </div>
                        </div>
                        <SubHeading getEnteredBy={getEnteredBy} selectedDates={this.props.selectedDates} />
                        <div className={style.bodyContent}>
                            <div className={style.topContent}>
                                <div className={style.contentLeft}>
                                    <h4>Understanding this Summary</h4>
                                    <Html content={summaryText} />
                                </div>
                                <div className={style.contentRight}>
                                    <h4>{this.props.title.toUpperCase()} DOMAINS</h4>
                                    <div
                                        className={style.scoreBox}
                                        style={{
                                            borderColor: '#308b85',
                                            color: '#308b85',
                                        }}
                                    >
                                        <div
                                            className={style.scoreBoxBadge}
                                            style={{
                                                background: '#308b85',
                                            }}
                                        >
                                            <span>{this.getMasteryTitle()}</span>
                                        </div>
                                        <p style={{ marginLeft: '1em', fontStyle: 'italic' }}>Composite Score</p>
                                        {masteryScores.map(s => {
                                            return (
                                                <div style={{ marginLeft: '4em' }} key={s.title}>
                                                    <div className={style.scoreDisplay}>
                                                        <div className={style.score}>
                                                            {Number.isNaN(parseFloat(s.score)) ? '*' : s.score} ....{' '}
                                                        </div>
                                                        <div>
                                                            <span
                                                                style={{
                                                                    color: '#333',
                                                                    display: 'block',
                                                                }}
                                                            >
                                                                {s.title}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div
                                        className={style.scoreBox}
                                        style={{
                                            borderColor: '#fcbd2b',
                                            color: '#fcbd2b',
                                        }}
                                    >
                                        <div
                                            className={style.scoreBoxBadge}
                                            style={{
                                                background: '#fcbd2b',
                                                maxWidth: '138px',
                                            }}
                                        >
                                            <span>PROGRESSING</span>
                                        </div>
                                        <p style={{ marginLeft: '1em', fontStyle: 'italic' }}>Composite Score</p>
                                        {progressingScores.map(s => {
                                            return (
                                                <div style={{ marginLeft: '4em' }} key={s.title}>
                                                    <div className={style.scoreDisplay}>
                                                        <div className={style.score}>
                                                            {Number.isNaN(parseFloat(s.score)) ? '*' : s.score} ....{' '}
                                                        </div>

                                                        <div>
                                                            <span style={{ color: '#333' }}>{s.title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div
                                        className={style.scoreBox}
                                        style={{
                                            borderColor: '#a94442',
                                            color: '#a94442',
                                        }}
                                    >
                                        <div
                                            className={style.scoreBoxBadge}
                                            style={{
                                                background: '#a94442',
                                            }}
                                        >
                                            <span>EMERGING</span>
                                        </div>
                                        <p style={{ marginLeft: '1em', fontStyle: 'italic' }}>Composite Score</p>
                                        {emergingScores.map(s => {
                                            return (
                                                <div style={{ marginLeft: '4em' }} key={s.title}>
                                                    <div className={style.scoreDisplay}>
                                                        <div className={style.score}>
                                                            {Number.isNaN(parseFloat(s.score)) ? '*' : s.score} ....{' '}
                                                        </div>
                                                        <div>
                                                            <span style={{ color: '#333' }}>{s.title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {[
                                        'careSchool',
                                        'careDistrict',
                                        'careEntity',
                                        'familyEngagementSchool',
                                        'familyEngagementDistrict',
                                        'familyEngagementEntity',
                                    ].includes(flowType) && (
                                        <div
                                            className={style.scoreBox}
                                            style={{
                                                borderColor: '#999',
                                                color: '#999',
                                            }}
                                        >
                                            <div
                                                className={style.scoreBoxBadge}
                                                style={{
                                                    background: '#999',
                                                    maxWidth: '160px',
                                                }}
                                            >
                                                <span>NOT ESTABLISHED</span>
                                            </div>
                                            <p style={{ marginLeft: '1em', fontStyle: 'italic' }}>Composite Score</p>
                                            {notEstablishedScores.map(s => {
                                                return (
                                                    <div style={{ marginLeft: '4em' }} key={s.title}>
                                                        <div className={style.scoreDisplay}>
                                                            <div className={style.score}>
                                                                {Number.isNaN(parseFloat(s.score)) ? '*' : s.score} ....{' '}
                                                            </div>
                                                            <div>
                                                                <span style={{ color: '#333' }}>{s.title}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                    {/* This is where figures for Screening and other non-scored assessments go */}
                                    {![
                                        'owbiSchool',
                                        'owbiDistrict',
                                        'owbiEntity',
                                        'trs',
                                        'mhqPreschool',
                                        'careSchool',
                                        'careDistrict',
                                        'careEntity',
                                        'familyEngagementSchool',
                                        'familyEngagementEntity',
                                        'familyEngagementDistrict',
                                    ].includes(flowType) && (
                                        <>
                                            <div
                                                className={style.scoreBox}
                                                style={{
                                                    borderColor: '#154988',
                                                    color: '#154988',
                                                }}
                                            >
                                                <div
                                                    className={style.scoreBoxBadge}
                                                    style={{
                                                        background: '#154988',
                                                        maxWidth: '280px',
                                                    }}
                                                >
                                                    <span>OTHER PERFORMANCE DOMAINS</span>
                                                </div>
                                                <p
                                                    style={{
                                                        marginLeft: '1em',
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    Overall Score
                                                </p>
                                                {/* If screening is passed in */}
                                                {domains.indexOf(3) !== -1 && flowType === 'mhqSchool' && (
                                                    <div style={{ marginLeft: '4em' }}>
                                                        <div className={style.scoreDisplay}>
                                                            <div className={style.score}>{screeningScore} .... </div>
                                                            <div>
                                                                <span style={{ color: '#333' }}>Screening</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {domains.indexOf(3) !== -1 && flowType === 'mhqDistrict' && (
                                                    <>
                                                        <div style={{ marginLeft: '4em' }}>
                                                            <div className={style.scoreDisplay}>
                                                                <div className={style.score}>
                                                                    {screeningScore} ....{' '}
                                                                    <span
                                                                        style={{
                                                                            color: '#333',
                                                                        }}
                                                                    >
                                                                        Screening
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {domains.indexOf(3) !== -1 && flowType === 'mhqEntity' && (
                                                    <div style={{ marginLeft: '4em' }}>
                                                        <div className={style.scoreDisplay}>
                                                            <div className={style.score}>{screeningScore} .... </div>
                                                            <div>
                                                                <span style={{ color: '#333' }}>Screening</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <p
                                                    style={{
                                                        marginLeft: '1em',
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    <i>* indicates data were not reported</i>
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={style.bottomContent}>
                                <Html content={additionalSummaryText} />
                            </div>
                        </div>
                    </div>
                    <div className={style.footer}>
                        <div className={flowType === 'trs' ? style.footerTextTRS : style.footerText}>
                            <div>
                                This progress report was developed by the{' '}
                                {flowType === 'trs' && 'Treatment and Services Adaptation Center and the'} National
                                Center for School Mental Health for the SHAPE System.
                            </div>
                            <div>Page 1 of {reportLength}</div>
                        </div>
                        {flowType === 'trs' && <div className={style.footerLogoTRS} />}
                        <div className={style.footerLogo} />
                    </div>
                </Page>
            );
        }
    }
);
