import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import SubHeading from '../SubHeading';
import style from './style.css';

import GaugeGreen from '../images/GaugeGreen.png';
import GaugeYellow from '../images/GaugeYellow.png';
import GaugeRed from '../images/GaugeRed.png';

import _some from 'lodash/some';
import _filter from 'lodash/filter';
import _round from 'lodash/round';

const mapStateToProps = state => {
    return {
        preschoolFields: state.app.params.preschoolFields,
    };
};

export default connect(mapStateToProps)(
    class PreschoolScreening extends React.Component {
        static propTypes = {
            domainId: PropTypes.number,
            enteredBy: PropTypes.array,
            domainName: PropTypes.string,
            pageNumber: PropTypes.number,
            totalPages: PropTypes.number,
            preschoolFields: PropTypes.object,
            data: PropTypes.object,
            handle: PropTypes.string,
            flowType: PropTypes.string,
            reportType: PropTypes.string,
            getEnteredBy: PropTypes.func,
            selectedDates: PropTypes.object,
        };

        getIndicatorScores = indicatorHandle => {
            const { data, handle } = this.props;
            let indicatorScores = {
                score: NaN,
                title: 'N/A',
            };

            if (data.totalScores) {
                indicatorScores = data.totalScores.find(item => item.handle === handle).indicators;
                const indicatorScore = indicatorScores.find(
                    item => item.handle === indicatorHandle
                );
                if (!indicatorScore) {
                    return '0';
                }
                if (!indicatorScore.score || typeof indicatorScore.score === 'object') {
                    return '0';
                }

                return indicatorScore.score;
            }
            return NaN;
        };

        getGaugeImage = score => {
            if (score < 3) {
                return GaugeRed;
            }
            if (score >= 3 && score < 5) {
                return GaugeYellow;
            }
            return GaugeGreen;
        };

        getScoreColor = score => {
            if (score < 3) {
                return style.scoreRed;
            }
            if (score >= 3 && score < 5) {
                return style.scoreYellow;
            }
            return style.scoreGreen;
        };

        getScore = () => {
            const { data, handle } = this.props;
            const scoreInfo = data.totalScores.find(item => item.handle === handle);
            return scoreInfo
                ? {
                      score: scoreInfo.score,
                  }
                : '*';
        };

        getDataByHandle = () => {
            const { data, handle } = this.props;
            const scoreData = data.totalScores.find(item => item.handle === handle);
            const indicatorData = { preschool: {} };

            if (_some(scoreData.indicators, indicator => indicator.group === 'preschool')) {
                indicatorData.preschool = _filter(
                    scoreData.indicators,
                    indicator => indicator.group === 'preschool'
                );
            } else {
                indicatorData.school = scoreData
                    ? scoreData.indicators
                    : { score: NaN, title: 'N/A' };
            }

            return indicatorData;
        };

        render() {
            const { domainName, pageNumber, totalPages, preschoolFields, flowType, reportType } =
                this.props;

            if (Number.isNaN(this.getIndicatorScores('MHS2'))) {
                return null;
            }

            return (
                <>
                    <Page>
                        <div className={style.content}>
                            <div className={style.header}>
                                <div className={style.headerLogo}>
                                    <div className={style.triangle} />
                                    <div className={style.logo} />
                                </div>
                                <div className={style.headerTitle}>{domainName}</div>
                            </div>
                            <SubHeading
                                getEnteredBy={this.props.getEnteredBy}
                                selectedDates={this.props.selectedDates}
                            />
                            <div className={style.reportPage}>
                                <div style={{ marginBottom: '1em' }}>
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            color: '#0b72b6',
                                            lineHeight: '1.8',
                                        }}
                                    >
                                        What is {domainName}?
                                    </span>
                                    <div className={style.description}>
                                        {flowType === 'mhqPreschool' && (
                                            <Html
                                                content={preschoolFields.MHScreeningAboutPreschool}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={style.screeningBody}>
                                    <p>
                                        During the last school year,{' '}
                                        <strong>{this.getIndicatorScores('MHS2')}</strong> students
                                        in your school were screened for a mental health concern of
                                        any type by a school-based or community-partnered mental
                                        health staff member. This is{' '}
                                        <b>
                                            {Number.isNaN(
                                                this.getIndicatorScores('MHS2') /
                                                    this.getIndicatorScores('MHS1')
                                            )
                                                ? '0'
                                                : _round(
                                                      (this.getIndicatorScores('MHS2') /
                                                          this.getIndicatorScores('MHS1')) *
                                                          100,
                                                      0
                                                  )}
                                            %
                                        </b>{' '}
                                        of your student body.
                                    </p>
                                    <div
                                        style={{
                                            fontSize: '18px',
                                            color: '#0b72b6',
                                            lineHeight: '1.8',
                                        }}
                                    >
                                        Based on screening:
                                    </div>
                                    <div style={{ marginLeft: '1em' }}>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS3')}</b> identified as
                                            being at-risk or already experiencing a mental health
                                            problem
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS4')}</b> referred to a
                                            mental health service following identification
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS5')}</b> received a
                                            mental health service (in-person contact with a
                                            preschool or community mental health professional)
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6a1')}</b> received
                                            developmental delays screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6b1')}</b> received
                                            physical health screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6c1')}</b> received
                                            social/emotional competencies screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6d1')}</b> received
                                            self-regulation of mood and behavior screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6e1')}</b> received
                                            trauma screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6f1')}</b> received
                                            general mental health screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6g1')}</b> received
                                            well-being or protective factors screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MHS6other')}</b> received
                                            other mental health screening
                                        </p>
                                        {reportType === 'Last Completed' && (
                                            <>
                                                {!Number.isNaN(this.getIndicatorScores('MHS6h2')) &&
                                                    Number(this.getIndicatorScores('MHS6h2')) >
                                                        0 && (
                                                        <p>
                                                            <b>
                                                                {this.getIndicatorScores('MHS6h2')}
                                                            </b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('MHS6h1')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                                {!Number.isNaN(this.getIndicatorScores('MHS6i2')) &&
                                                    Number(this.getIndicatorScores('MHS6i2')) >
                                                        0 && (
                                                        <p>
                                                            <b>
                                                                {this.getIndicatorScores('MHS6i2')}
                                                            </b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('MHS6i1')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                                {!Number.isNaN(this.getIndicatorScores('MHS6j2')) &&
                                                    Number(this.getIndicatorScores('MHS6j2')) >
                                                        0 && (
                                                        <p>
                                                            <b>
                                                                {this.getIndicatorScores('MHS6j2')}
                                                            </b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('MHS6j1')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.footer}>
                            <div className={style.footerText}>
                                <div>
                                    This progress report was developed by the National Center for
                                    School Mental Health for the SHAPE System.
                                </div>
                                <div>
                                    Page {pageNumber} of {totalPages}
                                </div>
                            </div>
                            <div className={style.footerLogo} />
                        </div>
                    </Page>
                </>
            );
        }
    }
);
