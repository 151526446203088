import React from 'react';
import PropTypes from 'prop-types';
import NavBar from 'js/components/NavBar';

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        return <NavBar />;
    }
}
