import React from 'react';
import PropTypes from 'prop-types';
import SubHeader from './SubHeader';
import { Page } from 'cccisd-laravel-report';
import IconCheck from 'cccisd-icons/checkmark3';
import style from './style.css';
const Fortress = window.cccisd && window.cccisd.fortress;

export default class StudentsServedSchool extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain1: PropTypes.object,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain1 } = this.props;
        return (
            <Page>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo}>
                            <div className={style.triangle} />
                            <div className={style.logo} />
                        </div>
                        <div className={style.headerTitle}>
                            Students Served and Data Systems
                            <div className={style.headerSubtitle}>
                                {this.props.groupName
                                    ? this.props.groupName
                                    : Fortress.user.acting.group.label}
                            </div>
                        </div>
                    </div>
                    <SubHeader lastUpdated={this.props.lastUpdated} />
                    <div className={style.reportPage}>
                        <table className={style.reportTable}>
                            <tr>
                                <th
                                    rowSpan="2"
                                    style={{
                                        fontSize: '16px',
                                        backgroundColor: '#387ab7',
                                        color: '#fff',
                                    }}
                                >
                                    Services and Supports
                                </th>
                                <th colSpan="5" style={{ background: '#f1f1f1' }}>
                                    Availability
                                </th>
                            </tr>
                            <tr style={{ background: '#f1f1f1' }}>
                                <th>No</th>
                                <th>Yes</th>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Mental health screening
                                </td>
                                <td>
                                    {domain1.mental_health_profile_screening === '0' && (
                                        <IconCheck />
                                    )}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_screening === '1' && (
                                        <IconCheck />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Tier 1 services and supports
                                </td>
                                <td>
                                    {domain1.mental_health_profile_tier_1_services === '0' && (
                                        <IconCheck />
                                    )}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_tier_1_services === '1' && (
                                        <IconCheck />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Tier 2 services and supports
                                </td>
                                <td>
                                    {domain1.mental_health_profile_tier_2_services === '0' && (
                                        <IconCheck />
                                    )}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_tier_2_services === '1' && (
                                        <IconCheck />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Tier 3 services and supports
                                </td>
                                <td>
                                    {domain1.mental_health_profile_tier_3_services === '0' && (
                                        <IconCheck />
                                    )}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_tier_3_services === '1' && (
                                        <IconCheck />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Evidence-based practices and programs (As identified in national
                                    evidence-based registries)
                                </td>
                                <td>
                                    {domain1.mental_health_profile_evidence_based_practices ===
                                        '0' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_evidence_based_practices ===
                                        '1' && <IconCheck />}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Community partnerships to augment school mental health services
                                    and supports provided by the school system.
                                </td>
                                <td>
                                    {domain1.mental_health_profile_community_partnerships ===
                                        '0' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_community_partnerships ===
                                        '1' && <IconCheck />}
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Quality improvement processes to understand and improve the
                                    comprehensive school mental health system.
                                </td>
                                <td>
                                    {domain1.mental_health_profile_quality_improvement === '0' && (
                                        <IconCheck />
                                    )}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_quality_improvement === '1' && (
                                        <IconCheck />
                                    )}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        <div>
                            This profile was developed by the Center for School Mental Health for
                            The SHAPE System.
                        </div>
                        <div>Page 2 of {this.props.totalPages}</div>
                    </div>
                    <div className={style.footerLogo} />
                </div>
            </Page>
        );
    }
}
