import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import funding1 from '../../../images/funding1.png';
import funding2 from '../../../images/funding2.png';
import funding3 from '../../../images/funding3.png';

const marginOffset = 40;

export default class Funding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            greenImageHeight: '200px',
            yellowImageHeight: '200px',
            redImageHeight: '200px',
        };

        this.greenDivRef = React.createRef();
        this.yellowDivRef = React.createRef();
        this.redDivRef = React.createRef();
    }

    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain4: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    setDivHeight = () => {
        const divHeight = this.greenDivRef.current.offsetHeight;
        const yellowDivHeight = this.yellowDivRef.current.offsetHeight;
        const redDivHeight = this.redDivRef.current.offsetHeight;

        const greenImageHeight = divHeight + marginOffset + 'px';
        const yellowImageHeight = yellowDivHeight + marginOffset + 'px';
        const redImageHeight = redDivHeight + marginOffset + 'px';

        this.setState({ greenImageHeight, yellowImageHeight, redImageHeight });
    };

    render() {
        const { domain4, stateTitle } = this.props;

        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content_fundingPage}>
                        <div className={style.headerFunding}>
                            <div className={style.headerLogo_appendixPage}>
                                <div className={style.triangle_appendixPage} />
                            </div>
                            <div className={style.title_appendixPage}> Funding </div>
                        </div>
                        <div className={style.reportPagefundingPage}>
                            <div className={style.bodyTopState}>
                                <div className={style.firstHeading}>
                                    <p className={style.firstHeadingContent}>
                                        The following funding sources support comprehensive school mental health
                                        promotion, prevention, and intervention in {stateTitle ? stateTitle : ''}:
                                    </p>
                                </div>
                            </div>
                            <div className={style.bigDiv}>
                                <div className={style.green1stDiv}>
                                    <div className={style.rightGreenOne}>
                                        <img
                                            className={style.greenOneImage}
                                            src={funding1}
                                            alt="polic_reg"
                                            onLoad={this.setDivHeight}
                                            style={{
                                                height: this.state.greenImageHeight,
                                                minHeight: '200px',
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={style.rightgreentext}
                                        ref={this.greenDivRef}
                                        style={{
                                            marginTop: marginOffset + marginOffset + 'px',
                                            minHeight: '150px',
                                        }}
                                    >
                                        <span className={style.rightgreentextpara}>
                                            <span className={style.rightHeading}>Primary</span>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(20% or more of total funding comes from each
                                            of the following):
                                        </span>
                                        <ul className={style.unorderListGreen}>
                                            {domain4.mental_health_profile_dstrctbdgt === '1' ? (
                                                <li>Local school district budgets</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other1 === '1' ? (
                                                <li>{domain4.mental_health_profile_d4_other1_text}</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other2 === '1' ? (
                                                <li>{domain4.mental_health_profile_d4_other2_text}</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_steduaid === '1' ? (
                                                <li>State education aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_stmhaid === '1' ? (
                                                <li>State mental health aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_feddiscgrant === '1' ? (
                                                <li>
                                                    Federal discretionary grants (e.g. Project AWARE, Systems of Care)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedformfund === '1' ? (
                                                <li>Federal formula funds (e.g. ESSA Title I, ESSA Title IV-A)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedideafund === '1' ? (
                                                <li>Federal IDEA funds (special education)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedblckgrant === '1' ? (
                                                <li>
                                                    Federal block grant funds (e.g. Community Mental Health Block Grant)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_medicaid === '1' ? (
                                                <li>Billing Medicaid/CHIP</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privinsur === '1' ? (
                                                <li>Students&apos;/family private insurance</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_selfpay === '1' ? (
                                                <li>Self-pay for community-partnered SMH services</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privcmmntyfund === '1' ? (
                                                <li>Private/community foundation funding</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_d4_otheragncy === '1' ? (
                                                <li>Funding from other systems/agencies</li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className={style.green1stDiv2}>
                                    <div className={style.rightGreenOne2}>
                                        <img
                                            className={style.greenOneImage2}
                                            src={funding2}
                                            alt="polic_reg"
                                            style={{
                                                height: this.state.yellowImageHeight,
                                                minHeight: '200px',
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={style.rightgreentext2}
                                        ref={this.yellowDivRef}
                                        style={{
                                            marginTop: marginOffset + 'px',
                                            minHeight: '150px',
                                        }}
                                    >
                                        <span className={style.rightgreentextpara2}>
                                            <span className={style.rightHeading2}>Secondary</span>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(less than 20% of total funding comes from
                                            each of the following):
                                        </span>
                                        <ul className={style.unorderListGreen2}>
                                            {domain4.mental_health_profile_dstrctbdgt === '2' ? (
                                                <li>Local school district budgets</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other1 === '2' ? (
                                                <li>{domain4.mental_health_profile_d4_other1_text}</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other2 === '2' ? (
                                                <li>{domain4.mental_health_profile_d4_other2_text}</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_steduaid === '2' ? (
                                                <li>State education aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_stmhaid === '2' ? (
                                                <li>State mental health aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_feddiscgrant === '2' ? (
                                                <li>
                                                    Federal discretionary grants (e.g. Project AWARE, Systems of Care)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedformfund === '2' ? (
                                                <li>Federal formula funds (e.g. ESSA Title I, ESSA Title IV-A)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedideafund === '2' ? (
                                                <li>Federal IDEA funds (special education)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedblckgrant === '2' ? (
                                                <li>
                                                    Federal block grant funds (e.g. Community Mental Health Block Grant)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_medicaid === '2' ? (
                                                <li>Billing Medicaid/CHIP</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privinsur === '2' ? (
                                                <li>Students&apos;/family private insurance</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_selfpay === '2' ? (
                                                <li>Self-pay for community-partnered SMH services</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privcmmntyfund === '2' ? (
                                                <li>Private/community foundation funding</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_d4_otheragncy === '2' ? (
                                                <li>Funding from other systems/agencies</li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className={style.green1stDiv3}>
                                    <div className={style.rightGreenOne3}>
                                        <img
                                            className={style.greenOneImage3}
                                            src={funding3}
                                            alt="polic_reg"
                                            style={{
                                                height: this.state.redImageHeight,
                                                minHeight: '200px',
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={style.rightgreentext3}
                                        ref={this.redDivRef}
                                        style={{
                                            marginTop: marginOffset + 'px',
                                            minHeight: '150px',
                                        }}
                                    >
                                        <span className={style.rightgreentextpara3}>
                                            <span className={style.rightHeading3}>None</span>
                                            <br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(no funding comes from each of the following):
                                        </span>
                                        <ul className={style.unorderListGreen3}>
                                            {domain4.mental_health_profile_dstrctbdgt === '0' ? (
                                                <li>Local school district budgets</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other1 === '0' ? (
                                                <li>{domain4.mental_health_profile_d4_other1_text}</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other2 === '0' ? (
                                                <li>{domain4.mental_health_profile_d4_other2_text}</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_steduaid === '0' ? (
                                                <li>State education aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_stmhaid === '0' ? (
                                                <li>State mental health aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_feddiscgrant === '0' ? (
                                                <li>
                                                    Federal discretionary grants (e.g. Project AWARE, Systems of Care)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedformfund === '0' ? (
                                                <li>Federal formula funds (e.g. ESSA Title I, ESSA Title IV-A)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedideafund === '0' ? (
                                                <li>Federal IDEA funds (special education)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedblckgrant === '0' ? (
                                                <li>
                                                    Federal block grant funds (e.g. Community Mental Health Block Grant)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_medicaid === '0' ? (
                                                <li>Billing Medicaid/CHIP</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privinsur === '0' ? (
                                                <li>Students&apos;/family private insurance</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_selfpay === '0' ? (
                                                <li>Self-pay for community-partnered SMH services</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privcmmntyfund === '0' ? (
                                                <li>Private/community foundation funding</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_d4_otheragncy === '0' ? (
                                                <li>Funding from other systems/agencies</li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Dont know box */}
                            <div className={style.mainCartHeader_unknBox}>
                                <div className={style.cartContentHeader_unknwBox}>
                                    <h4>&nbsp;Not sure:</h4>
                                </div>
                                <div className={style.OnOrderlist_MainContainer}>
                                    <div className={style.firstbox_checkedBox}>
                                        <ul>
                                            {domain4.mental_health_profile_dstrctbdgt === '777' ? (
                                                <li>Local school district budgets</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other1 === '777' ? (
                                                <li>{domain4.mental_health_profile_d4_other1_text}</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_d4_other2 === '777' ? (
                                                <li>{domain4.mental_health_profile_d4_other2_text}</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_steduaid === '777' ? (
                                                <li>State education aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}

                                            {domain4.mental_health_profile_stmhaid === '777' ? (
                                                <li>State mental health aid or appropriations</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_feddiscgrant === '777' ? (
                                                <li>
                                                    Federal discretionary grants (e.g. Project AWARE, Systems of Care)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedformfund === '777' ? (
                                                <li>Federal formula funds (e.g. ESSA Title I, ESSA Title IV-A)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedideafund === '777' ? (
                                                <li>Federal IDEA funds (special education)</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_fedblckgrant === '777' ? (
                                                <li>
                                                    Federal block grant funds (e.g. Community Mental Health Block Grant)
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_medicaid === '777' ? (
                                                <li>Billing Medicaid/CHIP</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privinsur === '777' ? (
                                                <li>Students&apos;/family private insurance</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_selfpay === '777' ? (
                                                <li>Self-pay for community-partnered SMH services</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_privcmmntyfund === '777' ? (
                                                <li>Private/community foundation funding</li>
                                            ) : (
                                                ''
                                            )}
                                            {domain4.mental_health_profile_d4_otheragncy === '777' ? (
                                                <li>Funding from other systems/agencies</li>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 8 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
