import React from 'react';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import BarGraphTRS from '../BarGraphTRS';
import BarGraph from '../BarGraph';
import SubHeading from '../SubHeading';
import style from './style.css';

const SinglePageReportSection = props => {
    const {
        domainName,
        getEnteredBy,
        selectedDates,
        siteFields,
        getReportFields,
        domainId,
        getGaugeImage,
        score,
        getScoreColor,
        getDataByHandle,
        getCategories,
        getSuffix,
        pageNumber,
        totalPages,
        flowType,
        hideAbout,
        hideGauge,
        filterGraphSubCategories,
        hideGraphScoreFeedback,
    } = props;

    const userGroupType = flowType.includes('familyEngagement')
        ? flowType.replace('familyEngagement', '')
        : flowType.slice(4);

    const getLogo = () => {
        if (['owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
            return style.logoOWBI;
        }
        if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
            return style.logoCARE;
        }
        if (flowType === 'trs') {
            return style.logoTRS;
        }
        if (flowType.includes('familyEngagement')) {
            return style.logoFE;
        }

        return style.logo;
    };

    const renderBarGraph = () => {
        if (['careSchool', 'careEntity'].includes(flowType)) {
            return (
                <BarGraph
                    metrics={getDataByHandle().school}
                    categories={getCategories().schoolFlowCategories}
                    flowType={flowType}
                />
            );
        }
        if (['careDistrict'].includes(flowType)) {
            return (
                <BarGraph
                    metrics={getDataByHandle().district}
                    categories={getCategories().districtFlowCategories}
                    flowType={flowType}
                />
            );
        }
        if (['familyEngagementSchool'].includes(flowType)) {
            return (
                <BarGraph
                    metrics={getDataByHandle().school}
                    categories={getCategories().schoolFlowCategories}
                    flowType={flowType}
                    filterGraphSubCategories={filterGraphSubCategories}
                />
            );
        }
        if (['familyEngagementDistrict'].includes(flowType)) {
            return (
                <BarGraph
                    metrics={getDataByHandle().district}
                    categories={getCategories().districtFlowCategories}
                    flowType={flowType}
                    filterGraphSubCategories={filterGraphSubCategories}
                />
            );
        }
        if (['familyEngagementEntity'].includes(flowType)) {
            return (
                <BarGraph
                    metrics={getDataByHandle().entity}
                    categories={getCategories().entityFlowCategories}
                    flowType={flowType}
                    filterGraphSubCategories={filterGraphSubCategories}
                />
            );
        }

        return (
            <BarGraphTRS
                metrics={getDataByHandle().school}
                categories={getCategories().schoolFlowCategories}
                flowType={flowType}
            />
        );
    };

    return (
        <Page>
            <div className={style.content}>
                <div className={style.header}>
                    <div className={style.headerLogo}>
                        <div className={style.triangle} />
                        <div className={getLogo()} />
                    </div>
                    <div className={style.headerTitle}>{domainName}</div>
                </div>
                <SubHeading getEnteredBy={getEnteredBy} selectedDates={selectedDates} />
                <div className={style.reportPage}>
                    {!hideAbout && (
                        <div style={{ marginBottom: '1em' }}>
                            <span
                                style={{
                                    fontSize: '18px',
                                    color: '#0b72b6',
                                    lineHeight: '1.8',
                                }}
                            >
                                About {domainName}
                            </span>
                            <div className={style.aboutText}>
                                <Html content={siteFields[getReportFields(domainId) + 'About' + userGroupType]} />
                            </div>
                        </div>
                    )}

                    {!hideGauge && (
                        <div className={style.overallScore}>
                            <div
                                className={style.gauge}
                                style={{
                                    backgroundImage: `url(${getGaugeImage(score)})`,
                                }}
                            />
                            <div className={style.gaugeText}>
                                <div className={style.gaugeScore}>
                                    OVERALL COMPOSITE SCORE: <span className={getScoreColor(score)}>{score}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {renderBarGraph()}

                    {!hideGraphScoreFeedback && (
                        <div style={{ marginBottom: '1em' }}>
                            <Html content={siteFields[getReportFields(domainId) + getSuffix(score) + userGroupType]} />
                        </div>
                    )}
                </div>
            </div>
            <div className={style.footer}>
                <div className={style.footerText}>
                    <div>
                        This progress report was developed by the National Center for School Mental Health for the SHAPE
                        System.
                    </div>
                    <div>
                        Page {pageNumber} of {totalPages}
                    </div>
                </div>
                <div className={style.footerLogo} />
            </div>
        </Page>
    );
};

export default SinglePageReportSection;
