export const school = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroSchool'],
        },
        {
            title: 'Work Climate & Environment',
            messages: [
                'workClimateEmergingSchool',
                'workClimateProgressingSchool',
                'workClimateProficientSchool',
                'workClimateAboutSchool',
            ],
        },
        {
            title: 'Input, Flexibility & Autonomy',
            messages: ['inputEmergingSchool', 'inputProgressingSchool', 'inputProficientSchool', 'inputAboutSchool'],
        },
        {
            title: 'Professional Development and Recognition',
            messages: [
                'developmentEmergingSchool',
                'developmentProgressingSchool',
                'developmentProficientSchool',
                'developmentAboutSchool',
            ],
        },
        {
            title: 'Organizational and Supervisory Support',
            messages: [
                'orgSupportEmergingSchool',
                'orgSupportProgressingSchool',
                'orgSupportProficientSchool',
                'orgSupportAboutSchool',
            ],
        },
        {
            title: 'Self-Care',
            messages: [
                'selfCareEmergingSchool',
                'selfCareProgressingSchool',
                'selfCareProficientSchool',
                'selfCareAboutSchool',
            ],
        },
        {
            title: 'Diversity, Equity, Inclusion, Access (DEIA)',
            messages: ['deiaEmergingSchool', 'deiaProgressingSchool', 'deiaProficientSchool', 'deiaAboutSchool'],
        },
        {
            title: 'Purpose and Meaningfulness',
            messages: [
                'purposeEmergingSchool',
                'purposeProgressingSchool',
                'purposeProficientSchool',
                'purposeAboutSchool',
            ],
        },
        {
            title: 'Professional Quality of Life',
            messages: ['qolEmergingSchool', 'qolProgressingSchool', 'qolProficientSchool', 'qolAboutSchool'],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'owbiStrategicPlanningPage1School',
                'owbiStrategicPlanningPage2School',
                'owbiStrategicPlanningPage3School',
                'owbiStrategicPlanningPage4School',
                'owbiStrategicPlanningPage5School',
            ],
        },
    ],
    messages: {
        summaryReportIntroSchool: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p>This report is generated based on the information you provided for the OWBI-S survey. The composite score for each domain is the average of your ratings for every item within the domain.</p><p>Composite scores of 1.0-1.9 are classified as <strong>"Emerging"</strong> areas, 2.0-2.9 are classified as <strong>"Progressing"</strong> areas, and 3.0-4.0 are classified as areas of <strong>"Proficient."</strong></p>',
        },
        workClimateEmergingSchool: {
            title: 'Work Climate & Environment Report (Emerging)',
            message:
                '<p>Based on your responses, this is an <i>emerging domain</i> for your school. Initial actions schools often take to begin improving their whole school safety planning include:</p><ul><li>Conduct a crisis workshop training for school leadership.</li><li>Identify "hot spots" on your campus that may require supervision. </li><li>Conduct a needs assessment to develop an understanding of bullying in your school using surveys and/or focus groups.</li></ul><p>For more in-depth guidance on these actions and next steps, please refer to the <i>Whole School Safety Planning Guide.</i></p>',
        },
        workClimateProgressingSchool: {
            title: 'Work Climate & Environment Report (Progressing)',
            message:
                '<p>Based on your responses, this is a <i>progressing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>',
        },
        workClimateProficientSchool: {
            title: 'Work Climate & Environment Report (Proficient)',
            message: `<p>Based on your responses, this is a <i>proficient domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>`,
        },
        workClimateAboutSchool: {
            title: 'Work Climate & Environment Report (About)',
            message:
                '<p>Work Climate and Environment is the physical and emotional climate of a school. This includes how well the physical space is maintained as well as whether policies support a friendly and supportive work environment in which employee rights are clear and protected. Your Work Climate and Environment Scale is comprised of eight indicators: 1) exterior and interior spaces are well-lit and physically well maintained (e.g., clean, secure, healthy); 2) provides employees with adequate resources to complete their job duties (e.g., providing adequate space, good Informational Technology (IT) systems, other practice supports ); 3) information and resources about employee rights and staff well-being are posted in places that are visible; 4) does not tolerate and appropriately responds to sexual harassment and other forms of harassment by adults or students;  5) has a formal, confidential process for employee grievances/complaints that is managed by those in the appropriate role; 6) only schedules meetings or activities that are relevant to my work and/or are an efficient use of my time; 7) leaders delegate work effectively; 8) employees are supported in setting and adhering to healthy workload boundaries.</p>',
        },
        inputEmergingSchool: {
            title: 'Input, Flexibility & Autonomy Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        inputProgressingSchool: {
            title: 'Input, Flexibility & Autonomy Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        inputProficientSchool: {
            title: 'Input, Flexibility & Autonomy Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your school.</p>',
        },
        inputAboutSchool: {
            title: 'Input, Flexibility & Autonomy Report (About)',
            message: `<p>Input, Flexibility & Autonomy is the degree to which employee input is valued and incorporated into practices and policies and the degree to which employees can work flexibly and independently. Your Input, Flexibility & Autonomy Scale is comprised of eight indicators: 1) provides employees with a clear, effective process for giving feedback about practices and policies; 2) incorporates employees' feedback into decisions about practices and policies; 3) Employees are involved in the creation of new and updated policies and procedures; 4) Employees feel empowered to speak up about concerns they have in the workplace; 5) Employees feel empowered to bring up ideas and make suggestions to improve the school; 6) Employees are supported to function well autonomously; 7) Employees are given the autonomy to self-manage their work; 8) accommodates individual needs or responsibilities in employees' personal lives.</p>`,
        },
        developmentEmergingSchool: {
            title: 'Professional Development and Recognition Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        developmentProgressingSchool: {
            title: 'Professional Development and Recognition Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        developmentProficientSchool: {
            title: 'Professional Development and Recognition Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your school.</p>',
        },
        developmentAboutSchool: {
            title: 'Professional Development and Recognition Report (About)',
            message: `<p>Professional Development and Recognition are efforts to train employees on relevant job skills and to acknowledge and reward job performance, professional accomplishments, and personal milestones and successes.  Your Professional Development and Recognition Scale is comprised of eight indicators: 1) provides all employees with training related to their specific job tasks; 2) Employees are provided opportunities, coverage, and continuing education credit to attend helpful trainings related to their role; 3) provides information and skills training and/or education on the steps necessary to advance in one's role; 4)  provides leadership development training; 5) offers perks/special privileges (e.g., better parking space) to employees who demonstrate high quality performance; 6) regularly recognizes and celebrates professional accomplishments (e.g., promotions); 7) regularly recognizes and celebrates personal milestones and successes (e.g., birthdays, life events);  8) recognizes people's passions, including activities and hobbies beyond work.</p>`,
        },
        orgSupportEmergingSchool: {
            title: 'Organizational and Supervisory Support Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        orgSupportProgressingSchool: {
            title: 'Organizational and Supervisory Support Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        orgSupportProficientSchool: {
            title: 'Organizational and Supervisory Support Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your school.</p>',
        },
        orgSupportAboutSchool: {
            title: 'Organizational and Supervisory Support Report (About)',
            message:
                '<p>Organizational and Supervisory Support is the degree to which employees have organizational and supervisory support to define and navigate job responsibilities and are evaluated fairly. Your Organizational and Supervisory Support Scale is comprised of eight indicators: 1) School employee roles and responsibilities are clearly defined and adhered to; 2) Staff-to-student ratios are sufficient for staff to effectively perform their jobs; 3) provides helpful support for administrative tasks and procedures (e.g., completing paperwork); 4) All employees receive sufficient, timely, and relevant supervision and coaching; 5) provides effective and timely support to address instructional issues (e.g., new curricula); 6) provides effective and timely support to address student-related issues (e.g., student emotional and behavioral concerns); 7) Our employee evaluation procedure is clear and fair; 8) School employees feel comfortable discussing concerns about the school or their job with supervisors without fear of negative consequences (e.g., being treated differently, feeling like their job is in jeopardy or having it impact their role on the team).</p>',
        },
        selfCareEmergingSchool: {
            title: 'Self-Care Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        selfCareProgressingSchool: {
            title: 'Self-Care Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        selfCareProficientSchool: {
            title: 'Self-Care Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your school.</p>',
        },
        selfCareAboutSchool: {
            title: 'Self-Care Report (About)',
            message:
                '<p>Self-Care are organizational efforts to assess and foster employee self-care, including education and training in job stress and self-care, accessible employee assistance programming, and opportunities for mindfulness and breaks.  Your Self-Care Scale is comprised of eight indicators: 1) provides continuous education and training for all employees to understand the connection between stress, well-being, and health including signs of burnout, compassion fatigue, and/or vicarious or secondary trauma; 2) provides protected time and encourages employees to participate in self-care and self-compassion activities; 3) An employee assistance program (EAP) is readily available to all employees and its use is encouraged; 4) Time is allotted in supervision and/or yearly reviews to freely discuss job stress and self-care strategies; 5) provides opportunities and space for all employees to practice mindfulness/take quiet time during the day; 6)  provides sufficient time for employees to take breaks during the day; 7) strongly encourages use of sick and vacation time; 8) At regular intervals, measures employee well-being and self-care.</p>',
        },
        deiaEmergingSchool: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        deiaProgressingSchool: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        deiaProficientSchool: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your school.</p>',
        },
        deiaAboutSchool: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (About)',
            message:
                '<p>Diversity, Equity, Inclusion, Access (DEIA)  are organizational efforts to ensure that multiple perspectives are represented, respected, and valued; that all members are treated fairly and justly; that space is made for the characteristics that each employee brings; and that diverse individuals are engaged in all aspects of organizational work, including decision-making processes. Your DEIA Scale is comprised of eight indicators: 1) has a clear working definition of diversity, equity, inclusion, and access; 2) Information about diversity, equity, inclusion, and access is posted in places that are visible; 3) regularly engages continuous quality improvement of organizational processes and practices related to DEIA; 4) provides training to all employees on diversity, equity, inclusion, and access; 5) engages in equitable decision-making by including input from multiple sources; 6) There is a clear feedback process and point of contact for diversity and equity related matters, including mechanisms to support employees harmed by acts of discrimination; 7) provides culturally relevant instruction, supports, and services to its students; 8) Employees feel accepted and respected.</p>',
        },
        purposeEmergingSchool: {
            title: 'Purpose and Meaningfulness Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        purposeProgressingSchool: {
            title: 'Purpose and Meaningfulness Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        purposeProficientSchool: {
            title: 'Purpose and Meaningfulness Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your school.</p>',
        },
        purposeAboutSchool: {
            title: 'Purpose and Meaningfulness Report (About)',
            message: `<p>Purpose and Meaningfulness are organizational strategies to align employee work with their personal values and strengths, and to provide work that is meaningful to employees.  Your Purpose and Meaningfulness Scale is comprised of eight indicators: 1) supports employees to identify their personal values and align those with their work; 2) Employees are proud to work in their school; 3) Job assignments and responsibilities are shaped around the employees' interests and strengths; 4) provides tasks and activities that are personally meaningful to employees; 5) Employees derive a sense of purpose from their careers; 6) provides opportunities for employees to identify their professional goals; 7) Employees feel that their work has meaningful impact; 8) helps employees recognize the value of their unique contributions to the overall mission of the school.</p>`,
        },
        qolEmergingSchool: {
            title: 'Professional Quality of Life Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        qolProgressingSchool: {
            title: 'Professional Quality of Life Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        qolProficientSchool: {
            title: 'Professional Quality of Life Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your school.</p>',
        },
        qolAboutSchool: {
            title: 'Professional Quality of Life Report (About)',
            message: `<p>Professional Quality of Life is the extent to which organizations create a day-to-day work environment in which employees have positive collegial relationships and derive pleasure from doing their work well. Your Professional Quality of Life Scale is comprised of eight indicators: 1) The workload for employees is reasonable and achievable; 2) Employees' training and skills are well utilized; 3) Employees feel their day-to-day tasks make a difference; 4) Employees are happy to work; 5) Employees enjoy the people they work with; 6) creates a sense of collegiality and teamwork among employees; 7) encourages new ideas and innovations from employees; 8) encourages appropriate boundaries between work and personal life.</p>`,
        },
        owbiStrategicPlanningPage1School: {
            title: 'Strategic Planning Guide - Page 1',
            message: `<p>This Strategic Planning Guide is a tool for teams who have completed the Organizational Well-Being Inventory for Schools (OWBI-S) and are ready to engage in a strategic planning process to improve organizational well-being in their schools. The OWBI-S provides an overview of the organizational well-being of your school system, often surfacing numerous strengths and opportunities for improvement or growth. However, most systems have more opportunities for improvement than they can realistically address in a given school year. Therefore, this guide is designed to help teams prioritize one to two areas for improvement, develop a measurable goal, and map out an initial plan including anticipated opportunities and barriers, action steps, and a timeline. <a title="Fillable PDF of Strategic Planning Guide for Schools" href="https://dm0gz550769cd.cloudfront.net/shape/09/091ad4716f223737e7593bb706ae366c.pdf" target="_blank" rel="noopener"><strong>Download a fillable version of this strategic planning guide.</strong></a></p>
                <p><strong><u>Prioritize 1&ndash;2 Improvement Areas </u></strong></p>
                <p>The Organizational Well-Being Inventory for Schools (OWBI-S) includes eight domains (Work Climate and Environment; Input; Flexibility and Autonomy; Professional Development and Recognition; Organizational and Supervisory Support; Self-Care; Diversity, Equity, Inclusion, and Access (DEIA); Purpose and Meaningfulness; and Professional Quality of Life). We encourage you to meet with your school team and review your scores on each area provided in the OWBI-S report.</p>
                <p>Some teams have a clear idea of which improvement area they want to start with after they complete the OWBI-S. If this is true for your team, select your area and move directly to Step 2! If you are not sure yet which improvement area is right for you, consider the following discussion questions to build consensus on which area(s) to prioritize:</p>
                <ul>
                <li><strong>Desire</strong> &ndash; How much desire (e.g., motivation, need) is there to improve this area?</li>
                <li><strong>Impact</strong> &ndash; How much impact would improvements in this area have on overall staff and/or school well-being?</li>
                <li><strong>Resources </strong>&ndash; What resources (e.g., staffing, capacity, financial resources, leadership support, community support, partnerships, expertise) are available to make changes and improvements in this area?</li>
                </ul>
                <p><strong>Select one or two areas that your team would like to improve.&nbsp;</strong><strong>Priority Area(s):</strong></p>
                <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                <tbody>
                <tr>
                <td style="width: 19%;">
                <p style="text-align: center;">Work Climate and Environment</p>
                </td>
                <td style="width: 23%; text-align: center;">Input, Flexibility, and Autonomy</td>
                <td style="width: 27%; text-align: center;">Professional Development and Recognition</td>
                <td style="width: 25.8621%;">Organizational and Supervisory Support</td>
                </tr>
                <tr>
                <td style="width: 19%;">
                <p style="text-align: center;">Self-Care</p>
                </td>
                <td style="width: 23%; text-align: center;">Diversity, Equity, Inclusion, Access (DEIA)</td>
                <td style="width: 27%; text-align: center;">Purpose and Meaningfulness</td>
                <td style="width: 25.8621%; text-align: center;">Professional Quality of Life</td>
                </tr>
                </tbody>
                </table>
                <p>These improvement areas are only your first step for the school year. Focusing on one area at a time will support consistency and momentum.</p>`,
        },
        owbiStrategicPlanningPage2School: {
            title: 'Strategic Planning Guide - Page 2',
            message: `<p>The following pages can be used as a tool to plan your next steps for this domain.<strong>&nbsp;</strong></p>
                <p><strong><u>Develop a Measurable Goal</u></strong></p>
                <p>Each improvement area is still quite broad. Develop a more specific, measurable goal to clarify and customize what you will focus on. For ideas about goals within a particular improvement area, refer to the OWBI-S Assessment or Report and other resources available in the SHAPE Resource Library.<strong> For a list of example SMARTIE goals by Improvement Area, please see pg. 2&nbsp;of the <a title="Fillable PDF of Strategic Planning Guide for Schools" href="https://dm0gz550769cd.cloudfront.net/shape/09/091ad4716f223737e7593bb706ae366c.pdf" target="_blank" rel="noopener">fillable version of this Strategic Planning Guide</a>.</strong></p>
                <p><strong>What are SMARTIE Goals?</strong></p>
                <ul>
                <li><strong>Specific&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Concrete, detailed, and well-defined</li>
                <li><strong>Measurable</strong>&nbsp; &nbsp;Numeric or descriptive with quantity, quality, or comparative to baseline</li>
                <li><strong>Actionable</strong>&nbsp; &nbsp; &nbsp;Feasible, attainable, actionable</li>
                <li><strong>Realistic </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Considers resources, barriers, strengths, and what can be achieved</li>
                <li><strong>Timebound</strong> &nbsp;&nbsp; Identified target dates, interim steps to monitor progress, and/or a timeline for goal to be achieved</li>
                <li><strong>Inclusive</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Brings traditionally marginalized students and families&mdash;particularly those most impacted&mdash;into intervention planning, <br />implementation, and progress monitoring in a way that shares power</li>
                <li><strong>Equitable</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Includes an element of fairness or justice that seeks to address systemic injustice, inequity, or oppression</li>
                </ul>
                <p><strong><u>Template</u></strong>: By [<em>time frame</em>], we will accomplish a [<em>measurable amount of</em>] [<em>our goal</em>] to improve [<em>quality indicator</em>].</p>
                <p>Be specific about how the goal or strategy is <em>inclusive and equitable</em>.</p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong><strong>GOAL</strong>: <u>_______________________________________________________________________</u></p>
                <p>&nbsp;</p>
                <p><u>______________________________________________________________________________</u></p>
                <p>&nbsp;</p>
                <p><strong>How will we know this goal has been achieved? <u>____________________________________</u></strong></p>
                <p>&nbsp;</p>
                <p><strong><u>______________________________________________________________________________</u></strong></p>
                <p>&nbsp;</p>`,
        },
        owbiStrategicPlanningPage3School: {
            title: 'Strategic Planning Guide - Page 3',
            message: `<p><strong>What opportunities exist related to this goal?</strong></p>
                <ul>
                <li>What have been our past successes?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What current work is taking place related to this goal?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What are our available resources (leadership, infrastructure, staffing, partnerships)?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>What barriers exist related to this goal?</strong></p>
                <ul>
                <li>What would prevent us from moving forward with this goal?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What would we need to overcome this/these barrier(s)?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>`,
        },
        owbiStrategicPlanningPage4School: {
            title: 'Strategic Planning Guide - Page 4',
            message: `<p><strong><u>Create an Action Plan</u></strong></p>
                <p>Identify 3-5 short-term action steps and 3-5 long-term action steps (if relevant) that can be taken to achieve this goal.</p>
                <p><strong>Short term: 0-6 months</strong></p>
                <p>&nbsp;</p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 22%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 22.6913%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.3087%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                </tbody>
                </table>`,
        },
        owbiStrategicPlanningPage5School: {
            title: 'Strategic Planning Guide - Page 5',
            message: `<p><strong>Long term: 7-24 months</strong></p>
            <p>&nbsp;</p>
            <table class="table table-bordered" style="width: 100%;">
            <tbody>
            <tr>
            <td style="width: 42%;"><strong>Action Step</strong></td>
            <td style="width: 19%;"><strong>Team Members Responsible</strong></td>
            <td style="width: 20.8055%;"><strong>Data to Evaluate Change</strong></td>
            <td style="width: 10.1945%;"><strong>Target Date</strong></td>
            </tr>
            <tr>
            <td style="width: 42%;">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            </td>
            <td style="width: 19%;">&nbsp;</td>
            <td style="width: 20.8055%;">&nbsp;</td>
            <td style="width: 10.1945%;">&nbsp;</td>
            </tr>
            <tr>
            <td style="width: 42%;">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            </td>
            <td style="width: 19%;">&nbsp;</td>
            <td style="width: 20.8055%;">&nbsp;</td>
            <td style="width: 10.1945%;">&nbsp;</td>
            </tr>
            <tr>
            <td style="width: 42%;">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            </td>
            <td style="width: 19%;">&nbsp;</td>
            <td style="width: 20.8055%;">&nbsp;</td>
            <td style="width: 10.1945%;">&nbsp;</td>
            </tr>
            <tr>
            <td style="width: 42%;">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            </td>
            <td style="width: 19%;">&nbsp;</td>
            <td style="width: 20.8055%;">&nbsp;</td>
            <td style="width: 10.1945%;">&nbsp;</td>
            </tr>
            <tr>
            <td style="width: 42%;">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            </td>
            <td style="width: 19%;">&nbsp;</td>
            <td style="width: 20.8055%;">&nbsp;</td>
            <td style="width: 10.1945%;">&nbsp;</td>
            </tr>
            </tbody>
            </table>`,
        },
    },
};

export const district = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroDistrict'],
        },
        {
            title: 'Work Climate & Environment',
            messages: [
                'workClimateEmergingDistrict',
                'workClimateProgressingDistrict',
                'workClimateProficientDistrict',
                'workClimateAboutDistrict',
            ],
        },
        {
            title: 'Input, Flexibility & Autonomy',
            messages: [
                'inputEmergingDistrict',
                'inputProgressingDistrict',
                'inputProficientDistrict',
                'inputAboutDistrict',
            ],
        },
        {
            title: 'Professional Development and Recognition',
            messages: [
                'developmentEmergingDistrict',
                'developmentProgressingDistrict',
                'developmentProficientDistrict',
                'developmentAboutDistrict',
            ],
        },
        {
            title: 'Organizational and Supervisory Support',
            messages: [
                'orgSupportEmergingDistrict',
                'orgSupportProgressingDistrict',
                'orgSupportProficientDistrict',
                'orgSupportAboutDistrict',
            ],
        },
        {
            title: 'Self-Care',
            messages: [
                'selfCareEmergingDistrict',
                'selfCareProgressingDistrict',
                'selfCareProficientDistrict',
                'selfCareAboutDistrict',
            ],
        },
        {
            title: 'Diversity, Equity, Inclusion, Access (DEIA)',
            messages: [
                'deiaEmergingDistrict',
                'deiaProgressingDistrict',
                'deiaProficientDistrict',
                'deiaAboutDistrict',
            ],
        },
        {
            title: 'Purpose and Meaningfulness',
            messages: [
                'purposeEmergingDistrict',
                'purposeProgressingDistrict',
                'purposeProficientDistrict',
                'purposeAboutDistrict',
            ],
        },
        {
            title: 'Professional Quality of Life',
            messages: ['qolEmergingDistrict', 'qolProgressingDistrict', 'qolProficientDistrict', 'qolAboutDistrict'],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'owbiStrategicPlanningPage1District',
                'owbiStrategicPlanningPage2District',
                'owbiStrategicPlanningPage3District',
                'owbiStrategicPlanningPage4District',
                'owbiStrategicPlanningPage5District',
            ],
        },
    ],
    messages: {
        summaryReportIntroDistrict: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p>This report is generated based on the information you provided for the OWBI-S survey. The composite score for each domain is the average of your ratings for every item within the domain.</p><p>Composite scores of 1.0-1.9 are classified as <strong>"Emerging"</strong> areas, 2.0-2.9 are classified as <strong>"Progressing"</strong> areas, and 3.0-4.0 are classified as areas of <strong>"Proficient."</strong></p>',
        },
        workClimateEmergingDistrict: {
            title: 'Work Climate & Environment Report (Emerging)',
            message:
                '<p>Based on your responses, this is an <i>emerging domain</i> for your school. Initial actions schools often take to begin improving their whole school safety planning include:</p><ul><li>Conduct a crisis workshop training for school leadership.</li><li>Identify "hot spots" on your campus that may require supervision. </li><li>Conduct a needs assessment to develop an understanding of bullying in your school using surveys and/or focus groups.</li></ul><p>For more in-depth guidance on these actions and next steps, please refer to the <i>Whole School Safety Planning Guide.</i></p>',
        },
        workClimateProgressingDistrict: {
            title: 'Work Climate & Environment Report (Progressing)',
            message:
                '<p>Based on your responses, this is a <i>progressing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>',
        },
        workClimateProficientDistrict: {
            title: 'Work Climate & Environment Report (Proficient)',
            message: `<p>Based on your responses, this is a <i>proficient domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>`,
        },
        workClimateAboutDistrict: {
            title: 'Work Climate & Environment Report (About)',
            message:
                '<p>Work Climate and Environment is the physical and emotional climate of a school. This includes how well the physical space is maintained as well as whether policies support a friendly and supportive work environment in which employee rights are clear and protected. Your Work Climate and Environment Scale is comprised of eight indicators: 1) exterior and interior spaces are well-lit and physically well maintained (e.g., clean, secure, healthy); 2) provides employees with adequate resources to complete their job duties (e.g., providing adequate space, good Informational Technology (IT) systems, other practice supports ); 3) information and resources about employee rights and staff well-being are posted in places that are visible; 4) does not tolerate and appropriately responds to sexual harassment and other forms of harassment by adults or students;  5) has a formal, confidential process for employee grievances/complaints that is managed by those in the appropriate role; 6) only schedules meetings or activities that are relevant to my work and/or are an efficient use of my time; 7) leaders delegate work effectively; 8) employees are supported in setting and adhering to healthy workload boundaries.</p>',
        },
        inputEmergingDistrict: {
            title: 'Input, Flexibility & Autonomy Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your district.</p>',
        },
        inputProgressingDistrict: {
            title: 'Input, Flexibility & Autonomy Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your district.</p>',
        },
        inputProficientDistrict: {
            title: 'Input, Flexibility & Autonomy Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your district.</p>',
        },
        inputAboutDistrict: {
            title: 'Input, Flexibility & Autonomy Report (About)',
            message: `<p>Input, Flexibility & Autonomy is the degree to which employee input is valued and incorporated into practices and policies and the degree to which employees can work flexibly and independently. Your Input, Flexibility & Autonomy Scale is comprised of eight indicators: 1) provides employees with a clear, effective process for giving feedback about practices and policies; 2) incorporates employees' feedback into decisions about practices and policies; 3) Employees are involved in the creation of new and updated policies and procedures; 4) Employees feel empowered to speak up about concerns they have in the workplace; 5) Employees feel empowered to bring up ideas and make suggestions to improve the school; 6) Employees are supported to function well autonomously; 7) Employees are given the autonomy to self-manage their work; 8) accommodates individual needs or responsibilities in employees' personal lives.</p>`,
        },
        developmentEmergingDistrict: {
            title: 'Professional Development and Recognition Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your district.</p>',
        },
        developmentProgressingDistrict: {
            title: 'Professional Development and Recognition Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your district.</p>',
        },
        developmentProficientDistrict: {
            title: 'Professional Development and Recognition Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your district.</p>',
        },
        developmentAboutDistrict: {
            title: 'Professional Development and Recognition Report (About)',
            message: `<p>Professional Development and Recognition are efforts to train employees on relevant job skills and to acknowledge and reward job performance, professional accomplishments, and personal milestones and successes.  Your Professional Development and Recognition Scale is comprised of eight indicators: 1) provides all employees with training related to their specific job tasks; 2) Employees are provided opportunities, coverage, and continuing education credit to attend helpful trainings related to their role; 3) provides information and skills training and/or education on the steps necessary to advance in one's role; 4)  provides leadership development training; 5) offers perks/special privileges (e.g., better parking space) to employees who demonstrate high quality performance; 6) regularly recognizes and celebrates professional accomplishments (e.g., promotions); 7) regularly recognizes and celebrates personal milestones and successes (e.g., birthdays, life events);  8) recognizes people's passions, including activities and hobbies beyond work.</p>`,
        },
        orgSupportEmergingDistrict: {
            title: 'Organizational and Supervisory Support Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your district.</p>',
        },
        orgSupportProgressingDistrict: {
            title: 'Organizational and Supervisory Support Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your district.</p>',
        },
        orgSupportProficientDistrict: {
            title: 'Organizational and Supervisory Support Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your district.</p>',
        },
        orgSupportAboutDistrict: {
            title: 'Organizational and Supervisory Support Report (About)',
            message:
                '<p>Organizational and Supervisory Support is the degree to which employees have organizational and supervisory support to define and navigate job responsibilities and are evaluated fairly. Your Organizational and Supervisory Support Scale is comprised of eight indicators: 1) School employee roles and responsibilities are clearly defined and adhered to; 2) Staff-to-student ratios are sufficient for staff to effectively perform their jobs; 3) provides helpful support for administrative tasks and procedures (e.g., completing paperwork); 4) All employees receive sufficient, timely, and relevant supervision and coaching; 5) provides effective and timely support to address instructional issues (e.g., new curricula); 6) provides effective and timely support to address student-related issues (e.g., student emotional and behavioral concerns); 7) Our employee evaluation procedure is clear and fair; 8) School employees feel comfortable discussing concerns about the school or their job with supervisors without fear of negative consequences (e.g., being treated differently, feeling like their job is in jeopardy or having it impact their role on the team).</p>',
        },
        selfCareEmergingDistrict: {
            title: 'Self-Care Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your district.</p>',
        },
        selfCareProgressingDistrict: {
            title: 'Self-Care Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your district.</p>',
        },
        selfCareProficientDistrict: {
            title: 'Self-Care Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your district.</p>',
        },
        selfCareAboutDistrict: {
            title: 'Self-Care Report (About)',
            message:
                '<p>Self-Care are organizational efforts to assess and foster employee self-care, including education and training in job stress and self-care, accessible employee assistance programming, and opportunities for mindfulness and breaks.  Your Self-Care Scale is comprised of eight indicators: 1) provides continuous education and training for all employees to understand the connection between stress, well-being, and health including signs of burnout, compassion fatigue, and/or vicarious or secondary trauma; 2) provides protected time and encourages employees to participate in self-care and self-compassion activities; 3) An employee assistance program (EAP) is readily available to all employees and its use is encouraged; 4) Time is allotted in supervision and/or yearly reviews to freely discuss job stress and self-care strategies; 5) provides opportunities and space for all employees to practice mindfulness/take quiet time during the day; 6)  provides sufficient time for employees to take breaks during the day; 7) strongly encourages use of sick and vacation time; 8) At regular intervals, measures employee well-being and self-care.</p>',
        },
        deiaEmergingDistrict: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your district.</p>',
        },
        deiaProgressingDistrict: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your district.</p>',
        },
        deiaProficientDistrict: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your district.</p>',
        },
        deiaAboutDistrict: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (About)',
            message:
                '<p>Diversity, Equity, Inclusion, Access (DEIA)  are organizational efforts to ensure that multiple perspectives are represented, respected, and valued; that all members are treated fairly and justly; that space is made for the characteristics that each employee brings; and that diverse individuals are engaged in all aspects of organizational work, including decision-making processes. Your DEIA Scale is comprised of eight indicators: 1) has a clear working definition of diversity, equity, inclusion, and access; 2) Information about diversity, equity, inclusion, and access is posted in places that are visible; 3) regularly engages continuous quality improvement of organizational processes and practices related to DEIA; 4) provides training to all employees on diversity, equity, inclusion, and access; 5) engages in equitable decision-making by including input from multiple sources; 6) There is a clear feedback process and point of contact for diversity and equity related matters, including mechanisms to support employees harmed by acts of discrimination; 7) provides culturally relevant instruction, supports, and services to its students; 8) Employees feel accepted and respected.</p>',
        },
        purposeEmergingDistrict: {
            title: 'Purpose and Meaningfulness Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your district.</p>',
        },
        purposeProgressingDistrict: {
            title: 'Purpose and Meaningfulness Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your district.</p>',
        },
        purposeProficientDistrict: {
            title: 'Purpose and Meaningfulness Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your district.</p>',
        },
        purposeAboutDistrict: {
            title: 'Purpose and Meaningfulness Report (About)',
            message: `<p>Purpose and Meaningfulness are organizational strategies to align employee work with their personal values and strengths, and to provide work that is meaningful to employees.  Your Purpose and Meaningfulness Scale is comprised of eight indicators: 1) supports employees to identify their personal values and align those with their work; 2) Employees are proud to work in their school; 3) Job assignments and responsibilities are shaped around the employees' interests and strengths; 4) provides tasks and activities that are personally meaningful to employees; 5) Employees derive a sense of purpose from their careers; 6) provides opportunities for employees to identify their professional goals; 7) Employees feel that their work has meaningful impact; 8) helps employees recognize the value of their unique contributions to the overall mission of the school.</p>`,
        },
        qolEmergingDistrict: {
            title: 'Professional Quality of Life Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your district.</p>',
        },
        qolProgressingDistrict: {
            title: 'Professional Quality of Life Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your district.</p>',
        },
        qolProficientDistrict: {
            title: 'Professional Quality of Life Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your district.</p>',
        },
        qolAboutDistrict: {
            title: 'Professional Quality of Life Report (About)',
            message: `<p>Professional Quality of Life is the extent to which organizations create a day-to-day work environment in which employees have positive collegial relationships and derive pleasure from doing their work well. Your Professional Quality of Life Scale is comprised of eight indicators: 1) The workload for employees is reasonable and achievable; 2) Employees' training and skills are well utilized; 3) Employees feel their day-to-day tasks make a difference; 4) Employees are happy to work; 5) Employees enjoy the people they work with; 6) creates a sense of collegiality and teamwork among employees; 7) encourages new ideas and innovations from employees; 8) encourages appropriate boundaries between work and personal life.</p>`,
        },
        owbiStrategicPlanningPage1District: {
            title: 'Strategic Planning Guide - Page 1',
            message: `<p>This Strategic Planning Guide is a tool for teams who have completed the Organizational Well-Being Inventory for Schools (OWBI-S) and are ready to engage in a strategic planning process to improve organizational well-being in their schools. The OWBI-S provides an overview of the organizational well-being of your school system, often surfacing numerous strengths and opportunities for improvement or growth. However, most systems have more opportunities for improvement than they can realistically address in a given school year. Therefore, this guide is designed to help teams prioritize one to two areas for improvement, develop a measurable goal, and map out an initial plan including anticipated opportunities and barriers, action steps, and a timeline. <a title="Fillable PDF of Strategic Planning Guide for Districts" href="https://dm0gz550769cd.cloudfront.net/shape/d8/d8d2a1b6c1de42eb469ca3cbf39c2246.pdf" target="_blank" rel="noopener"><strong>Download a fillable version of this strategic planning guide.</strong></a></p>
                <p><strong><u>Prioritize 1&ndash;2 Improvement Areas </u></strong></p>
                <p>The Organizational Well-Being Inventory for Schools (OWBI-S) includes eight domains (Work Climate and Environment; Input; Flexibility and Autonomy; Professional Development and Recognition; Organizational and Supervisory Support; Self-Care; Diversity, Equity, Inclusion, and Access (DEIA); Purpose and Meaningfulness; and Professional Quality of Life). The District Version of the OWBI-S includes an assessment of district implementation support. We encourage you to meet with your district team and review your scores on each area provided in the OWBI-S report. Some teams have a clear idea of which improvement area they want to start with after they complete the OWBI-S. If this is true for your team, select your area and move directly to Step 2! If you are not sure yet which improvement area is right for you, consider the following discussion questions to build consensus on which area(s) to prioritize:</p>
                <ul>
                <li><strong>Desire</strong> &ndash; How much desire (e.g., motivation, need) is there to improve this area?</li>
                <li><strong>Impact</strong> &ndash; How much impact would improvements in this area have on overall staff and/or school well-being?</li>
                <li><strong>Resources </strong>&ndash; What resources (e.g., staffing, capacity, financial resources, leadership support, community support, partnerships, expertise) are available to make changes and improvements in this area?</li>
                </ul>
                <p><strong>Select one or two areas that your team would like to improve.&nbsp;</strong><strong>Priority Area(s):</strong></p>
                <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                <tbody>
                <tr>
                <td style="width: 23%; text-align: center;"><span style="font-size: 1em;">Input, Flexibility, and Autonomy</span></td>
                <td style="width: 27%; text-align: center;"><span style="font-size: 1em;">Professional Development and Recognition</span></td>
                <td style="width: 25.8621%; text-align: center;">Organizational and <br />Supervisory Support</td>
                <td style="width: 25.8621%; text-align: center;"><span style="font-size: 1em;">Diversity, Equity, Inclusion, Access (DEIA)</span></td>
                </tr>
                <tr>
                <td style="width: 23%; text-align: center;"><span style="font-size: 1em;">Purpose and Meaningfulness</span></td>
                <td style="width: 27%; text-align: center;">Professional Quality of Life</td>
                <td style="width: 25.8621%; text-align: center;"><span style="font-size: 1em;">District Implementation Support</span></td>
                <td style="width: 25.8621%; text-align: center;">&nbsp;</td>
                </tr>
                </tbody>
                </table>
                <p>These improvement areas are only your first step for the school year. Focusing on one area at a time will support consistency and momentum.</p>`,
        },
        owbiStrategicPlanningPage2District: {
            title: 'Strategic Planning Guide - Page 2',
            message: `<p>The following pages can be used as a tool to plan your next steps for this domain.<strong>&nbsp;</strong></p>
                <p><strong><u>Develop a Measurable Goal</u></strong></p>
                <p>Each improvement area is still quite broad. Develop a more specific, measurable goal to clarify and customize what you will focus on. For ideas about goals within a particular improvement area, refer to the OWBI-S Assessment or Report and other resources available in the SHAPE Resource Library.<strong> For a list of example SMARTIE goals by Improvement Area, please see pg. 2&nbsp;of the <a title="Fillable PDF of Strategic Planning Guide for Districts" href="https://dm0gz550769cd.cloudfront.net/shape/d8/d8d2a1b6c1de42eb469ca3cbf39c2246.pdf" target="_blank" rel="noopener">fillable version of this Strategic Planning Guide</a>.</strong></p>
                <p><strong>What are SMARTIE Goals?</strong></p>
                <ul>
                <li><strong>Specific&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Concrete, detailed, and well-defined</li>
                <li><strong>Measurable</strong>&nbsp; &nbsp;Numeric or descriptive with quantity, quality, or comparative to baseline</li>
                <li><strong>Actionable</strong>&nbsp; &nbsp; &nbsp;Feasible, attainable, actionable</li>
                <li><strong>Realistic </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Considers resources, barriers, strengths, and what can be achieved</li>
                <li><strong>Timebound</strong> &nbsp;&nbsp; Identified target dates, interim steps to monitor progress, and/or a timeline for goal to be achieved</li>
                <li><strong>Inclusive</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Brings traditionally marginalized students and families&mdash;particularly those most impacted&mdash;into intervention planning, <br />implementation, and progress monitoring in a way that shares power</li>
                <li><strong>Equitable</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Includes an element of fairness or justice that seeks to address systemic injustice, inequity, or oppression</li>
                </ul>
                <p><strong><u>Template</u></strong>: By [<em>time frame</em>], we will accomplish a [<em>measurable amount of</em>] [<em>our goal</em>] to improve [<em>quality indicator</em>].</p>
                <p>Be specific about how the goal or strategy is <em>inclusive and equitable</em>.</p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong><strong>GOAL</strong>: <u>_______________________________________________________________________</u></p>
                <p>&nbsp;</p>
                <p><u>______________________________________________________________________________</u></p>
                <p>&nbsp;</p>
                <p><strong>How will we know this goal has been achieved? <u>____________________________________</u></strong></p>
                <p>&nbsp;</p>
                <p><strong><u>______________________________________________________________________________</u></strong></p>
                <p>&nbsp;</p>`,
        },
        owbiStrategicPlanningPage3District: {
            title: 'Strategic Planning Guide - Page 3',
            message: `<p><strong>What opportunities exist related to this goal?</strong></p>
                <ul>
                <li>What have been our past successes?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What current work is taking place related to this goal?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What are our available resources (leadership, infrastructure, staffing, partnerships)?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>What barriers exist related to this goal?</strong></p>
                <ul>
                <li>What would prevent us from moving forward with this goal?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What would we need to overcome this/these barrier(s)?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>`,
        },
        owbiStrategicPlanningPage4District: {
            title: 'Strategic Planning Guide - Page 4',
            message: `<p><strong><u>Create an Action Plan</u></strong></p>
                <p>Identify 3-5 short-term action steps and 3-5 long-term action steps (if relevant) that can be taken to achieve this goal.</p>
                <p><strong>Short term: 0-6 months</strong></p>
                <p>&nbsp;</p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 22%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 22.6913%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.3087%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                </tbody>
                </table>`,
        },
        owbiStrategicPlanningPage5District: {
            title: 'Strategic Planning Guide - Page 5',
            message: `<p><strong>Long term: 7-24 months</strong></p>
                <p>&nbsp;</p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 19%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 20.8055%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.1945%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                </tbody>
                </table>`,
        },
    },
};

export const entity = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroEntity'],
        },
        {
            title: 'Work Climate & Environment',
            messages: [
                'workClimateEmergingEntity',
                'workClimateProgressingEntity',
                'workClimateProficientEntity',
                'workClimateAboutEntity',
            ],
        },
        {
            title: 'Input, Flexibility & Autonomy',
            messages: ['inputEmergingEntity', 'inputProgressingEntity', 'inputProficientEntity', 'inputAboutEntity'],
        },
        {
            title: 'Professional Development and Recognition',
            messages: [
                'developmentEmergingEntity',
                'developmentProgressingEntity',
                'developmentProficientEntity',
                'developmentAboutEntity',
            ],
        },
        {
            title: 'Organizational and Supervisory Support',
            messages: [
                'orgSupportEmergingEntity',
                'orgSupportProgressingEntity',
                'orgSupportProficientEntity',
                'orgSupportAboutEntity',
            ],
        },
        {
            title: 'Self-Care',
            messages: [
                'selfCareEmergingEntity',
                'selfCareProgressingEntity',
                'selfCareProficientEntity',
                'selfCareAboutEntity',
            ],
        },
        {
            title: 'Diversity, Equity, Inclusion, Access (DEIA)',
            messages: ['deiaEmergingEntity', 'deiaProgressingEntity', 'deiaProficientEntity', 'deiaAboutEntity'],
        },
        {
            title: 'Purpose and Meaningfulness',
            messages: [
                'purposeEmergingEntity',
                'purposeProgressingEntity',
                'purposeProficientEntity',
                'purposeAboutEntity',
            ],
        },
        {
            title: 'Professional Quality of Life',
            messages: ['qolEmergingEntity', 'qolProgressingEntity', 'qolProficientEntity', 'qolAboutEntity'],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'owbiStrategicPlanningPage1Entity',
                'owbiStrategicPlanningPage2Entity',
                'owbiStrategicPlanningPage3Entity',
                'owbiStrategicPlanningPage4Entity',
                'owbiStrategicPlanningPage5Entity',
            ],
        },
    ],
    messages: {
        summaryReportIntroEntity: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p>This report is generated based on the information you provided for the OWBI-S survey. The composite score for each domain is the average of your ratings for every item within the domain.</p><p>Composite scores of 1.0-1.9 are classified as <strong>"Emerging"</strong> areas, 2.0-2.9 are classified as <strong>"Progressing"</strong> areas, and 3.0-4.0 are classified as areas of <strong>"Proficient."</strong></p>',
        },

        workClimateEmergingEntity: {
            title: 'Work Climate & Environment Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        workClimateProgressingEntity: {
            title: 'Work Climate & Environment Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        workClimateProficientEntity: {
            title: 'Work Climate & Environment Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        workClimateAboutEntity: {
            title: 'Work Climate & Environment Report (About)',
            message:
                '<p>Work Climate and Environment is the physical and emotional climate of a school. This includes how well the physical space is maintained as well as whether policies support a friendly and supportive work environment in which employee rights are clear and protected. Your Work Climate and Environment Scale is comprised of eight indicators: 1) exterior and interior spaces are well-lit and physically well maintained (e.g., clean, secure, healthy); 2) provides employees with adequate resources to complete their job duties (e.g., providing adequate space, good Informational Technology (IT) systems, other practice supports ); 3) information and resources about employee rights and staff well-being are posted in places that are visible; 4) does not tolerate and appropriately responds to sexual harassment and other forms of harassment by adults or students;  5) has a formal, confidential process for employee grievances/complaints that is managed by those in the appropriate role; 6) only schedules meetings or activities that are relevant to my work and/or are an efficient use of my time; 7) leaders delegate work effectively; 8) employees are supported in setting and adhering to healthy workload boundaries.</p>',
        },
        inputEmergingEntity: {
            title: 'Input, Flexibility & Autonomy Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        inputProgressingEntity: {
            title: 'Input, Flexibility & Autonomy Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        inputProficientEntity: {
            title: 'Input, Flexibility & Autonomy Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        inputAboutEntity: {
            title: 'Input, Flexibility & Autonomy Report (About)',
            message: `<p>Input, Flexibility & Autonomy is the degree to which employee input is valued and incorporated into practices and policies and the degree to which employees can work flexibly and independently. Your Input, Flexibility & Autonomy Scale is comprised of eight indicators: 1) provides employees with a clear, effective process for giving feedback about practices and policies; 2) incorporates employees' feedback into decisions about practices and policies; 3) Employees are involved in the creation of new and updated policies and procedures; 4) Employees feel empowered to speak up about concerns they have in the workplace; 5) Employees feel empowered to bring up ideas and make suggestions to improve the school; 6) Employees are supported to function well autonomously; 7) Employees are given the autonomy to self-manage their work; 8) accommodates individual needs or responsibilities in employees' personal lives.</p>`,
        },
        developmentEmergingEntity: {
            title: 'Professional Development and Recognition Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        developmentProgressingEntity: {
            title: 'Professional Development and Recognition Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        developmentProficientEntity: {
            title: 'Professional Development and Recognition Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        developmentAboutEntity: {
            title: 'Professional Development and Recognition Report (About)',
            message: `<p>Professional Development and Recognition are efforts to train employees on relevant job skills and to acknowledge and reward job performance, professional accomplishments, and personal milestones and successes.  Your Professional Development and Recognition Scale is comprised of eight indicators: 1) provides all employees with training related to their specific job tasks; 2) Employees are provided opportunities, coverage, and continuing education credit to attend helpful trainings related to their role; 3) provides information and skills training and/or education on the steps necessary to advance in one's role; 4)  provides leadership development training; 5) offers perks/special privileges (e.g., better parking space) to employees who demonstrate high quality performance; 6) regularly recognizes and celebrates professional accomplishments (e.g., promotions); 7) regularly recognizes and celebrates personal milestones and successes (e.g., birthdays, life events);  8) recognizes people's passions, including activities and hobbies beyond work.</p>`,
        },
        orgSupportEmergingEntity: {
            title: 'Organizational and Supervisory Support Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        orgSupportProgressingEntity: {
            title: 'Organizational and Supervisory Support Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        orgSupportProficientEntity: {
            title: 'Organizational and Supervisory Support Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        orgSupportAboutEntity: {
            title: 'Organizational and Supervisory Support Report (About)',
            message:
                '<p>Organizational and Supervisory Support is the degree to which employees have organizational and supervisory support to define and navigate job responsibilities and are evaluated fairly. Your Organizational and Supervisory Support Scale is comprised of eight indicators: 1) School employee roles and responsibilities are clearly defined and adhered to; 2) Staff-to-student ratios are sufficient for staff to effectively perform their jobs; 3) provides helpful support for administrative tasks and procedures (e.g., completing paperwork); 4) All employees receive sufficient, timely, and relevant supervision and coaching; 5) provides effective and timely support to address instructional issues (e.g., new curricula); 6) provides effective and timely support to address student-related issues (e.g., student emotional and behavioral concerns); 7) Our employee evaluation procedure is clear and fair; 8) School employees feel comfortable discussing concerns about the school or their job with supervisors without fear of negative consequences (e.g., being treated differently, feeling like their job is in jeopardy or having it impact their role on the team).</p>',
        },
        selfCareEmergingEntity: {
            title: 'Self-Care Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        selfCareProgressingEntity: {
            title: 'Self-Care Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        selfCareProficientEntity: {
            title: 'Self-Care Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        selfCareAboutEntity: {
            title: 'Self-Care Report (About)',
            message:
                '<p>Self-Care are organizational efforts to assess and foster employee self-care, including education and training in job stress and self-care, accessible employee assistance programming, and opportunities for mindfulness and breaks.  Your Self-Care Scale is comprised of eight indicators: 1) provides continuous education and training for all employees to understand the connection between stress, well-being, and health including signs of burnout, compassion fatigue, and/or vicarious or secondary trauma; 2) provides protected time and encourages employees to participate in self-care and self-compassion activities; 3) An employee assistance program (EAP) is readily available to all employees and its use is encouraged; 4) Time is allotted in supervision and/or yearly reviews to freely discuss job stress and self-care strategies; 5) provides opportunities and space for all employees to practice mindfulness/take quiet time during the day; 6)  provides sufficient time for employees to take breaks during the day; 7) strongly encourages use of sick and vacation time; 8) At regular intervals, measures employee well-being and self-care.</p>',
        },
        deiaEmergingEntity: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        deiaProgressingEntity: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        deiaProficientEntity: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        deiaAboutEntity: {
            title: 'Diversity, Equity, Inclusion, Access (DEIA) Report (About)',
            message:
                '<p>Diversity, Equity, Inclusion, Access (DEIA)  are organizational efforts to ensure that multiple perspectives are represented, respected, and valued; that all members are treated fairly and justly; that space is made for the characteristics that each employee brings; and that diverse individuals are engaged in all aspects of organizational work, including decision-making processes. Your DEIA Scale is comprised of eight indicators: 1) has a clear working definition of diversity, equity, inclusion, and access; 2) Information about diversity, equity, inclusion, and access is posted in places that are visible; 3) regularly engages continuous quality improvement of organizational processes and practices related to DEIA; 4) provides training to all employees on diversity, equity, inclusion, and access; 5) engages in equitable decision-making by including input from multiple sources; 6) There is a clear feedback process and point of contact for diversity and equity related matters, including mechanisms to support employees harmed by acts of discrimination; 7) provides culturally relevant instruction, supports, and services to its students; 8) Employees feel accepted and respected.</p>',
        },
        purposeEmergingEntity: {
            title: 'Purpose and Meaningfulness Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        purposeProgressingEntity: {
            title: 'Purpose and Meaningfulness Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        purposeProficientEntity: {
            title: 'Purpose and Meaningfulness Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        purposeAboutEntity: {
            title: 'Purpose and Meaningfulness Report (About)',
            message: `<p>Purpose and Meaningfulness are organizational strategies to align employee work with their personal values and strengths, and to provide work that is meaningful to employees.  Your Purpose and Meaningfulness Scale is comprised of eight indicators: 1) supports employees to identify their personal values and align those with their work; 2) Employees are proud to work in their school; 3) Job assignments and responsibilities are shaped around the employees' interests and strengths; 4) provides tasks and activities that are personally meaningful to employees; 5) Employees derive a sense of purpose from their careers; 6) provides opportunities for employees to identify their professional goals; 7) Employees feel that their work has meaningful impact; 8) helps employees recognize the value of their unique contributions to the overall mission of the school.</p>`,
        },
        qolEmergingEntity: {
            title: 'Professional Quality of Life Report (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your entity.</p>',
        },
        qolProgressingEntity: {
            title: 'Professional Quality of Life Report (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your entity.</p>',
        },
        qolProficientEntity: {
            title: 'Professional Quality of Life Report (Proficient)',
            message: '<p>Based on your responses, this is a <i>proficient domain</i> for your entity.</p>',
        },
        qolAboutEntity: {
            title: 'Professional Quality of Life Report (About)',
            message: `<p>Professional Quality of Life is the extent to which organizations create a day-to-day work environment in which employees have positive collegial relationships and derive pleasure from doing their work well. Your Professional Quality of Life Scale is comprised of eight indicators: 1) The workload for employees is reasonable and achievable; 2) Employees' training and skills are well utilized; 3) Employees feel their day-to-day tasks make a difference; 4) Employees are happy to work; 5) Employees enjoy the people they work with; 6) creates a sense of collegiality and teamwork among employees; 7) encourages new ideas and innovations from employees; 8) encourages appropriate boundaries between work and personal life.</p>`,
        },
        owbiStrategicPlanningPage1Entity: {
            title: 'Strategic Planning Guide - Page 1',
            message: `<p>This Strategic Planning Guide is a tool for teams who have completed the Organizational Well-Being Inventory for Schools (OWBI-S) and are ready to engage in a strategic planning process to improve organizational well-being in their schools. The OWBI-S provides an overview of the organizational well-being of your school system, often surfacing numerous strengths and opportunities for improvement or growth. However, most systems have more opportunities for improvement than they can realistically address in a given school year. Therefore, this guide is designed to help teams prioritize one to two areas for improvement, develop a measurable goal, and map out an initial plan including anticipated opportunities and barriers, action steps, and a timeline. <a title="Fillable PDF of Strategic Planning Guide for Entities" href="https://dm0gz550769cd.cloudfront.net/shape/40/40ce6eb68e88716c9effa9b8a5b84193.pdf" target="_blank" rel="noopener"><strong>Download a fillable version of this strategic planning guide.</strong></a></p>
               <p><strong><u>Prioritize 1&ndash;2 Improvement Areas </u></strong></p>
               <p>The Organizational Well-Being Inventory for Schools (OWBI-S) includes eight domains (Work Climate and Environment; Input; Flexibility and Autonomy; Professional Development and Recognition; Organizational and Supervisory Support; Self-Care; Diversity, Equity, Inclusion, and Access (DEIA); Purpose and Meaningfulness; and Professional Quality of Life). The Entity Version of the OWBI-S includes an assessment of entity implementation support. We encourage you to meet with your entity team and review your scores on each area provided in the OWBI-S report. Some teams have a clear idea of which improvement area they want to start with after they complete the OWBI-S. If this is true for your team, select your area and move directly to Step 2! If you are not sure yet which improvement area is right for you, consider the following discussion questions to build consensus on which area(s) to prioritize:</p>
               <ul>
               <li><strong>Desire</strong> &ndash; How much desire (e.g., motivation, need) is there to improve this area?</li>
               <li><strong>Impact</strong> &ndash; How much impact would improvements in this area have on overall staff and/or school well-being?</li>
               <li><strong>Resources </strong>&ndash; What resources (e.g., staffing, capacity, financial resources, leadership support, community support, partnerships, expertise) are available to make changes and improvements in this area?</li>
               </ul>
               <p><strong>Select one or two areas that your team would like to improve.&nbsp;</strong><strong>Priority Area(s):</strong></p>
               <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
               <tbody>
               <tr>
               <td style="width: 23%; text-align: center;">Input, Flexibility, and Autonomy</td>
               <td style="width: 27%; text-align: center;">Professional Development and Recognition</td>
               <td style="width: 25.8621%; text-align: center;">Organizational and <br />Supervisory Support</td>
               <td style="width: 25.8621%; text-align: center;">Diversity, Equity, Inclusion, Access (DEIA)</td>
               </tr>
               <tr>
               <td style="width: 23%; text-align: center;">Purpose and Meaningfulness</td>
               <td style="width: 27%; text-align: center;">Professional Quality of Life</td>
               <td style="width: 25.8621%; text-align: center;">Entity Implementation Support</td>
               <td style="width: 25.8621%; text-align: center;">&nbsp;</td>
               </tr>
               </tbody>
               </table>
               <p>These improvement areas are only your first step for the school year. Focusing on one area at a time will support consistency and momentum.</p>`,
        },
        owbiStrategicPlanningPage2Entity: {
            title: 'Strategic Planning Guide - Page 2',
            message: `<p>The following pages can be used as a tool to plan your next steps for this domain.<strong>&nbsp;</strong></p>
            <p><strong><u>Develop a Measurable Goal</u></strong></p>
            <p>Each improvement area is still quite broad. Develop a more specific, measurable goal to clarify and customize what you will focus on. For ideas about goals within a particular improvement area, refer to the OWBI-S Assessment or Report and other resources available in the SHAPE Resource Library.<strong> For a list of example SMARTIE goals by Improvement Area, please see pg. 2&nbsp;of the <a title="Fillable PDF of Strategic Planning Guide for Entities" href="https://dm0gz550769cd.cloudfront.net/shape/40/40ce6eb68e88716c9effa9b8a5b84193.pdf" target="_blank" rel="noopener">fillable version of this Strategic Planning Guide</a>.</strong></p>
            <p><strong>What are SMARTIE Goals?</strong></p>
            <ul>
            <li><strong>Specific&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Concrete, detailed, and well-defined</li>
            <li><strong>Measurable</strong>&nbsp; &nbsp;Numeric or descriptive with quantity, quality, or comparative to baseline</li>
            <li><strong>Actionable</strong>&nbsp; &nbsp; &nbsp;Feasible, attainable, actionable</li>
            <li><strong>Realistic </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Considers resources, barriers, strengths, and what can be achieved</li>
            <li><strong>Timebound</strong> &nbsp;&nbsp; Identified target dates, interim steps to monitor progress, and/or a timeline for goal to be achieved</li>
            <li><strong>Inclusive</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Brings traditionally marginalized students and families&mdash;particularly those most impacted&mdash;into intervention planning, <br />implementation, and progress monitoring in a way that shares power</li>
            <li><strong>Equitable</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Includes an element of fairness or justice that seeks to address systemic injustice, inequity, or oppression</li>
            </ul>
            <p><strong><u>Template</u></strong>: By [<em>time frame</em>], we will accomplish a [<em>measurable amount of</em>] [<em>our goal</em>] to improve [<em>quality indicator</em>].</p>
            <p>Be specific about how the goal or strategy is <em>inclusive and equitable</em>.</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>&nbsp;</strong><strong>GOAL</strong>: <u>_______________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><u>______________________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><strong>How will we know this goal has been achieved? <u>____________________________________</u></strong></p>
            <p>&nbsp;</p>
            <p><strong><u>______________________________________________________________________________</u></strong></p>
            <p>&nbsp;</p>`,
        },
        owbiStrategicPlanningPage3Entity: {
            title: 'Strategic Planning Guide - Page 3',
            message: `<p><strong>What opportunities exist related to this goal?</strong></p>
                <ul>
                <li>What have been our past successes?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What current work is taking place related to this goal?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What are our available resources (leadership, infrastructure, staffing, partnerships)?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>What barriers exist related to this goal?</strong></p>
                <ul>
                <li>What would prevent us from moving forward with this goal?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                <li>What would we need to overcome this/these barrier(s)?</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>`,
        },
        owbiStrategicPlanningPage4Entity: {
            title: 'Strategic Planning Guide - Page 4',
            message: `<p><strong><u>Create an Action Plan</u></strong></p>
                <p>Identify 3-5 short-term action steps and 3-5 long-term action steps (if relevant) that can be taken to achieve this goal.</p>
                <p><strong>Short term: 0-6 months</strong></p>
                <p>&nbsp;</p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 22%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 22.6913%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.3087%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                </tbody>
                </table>`,
        },
        owbiStrategicPlanningPage5Entity: {
            title: 'Strategic Planning Guide - Page 5',
            message: `<p><strong>Long term: 7-24 months</strong></p>
                <p>&nbsp;</p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 19%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 20.8055%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.1945%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 19%;">&nbsp;</td>
                <td style="width: 20.8055%;">&nbsp;</td>
                <td style="width: 10.1945%;">&nbsp;</td>
                </tr>
                </tbody>
                </table>`,
        },
    },
};
