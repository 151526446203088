import React from 'react';
import PropTypes from 'prop-types';
import NavBar from 'js/components/NavBar';
import Footer from 'js/components/Footer';
import style from './style.css';

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: `container ${style.container}`,
        showSecondaryNav: true,
    };

    render() {
        return (
            <div className={style.body}>
                <div className={style.content}>
                    <NavBar className={this.props.className} />
                    <div className={this.props.className}>{this.props.children}</div>
                </div>
                <div className={style.footer}>
                    <Footer className={this.props.className} />
                </div>
            </div>
        );
    }
}
