import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import IconCross from 'cccisd-icons/cross2';
import RejectModal from './rejectModal.js';

export default class ApproveButton extends React.Component {
    static propTypes = {
        row: PropTypes.object,
    };

    render() {
        return (
            <Modal
                trigger={
                    <button
                        type="button"
                        className="btn btn-danger btn-xs"
                        style={{ width: '6em' }}
                    >
                        <IconCross /> Reject
                    </button>
                }
                size="30vw"
                title="Are you sure?"
            >
                <RejectModal row={this.props.row} />
            </Modal>
        );
    }
}
