import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import IconCheck from 'cccisd-icons/checkmark3';
import ApproveModal from './approveModal.js';

export default class ApproveButton extends React.Component {
    static propTypes = {
        row: PropTypes.object,
    };

    render() {
        return (
            <Modal
                trigger={
                    <button
                        type="button"
                        className="btn btn-success btn-xs"
                        style={{ width: '6em' }}
                    >
                        <IconCheck /> Approve
                    </button>
                }
                size="30vw"
                title="Are you sure?"
            >
                <ApproveModal row={this.props.row} />
            </Modal>
        );
    }
}
