import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';

export default class RecommendedResources extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
        stateSmhpResources: PropTypes.object,
    };

    render() {
        const { stateTitle, stateSmhpResources } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header_recommended_page}>
                            <div className={style.headerLogo_stateLevel}>
                                <div className={style.triangleState_ecc1} />
                            </div>
                            <div className={style.headerTitle_state}>Recommended Resources</div>
                        </div>

                        <div>{stateSmhpResources}</div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 16 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
