import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import schsafOne from '../../../images/schsafOne.png';
import schsafeTwo from '../../../images/schsafeTwo.png';
import socialOne from '../../../images/socialOne.png';
import socialTwo from '../../../images/socialTwo.png';
import info2logo from '../../../images/info2logo.png';

export default class InnovationsEmergingAreasPage16 extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain6: PropTypes.object,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
    };

    render() {
        const { domain6, stateTitle } = this.props;

        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo_stateLevel}>
                                <div className={style.triangleState_innovations1} />
                            </div>
                            <div className={style.headerTitle_state}>Innovations and Emerging Areas</div>
                        </div>
                        <div className={style.reportPage}>
                            <div className={style.bodyContent}>
                                <div className={style.bodyTopState}>
                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4>School Safety/Climate</h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.traumaOne} src={schsafOne} alt="schsafOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain6.mental_health_profile_schsafeclim}</span>
                                                </div>

                                                <div className={style.innova1234}>
                                                    <img
                                                        className={style.traumaTwo}
                                                        src={schsafeTwo}
                                                        alt="schsafeTwo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4>Social Determinants of Health</h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.traumaOne} src={socialOne} alt="socialOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain6.mental_health_profile_socialdetschmh}</span>
                                                </div>

                                                <div className={style.invoPage123}>
                                                    <img className={style.traumaTwo} src={socialTwo} alt="socialTwo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.reportPage}>
                                        {/* unknown div */}

                                        <div className={style.mainCartHeader_unknBoxPage16}>
                                            <div className={style.cartContentHeader_unknwBoxPage16}>
                                                <h4>
                                                    {' '}
                                                    <img
                                                        className={style.questionMarkpage16}
                                                        src={info2logo}
                                                        alt="i_logo"
                                                    />{' '}
                                                    Additional Information:{' '}
                                                </h4>
                                            </div>
                                            <div className={style.CartContent_unknBoxpage16}>
                                                <div className={style.firstbox_checkedBoxpage16}>
                                                    <span>{domain6.mental_health_profile_emergissue}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 13 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
