import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import SubHeading from '../SubHeading';
import BarGraph from '../BarGraph';

import GaugeGreen from '../images/GaugeGreen.png';
import GaugeYellow from '../images/GaugeYellow.png';
import GaugeRed from '../images/GaugeRed.png';
import style from './style.css';

import _some from 'lodash/some';
import _filter from 'lodash/filter';

const mapStateToProps = state => {
    return {
        mhqFields: state.app.params.mhqFields,
    };
};

export default connect(mapStateToProps)(
    class Impact extends React.Component {
        static propTypes = {
            domainId: PropTypes.number,
            enteredBy: PropTypes.array,
            domainName: PropTypes.string,
            pageNumber: PropTypes.number,
            totalPages: PropTypes.number,
            mhqFields: PropTypes.object,
            data: PropTypes.object,
            handle: PropTypes.string,
            flowType: PropTypes.string,
            getEnteredBy: PropTypes.func,
            selectedDates: PropTypes.object,
        };

        getGaugeImage = score => {
            if (score < 3) {
                return GaugeRed;
            }
            if (score >= 3 && score < 5) {
                return GaugeYellow;
            }
            return GaugeGreen;
        };

        getScoreColor = score => {
            if (score < 3) {
                return style.scoreRed;
            }
            if (score >= 3 && score < 5) {
                return style.scoreYellow;
            }
            return style.scoreGreen;
        };

        getScore = () => {
            const { data, handle } = this.props;
            // console.log('data and handle', data, handle);
            const scoreInfo = data.totalScores.find(item => item.handle === handle);
            // console.log('getScore scoreInfo', scoreInfo);
            return scoreInfo
                ? {
                      score: scoreInfo.score,
                      districtScore: scoreInfo.districtScore,
                      districtReportingScore: scoreInfo.districtReportingScore,
                  }
                : '*';
        };

        getDataByHandle = () => {
            const { data, handle } = this.props;
            const scoreData = data.totalScores.find(item => item.handle === handle);
            const indicatorData = { school: {}, district: {} };

            if (_some(scoreData.indicators, indicator => indicator.group === 'school')) {
                indicatorData.school = _filter(scoreData.indicators, indicator => indicator.group === 'school');
            }
            if (_some(scoreData.indicators, indicator => indicator.group === 'districtReporting')) {
                indicatorData.districtReporting = _filter(
                    scoreData.indicators,
                    indicator => indicator.group === 'districtReporting'
                );
            }
            if (_some(scoreData.indicators, indicator => indicator.group === 'district')) {
                indicatorData.district = _filter(scoreData.indicators, indicator => indicator.group === 'district');
            } else {
                indicatorData.school = scoreData ? scoreData.indicators : { score: NaN, title: 'N/A' };
            }

            // console.log('indicatorData', handle, indicatorData);
            return indicatorData;
        };

        getIndicatorScores = indicatorHandle => {
            const { data, handle } = this.props;
            // console.log('impact data', handle, data);
            let indicatorScores = { score: NaN, title: 'N/A' };

            if (data.totalScores) {
                indicatorScores = data.totalScores.find(item => item.handle === handle).indicators;
                const indicator = indicatorScores.find(item => item.handle === indicatorHandle);
                if (indicator) {
                    if (!Number.isNaN(indicator.score)) {
                        return indicator.score;
                    }
                    return '*';
                }
                return '*';
            }
            return NaN;
        };

        render() {
            const { domainName, pageNumber, totalPages, mhqFields, flowType } = this.props;
            const score = this.getScore().score;
            const districtReportingScore = this.getScore().districtReportingScore;
            const districtScore = this.getScore().districtScore;
            if (flowType === 'mhqSchool' && Number.isNaN(this.getIndicatorScores('Identified'))) {
                return null;
            }
            if (flowType === 'mhqDistrict' && Number.isNaN(districtReportingScore)) {
                return null;
            }
            if (flowType === 'mhqEntity' && Number.isNaN(districtReportingScore)) {
                return null;
            }
            // console.log('from impact', score, districtReportingScore, districtScore);
            return (
                <>
                    <Page>
                        <div className={style.content}>
                            <div className={style.header}>
                                <div className={style.headerLogo}>
                                    <div className={style.triangle} />
                                    <div className={style.logo} />
                                </div>
                                <div className={style.headerTitle}>{domainName}</div>
                            </div>
                            <SubHeading
                                getEnteredBy={this.props.getEnteredBy}
                                selectedDates={this.props.selectedDates}
                            />
                            <div className={style.reportPage}>
                                <div style={{ marginBottom: '1em' }}>
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            color: '#0b72b6',
                                            lineHeight: '1.8',
                                        }}
                                    >
                                        What is {domainName}?
                                    </span>
                                    <div className={style.description}>
                                        {flowType === 'mhqDistrict' && <Html content={mhqFields.impactAboutDistrict} />}
                                        {flowType === 'mhqEntity' && <Html content={mhqFields.impactAboutEntity} />}
                                        {!['mhqDistrict', 'mhqEntity'].includes(flowType) && (
                                            <Html content={mhqFields.impactAboutSchool} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.footer}>
                            <div className={style.footerText}>
                                <div>
                                    This progress report was developed by the National Center for School Mental Health
                                    for the SHAPE System.
                                </div>
                                <div>
                                    Page {pageNumber} of {totalPages}
                                </div>
                            </div>
                            <div className={style.footerLogo} />
                        </div>
                    </Page>
                    <Page>
                        <div className={style.content}>
                            <div className={style.header}>
                                <div className={style.headerLogo}>
                                    <div className={style.triangle} />
                                    <div className={style.logo} />
                                </div>
                                <div className={style.headerTitle}>{domainName}</div>
                            </div>
                            <SubHeading
                                getEnteredBy={this.props.getEnteredBy}
                                selectedDates={this.props.selectedDates}
                            />
                            <div className={style.reportPage}>
                                <div className={style.overallScore}>
                                    <div
                                        className={style.gauge}
                                        style={{
                                            backgroundImage: `url(${this.getGaugeImage(score)})`,
                                        }}
                                    />
                                    <div className={style.gaugeText}>
                                        {flowType === 'mhqDistrict' && (
                                            <div>
                                                <span className={style.gaugeDescription}>
                                                    For schools in your district
                                                </span>
                                            </div>
                                        )}
                                        {flowType === 'mhqEntity' && (
                                            <div>
                                                <span className={style.gaugeDescription}>
                                                    For schools in your entity
                                                </span>
                                            </div>
                                        )}
                                        <div className={style.gaugeScore}>
                                            OVERALL COMPOSITE SCORE:{' '}
                                            <span className={this.getScoreColor(score)}>{score}</span>
                                        </div>
                                    </div>
                                </div>
                                <BarGraph
                                    metrics={_filter(this.getDataByHandle().school, i => i.group === 'school')}
                                    flowType={flowType}
                                />
                                {flowType === 'mhqSchool' && (
                                    <>
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                color: '#0b72b6',
                                                lineHeight: '1.8',
                                            }}
                                        >
                                            Number of students who:
                                        </div>
                                        <div style={{ marginLeft: '1em' }}>
                                            <p>
                                                <b>{this.getIndicatorScores('Identified')}</b> Were eligible to receive
                                                Tier 2 or Tier 3 school mental health services
                                            </p>
                                            <p>
                                                <b>{this.getIndicatorScores('Received')}</b> Received at least one Tier
                                                2 or Tier 3 service
                                            </p>
                                            <p>
                                                <b>{this.getIndicatorScores('DocumentedEducational')}</b> Demonstrated
                                                documented improvement in educational functioning
                                            </p>
                                            <p>
                                                <b>{this.getIndicatorScores('DocumentedSocial')}</b> Demonstrated
                                                documented improvement in social, emotional and behavioral functioning
                                            </p>
                                        </div>
                                    </>
                                )}
                                {['mhqDistrict', 'mhqEntity'].includes(flowType) && (
                                    <>
                                        <div className={style.overallScore}>
                                            <div
                                                className={style.gauge}
                                                style={{
                                                    backgroundImage: `url(${this.getGaugeImage(
                                                        districtReportingScore
                                                    )})`,
                                                }}
                                            />
                                            <div className={style.gaugeText}>
                                                <div>
                                                    {flowType === 'mhqDistrict' && (
                                                        <span className={style.gaugeDescription}>
                                                            District-level documenting and reporting
                                                        </span>
                                                    )}
                                                    {flowType === 'mhqEntity' && (
                                                        <span className={style.gaugeDescription}>
                                                            Entity-level documenting and reporting
                                                        </span>
                                                    )}
                                                </div>
                                                <div className={style.gaugeScore}>
                                                    OVERALL COMPOSITE SCORE:{' '}
                                                    <span className={this.getScoreColor(districtReportingScore)}>
                                                        {districtReportingScore}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <BarGraph
                                            metrics={this.getDataByHandle().districtReporting}
                                            flowType={flowType}
                                        />
                                        <div className={style.overallScore}>
                                            <div
                                                className={style.gauge}
                                                style={{
                                                    backgroundImage: `url(${this.getGaugeImage(districtScore)})`,
                                                }}
                                            />
                                            <div className={style.gaugeText}>
                                                <div>
                                                    {flowType === 'mhqDistrict' && (
                                                        <span className={style.gaugeDescription}>District support</span>
                                                    )}
                                                    {flowType === 'mhqEntity' && (
                                                        <span className={style.gaugeDescription}>Entity support</span>
                                                    )}
                                                </div>
                                                <div className={style.gaugeScore}>
                                                    OVERALL COMPOSITE SCORE:{' '}
                                                    <span className={this.getScoreColor(districtScore)}>
                                                        {districtScore}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <BarGraph metrics={this.getDataByHandle().district} flowType={flowType} />
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={style.footer}>
                            <div className={style.footerText}>
                                <div>
                                    This progress report was developed by the National Center for School Mental Health
                                    for the SHAPE System.
                                </div>
                                <div>
                                    Page {pageNumber + 'b'} of {totalPages}
                                </div>
                            </div>
                            <div className={style.footerLogo} />
                        </div>
                    </Page>
                </>
            );
        }
    }
);
