import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import style from '../ReportSection/style.css';

export default class StrategicPlanningGuide extends React.Component {
    static propTypes = {
        siteFields: PropTypes.object,
        spgPages: PropTypes.array,
        totalPages: PropTypes.number,
        pageNumber: PropTypes.number,
        flowType: PropTypes.string,
    };

    getStategicPlanningGuidePage = page => {
        const { flowType, siteFields } = this.props;
        if (flowType === 'trs') {
            return <Html content={siteFields['trsStrategicPlanningPage' + page]} />;
        }
        if (flowType === 'owbiSchool') {
            return <Html content={siteFields['owbiStrategicPlanningPage' + page + 'School']} />;
        }
        if (flowType === 'owbiDistrict') {
            return <Html content={siteFields['owbiStrategicPlanningPage' + page + 'District']} />;
        }
        if (flowType === 'owbiEntity') {
            return <Html content={siteFields['owbiStrategicPlanningPage' + page + 'Entity']} />;
        }
        if (flowType === 'careSchool') {
            return <Html content={siteFields['careStrategicPlanningPage' + page + 'School']} />;
        }
        if (flowType === 'careDistrict') {
            return <Html content={siteFields['careStrategicPlanningPage' + page + 'District']} />;
        }
        if (flowType === 'careEntity') {
            return <Html content={siteFields['careStrategicPlanningPage' + page + 'Entity']} />;
        }
        if (flowType === 'familyEngagementSchool') {
            return <Html content={siteFields['familyEngagementPlanningPage' + page + 'School']} />;
        }
        if (flowType === 'familyEngagementEntity') {
            return <Html content={siteFields['familyEngagementPlanningPage' + page + 'Entity']} />;
        }
        if (flowType === 'familyEngagementDistrict') {
            return <Html content={siteFields['familyEngagementPlanningPage' + page + 'District']} />;
        }

        return <Html content={siteFields['strategicPlanningPage' + page]} />;
    };

    getLogo = () => {
        const { flowType } = this.props;
        if (['owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
            return style.logoOWBI;
        }
        if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
            return style.logoCARE;
        }
        if (flowType === 'trs') {
            return style.logoTRS;
        }
        if (
            [
                'familyEngagement',
                'familyEngagementSchool',
                'familyEngagementDistrict',
                'familyEngagementEntity',
            ].includes(flowType)
        ) {
            return style.logoFE;
        }

        return style.logo;
    };

    renderSpgPages = pageNumbers => {
        const { totalPages, pageNumber, flowType } = this.props;
        const spg = pageNumbers.map((page, index) => {
            return (
                <Page key={`SPGPage ${page}`}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo}>
                                <div className={style.triangle} />
                                <div className={this.getLogo()} />
                            </div>
                            <div className={style.headerTitle}>Strategic Planning Guide</div>
                        </div>
                        <div className={style.spgContent}>{this.getStategicPlanningGuidePage(page)}</div>
                    </div>
                    <div className={style.footer}>
                        <div className={flowType === 'trs' ? style.footerTextTRS : style.footerText}>
                            <div>
                                This progress report was developed by the{' '}
                                {flowType === 'trs' && 'Treatment and Services Adaptation Center and the'} National
                                Center for School Mental Health for the SHAPE System.
                            </div>
                            <div>
                                Page {pageNumber + index} of {totalPages}
                            </div>
                        </div>
                        {flowType === 'trs' && <div className={style.footerLogoTRS} />}
                        <div className={style.footerLogo} />
                    </div>
                </Page>
            );
        });

        return spg;
    };

    render() {
        const { spgPages } = this.props;

        return <div>{this.renderSpgPages(spgPages)}</div>;
    }
}
