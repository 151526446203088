export const smhpFields = {
    domains: [
        {
            title: 'School Mental Health Report Fields',
            messages: ['schoolSmhpAbout'],
        },
        {
            title: 'District SMH Report Fields',
            messages: ['districtSmhpAbout'],
        },
        {
            title: 'Entity SMH Report Fields',
            messages: ['entitySmhpAbout'],
        },
        {
            title: 'State SMH Report About Fields',
            messages: ['stateSmhpAbout'],
        },
        {
            title: 'State SMH Report ResourcesFields',
            messages: ['stateSmhpResources'],
        },

        {
            title: 'State Profile Strategic Planning Guide',
            messages: [
                'stateProfileStrategicPlanningGuide',
                'stateProfileStrategicPlanningGuide2',
                'stateProfileStrategicPlanningGuide3',
                'stateProfileStrategicPlanningGuide4',
                'stateProfileStrategicPlanningGuide5',
                'stateProfileStrategicPlanningGuide6',
            ],
        },
    ],
    messages: {
        districtSmhpAbout: {
            title: 'District School Mental Health Profile (About)',
            message: ` <p>Congratulations! Your district's team has been counted in the National School Mental Health Census and achieved Bronze SHAPE recognition for completing the School Mental Health Profile. Complete the National School Mental Health Performance Measures on SHAPE (the Quality and Sustainability Assessments) to achieve Silver and Gold SHAPE Recognition.</p><p>Schools and districts who register with SHAPE aspire toward having strong school-community-family partnerships that provide a multi-tiered continuum of evidence-based mental health services to support students, families, and the school community.</p><p>To learn more about this team's SHAPE account, inquire about being added as a team member, and/or join them in their quality improvement and sustainability efforts, contact the team leader.</p><p>To register a new school or district with SHAPE, please visit: <a href="#">https://theshapesystem.com/register</a>.</p>`,
        },
        schoolSmhpAbout: {
            title: 'School Mental Health Profile (About)',
            message: ` <p>Congratulations! Your school's team has been counted in the National School Mental Health Census and achieved Bronze SHAPE recognition for completing the School Mental Health Profile. Complete the National School Mental Health Performance Measures on SHAPE (the Quality and Sustainability Assessments) to achieve Silver and Gold SHAPE Recognition.</p><p>Schools and districts who register with SHAPE aspire toward having strong school-community-family partnerships that provide a multi-tiered continuum of evidence-based mental health services to support students, families, and the school community.</p><p>To learn more about this team's SHAPE account, inquire about being added as a team member, and/or join them in their quality improvement and sustainability efforts, contact the team leader.</p><p>To register a new school or district with SHAPE, please visit: <a href="#">https://theshapesystem.com/register</a>.</p>`,
        },
        entitySmhpAbout: {
            title: 'Entity School Mental Health Profile (About)',
            message: ` <p>Congratulations! Your entity's team has been counted in the National School Mental Health Census and achieved Bronze SHAPE recognition for completing the School Mental Health Profile. Complete the National School Mental Health Performance Measures on SHAPE (the Quality and Sustainability Assessments) to achieve Silver and Gold SHAPE Recognition.</p><p>Schools and entities who register with SHAPE aspire toward having strong school-community-family partnerships that provide a multi-tiered continuum of evidence-based mental health services to support students, families, and the school community.</p><p>To learn more about this team's SHAPE account, inquire about being added as a team member, and/or join them in their quality improvement and sustainability efforts, contact the team leader.</p><p>To register a new school or entity with SHAPE, please visit: <a href="#">https://theshapesystem.com/register</a>.</p>`,
        },
        stateSmhpAbout: {
            title: 'State School Mental Health Profile (About)',
            message: ` <p>Congratulations! Your state's team has been counted in the National School Mental Health Census and achieved Bronze SHAPE recognition for completing the School Mental Health Profile. Complete the National School Mental Health Performance Measures on SHAPE (the Quality and Sustainability Assessments) to achieve Silver and Gold SHAPE Recognition.</p><p>Schools and entities who register with SHAPE aspire toward having strong school-community-family partnerships that provide a multi-tiered continuum of evidence-based mental health services to support students, families, and the school community.</p><p>To learn more about this team's SHAPE account, inquire about being added as a team member, and/or join them in their quality improvement and sustainability efforts, contact the team leader.</p><p>To register a new school or entity with SHAPE, please visit: <a href="#">https://theshapesystem.com/register</a>.</p>`,
        },
        stateSmhpResources: {
            title: 'State School Mental Health Profile (Recommended Resources)',
            message: `<p><strong>National Center for School Metal Health (NCSMH)</strong></p>
            <p>The National Center for School Mental Health advances high-quality, sustainable comprehensive school mental health systems through applied research, 
            technical assistance, training, and policy promotion. The NCSMH website includes several resources to help advance comprehensive school mental health systems, 
            including the School Mental Health Quality Guide series.</p>

            <p><strong>The School Health Assessment and Performance Evaluation (SHAPE) System
            <p>The SHAPE System is a free, public-access, web-based platform that offers schools, districts, entities, and states a
             workspace and targeted resources to support continuous quality improvement and advance high-quality school mental health 
             systems. States can use SHAPE to learn more about the quality of school mental health in schools and districts across their 
             state and to monitor progress. The State School Mental Health Profile can be used by state leaders to guide school mental health policy and practice across the state.</p>
            
             <p><strong>Mental Health Technology Transfer Center (MHTTC) School Mental Health Initiative</strong></p>

            <p>The MHTTC School Mental Health Initiative page includes the National School Mental Health Best Practices: Implementation Guidance Modules for States,
             Districts, and Schools. The modules were developed in collaboration with the National Center for School Mental Health and focus on core domains of 
             school mental health, including Foundations of School Mental Health, Teaming, Needs Assessment and Resource Mapping, Screening, Mental Health Promotion 
             for All (Tier I), Early Intervention and Treatment (Tiers II/III), Funding and Sustainability, and Impact.</p>`,
        },

        stateProfileStrategicPlanningGuide: {
            title: 'State Profile Strategic Planning Guide',
            message: `<p class="p1">The questions below are organized by the sections of the Profile and are designed to prompt further 
            discussion about how to advance and sustain school mental health across your state.</p><h5><strong>STATE AGENCIES AND ORGANIZATIONS</strong></h5>
            <p class="p1"><strong>Questions for Consideration:</strong></p><ul class="ul1">
            1. What organizations/agencies are not actively involved in the advancement of comprehensive school mental health services and support in your 
            state that should be involved?
            <p>&nbsp;</p>
          
            
            a. What contributions could these organizations/agencies make?
            <p>&nbsp;</p>
            b. How and when could they best be engaged?
            <p>&nbsp;</p>
           
            
            2. Are there opportunities for organizations/agencies currently involved in the advancement of comprehensive school mental health systems to contribute 
            in new ways (i.e. leadership/oversight, technical assistance and training, funding, advocacy and policy, and service provision)?
            <p>&nbsp;</p>
            
            <p class="p1"><strong>Opportunities</strong></p>
            
            1. What current work is taking place to enhance the engagement of organizations and agencies in school mental health in your state?
            <p>&nbsp;</p>
            2. What has been the most successful strategy for engaging organizations and agencies in advancing school mental health in your state?
             <p>&nbsp;</p>
            3. What resources could help facilitate further engagement in school mental health (e.g. leadership, infrastructure, partnerships, staffing)?
             <p>&nbsp;</p>

             <p class="p1"><strong>Barriers</strong></p>
        
            1. What would prevent further engagement of organizations and agencies in advancing school mental health in your state?
             <p>&nbsp;</p>
            2. What would you need to overcome/these barriers(s) to improve organization and agency engagement in school mental health?
             <p>&nbsp;</p>`,
        },

        stateProfileStrategicPlanningGuide2: {
            title: 'State Profile Strategic Planning Guide page 2',
            message: `<p class="p1"><h5><strong>STATE-LEVEL LEADERSHIP</strong></h5></p>
            <ul>
            1. If your state does not have a state-level workgroup, mental health advisory, committee, or community of practice 
            that guides policies and planning for school mental health, how can you work towards the development of one? Who should be involved in the planning, development, 
            and implementation process?
            <p>&nbsp;</p>
            
            2. If your state has a state-level group, are there any improvements that can be made?
            <p>&nbsp;</p>
            3. If your state has more than one state-level group, how can collaboration between groups be improved?
            <p>&nbsp;</p>

            <p><strong>Opportunities</strong></p>
            
            1. What have been past successes with developing state-level workgroups to address school mental health in your state?
            <p>&nbsp;</p>
            2. What are available resources to work towards developing a state-level workgroup or enhancing the existing group(s) and collaboration?
            <p>&nbsp;</p>
            
            <p><strong>Barriers</strong></p>

            1. What would prevent the development of a state-level workgroup or enhance existing groups and collaboration in your state?
            <p>&nbsp;</p>
            2. What would be needed to overcome/these barrier(s)?
            <p>&nbsp;</p>
            <h5><strong>POLICY</strong></h5>
            <p class="p1">The chart below lists the major policy areas asked about in the State School Mental Health Profile. In the space below, state a 
            goal or action to address this policy area (e.g. developing policy, improving existing policy) and initial action steps.</p><p class="p1">Focus on policy areas that are most 
            needed or need refinement; also consider the extent to which existing policies are implemented to fidelity. It may be helpful to select 1-3 areas.</p>`,
        },

        stateProfileStrategicPlanningGuide3: {
            title: 'State Profile Strategic Planning Guide page 3',
            message: `<table style="height: 271px; border-color: black; background-color: white;" border="1" width="480">
            <tbody>
                <tr style="height: 22px;">
                    <td style="width: 211.891px; height: 22px;">&nbsp;</td>
                    <td style="width: 133.547px; height: 22px;">GOAL</td>
                    <td style="width: 133.562px; height: 22px;">&nbsp;ACTION</td>
                </tr>
                <tr style="height: 5px;">
                    <td style="width: 211.891px; height: 5px;">&nbsp;Multi-tiered system of support</td>
                    <td style="width: 133.547px; height: 5px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 5px;">&nbsp;</td>
                </tr>
                <tr style="height: 21.5px;">
                    <td style="width: 211.891px; height: 21.5px;">&nbsp;school climate</td>
                    <td style="width: 133.547px; height: 21.5px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21.5px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;School safety</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Violence prevention</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Social-emotional learning</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Bullying prevention</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Mental health/substance use screening</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Suicide prevention</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Trauma-informed schools/care</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Mental health education for students</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Mental health education for school staff</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Substance use prevention&nbsp;</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Mental health education requirements for educator certification/renewal</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;staff wellness</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Substance use education for students</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Substance use education for school staff</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Substance use education for caregivers/families</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Data collection/sharing</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;Telemedicine/telemental health</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;health care coverage for school mental health services</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
                <tr style="height: 21px;">
                    <td style="width: 211.891px; height: 21px;">&nbsp;other</td>
                    <td style="width: 133.547px; height: 21px;">&nbsp;</td>
                    <td style="width: 133.562px; height: 21px;">&nbsp;</td>
                </tr>
            </tbody>
        </table>`,
        },

        stateProfileStrategicPlanningGuide4: {
            title: 'State Profile Strategic Planning Guide page 4',
            message: `<p><h5><strong>FUNDING</strong></h5></p>
  
            1. What further information do you need to have to understand the school mental health funding picture in your state? How can you access this information?
            <p>&nbsp;</p>
            2. Are there funding sources that are not currently being used to support school mental health that could be used? If so, how can your 
            state leverage those sources?
            <p>&nbsp;</p>
            3. How can current funding sources be used more strategically? Are there funding sources that your state can better leverage?
            <p>&nbsp;</p>
            4. To what extent is the current funding for school mental health sustainable?
            <p>&nbsp;</p>
            5. To what extent is the current funding (e.g. consider duplication of efforts) able to adequately support the full school mental health service array? 
            To what extent is the braiding of funding strategic?
            <p>&nbsp;</p>
            
            
            <p><h5><strong>SERVICES AND SUPPORT</strong></h5></p>
     
            1. How can services and supports best be expanded across districts in your state? (consider equity across districts)
            <p>&nbsp;</p>
            2. What type of support do districts need from your state (e.g. funding, technical assistance/training) to further develop services and supports?
            <p>&nbsp;</p>`,
        },

        stateProfileStrategicPlanningGuide5: {
            title: 'State Profile Strategic Planning Guide page 5',
            message: `<p class="p1"><strong>Opportunities</strong></p>
            
            1. What have been the past successes with supporting mental health services and supports in districts across the state?
            <p>&nbsp;</p>
            2. What are available resources to work towards further developing this support in your state?
            <p>&nbsp;</p>
           
            <p class="p1"><strong>Barriers</strong></p>
          
            1. What would prevent the state from being able to help districts to provide needed school mental health services and supports?
            <p>&nbsp;</p>
            2. What would your state need to overcome/these barriers(s)?
            <p>&nbsp;</p>
         
            <p><h5><strong>INNOVATIONS AND EMERGING AREAS</strong></h5></p>
           
            1. How can your state further build on work to address emerging areas (trauma/ACEs, substance use, telemental health, school safety/climate, 
              social determinants of health)?
            <p>&nbsp;</p>
            2. What areas have not been addressed and how can your state begin to address them?
            <p>&nbsp;</p>
            
            3. What area is the state&rsquo;s top priority? What are the first steps that your state can take to work towards this priority emerging area?
              <p>&nbsp;</p>`,
        },

        stateProfileStrategicPlanningGuide6: {
            title: 'State Profile Strategic Planning Guide page 6',
            message: `<p><h5><strong>STAFFING AND COMMUNITY-PARTNERED BEHAVIORAL HEALTH</strong></h5></p>
                
                1. What can your state do to improve staffing to student ratios for school social work, psychology, counseling, and occupational therapy?
                <p>&nbsp;</p>
                2. Are there opportunities to expand community-partnered school behavioral health? How can the state better support these efforts?
                <p>&nbsp;</p>
             
                <p><h5><strong>BARRIERS AND RESOURCES</strong></h5></p>
                
                1. What can your state do to overcome identified barriers to advancing school mental health?
                <p>&nbsp;</p>
                
                2. How can your state better share existing resources (e.g., training, tools, policy guidelines) to advance school mental health across the state?
                <p>&nbsp;</p>`,
        },
    },
};
