import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';

export default class AppendixTable extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain3: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain3, stateTitle } = this.props;

        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.contentAppendixPage}>
                        <div className={style.header}>
                            <div className={style.headerLogo_appendixPage}>
                                <div className={style.triangle_appendixPage} />
                            </div>
                            <div className={style.title_appendixPage}> Appendix Table </div>
                        </div>
                        <div className={style.reportPage}>
                            <table className={style.appendixTable}>
                                <tr>
                                    <th>Policy Type</th>
                                    <th>Name of Policy/Regulation</th>
                                    <th>Bill Number and Link</th>
                                </tr>

                                {domain3.mental_health_profile_mtss === '1' ? (
                                    <tr>
                                        <td>Multi-tiered systems of support</td>
                                        <td>{domain3.mental_health_profile_mtss_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_mtss_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}

                                {domain3.mental_health_profile_d3_other1 === '1' ? (
                                    <tr>
                                        <td>{domain3.mental_health_profile_d3_other1_text}</td>
                                        <td>{domain3.mental_health_profile_d3_other1_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_d3_other1_1}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}

                                {domain3.mental_health_profile_d3_other2 === '1' ? (
                                    <tr>
                                        <td>{domain3.mental_health_profile_d3_other2_text}</td>
                                        <td>{domain3.mental_health_profile_d3_other2_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_d3_other2_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}

                                {domain3.mental_health_profile_schclim === '1' ? (
                                    <tr>
                                        <td>School climate</td>
                                        <td>{domain3.mental_health_profile_schclim_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_schclim_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}

                                {domain3.mental_health_profile_schsafe === '1' ? (
                                    <tr>
                                        <td>School safety</td>
                                        <td>{domain3.mental_health_profile_schsafe_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_schsafe_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_violprev === '1' ? (
                                    <tr>
                                        <td>Violence prevention</td>
                                        <td>{domain3.mental_health_profile_violprev_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_violprev_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_sel === '1' ? (
                                    <tr>
                                        <td>Social emotional learning</td>
                                        <td>{domain3.mental_health_profile_sel_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_sel_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_bullyprev === '1' ? (
                                    <tr>
                                        <td>Bullying prevention</td>
                                        <td>{domain3.mental_health_profile_bullyprev_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_bullyprev_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_mhsuscreen === '1' ? (
                                    <tr>
                                        <td>Mental health/substance use screening</td>
                                        <td>{domain3.mental_health_profile_mhsuscreen_1}</td>
                                        <td className={style.wordBreak}>
                                            {domain3.mental_health_profile_mhsuscreen_2}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_suicprev === '1' ? (
                                    <tr>
                                        <td>Suicide prevention</td>
                                        <td>{domain3.mental_health_profile_suicprev_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_suicprev_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_trauminform === '1' ? (
                                    <tr>
                                        <td>Trauma-informed schools/care</td>
                                        <td>{domain3.mental_health_profile_trauminform_1}</td>
                                        <td className={style.wordBreak}>
                                            {domain3.mental_health_profile_trauminform_2}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_mhedustud === '1' ? (
                                    <tr>
                                        <td>Mental health education for students</td>
                                        <td>{domain3.mental_health_profile_mhedustud_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_mhedustud_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_mhedustaff === '1' ? (
                                    <tr>
                                        <td>Mental health education for school staff</td>
                                        <td>{domain3.mental_health_profile_mhedustaff_1}</td>
                                        <td className={style.wordBreak}>
                                            {domain3.mental_health_profile_mhedustaff_2}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_mhedureq === '1' ? (
                                    <tr>
                                        <td>Mental health education requirement for educator certification/renewal</td>
                                        <td>{domain3.mental_health_profile_mhedureq_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_mhedureq_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_staffwell === '1' ? (
                                    <tr>
                                        <td>Staff wellness</td>
                                        <td>{domain3.mental_health_profile_staffwell_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_staffwell_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_suedustud === '1' ? (
                                    <tr>
                                        <td>Substance use education for students</td>
                                        <td>{domain3.mental_health_profile_suedustud_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_suedustud_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_suedustaff === '1' ? (
                                    <tr>
                                        <td>Substance use education for school staff</td>
                                        <td>{domain3.mental_health_profile_suedustaff_1}</td>
                                        <td className={style.wordBreak}>
                                            {domain3.mental_health_profile_suedustaff_2}
                                        </td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_suedufmly === '1' ? (
                                    <tr>
                                        <td>Substance use education for caregivers/families</td>
                                        <td>{domain3.mental_health_profile_suedufmly_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_suedufmly_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_suprev === '1' ? (
                                    <tr>
                                        <td>Substance use prevention (e.g. tobacco, opioid, alcohol)</td>
                                        <td>{domain3.mental_health_profile_suprev_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_suprev_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_datashare === '1' ? (
                                    <tr>
                                        <td>Data collection/sharing</td>
                                        <td>{domain3.mental_health_profile_datashare_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_datashare_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_telemhlth === '1' ? (
                                    <tr>
                                        <td>Telemedicine/telemental health</td>
                                        <td>{domain3.mental_health_profile_telemhlth_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_telemhlth_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {domain3.mental_health_profile_coverage === '1' ? (
                                    <tr>
                                        <td>
                                            Health care coverage for school mental health services (e.g. Medicaid, CHIP,
                                            private insurance)
                                        </td>
                                        <td>{domain3.mental_health_profile_coverage_1}</td>
                                        <td className={style.wordBreak}>{domain3.mental_health_profile_coverage_2}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                            </table>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 17 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
