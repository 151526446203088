import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';

export default class DistrictServiceSupport91 extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain5: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    render() {
        const { stateTitle, domain5 } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content_page9}>
                        <div className={style.header}>
                            <div className={style.headerLogo_appendixPage}>
                                <div className={style.triangle_appendixPage} />
                            </div>
                            <div className={style.title_appendixPage}> District School Mental Health Supports </div>
                        </div>
                        <div>
                            <div className={style.bodyTopState}>
                                <div className={style.firstHeading}>
                                    <p className={style.firstHeadingContentPage11}>
                                        {stateTitle ? stateTitle : ''} reported that school mental health services and
                                        supports are available in the following percentage of districts:
                                    </p>
                                </div>

                                <div>
                                    <table className={style.appendixTable9}>
                                        <tr>
                                            <th>Tier</th>
                                            <th className={style.SecHeadingpage9}>0%</th>
                                            <th className={style.SecHeadingpage9}>1% – 25%</th>
                                            <th className={style.SecHeadingpage9}>26% – 50%</th>
                                            <th className={style.SecHeadingpage9}> 51% – 75%</th>
                                            <th className={style.SecHeadingpage9}>76% – 100%</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span
                                                    style={{
                                                        color: '#4283C4',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Tier 3
                                                </span>{' '}
                                                Services and Supports
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier3 === '0' ? (
                                                    <div className={style.checkmark} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier3 === '1' ? (
                                                    <div className={style.checkmark} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier3 === '2' ? (
                                                    <div className={style.checkmark} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier3 === '3' ? (
                                                    <div className={style.checkmark} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier3 === '4' ? (
                                                    <div className={style.checkmark} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span
                                                    style={{
                                                        color: '#124888',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {' '}
                                                    Tier 2
                                                </span>{' '}
                                                Services and Supports
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier2 === '0' ? (
                                                    <div className={style.checkmark1} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier2 === '1' ? (
                                                    <div className={style.checkmark1} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier2 === '2' ? (
                                                    <div className={style.checkmark1} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier2 === '3' ? (
                                                    <div className={style.checkmark1} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier2 === '4' ? (
                                                    <div className={style.checkmark1} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {' '}
                                                <span
                                                    style={{
                                                        color: '#0A3F62',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {' '}
                                                    Tier 1
                                                </span>{' '}
                                                Services and Supports
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier1 === '0' ? (
                                                    <div className={style.checkmark2} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier1 === '1' ? (
                                                    <div className={style.checkmark2} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier1 === '2' ? (
                                                    <div className={style.checkmark2} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier1 === '3' ? (
                                                    <div className={style.checkmark2} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_tier1 === '4' ? (
                                                    <div className={style.checkmark2} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    fontWeight: 'bold',
                                                    textAlign: 'left',
                                                    paddingLeft: '1.5em',
                                                }}
                                            >
                                                Mental Health Screening
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_MHS === '0' ? (
                                                    <div className={style.checkmark3} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_MHS === '1' ? (
                                                    <div className={style.checkmark3} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_MHS === '2' ? (
                                                    <div className={style.checkmark3} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_MHS === '3' ? (
                                                    <div className={style.checkmark3} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className={style.SecHeadingpage9}>
                                                {domain5.mental_health_profile_MHS === '4' ? (
                                                    <div className={style.checkmark3} />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            {/* // */}
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 11 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
