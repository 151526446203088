import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'cccisd-loader';
import moment from 'moment';
import { getDomainOptions, getUniqueUsers } from 'js/selectors/shape.js';
import {
    mhqSchoolFlows,
    mhqDistrictFlows,
    trsFlows,
    owbiSchoolFlows,
    owbiDistrictFlows,
    careSchoolFlows,
    careDistrictFlows,
    familyEngagementSchoolFlows,
    familyEngagementDistrictFlows,
} from 'js/selectors/flowData.js';
import {
    loadData,
    getShapeFlowData,
    setTrsDataLoaded,
    setMhqDataLoaded,
    setOwbiDataLoaded,
    setCareDataLoaded,
    setFamilyEngagementDataLoaded,
    saveReportingPeriods,
    loadReportingPeriods,
} from 'js/reducers/shape.js';
import ReportForm from 'js/components/ReportForm';
import ShapeReport from 'js/components/ShapeReport';
import SMHProfileReport from 'js/components/SMHProfileReport';
import style from './style.css';

const mapStateToProps = (state, props) => {
    return {
        mhqFlowData: state.app.shape.mhqFlowData,
        trsFlowData: state.app.shape.trsFlowData,
        owbiFlowData: state.app.shape.owbiFlowData,
        careFlowData: state.app.shape.careFlowData,
        familyEngagementFlowData: state.app.shape.familyEngagementFlowData,
        startDate: state.app.shape.startDate,
        endDate: state.app.shape.endDate,
        reportingPeriods: state.app.shape.reportingPeriods,
    };
};

export default connect(mapStateToProps, {
    loadData,
    getShapeFlowData,
    setTrsDataLoaded,
    setMhqDataLoaded,
    setOwbiDataLoaded,
    setCareDataLoaded,
    setFamilyEngagementDataLoaded,
    saveReportingPeriods,
    loadReportingPeriods,
})(
    class ReportModal extends React.Component {
        static propTypes = {
            endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            groupStartDate: PropTypes.string,
            groupEndDate: PropTypes.string,
            groupReportingPeriods: PropTypes.string,
            trsFlowData: PropTypes.object,
            mhqFlowData: PropTypes.object,
            owbiFlowData: PropTypes.object,
            careFlowData: PropTypes.object,
            familyEngagementFlowData: PropTypes.object,
            flowType: PropTypes.string,
            loadData: PropTypes.func,
            getShapeFlowData: PropTypes.func,
            groupId: PropTypes.number,
            groupName: PropTypes.string,
            setTrsDataLoaded: PropTypes.func,
            setMhqDataLoaded: PropTypes.func,
            setOwbiDataLoaded: PropTypes.func,
            setCareDataLoaded: PropTypes.func,
            setFamilyEngagementDataLoaded: PropTypes.func,
            saveReportingPeriods: PropTypes.func,
            loadReportingPeriods: PropTypes.func,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            const { flowType } = this.props;

            await this.props.loadData();
            await this.props.getShapeFlowData(
                this.props.groupId,
                this.props.flowType,
                this.props.groupStartDate,
                this.props.groupEndDate
            );
            // Loads reportingPeriods of child group
            await this.props.saveReportingPeriods(this.props.groupReportingPeriods);
            if (flowType === 'trs') {
                await this.props.setTrsDataLoaded(false);
            } else if (['owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                await this.props.setOwbiDataLoaded(false);
            } else if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                await this.props.setCareDataLoaded(false);
            } else if (
                ['familyEngagementSchool', 'familyEngagementDistrict', 'familyEngagementEntity'].includes(flowType)
            ) {
                await this.props.setFamilyEngagementDataLoaded(false);
            } else {
                await this.props.setMhqDataLoaded(false);
            }

            this.setState({ reportSettings: this.getInitialValues(), loading: false });
        };

        componentWillUnmount = async () => {
            // Restores reportingPeriods for acting group
            await this.props.loadReportingPeriods();
        };

        getGroupFlows = () => {
            const { flowType } = this.props;

            if (flowType === 'mhqDistrict') {
                return mhqDistrictFlows;
            }
            if (flowType === 'mhqSchool') {
                return mhqSchoolFlows;
            }
            if (flowType === 'owbiDistrict') {
                return owbiDistrictFlows;
            }
            if (flowType === 'owbiSchool') {
                return owbiSchoolFlows;
            }
            if (flowType === 'careDistrict') {
                return careDistrictFlows;
            }
            if (flowType === 'careSchool') {
                return careSchoolFlows;
            }
            if (flowType === 'familyEngagementDistrict') {
                return familyEngagementDistrictFlows;
            }
            if (flowType === 'familyEngagementSchool') {
                return familyEngagementSchoolFlows;
            }
            if (flowType === 'trs') {
                return trsFlows;
            }
        };

        handleReportForm = async values => {
            // Values: {
            //  domain: array of integers from 1-15
            //  endDate: string "YYYY-MM"
            //  startDate: string "YYYY-MM"
            //  enteredBy: array of integer pawn ids
            //  reportType: string "Average" or "Last Completed"
            // }
            await this.props.getShapeFlowData(
                this.props.groupId,
                this.props.flowType,
                values.startDate,
                values.endDate,
                values.timePeriods
            );
            this.setState({ reportSettings: values });
        };

        getShapeFlowData = () => {
            const { flowType, trsFlowData, owbiFlowData, mhqFlowData, careFlowData, familyEngagementFlowData } =
                this.props;
            let shapeFlowData = mhqFlowData;

            if (flowType === 'trs') {
                shapeFlowData = trsFlowData;
            } else if (['owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                shapeFlowData = owbiFlowData;
            } else if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                shapeFlowData = careFlowData;
            } else if (
                ['familyEngagementSchool', 'familyEngagementDistrict', 'familyEngagementEntity'].includes(flowType)
            ) {
                shapeFlowData = familyEngagementFlowData;
            } else {
                shapeFlowData = mhqFlowData;
            }

            return shapeFlowData;
        };

        getInitialValues = () => {
            const { groupStartDate, groupEndDate, flowType } = this.props;
            const shapeFlowData = this.getShapeFlowData();
            const flows = this.getGroupFlows();
            const domainOptions = getDomainOptions(groupStartDate, groupEndDate, shapeFlowData, flows).map(d => d.id);
            // console.log('domain options', domainOptions);
            const enteredByOptions = getUniqueUsers(domainOptions, domainOptions, flowType, shapeFlowData).map(
                u => u.pawn_id
            );
            // console.log('entered by options', enteredByOptions);
            return {
                startDate: groupStartDate,
                endDate: groupEndDate,
                domain: domainOptions,
                enteredBy: enteredByOptions,
                reportType: 'Average',
                timePeriods: [moment(groupStartDate).format('MM/YYYY') + '-' + moment(groupEndDate).format('MM/YYYY')],
            };
        };

        getSpgPages = () => {
            const { flowType } = this.props;
            if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                return [1, 2, 3, 4, 5, 6];
            }

            if (['familyEngagementSchool', 'familyEngagementDistrict', 'familyEngagementEntity'].includes(flowType)) {
                return [1, 2, 3, 4, 5, 6];
            }

            if (this.props.flowType === 'trs') {
                return [1, 2, 3, 4];
            }

            return [1, 2, 3, 4, 5];
        };

        render() {
            const { flowType } = this.props;

            if (this.state.loading) {
                return <Loader />;
            }
            return (
                <>
                    {flowType === 'mhpReportSchool' || flowType === 'mhpReportDistrict' ? (
                        <SMHProfileReport
                            groupId={this.props.groupId}
                            groupName={this.props.groupName}
                            flowType={this.props.flowType}
                        />
                    ) : (
                        <>
                            <div className={style.reportForm}>
                                <ReportForm
                                    flows={this.getGroupFlows()}
                                    onSubmit={this.handleReportForm}
                                    initialValues={this.getInitialValues()}
                                    shapeFlowData={this.getShapeFlowData()}
                                    flowType={this.props.flowType}
                                    groupId={this.props.groupId}
                                />
                            </div>
                            <div className={style.shapeReport}>
                                <ShapeReport
                                    reportSettings={this.state.reportSettings}
                                    startDate={this.props.groupStartDate}
                                    endDate={this.props.groupEndDate}
                                    spgPages={this.getSpgPages()}
                                    flowType={this.props.flowType}
                                    showSummaryReport
                                    groupName={this.props.groupName}
                                    groupId={this.props.groupId}
                                    flows={this.getGroupFlows()}
                                />
                            </div>
                        </>
                    )}
                </>
            );
        }
    }
);
