import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import EntityForm from 'js/components/EntityForm';
import IconPencil from 'cccisd-icons/pencil';
import axios from 'cccisd-axios';
import { connect } from 'react-redux';
import Tooltip from 'cccisd-tooltip';
import _mapKeys from 'lodash/mapKeys';

const Boilerplate = window.cccisd.boilerplate;

class Component extends React.Component {
    static propTypes = {
        row: PropTypes.object,
        loadData: PropTypes.func,
        modules: PropTypes.array,
    };

    state = {
        childGroups: [],
    };

    updateChildGroups = id =>
        this.setState((prevState, props) => ({
            childGroups: [...prevState.childGroups, id],
        }));

    onSubmit = async values => {
        const result = await axios.put(
            Boilerplate.route('api.nexus.group.update', { group: this.props.row['group.groupId'] }),
            {
                ...values,
                label: values.entityName,
                // parent: +this.props.row['group.groupId'],
            }
        );

        if (result.data.errors) {
            const errors = _mapKeys(result.data.errors, (value, key) => {
                if (key === 'Entity ID') {
                    return 'entityId';
                }
                if (key === 'label') {
                    return 'entityName';
                }

                return key;
            });

            throw errors;
        } else {
            // now save the child groups
            const secondResult = await axios.put(
                Boilerplate.route('api.shape.entity.update', {
                    group: this.props.row['group.groupId'],
                }),
                {
                    ...values,
                    label: values.entityName,
                    childGroups: [],
                }
            );

            if (secondResult.data.errors) {
                const errors = _mapKeys(secondResult.data.errors, (value, key) => {
                    if (key === 'Entity ID') {
                        return 'entityId';
                    }
                    if (key === 'groupId') {
                        return 'entityName';
                    }

                    return key;
                });

                throw errors;
            }
        }
    };

    getInitialValues = () => {
        const { row } = this.props;

        return {
            groupId: row['group.groupId'],
            entityName: row['group.label'],
            entityId: row['fields.entityId'],
            childGroups: row['entity.childGroups'] || [],
        };
    };

    render() {
        const initialValues = this.getInitialValues();
        return (
            <Modal
                trigger={
                    <Tooltip title="Edit Entity Account">
                        <button type="button" className="btn btn-xs btn-success">
                            <IconPencil />
                        </button>
                    </Tooltip>
                }
                title="Edit Entity Account"
            >
                <EntityForm
                    initialValues={initialValues}
                    loadData={this.props.loadData}
                    onSubmit={this.onSubmit}
                    updateChildGroups={this.updateChildGroups}
                    childGroups={this.state.childGroups}
                />
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps)(Component);
