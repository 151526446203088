import React from 'react';
import PropTypes from 'prop-types';
import SubHeader from './SubHeader';
import { Page } from 'cccisd-laravel-report';
import IconCheck from 'cccisd-icons/checkmark3';
import style from './style.css';
const Fortress = window.cccisd && window.cccisd.fortress;

export default class SystemComponents1 extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain1: PropTypes.object,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain1 } = this.props;
        return (
            <Page>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo}>
                            <div className={style.triangle} />
                            <div className={style.logo} />
                        </div>
                        <div className={style.headerTitle}>
                            Students Served and Data Systems
                            <div className={style.headerSubtitle}>
                                {this.props.groupName
                                    ? this.props.groupName
                                    : Fortress.user.acting.group.label}
                            </div>
                        </div>
                    </div>
                    <SubHeader lastUpdated={this.props.lastUpdated} />
                    <div className={style.reportPage}>
                        <table className={style.reportTable}>
                            <tr>
                                <th
                                    colSpan="7"
                                    style={{
                                        fontSize: '16px',
                                        backgroundColor: '#387ab7',
                                        color: '#fff',
                                    }}
                                >
                                    DATA COLLECTION AND USE
                                </th>
                            </tr>
                            <tr style={{ background: '#f1f1f1' }}>
                                <th style={{ width: '200px' }}>Data Point</th>
                                <th>Data Collected</th>
                                <th>Identify Students for Mental Health Risk</th>
                                <th>Match/Triage Students to SMH Service Delivery</th>
                                <th>Track Individual Student Progress in SMH Interventions</th>
                                <th>Monitor/Evaluate SMH System Outcomes</th>
                                <th>Not Used</th>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Attendance
                                </td>
                                <td>
                                    {domain1.mental_health_profile_data_collected_attendance ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_mh_risk_attendance === '1' && (
                                        <IconCheck />
                                    )}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_service_delivery_attendance ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_intervention_progress_attendance ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_system_outcomes_attendance ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_collected_not_used_attendance ===
                                        '1' && <IconCheck />}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>Grades</td>
                                <td>
                                    {domain1.mental_health_profile_data_collected_grades ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_mh_risk_grades === '1' && (
                                        <IconCheck />
                                    )}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_service_delivery_grades ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_intervention_progress_grades ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_system_outcomes_grades ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_collected_not_used_grades ===
                                        '1' && <IconCheck />}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Office discipline referrals
                                </td>
                                <td>
                                    {domain1.mental_health_profile_data_collected_office_referrals ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_mh_risk_office_referrals ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_service_delivery_office_referrals ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_intervention_progress_office_referrals ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_system_outcomes_office_referrals ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_collected_not_used_office_referrals ===
                                        '1' && <IconCheck />}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    Out of school suspensions
                                </td>
                                <td>
                                    {domain1.mental_health_profile_data_collected_oos_suspensions ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_mh_risk_oos_suspensions ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_service_delivery_oos_suspensions ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_intervention_progress_oos_suspensions ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_system_outcomes_oos_suspensions ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_collected_not_used_oos_suspensions ===
                                        '1' && <IconCheck />}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                    School climate
                                </td>
                                <td>
                                    {domain1.mental_health_profile_data_collected_school_climate ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_mh_risk_school_climate ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_service_delivery_school_climate ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_intervention_progress_school_climate ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_system_outcomes_school_climate ===
                                        '1' && <IconCheck />}
                                </td>
                                <td>
                                    {domain1.mental_health_profile_collected_not_used_school_climate ===
                                        '1' && <IconCheck />}
                                </td>
                            </tr>
                            {domain1.mental_health_profile_data_collected_user_added_1 && (
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        {domain1.mental_health_profile_row_text_user_added_1}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_data_collected_user_added_1 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_mh_risk_user_added_1 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_service_delivery_user_added_1 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_intervention_progress_user_added_1 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_system_outcomes_user_added_1 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_collected_not_used_user_added_1 ===
                                            '1' && <IconCheck />}
                                    </td>
                                </tr>
                            )}
                            {domain1.mental_health_profile_data_collected_user_added_2 && (
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        {domain1.mental_health_profile_row_text_user_added_2}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_data_collected_user_added_2 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_mh_risk_user_added_2 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_service_delivery_user_added_2 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_intervention_progress_user_added_2 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_system_outcomes_user_added_2 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_collected_not_used_user_added_2 ===
                                            '1' && <IconCheck />}
                                    </td>
                                </tr>
                            )}
                            {domain1.mental_health_profile_data_collected_user_added_3 && (
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        {domain1.mental_health_profile_row_text_user_added_3}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_data_collected_user_added_3 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_mh_risk_user_added_3 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_service_delivery_user_added_3 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_intervention_progress_user_added_3 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_system_outcomes_user_added_3 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_collected_not_used_user_added_3 ===
                                            '1' && <IconCheck />}
                                    </td>
                                </tr>
                            )}
                            {domain1.mental_health_profile_data_collected_user_added_4 && (
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        {domain1.mental_health_profile_row_text_user_added_4}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_data_collected_user_added_4 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_mh_risk_user_added_4 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_service_delivery_user_added_4 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_intervention_progress_user_added_4 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_system_outcomes_user_added_4 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_collected_not_used_user_added_4 ===
                                            '1' && <IconCheck />}
                                    </td>
                                </tr>
                            )}
                            {domain1.mental_health_profile_data_collected_user_added_5 && (
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        {domain1.mental_health_profile_row_text_user_added_5}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_data_collected_user_added_5 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_mh_risk_user_added_5 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_service_delivery_user_added_5 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_intervention_progress_user_added_5 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_system_outcomes_user_added_5 ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain1.mental_health_profile_collected_not_used_user_added_5 ===
                                            '1' && <IconCheck />}
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        <div>
                            This profile was developed by the Center for School Mental Health for
                            The SHAPE System.
                        </div>
                        <div>Page 3 of {this.props.totalPages}</div>
                    </div>
                    <div className={style.footerLogo} />
                </div>
            </Page>
        );
    }
}
