import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import deploymentGroupQuery from './deploymentGroupQuery.graphql';

const Boilerplate = window.cccisd.boilerplate;

const AnonymousAssessment = () => {
    /* 
        Get the current URL object which will have the following:
        - assessment (e.g.owbi, care)
        - group type (e.g. school, district, entity)
        - group id

        /anonSurvey/:flowType/:groupId
        anonSurvey/owbiSchool/136108

        If returning to complete survey, URL will have additional params
        anonSurvey/:flowType/:groupId/:respondentHash?
    */

    const { flowType, groupId, respondentHash } = useParams();
    const [loading, setLoading] = useState(true);
    const [deployment, setDeployment] = useState([]);
    const [group, setGroup] = useState([]);
    const [anonPawnData, setAnonPawnData] = useState({
        pawnId: 0,
        pawnHash: '',
        respondentHash: '',
    });
    const [reportingPeriod, setReportingPeriod] = useState({
        start: 0,
        end: 0,
    });

    useEffect(() => {
        setLoading(true);
        const getDeploymentData = () => {
            async function fetchData() {
                try {
                    const response = await client.query({
                        query: deploymentGroupQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            handle: flowType + '_anon',
                            groupId,
                            respondentHash: respondentHash ? respondentHash : '0',
                        },
                    });

                    setDeployment(response.data.flows.deployment);
                    setGroup(response.data.groups.anyGroup);

                    if (response.data.roles.respondent.pawn.pawnId !== null) {
                        // returning user
                        const id = response.data.roles.respondent.pawn.pawnId;
                        const hash = response.data.roles.respondent.pawn.pawnHash;
                        const respHash = response.data.roles.respondent.pawn.respondentHash;

                        setAnonPawnData({
                            pawnId: id,
                            pawnHash: hash,
                            respondentHash: respHash,
                        });

                        const rpVars = response.data.roles.respondent.assignmentProgress.variables;
                        let start;
                        let end;

                        if (rpVars.length !== 0) {
                            start = rpVars.app_variable_reportingPeriodStart;
                            end = rpVars.app_variable_reportingPeriodEnd;
                        } else {
                            start = response.data.groups.anyGroup.group
                                ? response.data.groups.anyGroup.fields.reportingPeriodStart
                                : '';
                            end = response.data.groups.anyGroup.group
                                ? response.data.groups.anyGroup.fields.reportingPeriodEnd
                                : '';
                        }
                        setReportingPeriod({ start, end });

                        // save reporting period used for this anonymous survey
                        const data = {
                            groupId,
                            flowHandle: flowType + '_anon',
                            reportingStart: start,
                            reportingEnd: end,
                        };
                        await axios.post(Boilerplate.route('save.anon.reporting.periods'), {
                            ...data,
                        });
                    } else {
                        // new user
                        const result = await axios.post(Boilerplate.route('api.assignment.player.assignment'), {
                            ...response.data.flows.deployment,
                        });

                        const id = result.data.data.pawn.id;
                        const hash = result.data.data.pawn.random_hash;
                        const respHash = result.data.data.pawn.respondent_hash;

                        setAnonPawnData({
                            pawnId: id,
                            pawnHash: hash,
                            respondentHash: respHash,
                        });

                        let start = response.data.groups.anyGroup.group
                            ? response.data.groups.anyGroup.fields.reportingPeriodStart
                            : '';
                        let end = response.data.groups.anyGroup.group
                            ? response.data.groups.anyGroup.fields.reportingPeriodEnd
                            : '';

                        setReportingPeriod({ start, end });

                        // save reporting period used for this anonymous survey
                        const data = {
                            groupId,
                            flowHandle: flowType + '_anon',
                            reportingStart: start,
                            reportingEnd: end,
                        };
                        await axios.post(Boilerplate.route('save.anon.reporting.periods'), {
                            ...data,
                        });
                    }

                    setLoading(false);
                } catch (error) {
                    setDeployment(null);
                    setGroup(null);
                }
            }
            fetchData();
        };

        getDeploymentData();
    }, []); // Re-run when url params change ?

    if (loading) {
        return <Loader loading />;
    }
    // console.log({
    //     flowType,
    //     groupId,
    //     respondentHash,
    //     anonPawnData,
    // });
    return (
        <>
            <DeploymentPlayer
                deploymentId={deployment.deploymentId}
                deploymentHandle={deployment.deploymentHandle}
                pawnId={anonPawnData.pawnId}
                pawnHash={anonPawnData.pawnHash}
                flowProps={{
                    variables: {
                        groupName: group.group ? group.group.label : '',
                        groupId: group.group ? groupId : '',
                        reportingPeriodStart: reportingPeriod.start,
                        reportingPeriodEnd: reportingPeriod.end,
                        anonUrl: Boilerplate.url(
                            '/anonSurvey/' + flowType + '/' + group.group.groupId + '/' + anonPawnData.respondentHash
                        ),
                    },
                }}
            />
        </>
    );
};

export default AnonymousAssessment;
