import React from 'react';
import IconStar from 'cccisd-icons/star-full';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

export default settings => {
    return ({ value, row }) => {
        if (value === 'Bronze') {
            return (
                <Tooltip title="Bronze star = completion of the School Mental Health Profile">
                    <div style={{ color: '#cd7f32' }} className={style.star}>
                        <span>Bronze</span> <IconStar />
                    </div>
                </Tooltip>
            );
        }
        if (value === 'Silver') {
            return (
                <Tooltip title="Silver star = completion of at least one domain of the Quality Assessment">
                    <div style={{ color: '#C0C0C0' }} className={style.star}>
                        <span>Silver</span> <IconStar />
                    </div>
                </Tooltip>
            );
        }
        if (value === 'Gold') {
            return (
                <Tooltip title="Gold star = completion of all domains of the Quality Assessment">
                    <div style={{ color: '#FFD700' }} className={style.star}>
                        <span>Gold</span> <IconStar />
                    </div>
                </Tooltip>
            );
        }

        if (value === 'Blue') {
            return (
                <Tooltip title="Blue star = completion of SHAPE registration">
                    <div style={{ color: '#387ab7' }} className={style.star}>
                        <span>Blue</span> <IconStar />
                    </div>
                </Tooltip>
            );
        }

        if (
            row['descendantRoles.schoolAdminCount'] > 0 ||
            row['descendantRoles.districtAdminCount'] > 0 ||
            row['descendantRoles.stateAdminCount'] > 0 ||
            row['descendantRoles.entityAdminCount'] > 0
        ) {
            return (
                <Tooltip title="There is at least one admin for the group.">
                    <div className={style.star}>
                        <IconStar />
                    </div>
                </Tooltip>
            );
        }
        return '';
    };
};
