import React from 'react';
import { format } from 'date-fns';

export default settings => {
    return ({ value, row }) => {
        if (value === null || typeof value === 'undefined') {
            return '';
        }

        const domains = settings.domains;
        let lastContributorDomain = null;

        domains.forEach(domain => {
            let field = 'fields.' + domain;

            // get the last one added
            let contributors = JSON.parse(row[field]);
            let lastContributor = contributors ? contributors[contributors.length - 1] : null;

            if (lastContributor === null) {
                return;
            }

            if (
                lastContributorDomain === null ||
                lastContributor.completedDate > lastContributorDomain.completedDate
            ) {
                lastContributorDomain = lastContributor;
            }
        });

        return (
            <div key={row} className="text-center">
                {settings.value === 'name'
                    ? lastContributorDomain.completedByFullName
                    : format(lastContributorDomain.completedDate, 'YYYY-MM-DD')}
            </div>
        );
    };
};
