import React from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import trsFeedbackQuery from './trsFeedbackQuery.graphql';
import trsFeedbackFlowProgressQuery from './trsFeedbackFlowProgressQuery.graphql';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import style from './style.css';
import logo from '../../../images/TRSAssessment-ResourcesIcon.png';
import _get from 'lodash/get';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

export default class TRSFeedback extends React.Component {
    state = {
        loading: true,
    };

    static defaultProps = {
        flowHandle: 'TRS_follow-up',
    };

    componentDidMount = async () => {
        await this.getTRSData();
        this.setState({ loading: false });
    };

    getTRSData = async () => {
        const { flowHandle } = this.props;

        // lets get the specific deployment handle for this user role
        const roleHandle = _get(Fortress, 'user.acting.role.handle');
        const deploymentHandle = flowHandle + '_' + roleHandle;

        // query for the deployment info based on the role
        const response = await apollo.query({
            query: trsFeedbackQuery,
            fetchPolicy: 'network-only',
            variables: {
                deploymentHandle,
            },
        });

        const deployment = _get(response, 'data.flows.deployment', {});

        // query for the flow Progress for all pawns of this user
        const result = await apollo.query({
            query: trsFeedbackFlowProgressQuery,
            fetchPolicy: 'network-only',
            variables: {
                userId: Fortress.user.acting.user.id,
                flowHandle,
            },
        });

        const pawnList = _get(result, 'data.roles.anyRoleList');

        // only need to complete it once per user, regardless
        // of the number of roles for this user
        let completed = false;
        pawnList.forEach(pawn => {
            if (pawn.flowProgress.completed) {
                completed = true;
            }
        });

        await this.setState({
            completed,
            deployment,
        });
    };

    render() {
        const { loading, deployment, completed } = this.state;
        const deploymentId = _get(deployment, 'deploymentId');

        return (
            <Loader loading={loading} removeChildren>
                {!completed ? (
                    <DeploymentPlayer
                        deploymentId={deploymentId}
                        pawnId={Fortress.user.acting.id}
                        pawnHash={Fortress.user.acting.random_hash}
                        onComplete={this.onComplete}
                        flowProps={{ hidePlayButton: true }}
                    />
                ) : (
                    <div>
                        <div className={style.message}>
                            <img src={logo} alt="Logo" />
                            <div>
                                <h3>Trauma Responsiveness Assessment Feedback</h3>
                                <div>
                                    You have already completed this survey. Thank you for your
                                    feedback.
                                </div>
                                <div className={style.complete}>
                                    <a href={Boilerplate.url('/traumaResponsiveness')}>
                                        Complete another assessment
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Loader>
        );
    }
}
