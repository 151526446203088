export const districtDomainFields = [
    {
        id: 1,
        tag: 'teamingAboutDistrict',
    },
    {
        id: 2,
        tag: 'needsAssessmentAboutDistrict',
    },
    {
        id: 3,
        tag: 'screeningAboutDistrict',
    },
    {
        id: 4,
        tag: 'promotionAboutDistrict',
    },
    {
        id: 5,
        tag: 'earlyInterventionAboutDistrict',
    },
    {
        id: 6,
        tag: 'fundingAboutDistrict',
    },
    {
        id: 7,
        tag: 'impactAboutDistrict',
    },
];

export const entityDomainFields = [
    {
        id: 1,
        tag: 'teamingAboutEntity',
    },
    {
        id: 2,
        tag: 'needsAssessmentAboutEntity',
    },
    {
        id: 3,
        tag: 'screeningAboutEntity',
    },
    {
        id: 4,
        tag: 'promotionAboutEntity',
    },
    {
        id: 5,
        tag: 'earlyInterventionAboutEntity',
    },
    {
        id: 6,
        tag: 'fundingAboutEntity',
    },
    {
        id: 7,
        tag: 'impactAboutEntity',
    },
];

export const domainFields = [
    {
        id: 1,
        tag: 'teamingAboutSchool',
    },
    {
        id: 2,
        tag: 'needsAssessmentAboutSchool',
    },
    {
        id: 3,
        tag: 'screeningAboutSchool',
    },
    {
        id: 4,
        tag: 'promotionAboutSchool',
    },
    {
        id: 5,
        tag: 'earlyInterventionAboutSchool',
    },
    {
        id: 6,
        tag: 'fundingAboutSchool',
    },
    {
        id: 7,
        tag: 'impactAboutSchool',
    },
    {
        id: 8,
        tag: 'trsWssPlanning',
    },
    {
        id: 9,
        tag: 'trsWssPrevention',
    },
    {
        id: 10,
        tag: 'trsWsTrauma',
    },
    {
        id: 11,
        tag: 'trsClassroomStrat',
    },
    {
        id: 12,
        tag: 'trsEarlyIntervention',
    },
    {
        id: 13,
        tag: 'trsTtiProgramming',
    },
    {
        id: 14,
        tag: 'trsStaffSelfCare',
    },
    {
        id: 15,
        tag: 'trsFamilyEngagement',
    },
];

export const preschoolDomainFields = [
    {
        id: 1,
        tag: 'familyPartnershipsAboutPreschool',
    },
    {
        id: 2,
        tag: 'servicesAndSupportsAboutPreschool',
    },
    {
        id: 3,
        tag: 'teamingAboutPreschool',
    },
    {
        id: 4,
        tag: 'mentalHealthPromotionAboutPreschool',
    },
    {
        id: 5,
        tag: 'kindergartenAboutPreschool',
    },
    {
        id: 6,
        tag: 'MHScreeningAboutPreschool',
    },
];

export const owbiSchoolDomainFields = [
    {
        id: 9,
        tag: 'workClimate',
    },
    {
        id: 10,
        tag: 'input',
    },
    {
        id: 11,
        tag: 'development',
    },
    {
        id: 12,
        tag: 'orgSupport',
    },
    {
        id: 13,
        tag: 'selfCare',
    },
    {
        id: 14,
        tag: 'deia',
    },
    {
        id: 15,
        tag: 'purpose',
    },
    {
        id: 16,
        tag: 'qol',
    },
];

export const owbiDistrictDomainFields = [
    {
        id: 9,
        tag: 'workClimate',
    },
    {
        id: 10,
        tag: 'input',
    },
    {
        id: 11,
        tag: 'development',
    },
    {
        id: 12,
        tag: 'orgSupport',
    },
    {
        id: 13,
        tag: 'selfCare',
    },
    {
        id: 14,
        tag: 'deia',
    },
    {
        id: 15,
        tag: 'purpose',
    },
    {
        id: 16,
        tag: 'qol',
    },
];

export const owbiEntityDomainFields = [
    {
        id: 9,
        tag: 'workClimate',
    },
    {
        id: 10,
        tag: 'input',
    },
    {
        id: 11,
        tag: 'development',
    },
    {
        id: 12,
        tag: 'orgSupport',
    },
    {
        id: 13,
        tag: 'selfCare',
    },
    {
        id: 14,
        tag: 'deia',
    },
    {
        id: 15,
        tag: 'purpose',
    },
    {
        id: 16,
        tag: 'qol',
    },
];

export const careSchoolDomainFields = [
    {
        id: 10,
        tag: 'teaming',
    },
    {
        id: 11,
        tag: 'climate',
    },
    {
        id: 12,
        tag: 'discipline',
    },
    {
        id: 13,
        tag: 'curricula',
    },
    {
        id: 14,
        tag: 'prof_dev',
    },
];

export const careDistrictDomainFields = [
    {
        id: 10,
        tag: 'teaming',
    },
    {
        id: 11,
        tag: 'climate',
    },
    {
        id: 12,
        tag: 'discipline',
    },
    {
        id: 13,
        tag: 'curricula',
    },
    {
        id: 14,
        tag: 'prof_dev',
    },
];

export const careEntityDomainFields = [
    {
        id: 10,
        tag: 'teaming',
    },
    {
        id: 11,
        tag: 'climate',
    },
    {
        id: 12,
        tag: 'discipline',
    },
    {
        id: 13,
        tag: 'curricula',
    },
    {
        id: 14,
        tag: 'prof_dev',
    },
];

export const familyEngagementSchoolDomainFields = [
    {
        id: 1,
        tag: 'activities',
    },
    {
        id: 2,
        tag: 'caregiver',
    },
];

export const familyEngagementDistrictDomainFields = [
    {
        id: 1,
        tag: 'activities',
    },
    {
        id: 2,
        tag: 'caregiver',
    },
];

export const familyEngagementEntityDomainFields = [
    {
        id: 1,
        tag: 'activities',
    },
    {
        id: 2,
        tag: 'caregiver',
    },
];
