import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import page13One from '../../../images/page13One.png';
import page13two from '../../../images/page13two.png';
import page13three from '../../../images/page13three.png';
import page13five from '../../../images/page13five.png';
import page13six from '../../../images/page13six.png';
import page13seven from '../../../images/page13seven.png';
import info2logo from '../../../images/info2logo.png';

export default class InnovationsEmergingAreas extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain8: PropTypes.object,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
    };

    render() {
        const { domain8, stateTitle } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo_stateLevel}>
                                <div className={style.triangleState_barr1} />
                            </div>
                            <div className={style.headerTitle_state}>Barriers and Resources</div>
                        </div>
                        <div>
                            <div className={style.bodyContent}>
                                <div className={style.bodyTopState}>
                                    <div className={style.page13firstdiv}>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4 className={style.headmargin}>
                                                {stateTitle ? stateTitle : ''} identified the following barriers to
                                                advancing school mental health:
                                            </h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.page13One} src={page13One} alt="traumaOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain8.mental_health_profile_barrier}</span>
                                                </div>

                                                <div className={style.invoPage123}>
                                                    <img className={style.traumaTwo} src={page13two} alt="traumaTwo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4 className={style.headmargin}>
                                                {stateTitle ? stateTitle : ''} reported using the following resources to
                                                advance school mental health quality and sustainability:
                                            </h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.page13One} src={page13three} alt="subsOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain8.mental_health_profile_resource}</span>
                                                </div>

                                                <div className={style.innova1234}>
                                                    <img className={style.traumaTwo} src={page13five} alt="subsTwo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4 className={style.headmargin}>
                                                {stateTitle ? stateTitle : ''} identified the following as the most
                                                pressing technical assistance and resource needs:
                                            </h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.page13One} src={page13six} alt="teleOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain8.mental_health_profile_taneeds}</span>
                                                </div>

                                                <div className={style.invoPage123}>
                                                    <img className={style.traumaTwo} src={page13seven} alt="teleTwo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.additionalbarr2}>
                                        <div className={style.page13lastbox}>
                                            <div className={style.cartContentHeader_unknwBoxPage16}>
                                                <h4>
                                                    {' '}
                                                    <img
                                                        className={style.questionMarkpage16}
                                                        src={info2logo}
                                                        alt="i_logo"
                                                    />{' '}
                                                    Additional Information:{' '}
                                                </h4>
                                            </div>
                                            <div className={style.CartContent_unknBoxpage16}>
                                                <div className={style.firstbox_checkedBoxpage16}>
                                                    <span>{domain8.mental_health_profile_addtlinfo}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 15 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
