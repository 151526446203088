import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import book from '../../../images/book.png';
import bookTwo from '../../../images/book_2.png';
import bookRight from '../../../images/book_right.png';
import rightUsers from '../../../images/right_users.png';

export default class StateLevelLeadership extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain2: PropTypes.object,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
    };

    render() {
        const { domain2, stateTitle } = this.props;
        const stateLevel = val => {
            if (val === '1') {
                return (
                    <span>
                        {stateTitle ? stateTitle : ''} identified at least one state work group,
                        mental health advisory committee, or community or practice that guides
                        policies and planning for school mental health in{' '}
                        {stateTitle ? stateTitle : ''}.
                    </span>
                );
            }
            if (val === '0') {
                return (
                    <span>
                        {stateTitle ? stateTitle : ''} did not identify at least one state work
                        group, mental health advisory committee, or community or practice that
                        guides policies and planning for school mental health in{' '}
                        {stateTitle ? stateTitle : ''}.
                    </span>
                );
            }

            return (
                <span>
                    {stateTitle ? stateTitle : ''} did not indicate whether there is a state work
                    group, mental health advisory committee, or community or practice that guides
                    policies and planning for school mental health in {stateTitle ? stateTitle : ''}
                    .
                </span>
            );
        };

        const stateLevel2 = val => {
            if (val === '1') {
                return (
                    <span>
                        {stateTitle ? stateTitle : ''} identified a state-level school mental health
                        director or coordinator.
                    </span>
                );
            }
            if (val === '0') {
                return (
                    <span>
                        {stateTitle ? stateTitle : ''} indicated that there is not a state-level
                        school mental health director or coordinator.
                    </span>
                );
            }

            return (
                <span>
                    {stateTitle ? stateTitle : ''} did not indicate whether there is a state-level
                    school mental health director or coordinator.
                </span>
            );
        };
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo_stateLevel}>
                                <div className={style.triangleState_statelevel} />
                            </div>
                            <div className={style.headerTitle_state}>State-level Leadership</div>
                        </div>
                        <div className={style.reportPage}>
                            <div className={style.bodyContent}>
                                <div className={style.bodyTopState}>
                                    <p className={style.topStateContent_page3}>
                                        {stateLevel(domain2.mental_health_profile_ldrshp_1)}
                                    </p>

                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.cartContentHeader_stateLevel_01}>
                                            <h4>
                                                Groups that guide policies and planning for school
                                                mental health in state{' '}
                                                {stateTitle ? stateTitle : ''}:
                                            </h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img
                                                className={style.bookLogo_stateLevel}
                                                src={book}
                                                alt="bookLogo"
                                            />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.orderlist_div1}>
                                                    <ul>
                                                        {domain2.mental_health_profile_ldrshp_1 ===
                                                            '1' &&
                                                        domain2.mental_health_profile_ldrshp_2 !==
                                                            null
                                                            ? domain2.mental_health_profile_ldrshp_2
                                                                  .split('\n')
                                                                  .map(myListFirst => (
                                                                      <li>{myListFirst}</li>
                                                                  ))
                                                            : ''}
                                                    </ul>
                                                </div>

                                                <div className={style.orderlist_div2}>
                                                    <img
                                                        className={style.book_right_stateLevel}
                                                        src={bookRight}
                                                        alt="bookLogo"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.cartcontent_stateLevel}>
                                            <p>
                                                {stateLevel2(
                                                    domain2.mental_health_profile_ldrshp_3
                                                )}
                                            </p>
                                        </div>

                                        <div
                                            className={style.cartContentHeader_stateLevel_secondDiv}
                                        >
                                            <h4>
                                                Mental health director or coordinator name and
                                                information:
                                            </h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img
                                                className={style.bookLogo_2_stateLevel}
                                                src={bookTwo}
                                                alt="bookLogo"
                                            />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.orderlist_div1}>
                                                    {domain2.mental_health_profile_ldrshp_3 ===
                                                        '1' &&
                                                    domain2.mental_health_profile_ldrshp_4 !== null
                                                        ? domain2.mental_health_profile_ldrshp_4
                                                              .split('\n')
                                                              .map(myListFirst => (
                                                                  <span>
                                                                      {myListFirst} <br />{' '}
                                                                  </span>
                                                              ))
                                                        : ''}
                                                </div>

                                                <div className={style.orderlist_div2}>
                                                    <img
                                                        className={style.right_users_stateLevel}
                                                        src={rightUsers}
                                                        alt="Users"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on
                            responses from {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 5 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
