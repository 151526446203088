import React from 'react';
import Checkbox from './checkbox';

export default settings => {
    return ({ value, row, loadData, isCsv }) => {
        if (isCsv) {
            return value;
        }

        return <Checkbox value={value ? value : false} row={row} loadData={loadData} />;
    };
};
