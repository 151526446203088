import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Form from './form.js';
import getStatesQuery from './getStates.graphql';
import getDistrictsQuery from './districtsQuery.graphql';
import getSchoolsQuery from './schoolsQuery.graphql';
import getEntityGroupsQuery from './getEntityGroups.graphql';

class EntityForm extends React.Component {
    static propTypes = {
        initialValues: PropTypes.object,
        data: PropTypes.object,
        loadData: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        updateChildGroups: PropTypes.func,
        childGroups: PropTypes.array,
    };

    state = {
        tableData: [],
    };

    onSubmit = async values => {
        await this.props.onSubmit(values);
        await this.props.loadData();
        this.props.closeModal();
    };

    getStates = () => {
        return this.props.data.groups.stateList.map(item => ({
            groupId: item.group.groupId,
            label: item.group.label,
        }));
    };

    getDistricts = async stateId => {
        const response = await apollo.query({
            query: getDistrictsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: stateId,
            },
        });
        return response.data;
    };

    getSchools = async districtId => {
        const response = await apollo.query({
            query: getSchoolsQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId: districtId,
            },
        });
        return response.data;
    };

    getInitialValues = () => {
        return {
            ...this.props.initialValues,
        };
    };

    getTableData = async () => {
        const tableGroups = this.props.childGroups.map(id => Number(id));
        const result = await apollo.query({
            query: getEntityGroupsQuery,
            variables: {
                groupIds: tableGroups,
            },
            fetchPolicy: 'network-only',
        });
        const data = result.data.groups.anyGroupList;
        // console.log({ data });
        await this.setState({ tableData: data });
    };

    render() {
        const { loading } = this.props.data;

        if (loading) {
            return <Loader loading type="inline" />;
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValues()}
                states={this.getStates()}
                getDistricts={this.getDistricts}
                getSchools={this.getSchools}
                updateChildGroups={this.props.updateChildGroups}
                childGroups={this.props.childGroups}
                tableData={this.state.tableData}
                getTableData={this.getTableData}
            />
        );
    }
}

const mapStateToProps = (state, props) => ({});

export default graphql(getStatesQuery)(connect(mapStateToProps)(EntityForm));
