import React from 'react';
import ApproveButton from './ApproveButton';
import RejectButton from './RejectButton';

export default settings => {
    return ({ value, row }) => {
        return (
            <div style={{ padding: '0.5em' }}>
                <ApproveButton row={row} />
                <div style={{ marginTop: '0.5em' }} />
                <RejectButton row={row} />
            </div>
        );
    };
};
