import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import users from '../../../images/users.png';
import users22 from '../../../images/users22.png';
import users2 from '../../../images/users2.png';
import IconFile from 'cccisd-icons/file-text2';
import IconStar from 'cccisd-icons/star';

export default class StaffingAndcommunity extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain7: PropTypes.object,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
    };

    render() {
        const { domain7, stateTitle } = this.props;
        return (
            <Page>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo_stateLevel}>
                            <div className={style.triangleState_staffcommunity1} />
                        </div>
                        <div className={style.headerTitle_statepage17}>
                            Staffing and Community-Partnered Behavioral Health
                        </div>
                    </div>
                    <div className={style.reportPage17}>
                        <div className={style.bodyContent}>
                            <div className={style.bodyTopState}>
                                <div className={style.mainCartHeader_stateLevel}>
                                    <div>
                                        <p className={style.staff01}>
                                            Comprehensive school mental health systems are staffed in a variety of ways
                                            including a combination of community-employed and school-employed staff.
                                        </p>
                                    </div>

                                    <div className={style.staffdiv01}>
                                        <p className={style.staff01}>
                                            {stateTitle ? stateTitle : ''} reported the following approximate ratios
                                            between students and providers:
                                        </p>
                                    </div>

                                    <div className={style.tabledivpage17}>
                                        <div className={style.tabledivpage17One}>
                                            <table className={style.appendixTablepage17}>
                                                <tr>
                                                    <th>
                                                        <IconFile /> {stateTitle ? stateTitle : ''}{' '}
                                                    </th>
                                                    <th className={style.SecHeadingpage9} />
                                                </tr>
                                                <tr>
                                                    <td>School Counselors</td>

                                                    <td>
                                                        {domain7.mental_health_profile_schcnslr !== '' ? (
                                                            <span>{domain7.mental_health_profile_schcnslr}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>School Nurses</td>

                                                    <td>
                                                        {domain7.mental_health_profile_schnrse !== '' ? (
                                                            <span>{domain7.mental_health_profile_schnrse}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>School Social Workers</td>

                                                    <td>
                                                        {domain7.mental_health_profile_schsocwrk !== '' ? (
                                                            <span>{domain7.mental_health_profile_schsocwrk}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>School Psychologists</td>

                                                    <td>
                                                        {domain7.mental_health_profile_schpsych !== '' ? (
                                                            <span>{domain7.mental_health_profile_schpsych}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Occupational Therapists</td>

                                                    <td>
                                                        {domain7.mental_health_profile_occthrpst !== '' ? (
                                                            <span>{domain7.mental_health_profile_occthrpst}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className={style.tabledivpage17two}>
                                            <table className={style.appendixTablepage17two}>
                                                <tr>
                                                    <th>
                                                        {' '}
                                                        <IconStar /> National Recommendations
                                                    </th>
                                                    <th className={style.SecHeadingpage9} />
                                                </tr>
                                                <tr>
                                                    <td>School Counselors</td>

                                                    <td>250:1</td>
                                                </tr>
                                                <tr>
                                                    <td>School Nurses</td>

                                                    <td>750:1</td>
                                                </tr>
                                                <tr>
                                                    <td>School Social Workers</td>

                                                    <td>250:1</td>
                                                </tr>
                                                <tr>
                                                    <td>School Psychologists</td>

                                                    <td>500-700:1</td>
                                                </tr>
                                                <tr>
                                                    <td>Occupational Therapists</td>

                                                    <td>N/A</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div className={style.cartContentHeader_stateLevel}>
                                        <h4 className={style.heading1st}>
                                            {stateTitle ? stateTitle : ''} reported that the following school-employed
                                            professionals provide mental health services:
                                        </h4>
                                    </div>

                                    <div className={style.CartContent_stateLevel}>
                                        <img className={style.polreddivOne} src={users} alt="polic_reg" />
                                        <div className={style.OnOrderlist_progRed}>
                                            <div className={style.polregDivOne}>
                                                <span>{domain7.mental_health_profile_d7_other}</span>
                                            </div>

                                            <div className={style.polregDivTwo}>
                                                <img className={style.polregImageOne} src={users2} alt="polreg2" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.staffdiv03}>
                                        <p className={style.staff01}>
                                            Community-partnered school behavioral health programs refer to university
                                            hospitals and other clinical programs that provide school mental health
                                            services and supports within schools.
                                        </p>
                                    </div>
                                    {/* //last div */}

                                    <div className={style.tabledivpage17lastdiv}>
                                        <div className={style.tabledivpage17Onelastdiv}>
                                            <div className={style.lastdivPercentyage}>
                                                <h4 className={style.heading1st}>
                                                    {domain7.mental_health_profile_prtnrpercent === '777' ? (
                                                        <span className={style.spaceBetween}>
                                                            {stateTitle ? stateTitle : ''} reported that the percentage
                                                            of districts across the state that offer community-partnered
                                                            school behavioral health is unknown.
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {stateTitle ? stateTitle : ''} reported that
                                                            {domain7.mental_health_profile_prtnrpercent === '0' ? (
                                                                <span className={style.spaceBetween}> 0% </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {domain7.mental_health_profile_prtnrpercent === '1' ? (
                                                                <span className={style.spaceBetween}> 1-25% </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {domain7.mental_health_profile_prtnrpercent === '2' ? (
                                                                <span className={style.spaceBetween}> 26-50% </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {domain7.mental_health_profile_prtnrpercent === '3' ? (
                                                                <span className={style.spaceBetween}> 51-75% </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {domain7.mental_health_profile_prtnrpercent === '4' ? (
                                                                <span className={style.spaceBetween}> 76-100% </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            of districts across the state offer community-partnered
                                                            school behavioral health.
                                                        </>
                                                    )}
                                                </h4>
                                            </div>

                                            <img className={style.staffdiv04} src={users22} alt="polic_reg" />
                                        </div>
                                        <div className={style.tabledivpage17twolastdiv}>
                                            <svg width="140" height="140">
                                                <circle r="50" cx="70" cy="70" />
                                            </svg>
                                            <h1 className={style.headCircle}>
                                                {domain7.mental_health_profile_prtnrpercent === '0' ? (
                                                    <span className={style.spaceBetween} style={{ marginLeft: '1em' }}>
                                                        0%
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                                {domain7.mental_health_profile_prtnrpercent === '1' ? (
                                                    <span className={style.spaceBetween}>1-25%</span>
                                                ) : (
                                                    ''
                                                )}
                                                {domain7.mental_health_profile_prtnrpercent === '2' ? (
                                                    <span className={style.spaceBetween}>26-50%</span>
                                                ) : (
                                                    ''
                                                )}
                                                {domain7.mental_health_profile_prtnrpercent === '3' ? (
                                                    <span className={style.spaceBetween}>51-75%</span>
                                                ) : (
                                                    ''
                                                )}
                                                {domain7.mental_health_profile_prtnrpercent === '4' ? (
                                                    <span className={style.spaceBetween}>76-100%</span>
                                                ) : (
                                                    ''
                                                )}
                                                {domain7.mental_health_profile_prtnrpercent === '777' ? '' : ''}
                                            </h1>
                                        </div>
                                    </div>

                                    {/* end last div */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.footer_page3}>
                    <div className={style.footerText_Page}>
                        This report was automatically generated by the SHAPE System based on responses from{' '}
                        {stateTitle ? stateTitle : ''} state leaders.
                    </div>
                    <div className={style.footerLogoStateReport} />
                </div>
                <div className={style.pageNumber}>Page 14 of {this.props.totalPages}</div>
            </Page>
        );
    }
}
