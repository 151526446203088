import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'd3-format';
import { Page } from 'cccisd-laravel-report';
import SubHeader from '../SubHeader';
import StudentsServed from '../studentsServed.js';
import SystemComponents1 from '../systemComponents1.js';
import StaffingInformation from '../staffingInformation.js';
import ServicesProvided from '../servicesProvided.js';
import style from '../style.css';
const Fortress = window.cccisd.fortress;

export default class DistrictReport extends React.Component {
    static propTypes = {
        profileUpdatedDate: PropTypes.string,
        groupName: PropTypes.string,
        domain1: PropTypes.object,
        domain2: PropTypes.object,
        domain3: PropTypes.object,
        siteFields: PropTypes.node,
        totalPages: PropTypes.string,
        flowType: PropTypes.string,
    };

    render() {
        const {
            profileUpdatedDate,
            domain1,
            domain2,
            domain3,
            totalPages,
            groupName,
            flowType,
        } = this.props;

        return (
            <>
                <Page>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo}>
                                <div className={style.triangle} />
                                <div className={style.logo} />
                            </div>
                            <div className={style.headerTitle}>
                                {flowType === 'mhpReportEntity'
                                    ? 'School Mental Health Profile District'
                                    : 'School District Mental Health Profile District'}
                                <div className={style.headerSubtitle}>
                                    {groupName ? groupName : Fortress.user.acting.group.label}
                                </div>
                            </div>
                        </div>
                        <SubHeader lastUpdated={profileUpdatedDate} />
                        <div className={style.reportPage}>
                            <div className={style.bodyContent}>
                                <div className={style.bodyTop}>
                                    <div className={style.contentLeft}>
                                        <h4>Understanding this Summary</h4>
                                        {flowType === 'mhpReportEntity' ? (
                                            <>
                                                <p>
                                                    This report is generated based on the
                                                    information you provided for your School Mental
                                                    Health Profile.
                                                </p>
                                                <p>
                                                    {`This profile provides a snapshot of the structure and operations of your entity's comprehensive school mental health system.`}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    This report is generated based on the
                                                    information you provided for your School
                                                    District Mental Health Profile.
                                                </p>
                                                <p>
                                                    {`This profile provides a snapshot of the structure and operations of your school district's comprehensive school mental health system.`}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    <div className={style.contentRight}>
                                        {flowType === 'mhpReportEntity' ? (
                                            <>
                                                <p>Number of schools in your entity:</p>
                                                <p>
                                                    <span
                                                        style={{
                                                            marginLeft: '1em',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {format(',.0f')(
                                                            domain1.mental_health_profile_number_schools_entity
                                                        )}
                                                    </span>
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p>Number of schools in your district:</p>
                                                <p>
                                                    <span
                                                        style={{
                                                            marginLeft: '1em',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {format(',.0f')(
                                                            domain1.mental_health_profile_number_schools_district
                                                        )}
                                                    </span>
                                                </p>
                                            </>
                                        )}

                                        <p>Number of students in grades K-12:</p>
                                        <p>
                                            <span
                                                style={{
                                                    marginLeft: '1em',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {format(',.0f')(
                                                    domain1.mental_health_profile_students_enrolled
                                                )}
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div style={{ marginBottom: '1em' }}>
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            color: '#0b72b6',
                                            lineHeight: '1.8',
                                        }}
                                    >
                                        {flowType === 'mhpReportEntity'
                                            ? 'About Your School Mental Health Report'
                                            : 'About Your School District Mental Health Report'}
                                    </span>
                                    {this.props.siteFields}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer}>
                        <div className={style.footerText}>
                            <div>
                                This profile was developed by the Center for School Mental Health
                                for The SHAPE System.
                            </div>
                            <div>Page 1 of 6</div>
                        </div>
                        <div className={style.footerLogo} />
                    </div>
                </Page>

                <StudentsServed
                    lastUpdated={profileUpdatedDate}
                    domain1={domain1}
                    totalPages={totalPages}
                    groupName={groupName}
                />
                <SystemComponents1
                    lastUpdated={profileUpdatedDate}
                    domain1={domain1}
                    totalPages={totalPages}
                    groupName={groupName}
                />

                <StaffingInformation
                    lastUpdated={profileUpdatedDate}
                    domain2={domain2}
                    totalPages={totalPages}
                    groupName={groupName}
                />

                <ServicesProvided
                    lastUpdated={profileUpdatedDate}
                    domain3={domain3}
                    totalPages={totalPages}
                    groupName={groupName}
                />
            </>
        );
    }
}
