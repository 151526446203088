import React from 'react';
import PropTypes from 'prop-types';
import _difference from 'lodash/difference';
import _without from 'lodash/without';
import _union from 'lodash/union';
import IconArrowLeft from 'cccisd-icons/arrow-left3';
import IconArrowDown from 'cccisd-icons/arrow-down3';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
const Fortress = window.cccisd.fortress;

const admin = ['schoolAdmin', 'districtAdmin', 'stateAdmin', 'entityAdmin', 'preschoolAdmin'];
const teamMember = [
    'schoolTeamMember',
    'districtTeamMember',
    'stateTeamMember',
    'entityTeamMember',
    'preschoolTeamMember',
];

export default class UsersDropdown extends React.Component {
    static propTypes = {
        label: PropTypes.node,
        field: PropTypes.object,
        options: PropTypes.array,
    };

    state = {
        adminListVisible: false,
        userListVisible: false,
    };

    componentDidMount = () => {
        this.checkAllChecked();
        this.checkAdminsChecked();
        this.checkRegularUsersChecked();
    };

    onCheckBoxChange = e => {
        const { field } = this.props;
        const id = Number(e.target.value);
        const checked = e.target.checked;

        if (checked) {
            field.onChange([...field.value, id]);
        } else {
            field.onChange(field.value.filter(item => item !== id));
        }
        this.forceUpdate(() => {
            this.checkAllChecked();
            this.checkAdminsChecked();
            this.checkRegularUsersChecked();
        });
    };

    onCheckAllBoxChange = e => {
        const checked = e.target.checked;

        if (checked) {
            this.props.field.onChange(this.props.options.map(user => user.pawn_id));
        } else {
            this.props.field.onChange([]);
        }

        this.forceUpdate(() => {
            this.checkAdminsChecked();
            this.checkRegularUsersChecked();
        });
    };

    onGroupCheckChange = (e, role) => {
        const checked = e.target.checked;
        const userGroup = this.getUserGroup(role);
        const addGroup = _union(userGroup, this.props.field.value);
        const withoutGroup = _without(this.props.field.value, ...userGroup);
        if (checked) {
            this.props.field.onChange(addGroup);
        } else {
            this.props.field.onChange(withoutGroup);
        }
        this.forceUpdate(() => {
            this.checkAllChecked();
            this.checkAdminsChecked();
            this.checkRegularUsersChecked();
        });
    };

    getUserGroup = role => {
        return this.props.options
            .filter(user => role.indexOf(user.role) > -1)
            .map(user => user.pawn_id);
    };

    getName = () => {
        const { label, field, options } = this.props;
        // console.log('users options', options);
        // console.log('getName', field.value);
        if (options.length > 0) {
            if (field.value.length === 0) {
                return `Select ${label}`;
            }

            if (field.value.length === 1) {
                if (this.isTeamMember()) {
                    return '1 User';
                }
                const user = options.find(u => u.pawn_id === field.value[0]);
                if (user) {
                    return user.first_name + ' ' + user.last_name;
                }
            }
        }
        if (options.length === 0) {
            return 'No users found';
        }
        return `${field.value.length} ${label}s`;
    };

    checkAllChecked = () => {
        const { field, options } = this.props;
        const optionIds = options.map(user => user.pawn_id);

        return field.value.length === optionIds.length;
    };

    checkAdminsChecked = () => {
        const { field } = this.props;
        const admins = this.getUserGroup(admin);
        return _difference(admins, field.value).length === 0;
    };

    checkRegularUsersChecked = () => {
        const { field } = this.props;
        const users = this.getUserGroup(teamMember);

        return _difference(users, field.value).length === 0;
    };

    expandAdminList = () => {
        this.setState(prevState => ({ adminListVisible: !prevState.adminListVisible }));
    };

    expandUsersList = () => {
        this.setState(prevState => ({ userListVisible: !prevState.userListVisible }));
    };

    stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // console.log('Stop Propogation!');
    };

    isTeamMember = () => {
        const role = Fortress.user.acting.data_type;
        return (
            role === 'districtTeamMember' ||
            role === 'schoolTeamMember' ||
            role === 'stateTeamMember' ||
            role === 'preschoolTeamMember'
        );
    };

    render() {
        // console.log('dropdown props', this.props);
        const { field, label, options } = this.props;
        // console.log('usersDropdown', 'field', field, 'options', options);
        return (
            <div>
                <div className="dropdown">
                    <button
                        type="button"
                        className={`btn btn-default dropdown-toggle ${style.button}`}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                    >
                        <Tooltip title={this.getName()} placement="top">
                            <div className={style.buttonText}>
                                {this.getName()} <span className="caret" />
                            </div>
                        </Tooltip>
                    </button>

                    <ul
                        className="dropdown-menu"
                        style={{ padding: '0.5em', overflow: 'auto', width: '250px' }}
                    >
                        <form>
                            <div className={style.dropdownList}>
                                {options.length > 0 && (
                                    <li>
                                        <label className="dropdown-item">
                                            <div className={style.listItem}>
                                                <input
                                                    type="checkbox"
                                                    name="select-all"
                                                    value="select-all"
                                                    checked={this.checkAllChecked()}
                                                    onChange={this.onCheckAllBoxChange}
                                                />
                                                <div
                                                    className={style.optionName}
                                                >{`All  ${label}s`}</div>
                                            </div>
                                        </label>
                                    </li>
                                )}
                                {this.isTeamMember() &&
                                    options
                                        .filter(user => user.pawn_id === Fortress.user.acting.id)
                                        .map(option => (
                                            <li key={option.pawn_id}>
                                                <label className="dropdown-item">
                                                    <div className={style.listItem}>
                                                        <input
                                                            type="checkbox"
                                                            name={option.first_name}
                                                            value={option.pawn_id}
                                                            checked={field.value.includes(
                                                                option.pawn_id
                                                            )}
                                                            onChange={this.onCheckBoxChange}
                                                        />
                                                        <div className={style.optionName}>
                                                            {option.first_name +
                                                                ' ' +
                                                                option.last_name}
                                                        </div>
                                                    </div>
                                                </label>
                                            </li>
                                        ))}
                                {!this.isTeamMember() && (
                                    <li role="separator" className="divider" />
                                )}
                                {this.getUserGroup(admin).length > 0 && !this.isTeamMember() && (
                                    <li className={style.dropdownHead}>
                                        <label className="dropdown-item">
                                            <div className={style.listItem}>
                                                <input
                                                    type="checkbox"
                                                    name="select-admins"
                                                    value="select-admins"
                                                    checked={this.checkAdminsChecked()}
                                                    onChange={e =>
                                                        this.onGroupCheckChange(e, admin)
                                                    }
                                                />

                                                <div className={style.optionName}>All Admins</div>
                                            </div>
                                        </label>
                                        <div>
                                            <span
                                                className={style.dropdownIcon}
                                                onClick={this.expandAdminList}
                                            >
                                                {this.state.adminListVisible ? (
                                                    <IconArrowDown />
                                                ) : (
                                                    <IconArrowLeft />
                                                )}
                                            </span>
                                        </div>
                                    </li>
                                )}
                                {this.state.adminListVisible &&
                                    this.getUserGroup(admin).length > 0 &&
                                    !this.isTeamMember() && (
                                        <li role="separator" className="divider" />
                                    )}
                                <div
                                    className={
                                        this.state.adminListVisible ? style.list : style.listHidden
                                    }
                                >
                                    {!this.isTeamMember() &&
                                        options
                                            .filter(user => admin.indexOf(user.role) > -1)
                                            .map(option => (
                                                <li key={option.pawn_id}>
                                                    <label className="dropdown-item">
                                                        <div className={style.listItem}>
                                                            <input
                                                                type="checkbox"
                                                                name={option.first_name}
                                                                value={option.pawn_id}
                                                                checked={field.value.includes(
                                                                    option.pawn_id
                                                                )}
                                                                onChange={this.onCheckBoxChange}
                                                            />

                                                            <div className={style.optionName}>
                                                                {option.first_name +
                                                                    ' ' +
                                                                    option.last_name}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </li>
                                            ))}
                                </div>
                                {!this.isTeamMember() && (
                                    <li role="separator" className="divider" />
                                )}
                                {this.getUserGroup(teamMember).length > 0 && !this.isTeamMember() && (
                                    <li className={style.dropdownHead}>
                                        <label className="dropdown-item">
                                            <div className={style.listItem}>
                                                <input
                                                    type="checkbox"
                                                    name="select-users"
                                                    value="select-users"
                                                    checked={this.checkRegularUsersChecked()}
                                                    onChange={e =>
                                                        this.onGroupCheckChange(e, teamMember)
                                                    }
                                                />
                                                <div className={style.optionName}>
                                                    All Team Members
                                                </div>
                                            </div>
                                        </label>
                                        <div>
                                            <span
                                                className={style.dropdownIcon}
                                                onClick={this.expandUsersList}
                                            >
                                                {this.state.userListVisible ? (
                                                    <IconArrowDown />
                                                ) : (
                                                    <IconArrowLeft />
                                                )}
                                            </span>
                                        </div>
                                    </li>
                                )}
                                {this.state.userListVisible &&
                                    this.getUserGroup(teamMember).length > 0 && (
                                        <li role="separator" className="divider" />
                                    )}
                                <div
                                    className={
                                        this.state.userListVisible ? style.list : style.listHidden
                                    }
                                >
                                    {!this.isTeamMember() &&
                                        options
                                            .filter(user => teamMember.indexOf(user.role) > -1)
                                            .map(option => (
                                                <li key={option.pawn_id}>
                                                    <label className="dropdown-item">
                                                        <div className={style.listItem}>
                                                            <input
                                                                type="checkbox"
                                                                name={option.first_name}
                                                                value={option.pawn_id}
                                                                checked={field.value.includes(
                                                                    option.pawn_id
                                                                )}
                                                                onChange={this.onCheckBoxChange}
                                                            />
                                                            <div className={style.optionName}>
                                                                {option.first_name +
                                                                    ' ' +
                                                                    option.last_name}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </li>
                                            ))}
                                </div>
                            </div>
                        </form>
                    </ul>
                </div>
            </div>
        );
    }
}
