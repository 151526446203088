import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import bridgeDistrict from '../../../images/bridgeDistrict.png';

export default class DistrictServiceSupport8 extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain5: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    render() {
        const { stateTitle } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content_fundingPage}>
                        <div className={style.header}>
                            <div className={style.headerLogo_appendixPage}>
                                <div className={style.triangle_appendixPage} />
                            </div>
                            <div className={style.title_appendixPage}> District School Mental Health Supports </div>
                        </div>
                        <div>
                            <div className={style.bodyTopState}>
                                <div className={style.cardTopHeaderImage}>
                                    <h3>Definitions of Services and Supports</h3>
                                    <div className={style.BottomTriangle} />
                                </div>

                                <div className={style.bridgeDiv}>
                                    <img className={style.bridgeDistrictStyle} src={bridgeDistrict} alt="polic_reg" />
                                </div>

                                <div className={style.secondDivBridge2}>
                                    <p className={style.secondDivBridge}>
                                        <span className={style.secondDivBridge1}>Screening </span> is assessment in the
                                        absence of known risk factors to identify supports and interventions (e.g.,
                                        individual, family, school, community, system interventions) to prevent or
                                        address mental health concerns. Screening instruments may assess for individual,
                                        family, and community needs and strengths. This can be accomplished with a
                                        systematic tool or process that is culturally relevant for the population,
                                        including standardized student-, caregiver-, and/or teacher-report measures,
                                        mental health surveillance data, or a structured teacher nomination.
                                    </p>
                                </div>
                            </div>
                            {/* // */}
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 9 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
