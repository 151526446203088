import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import polreg from '../../../images/polreg.png';
import star1 from '../../../images/star1.png';
import polreg2 from '../../../images/polreg2.png';
import star2 from '../../../images/star2.png';

export default class PoliciesStateRegulations extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain3: PropTypes.object,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
    };

    render() {
        const { domain3, stateTitle } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo_stateLevel}>
                                <div className={style.triangleState_polices} />
                            </div>
                            <div className={style.headerTitle_state}>Policies</div>
                        </div>
                        <div className={style.reportPage}>
                            <div className={style.bodyContent}>
                                <div className={style.bodyTopState}>
                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.polices_state_reg}>
                                            <h4 className={style.heading1st}>
                                                The policy or regulation with the greatest impact on school mental
                                                health in {stateTitle ? stateTitle : ''}:
                                            </h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.polreddivOne} src={polreg} alt="polic_reg" />
                                            <div className={style.OnOrderlist_progRed}>
                                                <div className={style.polregDivOne}>
                                                    {domain3.mental_health_profile_impact !== '' ? (
                                                        <span>{domain3.mental_health_profile_impact}</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>

                                                <div className={style.polregDivTwo}>
                                                    <img className={style.polregImageOne} src={polreg2} alt="polreg2" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.content2nddiv}>
                                            <h4>
                                                The policies and regulations not currently in place in{' '}
                                                {stateTitle ? stateTitle : ''} that are most needed to advance school
                                                mental health:
                                            </h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.star12ndDiv} src={star1} alt="star1" />
                                            <div className={style.OnOrderlist_progRed}>
                                                <div className={style.polregDivOne}>
                                                    {domain3.mental_health_profile_needed !== '' ? (
                                                        <span>{domain3.mental_health_profile_needed}</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>

                                                <div className={style.polregDivTwo}>
                                                    <img className={style.polregImageOne} src={star2} alt="star2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 7 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
