import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';

export default class DistrictServiceSupport8_ extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain5: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    render() {
        const { stateTitle } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content_fundingPage}>
                        <div className={style.header}>
                            <div className={style.headerLogo_appendixPage}>
                                <div className={style.triangle_appendixPage} />
                            </div>
                            <div className={style.title_appendixPage}> District School Mental Health Supports </div>
                        </div>
                        <div className={style.reportPagedistPageOne}>
                            <div className={style.bodyTopStateDistPageOne}>
                                <div className={style.secondDivBridge3}>
                                    <p className={style.secondDivBridge4}>
                                        <span className={style.secondDivBridge5}>
                                            Mental health promotion (Tier 1){' '}
                                        </span>{' '}
                                        are mental health-related activities that are designed to meet the needs of all
                                        students regardless of whether they are at risk for mental health problems. Tier
                                        1 activities include promotion of positive social, emotional, and behavioral
                                        skills and well-being. These activities also include efforts to support staff
                                        well-being, improve school climate, and promote positive behavior. These
                                        activities can be implemented schoolwide, at the grade level, and/or at the
                                        classroom level and can be provided by school-employed and community-employed
                                        school-based professionals.
                                    </p>
                                </div>

                                <div className={style.secondDivBridge6}>
                                    <div className={style.secondDivBridge7} />
                                    <div className={style.secondDivBridge8}>
                                        Examples include schoolwide mental health education lessons, school climate
                                        improvement efforts, and classroom-based social emotional learning for all
                                        students.
                                    </div>
                                </div>

                                <div className={style.secondDivBridge3}>
                                    <p className={style.secondDivBridge4}>
                                        <span className={style.secondDivBridge5}>Early intervention (Tier 2) </span>{' '}
                                        address the mental health concerns of students who are experiencing mild
                                        distress, functional impairment, or are at risk for a given problem or concern.
                                        These students can be identified through needs assessments, screening, referral,
                                        or other school teaming processes. When mental health needs are identified early
                                        and culturally responsive, anti-racist, and equitable (CARE) supports are put in
                                        place, positive youth development is promoted, and the chronicity and severity
                                        of mental health concerns can be eliminated or reduced. Sometimes these are
                                        referred to as “selective” mental health “prevention” or “secondary prevention”
                                        services. Tier 2 services include services provided by all school-based mental
                                        health professionals, school-employed and community-employed.
                                    </p>
                                </div>

                                <div className={style.secondDivBridge6}>
                                    <div className={style.secondDivBridge7} />
                                    <div className={style.secondDivBridge8}>
                                        Examples include small group interventions for students identified with similar
                                        needs, transition support groups for newcomers, brief individualized
                                        interventions (e.g., motivational interviewing, problem solving), mentoring,
                                        and/or low-intensity classroom-based supports such as a daily report card, daily
                                        teacher check-in, and/or home/school note system.
                                    </div>
                                </div>

                                <div className={style.secondDivBridge3Last}>
                                    <p className={style.secondDivBridge4}>
                                        <span className={style.secondDivBridge5}>Treatment (Tier 3) </span> to address
                                        mental health concerns are provided for students who are already experiencing
                                        significant distress and functional impairment. Sometimes these are referred to
                                        as “indicated” mental health “intervention,” “tertiary,” or intensive services
                                        and are individualized to specific student needs. Tier 3 services include
                                        services provided by all school-based mental health professionals, including
                                        school-employed and community-employed.
                                    </p>
                                </div>

                                <div className={style.secondDivBridge63rd}>
                                    <div className={style.secondDivBridge79} />
                                    <div className={style.secondDivBridge8}>
                                        Examples include individual, group, or family therapy for students receiving
                                        general or special education who have been identified, and often diagnosed, with
                                        social, emotional, and/or behavioral needs.
                                    </div>
                                </div>
                            </div>
                            {/* // */}
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 10 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
