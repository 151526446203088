import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './style.css';

export default class SubHeading extends React.Component {
    static propTypes = {
        getEnteredBy: PropTypes.func,
        selectedDates: PropTypes.object,
    };

    render() {
        const { selectedDates, getEnteredBy } = this.props;
        return (
            <div className={style.date}>
                <div>
                    Reporting Period:{' '}
                    <span style={{ fontWeight: '300' }}>
                        {moment(selectedDates.startDate).format('MMMM YYYY')}
                        {' - '}
                        {moment(selectedDates.endDate).format('MMMM YYYY')}
                    </span>
                </div>
                <div>
                    Date of Report:{' '}
                    <span style={{ fontWeight: '300' }}>{moment().format('MM/DD/YYYY')}</span>
                </div>
                <div>
                    Entered By: <span style={{ fontWeight: '300' }}>{getEnteredBy()}</span>
                </div>
            </div>
        );
    }
}
