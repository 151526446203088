import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';

export default class StateStrategicPlanningGuide extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
        siteFieldPlanningGuide: PropTypes.object,
        siteFieldPlanningGuide2: PropTypes.object,
        siteFieldPlanningGuide3: PropTypes.object,
        siteFieldPlanningGuide4: PropTypes.object,
        siteFieldPlanningGuide5: PropTypes.object,
        siteFieldPlanningGuide6: PropTypes.object,
        flowType: PropTypes.string,
    };

    render() {
        const {
            stateTitle,
            siteFieldPlanningGuide,
            siteFieldPlanningGuide2,
            siteFieldPlanningGuide3,
            siteFieldPlanningGuide4,
            siteFieldPlanningGuide5,
            siteFieldPlanningGuide6,
        } = this.props;
        return (
            <>
                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.contentAppendixPage}>
                            <div className={style.header_spg2}>
                                <div className={style.headerLogo_appendixPage}>
                                    <div className={style.triangle_appendixPage} />
                                </div>
                                <div className={style.title_spg1}> State Strategic Planning Guide </div>
                            </div>

                            <div className={style.reportPage_spg}>{siteFieldPlanningGuide}</div>
                        </div>
                        <div className={style.footer_page3}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 18 of {this.props.totalPages}</div>
                    </div>
                </Page>

                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.contentAppendixPage}>
                            <div className={style.header_spg2}>
                                <div className={style.headerLogo_appendixPage}>
                                    <div className={style.triangle_appendixPage} />
                                </div>
                                <div className={style.title_spg1}> State Strategic Planning Guide </div>
                            </div>

                            <div className={style.reportPage_spg}>{siteFieldPlanningGuide2}</div>
                        </div>
                        <div className={style.footer_page3}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 19 of {this.props.totalPages}</div>
                    </div>
                </Page>

                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.contentAppendixPage}>
                            <div className={style.header_spg2}>
                                <div className={style.headerLogo_appendixPage}>
                                    <div className={style.triangle_appendixPage} />
                                </div>
                                <div className={style.title_spg1}> State Strategic Planning Guide </div>
                            </div>

                            <div className={style.reportPage_spg}>{siteFieldPlanningGuide3}</div>
                        </div>
                        <div className={style.footer_page3}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 20 of {this.props.totalPages}</div>
                    </div>
                </Page>

                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.contentAppendixPage}>
                            <div className={style.header_spg2}>
                                <div className={style.headerLogo_appendixPage}>
                                    <div className={style.triangle_appendixPage} />
                                </div>
                                <div className={style.title_spg1}> State Strategic Planning Guide </div>
                            </div>

                            <div className={style.reportPage_spg}>{siteFieldPlanningGuide4}</div>
                        </div>
                        <div className={style.footer_page3}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 21 of {this.props.totalPages}</div>
                    </div>
                </Page>

                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.contentAppendixPage}>
                            <div className={style.header_spg2}>
                                <div className={style.headerLogo_appendixPage}>
                                    <div className={style.triangle_appendixPage} />
                                </div>
                                <div className={style.title_spg1}> State Strategic Planning Guide </div>
                            </div>

                            <div className={style.reportPage_spg}>{siteFieldPlanningGuide5}</div>
                        </div>
                        <div className={style.footer_page3}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 22 of {this.props.totalPages}</div>
                    </div>
                </Page>

                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.contentAppendixPage}>
                            <div className={style.header_spg2}>
                                <div className={style.headerLogo_appendixPage}>
                                    <div className={style.triangle_appendixPage} />
                                </div>
                                <div className={style.title_spg1}> State Strategic Planning Guide </div>
                            </div>

                            <div className={style.reportPage_spg}>{siteFieldPlanningGuide6}</div>
                        </div>
                        <div className={style.footer_page3}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 23 of {this.props.totalPages}</div>
                    </div>
                </Page>
            </>
        );
    }
}
