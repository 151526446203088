import React from 'react';

/**
 * Set the last completed date based on the status of
 * the flow progress
 *
 */
export default settings => {
    return ({ value, row }) => {
        const status = row[settings.status];
        const completedDate = row[settings.completedDate];

        if (status === 'Complete') {
            return (
                <div key={row} className="text-center">
                    {completedDate}
                </div>
            );
        }

        return <div />;
    };
};
