import React from 'react';
import PropTypes from 'prop-types';
// import { format } from 'd3-format';
import { Page } from 'cccisd-laravel-report';
import SubHeader from '../SubHeader';
import style from '../style.css';
import OrgAgencyInvolvement from '../OrgAgencyInvolvement';
import OrgAgencyInvolvement2 from '../OrgAgencyInvolvement2';
import StateLevelLeadership from '../StateLevelLeadership';
import Polices from '../Polices';
import PoliciesStateRegulations from '../PoliciesStateRegulations';
import AppendixTable from '../AppendixTable';
import Funding from '../Funding';
import DistrictServiceSupport8 from '../DistrictServiceSupport8';
import DistrictServiceSupport81 from '../DistrictServiceSupport81';
import DistrictServiceSupport91 from '../DistrictServiceSupport91';
import InnovationsEmergingAreas from '../InnovationsEmergingAreas';
import InnovationsEmergingAreasPage16 from '../InnovationsEmergingAreasPage16';
import StaffingAndcommunity from '../StaffingAndcommunity';
import BarriersandResources from '../BarriersandResources';
import RecommendedResources from '../RecommendedResources';
import StateStrategicPlanningGuide from '../StateStrategicPlanningGuide';

export default class StateReport extends React.Component {
    static propTypes = {
        profileUpdatedDate: PropTypes.string,
        groupName: PropTypes.string,
        domain1: PropTypes.object,
        domain2: PropTypes.object,
        domain3: PropTypes.object,
        domain4: PropTypes.object,
        domain5: PropTypes.object,
        domain6: PropTypes.object,
        domain7: PropTypes.object,
        domain8: PropTypes.object,
        siteFields: PropTypes.node,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
        siteFieldPlanningGuide: PropTypes.object,
        siteFieldPlanningGuide2: PropTypes.object,
        siteFieldPlanningGuide3: PropTypes.object,
        siteFieldPlanningGuide4: PropTypes.object,
        siteFieldPlanningGuide5: PropTypes.object,
        siteFieldPlanningGuide6: PropTypes.object,
        stateSmhpResources: PropTypes.object,
    };

    render() {
        const {
            profileUpdatedDate,
            domain1,
            domain2,
            domain3,
            domain4,
            domain5,
            domain6,
            domain7,
            domain8,
            totalPages,
            groupName,
            stateTitle,
            siteFieldPlanningGuide,
            siteFieldPlanningGuide2,
            siteFieldPlanningGuide3,
            siteFieldPlanningGuide4,
            siteFieldPlanningGuide5,
            siteFieldPlanningGuide6,
        } = this.props;
        return (
            <>
                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.content}>
                            <div className={style.header}>
                                <div className={style.headerLogo}>
                                    <div className={style.triangle} />
                                    <div className={style.logo} />
                                </div>
                                <div className={style.headerTitle}>
                                    State School Mental Health Profile
                                    <div className={style.headerSubtitle}>{stateTitle ? stateTitle : ''}</div>
                                </div>
                            </div>
                            <SubHeader lastUpdated={profileUpdatedDate} />
                            <div className={style.reportPage1st}>
                                <div className={style.bodyContent}>
                                    <div style={{ marginBottom: '1em' }}>
                                        <div>
                                            <p>
                                                <b>The State School Mental Health Profile</b> summarizes information
                                                provided by {stateTitle ? stateTitle : ''} leaders about state-level
                                                school mental health indicators, including infrastructure, technical
                                                assistance and training, state policies, funding, staffing, and emerging
                                                school mental health issues. States can use this report to advance
                                                school mental health by identifying strengths and areas for growth.
                                            </p>
                                        </div>

                                        <div>
                                            <p>
                                                <b>Comprehensive school mental health systems provide</b> a full array
                                                of services and supports that promote positive school climate, social
                                                emotional learning, mental health, and well-being, while reducing the
                                                prevalence and severity of mental illness. Comprehensive school mental
                                                health systems are built on a strong foundation of district and school
                                                professionals, including administrators, educators, and specialized
                                                instructional support personnel (e.g., school psychologists, school
                                                social workers, school counselors, school nurses, and other school
                                                health professionals), in strategic partnership with students, families,
                                                and community health and mental health partners. These systems assess
                                                and address the social and environmental factors that influence
                                                education and mental health.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.footer}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 1 of {this.props.totalPages}</div>
                    </div>
                </Page>

                <Page>
                    <div className={style.pageWrapper}>
                        <div className={style.content}>
                            <div className={style.headerNew}>
                                <div className={style.headerLogoNew}>
                                    <div className={style.triangleStateNew} />
                                </div>
                                <div className={style.headerTitle_stateNew}>Organization and Agency Involvement</div>
                            </div>

                            <div className={style.reportPage}>
                                <div className={style.bodyContent}>
                                    <div className={style.bodyTopState}>
                                        <p className={style.topStateContent}>
                                            State organizations and agencies can support the advancement of school
                                            mental health in several ways, including: Leadership and Oversight,
                                            Technical Assistance and Training, Funding, Advocacy and Policy, and Service
                                            Provision.
                                        </p>
                                        <div className={style.cardTopHeader2ndPage}>
                                            <h3>Definitions of Involvement</h3>
                                            <div className={style.BottomTriangle} />
                                        </div>

                                        <div className={style.mainCartHeader1}>
                                            <div className={style.triangletop_left} />
                                            <div className={style.cartContentHeader}>
                                                <h4>Leadership and Oversight</h4>
                                            </div>
                                            {/* <div className={style.header_shape} /> */}
                                            <div className={style.CartContent}>
                                                <p>
                                                    {' '}
                                                    <span className={style.content_words}>
                                                        Leadership and oversight{' '}
                                                    </span>{' '}
                                                    refers to an organization/agency that drives the establishment of
                                                    vision and mission for school mental health in the state and
                                                    organizes and motivates others to work toward shared goals and
                                                    actions.
                                                </p>
                                            </div>
                                        </div>

                                        <div className={style.mainCartHeader_2nd}>
                                            <div className={style.triangletop_left_2nd} />
                                            <div className={style.cartContentHeader_2nd}>
                                                <h4>Technical Assistance and Training</h4>
                                            </div>
                                            {/* <div className={style.header_shape_2nd} /> */}
                                            <div className={style.CartContent_2nd}>
                                                <p>
                                                    {' '}
                                                    <span className={style.content_words}>
                                                        {' '}
                                                        Technical assistance and training{' '}
                                                    </span>{' '}
                                                    refers to the provision of knowledge and expertise, instruction,
                                                    skills training, resource development and sharing, and
                                                    implementation support.
                                                </p>
                                            </div>
                                        </div>

                                        <div className={style.mainCartHeader_3rd}>
                                            <div className={style.triangletop_left_3rd} />
                                            <div className={style.cartContentHeader_3rd}>
                                                <h4>Funding</h4>
                                            </div>
                                            {/* <div className={style.header_shape_3rd} /> */}
                                            <div className={style.CartContent_3rd}>
                                                <p>
                                                    {' '}
                                                    <span className={style.content_words}> Funding </span> refers to
                                                    financial and non-financial supports and resources related to
                                                    developing, maintaining, and expanding school mental health.
                                                </p>
                                            </div>
                                        </div>

                                        <div className={style.mainCartHeader_4rth}>
                                            <div className={style.triangletop_left_4rth} />
                                            <div className={style.cartContentHeader_4rth}>
                                                <h4>Advocacy and Policy</h4>
                                            </div>
                                            {/* <div className={style.header_shape_4rth} /> */}
                                            <div className={style.CartContent_4rth}>
                                                <p>
                                                    {' '}
                                                    <span className={style.content_words}>
                                                        {' '}
                                                        Advocacy and policy{' '}
                                                    </span>{' '}
                                                    refers to the act of increasing awareness and/or influencing
                                                    decision-making related to policy, procedures, and regulations
                                                    related to school mental health.
                                                </p>
                                            </div>
                                        </div>

                                        <div className={style.mainCartHeader_5th}>
                                            <div className={style.triangletop_left_5th} />
                                            <div className={style.cartContentHeader_5th}>
                                                <h4>Service Provision</h4>
                                            </div>
                                            {/* <div className={style.header_shape_5th} /> */}
                                            <div className={style.CartContent_5th}>
                                                <p>
                                                    {' '}
                                                    <span className={style.content_words}>
                                                        {' '}
                                                        Service provision{' '}
                                                    </span>{' '}
                                                    refers to the delivery of direct, school-based mental health or
                                                    wellness promotion, prevention, or intervention services to
                                                    students, families, or school staff.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.footer}>
                            <div className={style.footerText_Page}>
                                This report was automatically generated by the SHAPE System based on responses from{' '}
                                {stateTitle ? stateTitle : ''} state leaders.
                            </div>
                            <div className={style.footerLogoStateReport} />
                        </div>
                        <div className={style.pageNumber}>Page 2 of {this.props.totalPages}</div>
                    </div>
                </Page>

                <OrgAgencyInvolvement
                    lastUpdated={profileUpdatedDate}
                    domain1={domain1}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <OrgAgencyInvolvement2
                    lastUpdated={profileUpdatedDate}
                    domain1={domain1}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <StateLevelLeadership
                    lastUpdated={profileUpdatedDate}
                    domain2={domain2}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <Polices
                    lastUpdated={profileUpdatedDate}
                    domain3={domain3}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <PoliciesStateRegulations
                    lastUpdated={profileUpdatedDate}
                    domain3={domain3}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <Funding
                    lastUpdated={profileUpdatedDate}
                    domain4={domain4}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <DistrictServiceSupport8
                    lastUpdated={profileUpdatedDate}
                    domain5={domain5}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <DistrictServiceSupport81
                    lastUpdated={profileUpdatedDate}
                    domain5={domain5}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <DistrictServiceSupport91
                    lastUpdated={profileUpdatedDate}
                    domain5={domain5}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <InnovationsEmergingAreas
                    lastUpdated={profileUpdatedDate}
                    domain6={domain6}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <InnovationsEmergingAreasPage16
                    lastUpdated={profileUpdatedDate}
                    domain6={domain6}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <StaffingAndcommunity
                    lastUpdated={profileUpdatedDate}
                    domain7={domain7}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <BarriersandResources
                    lastUpdated={profileUpdatedDate}
                    domain8={domain8}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <RecommendedResources
                    lastUpdated={profileUpdatedDate}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                    stateSmhpResources={this.props.stateSmhpResources}
                />
                <AppendixTable
                    lastUpdated={profileUpdatedDate}
                    domain3={domain3}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                />
                <StateStrategicPlanningGuide
                    lastUpdated={profileUpdatedDate}
                    totalPages={totalPages}
                    groupName={groupName}
                    stateTitle={this.props.stateTitle}
                    siteFieldPlanningGuide={siteFieldPlanningGuide}
                    siteFieldPlanningGuide2={siteFieldPlanningGuide2}
                    siteFieldPlanningGuide3={siteFieldPlanningGuide3}
                    siteFieldPlanningGuide4={siteFieldPlanningGuide4}
                    siteFieldPlanningGuide5={siteFieldPlanningGuide5}
                    siteFieldPlanningGuide6={siteFieldPlanningGuide6}
                />
            </>
        );
    }
}
