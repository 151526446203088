import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
export default class OrgAgencyInvolvement extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain1: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain1, stateTitle } = this.props;

        return (
            <Page>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo_organ1}>
                            <div className={style.triangleState_organiz1} />
                            {/* <div className={style.logo} /> */}
                        </div>
                        <div className={style.headerTitle_state}>
                            Organization and Agency Involvement
                        </div>
                    </div>
                    {/* <SubHeader lastUpdated={profileUpdatedDate} /> */}
                    <div className={style.reportPage}>
                        <div className={style.bodyContent}>
                            <div className={style.bodyTopState}>
                                <p className={style.topStateContent_page3}>
                                    The organizations and agencies involved in the advancement of
                                    comprehensive school mental health services and supports in{' '}
                                    {stateTitle ? stateTitle : ''} include:
                                </p>

                                <div className={style.mainCartHeaderPage3}>
                                    {/* <div className={style.triangletop_left} /> */}
                                    <div className={style.cartContentHeaderPage3}>
                                        <h4>Leadership and Oversight</h4>
                                    </div>
                                    {/* <div className={style.header_shape} /> */}
                                    <div className={style.CartContent}>
                                        <div className={style.OnOrderlist_MainContainer}>
                                            <div className={style.OnOrderlist_Block}>
                                                <ul>
                                                    {domain1.mental_health_profile_edu_ldrshp ===
                                                    '1' ? (
                                                        <li>Education</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_other1_ldrshp ===
                                                    '1' ? (
                                                        <li>
                                                            {
                                                                domain1.mental_health_profile_d1_other1_text
                                                            }
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_other2_ldrshp ===
                                                    '1' ? (
                                                        <li>
                                                            {
                                                                domain1.mental_health_profile_d1_other2_text
                                                            }
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_ec_ldrshp ===
                                                    '1' ? (
                                                        <li>Early Childhood </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_physhlth_ldrshp ===
                                                    '1' ? (
                                                        <li>Physical health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_pubhlth_ldrshp ===
                                                    '1' ? (
                                                        <li>Public Health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_behhlth_ldrshp ===
                                                    '1' ? (
                                                        <li>Behavioral Health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_mhhlth_ldrshp ===
                                                    '1' ? (
                                                        <li>Mental Health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_su_ldrshp ===
                                                    '1' ? (
                                                        <li>Substance Use</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_juvjussrvc_ldrshp ===
                                                    '1' ? (
                                                        <li>Juvenile Justice Services</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_chldwlfr_ldrshp ===
                                                    '1' ? (
                                                        <li>
                                                            Child Welfare/Social Services/Human
                                                            Services
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_fthorg_ldrshp ===
                                                    '1' ? (
                                                        <li>Faith-based organizations </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_bus_ldrshp ===
                                                    '1' ? (
                                                        <li>Businesses</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_phlthrpcfnd_ldrshp ===
                                                    '1' ? (
                                                        <li>
                                                            National, state, and local private
                                                            philanthropic foundations{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_ythdev_ldrshp ===
                                                    '1' ? (
                                                        <li>
                                                            Youth development/advocacy organizations{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_fmlyldrshp_ldrshp ===
                                                    '1' ? (
                                                        <li>
                                                            Family leadership/advocacy organizations{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_lawenforce_ldrshp ===
                                                    '1' ? (
                                                        <li>Law enforcement and public safety </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_prfst_ldrshp ===
                                                    '1' ? (
                                                        <li>
                                                            Professional association state chapters{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_univ_ldrshp ===
                                                    '1' ? (
                                                        <li>Universities, colleges</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_housauth_ldrshp ===
                                                    '1' ? (
                                                        <li>Housing authorities</li>
                                                    ) : (
                                                        ''
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={style.mainCartHeader_2nd_Page3}>
                                    {/* <div className={style.triangletop_left_2nd} /> */}
                                    <div className={style.cartContentHeader_2nd_Page3}>
                                        <h4>Technical Assistance and Training</h4>
                                    </div>
                                    {/* <div className={style.header_shape_page3} /> */}
                                    <div className={style.CartContent_2nd}>
                                        <div className={style.OnOrderlist_MainContainer}>
                                            <div className={style.OnOrderlist_Block}>
                                                <ul>
                                                    {domain1.mental_health_profile_edu_ta ===
                                                    '1' ? (
                                                        <li>Education</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_other2_ta ===
                                                    '1' ? (
                                                        <li>
                                                            {
                                                                domain1.mental_health_profile_d1_other2_text
                                                            }
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_other1_ta ===
                                                    '1' ? (
                                                        <li>
                                                            {
                                                                domain1.mental_health_profile_d1_other1_text
                                                            }
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_ec_ta === '1' ? (
                                                        <li>Early childhood</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_physhlth_ta ===
                                                    '1' ? (
                                                        <li>Physical health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_lawenforce_ta ===
                                                    '1' ? (
                                                        <li>Law enforcement and public safety</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_prfst_ta ===
                                                    '1' ? (
                                                        <li>
                                                            Professional association state chapters{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_univ_ta ===
                                                    '1' ? (
                                                        <li>Universities, colleges</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_housauth_ta ===
                                                    '1' ? (
                                                        <li>Housing authorities</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_pubhlth_ta ===
                                                    '1' ? (
                                                        <li>Public health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_behhlth_ta ===
                                                    '1' ? (
                                                        <li>Behavioral health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_mhhlth_ta ===
                                                    '1' ? (
                                                        <li>Mental health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_su_ta === '1' ? (
                                                        <li>Substance use</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_ythdev_ta ===
                                                    '1' ? (
                                                        <li>
                                                            Youth development/advocacy organizations
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_fmlyldrshp_ta ===
                                                    '1' ? (
                                                        <li>
                                                            Family leadership/advocacy organizations
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_juvjussrvc_ta ===
                                                    '1' ? (
                                                        <li>Juvenile justice services </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_chldwlfr_ta ===
                                                    '1' ? (
                                                        <li>
                                                            Child welfare/social services/human
                                                            services{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_fthorg_ta ===
                                                    '1' ? (
                                                        <li>Faith-based organizations</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_bus_ta ===
                                                    '1' ? (
                                                        <li>Businesses</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_phlthrpcfnd_ta ===
                                                    '1' ? (
                                                        <li>
                                                            National, state, and local private
                                                            philanthropic foundations{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={style.mainCartHeader_3rd_Page3}>
                                    {/* <div className={style.triangletop_left_3rd} /> */}
                                    <div className={style.cartContentHeader_3rd_Page3}>
                                        <h4>Funding</h4>
                                    </div>
                                    {/* <div className={style.header_shape_3rd} /> */}
                                    <div className={style.CartContent_3rd}>
                                        <div className={style.OnOrderlist_MainContainer}>
                                            <div className={style.OnOrderlist_Block}>
                                                <ul>
                                                    {domain1.mental_health_profile_edu_fund ===
                                                    '1' ? (
                                                        <li>Education</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_other2_fund ===
                                                    '1' ? (
                                                        <li>
                                                            {
                                                                domain1.mental_health_profile_d1_other2_text
                                                            }
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_other1_fund ===
                                                    '1' ? (
                                                        <li>
                                                            {
                                                                domain1.mental_health_profile_d1_other1_text
                                                            }
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_ec_fund ===
                                                    '1' ? (
                                                        <li>Early childhood </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_physhlth_fund ===
                                                    '1' ? (
                                                        <li>Physical health </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_pubhlth_fund ===
                                                    '1' ? (
                                                        <li>Public health </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_behhlth_fund ===
                                                    '1' ? (
                                                        <li>Behavioral health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_housauth_fund ===
                                                    '1' ? (
                                                        <li>Housing authorities</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_mhhlth_fund ===
                                                    '1' ? (
                                                        <li>Mental health</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_su_fund ===
                                                    '1' ? (
                                                        <li>Substance use</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_juvjussrvc_fund ===
                                                    '1' ? (
                                                        <li>Juvenile justice services </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_chldwlfr_fund ===
                                                    '1' ? (
                                                        <li>
                                                            Child welfare/social services/human
                                                            services{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_fthorg_fund ===
                                                    '1' ? (
                                                        <li>Faith-based organizations</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_univ_fund ===
                                                    '1' ? (
                                                        <li>Universities, colleges</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_bus_fund ===
                                                    '1' ? (
                                                        <li>Businesses</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_phlthrpcfnd_fund ===
                                                    '1' ? (
                                                        <li>
                                                            National, state, and local private
                                                            philanthropic foundations{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_ythdev_fund ===
                                                    '1' ? (
                                                        <li>
                                                            Youth development/advocacy organizations
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_fmlyldrshp_fund ===
                                                    '1' ? (
                                                        <li>
                                                            Family leadership/advocacy organizations{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_lawenforce_fund ===
                                                    '1' ? (
                                                        <li>Law enforcement and public safety</li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {domain1.mental_health_profile_prfst_fund ===
                                                    '1' ? (
                                                        <li>
                                                            Professional association state chapters{' '}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.footer_page3}>
                    <div className={style.footerText_Page}>
                        This report was automatically generated by the SHAPE System based on
                        responses from {stateTitle ? stateTitle : ''} state leaders.
                    </div>
                    <div className={style.footerLogoStateReport} />
                </div>
                <div className={style.pageNumber}>Page 3 of {this.props.totalPages}</div>
            </Page>
        );
    }
}
