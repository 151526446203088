import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Table from 'cccisd-table';
import { TextBlock } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import AddDistrictModal from './addDistrictModal.js';
import AddSchoolModal from './addSchoolModal.js';
import style from './style.css';

const fields = ['groupId', 'entityName'];

export const validate = (values, props = {}) => {
    const errors = {};

    if (!values.groupId) {
        errors.groupId = 'Entity ID is required';
    }
    if (!values.entityName) {
        errors.entityName = 'Entity Name is required';
    }

    return errors;
};

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.districtModalRef = React.createRef();
        this.schoolModalRef = React.createRef();
    }

    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        getDistricts: PropTypes.func,
        getSchools: PropTypes.func,
        states: PropTypes.array,
        updateChildGroups: PropTypes.func,
        childGroups: PropTypes.array,
        values: PropTypes.object,
        tableData: PropTypes.array,
        getTableData: PropTypes.func,
    };

    state = {
        stateId: '',
        districtId: '',
        schoolId: '',
        schoolStateId: '',
        schoolDistrictId: '',
        districtList: [],
        schoolList: [],
    };

    Group = props => (
        <div className={style.group}>
            <div className={style.title}>{props.title}</div>
            {props.children}
        </div>
    );

    onStateSelectChange = async e => {
        await this.setState({ stateId: e.target.value });
        const response = await this.props.getDistricts(this.state.stateId);
        await this.setState({ districtList: response.groups.districtList });
    };

    onSchoolStateSelectChange = async e => {
        await this.setState({ schoolStateId: e.target.value });
        const response = await this.props.getDistricts(this.state.schoolStateId);
        await this.setState({ districtList: response.groups.districtList });
    };

    onDistrictSelectChange = async e => {
        await this.setState({ schoolDistrictId: e.target.value });
        const response = await this.props.getSchools(this.state.schoolDistrictId);
        await this.setState({ schoolList: response.groups.schoolList });
    };

    onAddDistrictSubmit = async () => {
        await this.props.updateChildGroups(this.state.districtId);
        await this.props.getTableData();
        this.districtModalRef.current.close();
    };

    onAddSchoolSubmit = async () => {
        await this.props.updateChildGroups(this.state.schoolId);
        await this.props.getTableData();
        this.schoolModalRef.current.close();
    };

    renderTable = tableGroups => {
        if (tableGroups.length > 0) {
            const tableData = this.props.tableData.map(d => d.group);
            return (
                <Table
                    data={tableData}
                    rowKey="groupId"
                    columns={[
                        { name: 'groupId', label: 'ID' },
                        { name: 'label', label: 'Name' },
                        { name: 'groupType', label: 'Group Type' },
                    ]}
                />
            );
        }
        return null;
    };

    render() {
        const {
            fields: { groupId, entityName },
            handleSubmit,
            submitting,
            // errors,
            states,
        } = this.props;
        const tableGroups = this.props.childGroups.map(id => Number(id));
        return (
            <form onSubmit={handleSubmit}>
                <div className={style.grid}>
                    <div style={{ flexGrow: 1 }}>
                        <TextBlock field={entityName} label="Entity Name" />
                    </div>
                    <TextBlock field={groupId} label="Entity ID" style={{ maxWidth: '100px' }} />
                </div>
                <AddDistrictModal
                    states={states}
                    stateId={this.state.stateId}
                    districtId={this.state.districtId}
                    districtList={this.state.districtList}
                    onStateSelectChange={this.onStateSelectChange}
                    onAddDistrictSubmit={this.onAddDistrictSubmit}
                    setDistrictId={e => this.setState({ districtId: e.target.value })}
                    myModalRef={this.districtModalRef}
                />
                <AddSchoolModal
                    states={states}
                    stateId={this.state.schoolStateId}
                    districtId={this.state.schoolDistrictId}
                    districtList={this.state.districtList}
                    schoolId={this.state.schoolId}
                    schoolList={this.state.schoolList}
                    onStateSelectChange={this.onSchoolStateSelectChange}
                    onDistrictSelectChange={this.onDistrictSelectChange}
                    onAddSchoolSubmit={this.onAddSchoolSubmit}
                    setSchoolId={e => this.setState({ schoolId: e.target.value })}
                    myModalRef={this.schoolModalRef}
                />
                <h3>Entity Groups to Request</h3>
                {tableGroups.length > 0 && this.renderTable(tableGroups)}
                <div>
                    <ClickButton
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        isLoading={submitting}
                        title="Submit"
                    />
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(
    reduxForm({
        form: 'EntityGroupsForm',
        fields,
        validate,
    })(Form)
);
