import React from 'react';
import Modal from 'cccisd-modal';
import Table from 'cccisd-table';
import style from './style.css';

export default settings => {
    return ({ value, row, isCsv }) => {
        if (isCsv) {
            return value;
        }

        const userList = row[settings.users];
        const data = userList.map((user, i) => {
            return {
                id: user.user.userId,
                name: user.user.fullName,
                email: user.user.email,
                position: user.fields.position,
                lastLogin: user.user.lastLoginDate,
            };
        });

        const columns = [
            { name: 'name', label: 'Name' },
            { name: 'email', label: 'Email' },
            { name: 'position', label: 'Position' },
            { name: 'lastLogin', label: 'Last Login' },
        ];

        return (
            <div className={style.box}>
                <Modal trigger={value > 0 ? <a>{value}</a> : value} title={settings.title}>
                    <Table columns={columns} data={data} rowKey="id" />
                </Modal>
            </div>
        );
    };
};
