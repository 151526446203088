export const careSchool = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroSchool', 'summaryReportAdditionalText'],
        },
        {
            title: 'Teaming and Collaboration',
            messages: [
                'teamingNotEstablishedSchool',
                'teamingEmergingSchool',
                'teamingProgressingSchool',
                'teamingAdvancingSchool',
                'teamingAboutSchool',
            ],
        },
        {
            title: 'School Climate',
            messages: [
                'climateNotEstablishedSchool',
                'climateEmergingSchool',
                'climateProgressingSchool',
                'climateAdvancingSchool',
                'climateAboutSchool',
            ],
        },
        {
            title: 'School Discipline',
            messages: [
                'disciplineNotEstablishedSchool',
                'disciplineEmergingSchool',
                'disciplineProgressingSchool',
                'disciplineAdvancingSchool',
                'disciplineAboutSchool',
            ],
        },
        {
            title: 'Curricula and Teaching Practices',
            messages: [
                'curriculaNotEstablishedSchool',
                'curriculaEmergingSchool',
                'curriculaProgressingSchool',
                'curriculaAdvancingSchool',
                'curriculaAboutSchool',
            ],
        },
        {
            title: 'Professional Development',
            messages: [
                'prof_devNotEstablishedSchool',
                'prof_devEmergingSchool',
                'prof_devProgressingSchool',
                'prof_devAdvancingSchool',
                'prof_devAboutSchool',
            ],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'careStrategicPlanningPage1School',
                'careStrategicPlanningPage2School',
                'careStrategicPlanningPage3School',
                'careStrategicPlanningPage4School',
                'careStrategicPlanningPage5School',
                'careStrategicPlanningPage6School',
            ],
        },
        {
            title: 'Principal Report',
            messages: [
                'carePrincipalReportIntro',
                'carePrincipalReportTableFooter',
                'carePrincipalReportReflectionQuestions',
                'carePrincipalReportDistrictResources',
            ],
        },
    ],
    messages: {
        summaryReportIntroSchool: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p>This report is generated from information your team provided on the Culturally Responsive, Anti-Racist, and Equitable (CARE) assessment. The composite score is the average of the rating for every item within the domain.</p>',
        },
        summaryReportAdditionalText: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p><strong>Not Established - </strong>Domains where contributors reported the practices within the domain do not exist yet within the organization or they are rarely observed. The overall mean falls between 1.0-1.9.</p><p><strong>Emerging - </strong>Domains where contributors reported the practices within the domain are happening MUCH LESS often than there is opportunity to do so. The overall mean falls between 2.0-2.9. </p><p><strong>Progressing - </strong>Domains where contributors reported the practices within the domain are carried out LESS often than there are opportunities to do so within the school. The overall mean falls between 3.0-4.9.</p><p><strong>Advancing - </strong>Domains where contributors reported of the practices within the domain are part of the every-day practice and happening almost always. The overall mean falls between 5.0-6.0.</p><p><strong>Principal Report - </strong>A mini report is provided to share with your school leadership and includes highest and lowest items in each domain. It is important for school leadership to be involved to the promotion of CARE policies and practices. Also, there are reflection questions about district CARE policies and practices for principals to consider in planning for quality improvement initiatives.</p><p>Need resources? The SHAPE Resource Library offers a wide variety of tools and resources to help your team with their continuous quality improvement efforts and action planning. The library is organized by domain to help you locate exactly what you need.</p>',
        },
        teamingNotEstablishedSchool: {
            title: 'Teaming and Collaboration (Not Established)',
            message: '<p>Based on your responses, this is a <i>not establisehd domain</i> for your school.</p>',
        },
        teamingEmergingSchool: {
            title: 'Teaming and Collaboration (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        teamingProgressingSchool: {
            title: 'Teaming and Collaboration (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        teamingAdvancingSchool: {
            title: 'Teaming and Collaboration (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        teamingAboutSchool: {
            title: 'Teaming and Collaboration (About)',
            message: '<p>Teaming and Collaboration is </p>',
        },
        climateNotEstablishedSchool: {
            title: 'School Climate (Not Establised)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        climateEmergingSchool: {
            title: 'School Climate (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        climateProgressingSchool: {
            title: 'School Climate (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        climateAdvancingSchool: {
            title: 'School Climate (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        climateAboutSchool: {
            title: 'School Climate (About)',
            message: `<p>School Climate is </p>`,
        },
        disciplineNotEstablishedSchool: {
            title: 'School Discipline (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        disciplineEmergingSchool: {
            title: 'School Discipline (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        disciplineProgressingSchool: {
            title: 'School Discipline (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        disciplineAdvancingSchool: {
            title: 'School Discipline (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        disciplineAboutSchool: {
            title: 'School Discipline (About)',
            message: `<p>School Discipline is  </p>`,
        },
        curriculaNotEstablishedSchool: {
            title: 'Curricula and Teaching Practices (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        curriculaEmergingSchool: {
            title: 'Curricula and Teaching Practices (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        curriculaProgressingSchool: {
            title: 'Curricula and Teaching Practices (Progressing)',
            message:
                '<p>Based on your responses, this is a <i>progressing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>',
        },
        curriculaAdvancingSchool: {
            title: 'Curricula and Teaching Practices (Advancing)',
            message: `<p>Based on your responses, this is a <i>advancing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>`,
        },
        curriculaAboutSchool: {
            title: 'Curricula and Teaching Practices (About)',
            message: '<p>Curricula and Teaching Practices is </p>',
        },
        prof_devNotEstablishedSchool: {
            title: 'Professional Development (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        prof_devEmergingSchool: {
            title: 'Professional Development (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        prof_devProgressingSchool: {
            title: 'Professional Development (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        prof_devAdvancingSchool: {
            title: 'Professional Development (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        prof_devAboutSchool: {
            title: 'Professional Development (About)',
            message: '<p>Professional Development is </p>',
        },
        careStrategicPlanningPage1School: {
            title: 'Strategic Planning Guide - Page 1',
            message: `
            <p>This Strategic Planning Guide is a tool for teams who have completed the Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment and are ready to engage in a strategic planning process to improve CARE practices. The CARE assessment provides a comprehensive picture of CARE practices in the school environment, often surfacing numerous strengths and opportunities for improvement or growth across different areas. However, most schools have more opportunities for improvement than they can realistically address in a given school year. Therefore, this guide is designed to help teams prioritize one area for improvement, develop a measurable goal, and map out an initial plan including anticipated opportunities and barriers, action steps, and a timeline. </p>
                <p><strong><u>Prioritize One to Two Improvement Areas </u></strong></p>
                <p>The CARE School Assessment includes five areas (Curricula and Teaching Practices, School Climate, Teaming and Collaboration, School Discipline, and Professional Development). We encourage you to meet with your implementation team and review your scores on each area of the report. </p>
                <p>Some teams have a clear idea of which improvement area they want to start with after they complete the assessment. If this is your team, select your area and move directly to “Develop a Measurable Goal.” </p>
                <p>If you are not sure yet which improvement area is right for you, consider the following discussion questions to build consensus on what area(s) to prioritize:</p>
                <ul>
                <li><strong>Desire</strong> &ndash; How much desire (e.g., motivation, need) is there to improve this area?</li>
                <li><strong>Impact</strong> &ndash; How much impact would improvements in this area have on student, staff and/or school and community outcomes?</li>
                <li><strong>Resources </strong>&ndash; What resources (e.g., staffing, capacity, financial resources, leadership support, community support, partnerships, expertise) are available to make changes and improvements in this area?</li>
                </ul>`,
        },
        careStrategicPlanningPage2School: {
            title: 'Strategic Planning Guide - Page 2',
            message: `
            <p><strong>Select one area that your team would like to improve.</strong></p>
                <p><strong>Priority Area #1:</strong></p>
                <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                <tbody>
                <tr>
                <td style="width: 19%;">
                <p style="text-align: center;">Teaming and Collaboration</p>
                </td>
                <td style="width: 23%; text-align: center;">School Climate</td>
                <td style="width: 27%; text-align: center;">School Discipline</td>
                </tr>
                <tr>
                <td style="width: 27%; text-align: center;">Professional Development</td>
                <td style="width: 23%; text-align: center;">Curricula and Teaching Practices</td>
                </tr>
                </tbody>
                </table>
                <p>Identifying an improvement area is a first step for the school year. Focusing on one area at a time will support consistency and momentum.</p>
                <p><strong>(OPTIONAL) Some teams have other improvement areas they would like to address secondarily, or in the near future. If so, check other improvement area(s) here:</strong></p>
                   <p><strong>Priority Area #2:</strong></p>
                   <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                   <tbody>
                   <tr>
                   <td style="width: 19%;">
                   <p style="text-align: center;">Teaming and Collaboration</p>
                   </td>
                   <td style="width: 23%; text-align: center;">School Climate</td>
                   <td style="width: 27%; text-align: center;">School Discipline</td>
                   </tr>
                   <tr>
                   <td style="width: 27%; text-align: center;">Professional Development</td>
                   <td style="width: 23%; text-align: center;">Curricula and Teaching Practices</td>
                   </tr>
                   </tbody>
                   </table>
                   <p>The following pages can be used as a tool to plan your next steps for this domain. </p>`,
        },
        careStrategicPlanningPage3School: {
            title: 'Strategic Planning Guide - Page 3',
            message: `
                <p><strong><u>Develop a Measurable Goal</u></strong></p>
                <p>Develop a specific, measurable goal to clarify and customize what you will focus on by setting a SMARTIE goal.</p>
                <p><strong>Example SMARTIE Goals by Improvement Area:</strong></p>
                <ul>
                <li>By October 1st, we will obtain oral or written commitment from 5 students and 5 parents/caregivers as advisors to our School Mental Health Culture and Climate Team. This is our first step to improve equity and inclusion in our teaming processes, with a specific focus on youth and family partnerships first.&nbsp;<strong>(Teaming)</strong></li>
                <li>By September 30th, we will conduct walkthroughs of 7th and 8th grade classrooms and use a standardized checklist to monitor the use of diverse classroom materials. A student and teacher representative will be invited to participate. They will partner with a team member on the walkthroughs and provide input on how to increase representation in the classroom and improve the checklist.&nbsp;<strong>(Curricula and Teaching Practices)</strong></li>
                <li>By January 30th, we will review discipline data from September to December and disaggregate by student groups to monitor the impact of school discipline policies on different student groups. Team will develop summary reports for students and families to improve transparency and collaboration by the following month.<strong>(School Discipline)</strong></li>
                <li>By December 20th, we will host 1 community night at the school and 2 school members will attend 2 community events, chosen from a calendar of events, to foster relationships with the community and create a welcoming environment at the school. To center student voice, the student council will lead the initiative, with a team member as a coach, meeting weekly afterschool. The implementation team will meet monthly to monitor progress toward goal and address barriers to completion.&nbsp;<strong>(School Climate)</strong></li>
                <li>By June of this school year, we will develop a professional development curriculum including 9, 30-minute sessions for CARE practices to ensure staff understand the impact of cultural responsiveness, anti-racism, and equity on student success. School will consult with CARE experts to develop high-quality content.&nbsp;<strong>(Professional Development)</strong></li>
                </ul>
                <p><strong>What are SMARTIE Goals?</strong></p>
            <ul>
            <li><strong>Specific&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Concrete, detailed, and well-defined</li>
            <li><strong>Measurable</strong>&nbsp; &nbsp;Numeric or descriptive with quantity, quality, or comparative to baseline</li>
            <li><strong>Actionable</strong>&nbsp; &nbsp; &nbsp;Feasible, attainable, actionable</li>
            <li><strong>Realistic </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Considers resources, barriers, strengths, and what can be achieved</li>
            <li><strong>Timebound</strong> &nbsp;&nbsp; Identified target dates, interim steps to monitor progress, and/or a timeline for goal to be achieved</li>
            <li><strong>Inclusive</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Brings traditionally marginalized students and families&mdash;particularly those most impacted&mdash;into intervention planning, <br />implementation, and progress monitoring in a way that shares power</li>
            <li><strong>Equitable</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Includes an element of fairness or justice that seeks to address systemic injustice, inequity, or oppression</li>
            </ul>
                `,
        },
        careStrategicPlanningPage4School: {
            title: 'Strategic Planning Guide - Page 4',
            message: `
            <p><strong><u>Template</u></strong>: By [<em>time frame</em>], we will accomplish a [<em>measurable amount of</em>] [<em>our goal</em>] to improve [<em>quality indicator</em>].</p>
            <p>Be specific about how the goal or strategy is <em>inclusive and equitable</em>.</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>&nbsp;</strong><strong>GOAL</strong>: <u>_______________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><u>______________________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><strong>How will we know this goal has been achieved? <u>____________________________________</u></strong></p>
            <p>&nbsp;</p>
            <p><strong><u>______________________________________________________________________________</u></strong></p>
            <p>&nbsp;</p>
            <p><strong>What opportunities exist related to this goal?</strong></p>
                   <ul>
                   <li>What have been our past successes?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What current work is taking place related to this goal?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What are our available resources (leadership, infrastructure, staffing, partnerships)?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
            `,
        },
        careStrategicPlanningPage5School: {
            title: 'Strategic Planning Guide - Page 5',
            message: `
            <p><strong>What barriers exist related to this goal?</strong></p>
                   <ul>
                   <li>What would prevent us from moving forward with this goal?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What would we need to overcome this/these barrier(s)?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
            <p><strong><u>Create an Action Plan</u></strong></p>
                <p>Identify 3-5 short-term action steps and 3-5 long-term action steps (if relevant) that can be taken to achieve this goal.</p>
                <p><strong>Short term: 0-6 months</strong></p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 22%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 22.6913%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.3087%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                </tbody>
                <p>&nbsp;</p>
                </table>`,
        },
        careStrategicPlanningPage6School: {
            title: 'Strategic Planning Guide - Page 6',
            message: `
             </table><p><strong>Long term: 7-24 months</strong></p>
             <p>&nbsp;</p>
             <table class="table table-bordered" style="width: 100%;">
             <tbody>
             <tr>
             <td style="width: 42%;"><strong>Action Step</strong></td>
             <td style="width: 19%;"><strong>Team Members Responsible</strong></td>
             <td style="width: 20.8055%;"><strong>Data to Evaluate Change</strong></td>
             <td style="width: 10.1945%;"><strong>Target Date</strong></td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             </tbody>
             </table>`,
        },
        carePrincipalReportIntro: {
            title: 'Intro paragraph (above table)',
            message: `<p>Principals are essential in creating a Culturally Responsive, Anti-Racist, and Equitable (CARE) environment and in engaging staff and students in their ongoing development. Below is a summary of your school's data, including overall averages for each domain, and the highest and lowest scored item in each domain. We hope this provides an overview of strengths and brings areas of need to leadership's attention. We encourage school leadership to connect with your CARE team and collaborate on next steps. It is important for principals to engage in <strong>your own personal reflection on these concepts and an examination of the influence your identities and biases have on your work and your school. </strong>Below is a summary of the report your CARE team completed with identified strengths and areas of growth in each domain.</p><p>&nbsp;</p>`,
        },
        carePrincipalReportTableFooter: {
            title: 'Table Footer (below table)',
            message: `<p>* Lowest items may also be the most difficult to change and may NOT be the items your CARE team chose to work on for their quality improvement efforts. They are listed here for your awareness and for you to begin thinking through what conditions need to be addressed for your organization to be ready for change in these areas.</p>`,
        },
        carePrincipalReportReflectionQuestions: {
            title: 'Reflection Questions - Page 1',
            message: `
            <p><strong>Reflection Questions:</strong></p>
            <p>&nbsp;</p>
                   <ul>
                   <li>How do my choices as a leader facilitate my school progressing in creating a CARE environment?</li>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <li>How do my choices as a leader <strong>create barriers</strong> for my school progressing in creating a CARE environment?</li>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <li>Of the items listed above, which two do I feel I have influence over at my school?</li>
                   <p>a.</p>
                   <p>b.</p>
                   <p>&nbsp;</p>
                   <li>Of the items listed above, where can I make the most impact near future?</li>
                   <p>a.</p>
                   <p>b.</p>
                   <p>&nbsp;</p>
                   <li>What is one small step I can take to support my CARE school team?</li>
                   </ul>
            `,
        },
        carePrincipalReportDistrictResources: {
            title: 'Reflection Questions - Page 1',
            message: `
                   <li>What is one small step I can take to support my own growth?</li>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
            <p><strong>Can you leverage district resources?</strong>Since district policies and practices impact school-level funding, implementation, and evaluation for services and supports, we have included reflection questions to consider as you engage in action -planning about resources and guidance available to you around CARE activities:</p>
            <p>&nbsp;</p>
                   <ul>
                   <li>Are there established written, standard policies and procedures for CARE schools in my district? How do they influence the CARE work in my school?</li>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <li>Is there district support available for the implementation of CARE policies and practices that I can leverage for my school?</li>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <li>How can I advocate for my school with my district-level colleagues?</li>
                   </ul>
            `,
        },
    },
};

export const careDistrict = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroDistrict', 'summaryReportAdditionalText'],
        },
        {
            title: 'Teaming and Collaboration',
            messages: [
                'teamingNotEstablishedDistrict',
                'teamingEmergingDistrict',
                'teamingProgressingDistrict',
                'teamingAdvancingDistrict',
                'teamingAboutDistrict',
            ],
        },
        {
            title: 'School Climate',
            messages: [
                'climateNotEstablishedDistrict',
                'climateEmergingDistrict',
                'climateProgressingDistrict',
                'climateAdvancingDistrict',
                'climateAboutDistrict',
            ],
        },
        {
            title: 'School Discipline',
            messages: [
                'disciplineNotEstablishedDistrict',
                'disciplineEmergingDistrict',
                'disciplineProgressingDistrict',
                'disciplineAdvancingDistrict',
                'disciplineAboutDistrict',
            ],
        },
        {
            title: 'Curricula and Teaching Practices',
            messages: [
                'curriculaNotEstablishedDistrict',
                'curriculaEmergingDistrict',
                'curriculaProgressingDistrict',
                'curriculaAdvancingDistrict',
                'curriculaAboutDistrict',
            ],
        },
        {
            title: 'Professional Development',
            messages: [
                'prof_devNotEstablishedDistrict',
                'prof_devEmergingDistrict',
                'prof_devProgressingDistrict',
                'prof_devAdvancingDistrict',
                'prof_devAboutDistrict',
            ],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'careStrategicPlanningPage1District',
                'careStrategicPlanningPage2District',
                'careStrategicPlanningPage3District',
                'careStrategicPlanningPage4District',
                'careStrategicPlanningPage5District',
                'careStrategicPlanningPage6District',
            ],
        },
    ],
    messages: {
        summaryReportIntroDistrict: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p>This report is generated based on the information you provided for the CARE survey. The composite score for each domain is the average of your ratings for every item within the domain.</p><p>Composite scores of 1.0-1.9 are classified as <strong>"Emerging"</strong> areas, 2.0-2.9 are classified as <strong>"Progressing"</strong> areas, and 3.0-4.0 are classified as areas of <strong>"Advancing."</strong></p>',
        },
        summaryReportAdditionalText: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p><strong>Not Established - </strong>Domains where contributors reported the practices within the domain do not exist yet within the organization or they are rarely observed. The overall mean falls between 1.0-1.9.</p><p><strong>Emerging - </strong>Domains where contributors reported the practices within the domain are happening MUCH LESS often than there is opportunity to do so. The overall mean falls between 2.0-2.9. </p><p><strong>Progressing - </strong>Domains where contributors reported the practices within the domain are carried out LESS often than there are opportunities to do so within the school. The overall mean falls between 3.0-4.9.</p><p><strong>Advancing - </strong>Domains where contributors reported of the practices within the domain are part of the every-day practice and happening almost always. The overall mean falls between 5.0-6.0.</p><p><strong>Principal Report - </strong>A mini report is provided to share with your school leadership and includes highest and lowest items in each domain. It is important for school leadership to be involved to the promotion of CARE policies and practices. Also, there are reflection questions about district CARE policies and practices for principals to consider in planning for quality improvement initiatives.</p><p>Need resources? The SHAPE Resource Library offers a wide variety of tools and resources to help your team with their continuous quality improvement efforts and action planning. The library is organized by domain to help you locate exactly what you need.</p>',
        },
        teamingNotEstablishedDistrict: {
            title: 'Teaming and Collaboration (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        teamingEmergingDistrict: {
            title: 'Teaming and Collaboration (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        teamingProgressingDistrict: {
            title: 'Teaming and Collaboration (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        teamingAdvancingDistrict: {
            title: 'Teaming and Collaboration (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        teamingAboutDistrict: {
            title: 'Teaming and Collaboration (About)',
            message: '<p>Teaming and Collaboration is </p>',
        },
        climateNotEstablishedDistrict: {
            title: 'School Climate (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        climateEmergingDistrict: {
            title: 'School Climate (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        climateProgressingDistrict: {
            title: 'School Climate (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        climateAdvancingDistrict: {
            title: 'School Climate (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        climateAboutDistrict: {
            title: 'School Climate (About)',
            message: `<p>School Climatee is </p>`,
        },
        disciplineNotEstablishedDistrict: {
            title: 'School Discipline (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        disciplineEmergingDistrict: {
            title: 'School Discipline (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        disciplineProgressingDistrict: {
            title: 'School Discipline (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        disciplineAdvancingDistrict: {
            title: 'School Discipline (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        disciplineAboutDistrict: {
            title: 'School Discipline (About)',
            message: `<p>School Discipline is </p>`,
        },
        curriculaNotEstablishedDistrict: {
            title: 'Curricula and Teaching Practices (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        curriculaEmergingDistrict: {
            title: 'Curricula and Teaching Practices (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        curriculaProgressingDistrict: {
            title: 'Curricula and Teaching Practices (Progressing)',
            message:
                '<p>Based on your responses, this is a <i>progressing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>',
        },
        curriculaAdvancingDistrict: {
            title: 'Curricula and Teaching Practices (Advancing)',
            message: `<p>Based on your responses, this is a <i>advancing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>`,
        },
        curriculaAboutDistrict: {
            title: 'Curricula and Teaching Practices (About)',
            message: '<p>Curricula and Teaching Practices is </p>',
        },
        prof_devNotEstablishedDistrict: {
            title: 'Professional Development (Not Established)',
            message: '<p>Based on your responses, this is an <i>not established domain</i> for your school.</p>',
        },
        prof_devEmergingDistrict: {
            title: 'Professional Development (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        prof_devProgressingDistrict: {
            title: 'Professional Development (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        prof_devAdvancingDistrict: {
            title: 'Professional Development (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        prof_devAboutDistrict: {
            title: 'Professional Development (About)',
            message: '<p>Professional Development is </p>',
        },
        careStrategicPlanningPage1District: {
            title: 'Strategic Planning Guide - Page 1',
            message: `
            <p>This Strategic Planning Guide is a tool for teams who have completed the Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment and are ready to engage in a strategic planning process to improve CARE practices. The CARE assessment provides a comprehensive picture of CARE practices in the school environment, often surfacing numerous strengths and opportunities for improvement or growth across different areas. However, most schools have more opportunities for improvement than they can realistically address in a given school year. Therefore, this guide is designed to help teams prioritize one area for improvement, develop a measurable goal, and map out an initial plan including anticipated opportunities and barriers, action steps, and a timeline. </p>
                <p><strong><u>Prioritize One to Two Improvement Areas </u></strong></p>
                <p>The CARE School Assessment includes five areas (Curricula and Teaching Practices, School Climate, Teaming and Collaboration, School Discipline, and Professional Development). We encourage you to meet with your implementation team and review your scores on each area of the report. </p>
                <p>Some teams have a clear idea of which improvement area they want to start with after they complete the assessment. If this is your team, select your area and move directly to “Develop a Measurable Goal.” </p>
                <p>If you are not sure yet which improvement area is right for you, consider the following discussion questions to build consensus on what area(s) to prioritize:</p>
                <ul>
                <li><strong>Desire</strong> &ndash; How much desire (e.g., motivation, need) is there to improve this area?</li>
                <li><strong>Impact</strong> &ndash; How much impact would improvements in this area have on student, staff and/or school and community outcomes?</li>
                <li><strong>Resources </strong>&ndash; What resources (e.g., staffing, capacity, financial resources, leadership support, community support, partnerships, expertise) are available to make changes and improvements in this area?</li>
                </ul>`,
        },
        careStrategicPlanningPage2District: {
            title: 'Strategic Planning Guide - Page 2',
            message: `
            <p><strong>Select one area that your team would like to improve.</strong></p>
                <p><strong>Priority Area #1:</strong></p>
                <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                <tbody>
                <tr>
                <td style="width: 19%;">
                <p style="text-align: center;">Teaming and Collaboration</p>
                </td>
                <td style="width: 23%; text-align: center;">School Climate</td>
                <td style="width: 27%; text-align: center;">School Discipline</td>
                </tr>
                <tr>
                <td style="width: 27%; text-align: center;">Professional Development</td>
                <td style="width: 23%; text-align: center;">Curricula and Teaching Practices</td>
                </tr>
                </tbody>
                </table>
                <p>Identifying an improvement area is a first step for the school year. Focusing on one area at a time will support consistency and momentum.</p>
                <p><strong>(OPTIONAL) Some teams have other improvement areas they would like to address secondarily, or in the near future. If so, check other improvement area(s) here:</strong></p>
                   <p><strong>Priority Area #2:</strong></p>
                   <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                   <tbody>
                   <tr>
                   <td style="width: 19%;">
                   <p style="text-align: center;">Teaming and Collaboration</p>
                   </td>
                   <td style="width: 23%; text-align: center;">School Climate</td>
                   <td style="width: 27%; text-align: center;">School Discipline</td>
                   </tr>
                   <tr>
                   <td style="width: 27%; text-align: center;">Professional Development</td>
                   <td style="width: 23%; text-align: center;">Curricula and Teaching Practices</td>
                   </tr>
                   </tbody>
                   </table>
                   <p>The following pages can be used as a tool to plan your next steps for this domain. </p>`,
        },
        careStrategicPlanningPage3District: {
            title: 'Strategic Planning Guide - Page 3',
            message: `
                <p><strong><u>Develop a Measurable Goal</u></strong></p>
                <p>Develop a specific, measurable goal to clarify and customize what you will focus on by setting a SMARTIE goal.</p>
                <p><strong>Example SMARTIE Goals by Improvement Area:</strong></p>
                <ul>
                <li>By October 1st, we will obtain oral or written commitment from 5 students and 5 parents/caregivers as advisors to our School Mental Health Culture and Climate Team. This is our first step to improve equity and inclusion in our teaming processes, with a specific focus on youth and family partnerships first.&nbsp;<strong>(Teaming)</strong></li>
                <li>By September 30th, we will conduct walkthroughs of 7th and 8th grade classrooms and use a standardized checklist to monitor the use of diverse classroom materials. A student and teacher representative will be invited to participate. They will partner with a team member on the walkthroughs and provide input on how to increase representation in the classroom and improve the checklist.&nbsp;<strong>(Curricula and Teaching Practices)</strong></li>
                <li>By January 30th, we will review discipline data from September to December and disaggregate by student groups to monitor the impact of school discipline policies on different student groups. Team will develop summary reports for students and families to improve transparency and collaboration by the following month.<strong>(School Discipline)</strong></li>
                <li>By December 20th, we will host 1 community night at the school and 2 school members will attend 2 community events, chosen from a calendar of events, to foster relationships with the community and create a welcoming environment at the school. To center student voice, the student council will lead the initiative, with a team member as a coach, meeting weekly afterschool. The implementation team will meet monthly to monitor progress toward goal and address barriers to completion.&nbsp;<strong>(School Climate)</strong></li>
                <li>By June of this school year, we will develop a professional development curriculum including 9, 30-minute sessions for CARE practices to ensure staff understand the impact of cultural responsiveness, anti-racism, and equity on student success. School will consult with CARE experts to develop high-quality content.&nbsp;<strong>(Professional Development)</strong></li>
                </ul>
                <p><strong>What are SMARTIE Goals?</strong></p>
            <ul>
            <li><strong>Specific&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Concrete, detailed, and well-defined</li>
            <li><strong>Measurable</strong>&nbsp; &nbsp;Numeric or descriptive with quantity, quality, or comparative to baseline</li>
            <li><strong>Actionable</strong>&nbsp; &nbsp; &nbsp;Feasible, attainable, actionable</li>
            <li><strong>Realistic </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Considers resources, barriers, strengths, and what can be achieved</li>
            <li><strong>Timebound</strong> &nbsp;&nbsp; Identified target dates, interim steps to monitor progress, and/or a timeline for goal to be achieved</li>
            <li><strong>Inclusive</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Brings traditionally marginalized students and families&mdash;particularly those most impacted&mdash;into intervention planning, implementation, and progress monitoring in a way that shares power</li>
            <li><strong>Equitable</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Includes an element of fairness or justice that seeks to address systemic injustice, inequity, or oppression</li>
            </ul>
                `,
        },
        careStrategicPlanningPage4District: {
            title: 'Strategic Planning Guide - Page 4',
            message: `
            <p><strong><u>Template</u></strong>: By [<em>time frame</em>], we will accomplish a [<em>measurable amount of</em>] [<em>our goal</em>] to improve [<em>quality indicator</em>].</p>
            <p>Be specific about how the goal or strategy is <em>inclusive and equitable</em>.</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>&nbsp;</strong><strong>GOAL</strong>: <u>_______________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><u>______________________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><strong>How will we know this goal has been achieved? <u>____________________________________</u></strong></p>
            <p>&nbsp;</p>
            <p><strong><u>______________________________________________________________________________</u></strong></p>
            <p>&nbsp;</p>
            <p><strong>What opportunities exist related to this goal?</strong></p>
                   <ul>
                   <li>What have been our past successes?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What current work is taking place related to this goal?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What are our available resources (leadership, infrastructure, staffing, partnerships)?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
            `,
        },
        careStrategicPlanningPage5District: {
            title: 'Strategic Planning Guide - Page 5',
            message: `
            <p><strong>What barriers exist related to this goal?</strong></p>
                   <ul>
                   <li>What would prevent us from moving forward with this goal?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What would we need to overcome this/these barrier(s)?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
            <p><strong><u>Create an Action Plan</u></strong></p>
                <p>Identify 3-5 short-term action steps and 3-5 long-term action steps (if relevant) that can be taken to achieve this goal.</p>
                <p><strong>Short term: 0-6 months</strong></p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 22%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 22.6913%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.3087%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                </tbody>
                <p>&nbsp;</p>
                </table>`,
        },
        careStrategicPlanningPage6District: {
            title: 'Strategic Planning Guide - Page 6',
            message: `
             </table><p><strong>Long term: 7-24 months</strong></p>
             <p>&nbsp;</p>
             <table class="table table-bordered" style="width: 100%;">
             <tbody>
             <tr>
             <td style="width: 42%;"><strong>Action Step</strong></td>
             <td style="width: 19%;"><strong>Team Members Responsible</strong></td>
             <td style="width: 20.8055%;"><strong>Data to Evaluate Change</strong></td>
             <td style="width: 10.1945%;"><strong>Target Date</strong></td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             </tbody>
             </table>`,
        },
    },
};

export const careEntity = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroEntity', 'summaryReportAdditionalText'],
        },
        {
            title: 'Teaming and Collaboration',
            messages: [
                'teamingNotEstablishedEntity',
                'teamingEmergingEntity',
                'teamingProgressingEntity',
                'teamingAdvancingEntity',
                'teamingAboutEntity',
            ],
        },
        {
            title: 'School Climate',
            messages: [
                'climateNotEstablishedEntity',
                'climateEmergingEntity',
                'climateProgressingEntity',
                'climateAdvancingEntity',
                'climateAboutEntity',
            ],
        },
        {
            title: 'School Discipline',
            messages: [
                'disciplineNotEstablishedEntity',
                'disciplineEmergingEntity',
                'disciplineProgressingEntity',
                'disciplineAdvancingEntity',
                'disciplineAboutEntity',
            ],
        },
        {
            title: 'Curricula and Teaching Practices',
            messages: [
                'curriculaNotEstablishedEntity',
                'curriculaEmergingEntity',
                'curriculaProgressingEntity',
                'curriculaAdvancingEntity',
                'curriculaAboutEntity',
            ],
        },
        {
            title: 'Professional Development',
            messages: [
                'prof_devNotEstablishedEntity',
                'prof_devEmergingEntity',
                'prof_devProgressingEntity',
                'prof_devAdvancingEntity',
                'prof_devAboutEntity',
            ],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'careStrategicPlanningPage1Entity',
                'careStrategicPlanningPage2Entity',
                'careStrategicPlanningPage3Entity',
                'careStrategicPlanningPage4Entity',
                'careStrategicPlanningPage5Entity',
                'careStrategicPlanningPage6Entity',
            ],
        },
    ],
    messages: {
        summaryReportIntroEntity: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p>This report is generated based on the information you provided for the CARE survey. The composite score for each domain is the average of your ratings for every item within the domain.</p><p>Composite scores of 1.0-1.9 are classified as <strong>"Emerging"</strong> areas, 2.0-2.9 are classified as <strong>"Progressing"</strong> areas, and 3.0-4.0 are classified as areas of <strong>"Advancing."</strong></p>',
        },
        summaryReportAdditionalText: {
            title: 'Report instruction text (left column of Summary Report)',
            message:
                '<p><strong>Not Established - </strong>Domains where contributors reported the practices within the domain do not exist yet within the organization or they are rarely observed. The overall mean falls between 1.0-1.9.</p><p><strong>Emerging - </strong>Domains where contributors reported the practices within the domain are happening MUCH LESS often than there is opportunity to do so. The overall mean falls between 2.0-2.9. </p><p><strong>Progressing - </strong>Domains where contributors reported the practices within the domain are carried out LESS often than there are opportunities to do so within the school. The overall mean falls between 3.0-4.9.</p><p><strong>Advancing - </strong>Domains where contributors reported of the practices within the domain are part of the every-day practice and happening almost always. The overall mean falls between 5.0-6.0.</p><p><strong>Principal Report - </strong>A mini report is provided to share with your school leadership and includes highest and lowest items in each domain. It is important for school leadership to be involved to the promotion of CARE policies and practices. Also, there are reflection questions about Entity CARE policies and practices for principals to consider in planning for quality improvement initiatives.</p><p>Need resources? The SHAPE Resource Library offers a wide variety of tools and resources to help your team with their continuous quality improvement efforts and action planning. The library is organized by domain to help you locate exactly what you need.</p>',
        },
        teamingNotEstablishedEntity: {
            title: 'Teaming and Collaboration (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        teamingEmergingEntity: {
            title: 'Teaming and Collaboration (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        teamingProgressingEntity: {
            title: 'Teaming and Collaboration (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        teamingAdvancingEntity: {
            title: 'Teaming and Collaboration (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        teamingAboutEntity: {
            title: 'Teaming and Collaboration (About)',
            message: '<p>Teaming and Collaboration is </p>',
        },
        climateNotEstablishedEntity: {
            title: 'School Climate (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        climateEmergingEntity: {
            title: 'School Climate (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        climateProgressingEntity: {
            title: 'School Climate (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        climateAdvancingEntity: {
            title: 'School Climate (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        climateAboutEntity: {
            title: 'School Climate (About)',
            message: `<p>School Climatee is </p>`,
        },
        disciplineNotEstablishedEntity: {
            title: 'School Discipline (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        disciplineEmergingEntity: {
            title: 'School Discipline (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        disciplineProgressingEntity: {
            title: 'School Discipline (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        disciplineAdvancingEntity: {
            title: 'School Discipline (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        disciplineAboutEntity: {
            title: 'School Discipline (About)',
            message: `<p>School Discipline is </p>`,
        },
        curriculaNotEstablishedEntity: {
            title: 'Curricula and Teaching Practices (Not Established)',
            message: '<p>Based on your responses, this is a <i>not established domain</i> for your school.</p>',
        },
        curriculaEmergingEntity: {
            title: 'Curricula and Teaching Practices (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        curriculaProgressingEntity: {
            title: 'Curricula and Teaching Practices (Progressing)',
            message:
                '<p>Based on your responses, this is a <i>progressing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>',
        },
        curriculaAdvancingEntity: {
            title: 'Curricula and Teaching Practices (Advancing)',
            message: `<p>Based on your responses, this is a <i>advancing domain</i> for your school. Because your score indicates you have many of the building blocks/foundational steps for this domain in place, there are a number of next steps you can take to further enhance your effort in this area. Please refer to the Resource Library to support you with next steps.</p>`,
        },
        curriculaAboutEntity: {
            title: 'Curricula and Teaching Practices (About)',
            message: '<p>Curricula and Teaching Practices is </p>',
        },
        prof_devNotEstablishedEntity: {
            title: 'Professional Development (Not Established)',
            message: '<p>Based on your responses, this is an <i>not established domain</i> for your school.</p>',
        },
        prof_devEmergingEntity: {
            title: 'Professional Development (Emerging)',
            message: '<p>Based on your responses, this is an <i>emerging domain</i> for your school.</p>',
        },
        prof_devProgressingEntity: {
            title: 'Professional Development (Progressing)',
            message: '<p>Based on your responses, this is a <i>progressing domain</i> for your school.</p>',
        },
        prof_devAdvancingEntity: {
            title: 'Professional Development (Advancing)',
            message: '<p>Based on your responses, this is a <i>advancing domain</i> for your school.</p>',
        },
        prof_devAboutEntity: {
            title: 'Professional Development (About)',
            message: '<p>Professional Development is </p>',
        },
        careStrategicPlanningPage1Entity: {
            title: 'Strategic Planning Guide - Page 1',
            message: `
            <p>This Strategic Planning Guide is a tool for teams who have completed the Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment and are ready to engage in a strategic planning process to improve CARE practices. The CARE assessment provides a comprehensive picture of CARE practices in the school environment, often surfacing numerous strengths and opportunities for improvement or growth across different areas. However, most schools have more opportunities for improvement than they can realistically address in a given school year. Therefore, this guide is designed to help teams prioritize one area for improvement, develop a measurable goal, and map out an initial plan including anticipated opportunities and barriers, action steps, and a timeline. </p>
                <p><strong><u>Prioritize One to Two Improvement Areas </u></strong></p>
                <p>The CARE School Assessment includes five areas (Curricula and Teaching Practices, School Climate, Teaming and Collaboration, School Discipline, and Professional Development). We encourage you to meet with your implementation team and review your scores on each area of the report. </p>
                <p>Some teams have a clear idea of which improvement area they want to start with after they complete the assessment. If this is your team, select your area and move directly to “Develop a Measurable Goal.” </p>
                <p>If you are not sure yet which improvement area is right for you, consider the following discussion questions to build consensus on what area(s) to prioritize:</p>
                <ul>
                <li><strong>Desire</strong> &ndash; How much desire (e.g., motivation, need) is there to improve this area?</li>
                <li><strong>Impact</strong> &ndash; How much impact would improvements in this area have on student, staff and/or school and community outcomes?</li>
                <li><strong>Resources </strong>&ndash; What resources (e.g., staffing, capacity, financial resources, leadership support, community support, partnerships, expertise) are available to make changes and improvements in this area?</li>
                </ul>`,
        },
        careStrategicPlanningPage2Entity: {
            title: 'Strategic Planning Guide - Page 2',
            message: `
            <p><strong>Select one area that your team would like to improve.</strong></p>
                <p><strong>Priority Area #1:</strong></p>
                <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                <tbody>
                <tr>
                <td style="width: 19%;">
                <p style="text-align: center;">Teaming and Collaboration</p>
                </td>
                <td style="width: 23%; text-align: center;">School Climate</td>
                <td style="width: 27%; text-align: center;">School Discipline</td>
                </tr>
                <tr>
                <td style="width: 27%; text-align: center;">Professional Development</td>
                <td style="width: 23%; text-align: center;">Curricula and Teaching Practices</td>
                </tr>
                </tbody>
                </table>
                <p>Identifying an improvement area is a first step for the school year. Focusing on one area at a time will support consistency and momentum.</p>
                <p><strong>(OPTIONAL) Some teams have other improvement areas they would like to address secondarily, or in the near future. If so, check other improvement area(s) here:</strong></p>
                   <p><strong>Priority Area #2:</strong></p>
                   <table class="table table-bordered" style="width: 83.1185%; margin-left: auto; margin-right: auto;">
                   <tbody>
                   <tr>
                   <td style="width: 19%;">
                   <p style="text-align: center;">Teaming and Collaboration</p>
                   </td>
                   <td style="width: 23%; text-align: center;">School Climate</td>
                   <td style="width: 27%; text-align: center;">School Discipline</td>
                   </tr>
                   <tr>
                   <td style="width: 27%; text-align: center;">Professional Development</td>
                   <td style="width: 23%; text-align: center;">Curricula and Teaching Practices</td>
                   </tr>
                   </tbody>
                   </table>
                   <p>The following pages can be used as a tool to plan your next steps for this domain. </p>`,
        },
        careStrategicPlanningPage3Entity: {
            title: 'Strategic Planning Guide - Page 3',
            message: `
                <p><strong><u>Develop a Measurable Goal</u></strong></p>
                <p>Develop a specific, measurable goal to clarify and customize what you will focus on by setting a SMARTIE goal.</p>
                <p><strong>Example SMARTIE Goals by Improvement Area:</strong></p>
                <ul>
                <li>By October 1st, we will obtain oral or written commitment from 5 students and 5 parents/caregivers as advisors to our School Mental Health Culture and Climate Team. This is our first step to improve equity and inclusion in our teaming processes, with a specific focus on youth and family partnerships first.&nbsp;<strong>(Teaming)</strong></li>
                <li>By September 30th, we will conduct walkthroughs of 7th and 8th grade classrooms and use a standardized checklist to monitor the use of diverse classroom materials. A student and teacher representative will be invited to participate. They will partner with a team member on the walkthroughs and provide input on how to increase representation in the classroom and improve the checklist.&nbsp;<strong>(Curricula and Teaching Practices)</strong></li>
                <li>By January 30th, we will review discipline data from September to December and disaggregate by student groups to monitor the impact of school discipline policies on different student groups. Team will develop summary reports for students and families to improve transparency and collaboration by the following month.<strong>(School Discipline)</strong></li>
                <li>By December 20th, we will host 1 community night at the school and 2 school members will attend 2 community events, chosen from a calendar of events, to foster relationships with the community and create a welcoming environment at the school. To center student voice, the student council will lead the initiative, with a team member as a coach, meeting weekly afterschool. The implementation team will meet monthly to monitor progress toward goal and address barriers to completion.&nbsp;<strong>(School Climate)</strong></li>
                <li>By June of this school year, we will develop a professional development curriculum including 9, 30-minute sessions for CARE practices to ensure staff understand the impact of cultural responsiveness, anti-racism, and equity on student success. School will consult with CARE experts to develop high-quality content.&nbsp;<strong>(Professional Development)</strong></li>
                </ul>
                <p><strong>What are SMARTIE Goals?</strong></p>
            <ul>
            <li><strong>Specific&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Concrete, detailed, and well-defined</li>
            <li><strong>Measurable</strong>&nbsp; &nbsp;Numeric or descriptive with quantity, quality, or comparative to baseline</li>
            <li><strong>Actionable</strong>&nbsp; &nbsp; &nbsp;Feasible, attainable, actionable</li>
            <li><strong>Realistic </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Considers resources, barriers, strengths, and what can be achieved</li>
            <li><strong>Timebound</strong> &nbsp;&nbsp; Identified target dates, interim steps to monitor progress, and/or a timeline for goal to be achieved</li>
            <li><strong>Inclusive</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Brings traditionally marginalized students and families&mdash;particularly those most impacted&mdash;into intervention planning, implementation, and progress monitoring in a way that shares power</li>
            <li><strong>Equitable</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Includes an element of fairness or justice that seeks to address systemic injustice, inequity, or oppression</li>
            </ul>
                `,
        },
        careStrategicPlanningPage4Entity: {
            title: 'Strategic Planning Guide - Page 4',
            message: `
            <p><strong><u>Template</u></strong>: By [<em>time frame</em>], we will accomplish a [<em>measurable amount of</em>] [<em>our goal</em>] to improve [<em>quality indicator</em>].</p>
            <p>Be specific about how the goal or strategy is <em>inclusive and equitable</em>.</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>&nbsp;</strong><strong>GOAL</strong>: <u>_______________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><u>______________________________________________________________________________</u></p>
            <p>&nbsp;</p>
            <p><strong>How will we know this goal has been achieved? <u>____________________________________</u></strong></p>
            <p>&nbsp;</p>
            <p><strong><u>______________________________________________________________________________</u></strong></p>
            <p>&nbsp;</p>
            <p><strong>What opportunities exist related to this goal?</strong></p>
                   <ul>
                   <li>What have been our past successes?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What current work is taking place related to this goal?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What are our available resources (leadership, infrastructure, staffing, partnerships)?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
            `,
        },
        careStrategicPlanningPage5Entity: {
            title: 'Strategic Planning Guide - Page 5',
            message: `
            <p><strong>What barriers exist related to this goal?</strong></p>
                   <ul>
                   <li>What would prevent us from moving forward with this goal?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <ul>
                   <li>What would we need to overcome this/these barrier(s)?</li>
                   </ul>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
                   <p>&nbsp;</p>
            <p><strong><u>Create an Action Plan</u></strong></p>
                <p>Identify 3-5 short-term action steps and 3-5 long-term action steps (if relevant) that can be taken to achieve this goal.</p>
                <p><strong>Short term: 0-6 months</strong></p>
                <table class="table table-bordered" style="width: 100%;">
                <tbody>
                <tr>
                <td style="width: 42%;"><strong>Action Step</strong></td>
                <td style="width: 22%;"><strong>Team Members Responsible</strong></td>
                <td style="width: 22.6913%;"><strong>Data to Evaluate Change</strong></td>
                <td style="width: 10.3087%;"><strong>Target Date</strong></td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                <tr>
                <td style="width: 42%;">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                </td>
                <td style="width: 22%;">&nbsp;</td>
                <td style="width: 22.6913%;">&nbsp;</td>
                <td style="width: 10.3087%;">&nbsp;</td>
                </tr>
                </tbody>
                <p>&nbsp;</p>
                </table>`,
        },
        careStrategicPlanningPage6Entity: {
            title: 'Strategic Planning Guide - Page 6',
            message: `
             </table><p><strong>Long term: 7-24 months</strong></p>
             <p>&nbsp;</p>
             <table class="table table-bordered" style="width: 100%;">
             <tbody>
             <tr>
             <td style="width: 42%;"><strong>Action Step</strong></td>
             <td style="width: 19%;"><strong>Team Members Responsible</strong></td>
             <td style="width: 20.8055%;"><strong>Data to Evaluate Change</strong></td>
             <td style="width: 10.1945%;"><strong>Target Date</strong></td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             <tr>
             <td style="width: 42%;">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             </td>
             <td style="width: 19%;">&nbsp;</td>
             <td style="width: 20.8055%;">&nbsp;</td>
             <td style="width: 10.1945%;">&nbsp;</td>
             </tr>
             </tbody>
             </table>`,
        },
    },
};
