import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import traumaOne from '../../../images/traumaOne.png';
import subsOne from '../../../images/subsOne.png';
import traumaTwo from '../../../images/traumaTwo.png';
import subsTwo from '../../../images/subsTwo.png';
import teleOne from '../../../images/teleOne.png';
import teleTwo from '../../../images/teleTwo.png';

export default class InnovationsEmergingAreas extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain6: PropTypes.object,
        totalPages: PropTypes.string,
        stateTitle: PropTypes.string,
    };

    render() {
        const { domain6, stateTitle } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo_stateLevel}>
                                <div className={style.triangleState_innovations1} />
                            </div>
                            <div className={style.headerTitle_state}>Innovations and Emerging Areas</div>
                        </div>
                        <div className={style.reportPageInnvo}>
                            <div className={style.bodyContent}>
                                <div className={style.bodyTopState}>
                                    <p className={style.topInnvo}>
                                        {stateTitle ? stateTitle : ''} reported recent school mental health innovations
                                        and activities in the following emerging areas:
                                    </p>

                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4>Trauma/Adverse Childhood Experiences (ACEs)</h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.traumaOne} src={traumaOne} alt="traumaOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain6.mental_health_profile_ACES}</span>
                                                </div>

                                                <div className={style.invoPage123}>
                                                    <img className={style.traumaTwo} src={traumaTwo} alt="traumaTwo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.mainCartHeader_stateLevel}>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4>Substance Use</h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.traumaOne} src={subsOne} alt="subsOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain6.mental_health_profile_SBIRT}</span>
                                                </div>

                                                <div className={style.innova1234}>
                                                    <img className={style.traumaTwo} src={subsTwo} alt="subsTwo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={style.cartContentHeader_stateLevel}>
                                            <h4>Telemental Health</h4>
                                        </div>

                                        <div className={style.CartContent_stateLevel}>
                                            <img className={style.traumaOne} src={teleOne} alt="teleOne" />
                                            <div className={style.OnOrderlist_Block_stateLevel}>
                                                <div className={style.invoPage12}>
                                                    <span>{domain6.mental_health_profile_telem}</span>
                                                </div>

                                                <div className={style.invoPage123}>
                                                    <img className={style.traumaTwo} src={teleTwo} alt="teleTwo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on responses from{' '}
                            {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 12 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
