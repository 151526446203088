export const instructionFields = {
    domains: [
        {
            title: 'Mental Health Quality Instructions',
            messages: [
                'mhqInstructionsSchool',
                'mhqInstructionsPreschool',
                'mhqInstructionsDistrict',
                'mhqInstructionsIndividual',
                'mhqInstructionsEntity',
            ],
        },
        {
            title: 'CARE Instructions',
            messages: [
                'careInstructionsSchool',
                'careInstructionsDistrict',
                'careInstructionsEntity',
                'careInstructionsIndividual',
            ],
        },
        {
            title: 'OWBI Instructions',
            messages: [
                'owbiInstructionsSchool',
                'owbiInstructionsDistrict',
                'owbiInstructionsIndividual',
                'owbiInstructionsEntity',
            ],
        },
        {
            title: 'Trauma Responsiveness Instructions',
            messages: [
                'trsInstructionsSchool',
                'trsInstructionsDistrict',
                'trsInstructionsIndividual',
                'trsInstructionsEntity',
            ],
        },

        {
            title: 'Mental Health Profile Instructions',
            messages: [
                'mhpInstructionsSchool',
                'mhpInstructionsDistrict',
                'mhpInstructionsState',
                'mhpInstructionsEntity',
            ],
        },
        {
            title: 'Family Engagement Instructions',
            messages: [
                'familyEngagementInstructionsSchool',
                'familyEngagementInstructionsDistrict',
                'familyEngagementInstructionsEntity',
                'familyEngagementInstructionsIndividual',
            ],
        },
    ],
    messages: {
        mhqInstructionsSchool: {
            title: 'Mental Health Quality Page Instructions (School)',
            message: `
            <h2 style="font-size: 2em">The School Mental Health Quality Assessment</h2><p>is designed for teams to assess the comprehensiveness of their school mental health system and to identify priority areas for improvement. The Quality Assessment is comprised of seven domains (Teaming, Needs Assessment/Resource Mapping, Screening, Mental Health Promotion, Early Intervention and Treatment, Funding and Sustainability, Impact). Your team will receive custom reports as you complete each domain.<p>`,
        },
        mhqInstructionsPreschool: {
            title: 'Mental Health Quality Page Instructions (Preschool)',
            message: `
            <h2 style="font-size: 2em">The School Mental Health Quality Assessment - Preschool</h2><p>is designed for preschool and early childhood care center teams to (1) assess their mental health services and supports and (2) identify priority areas for quality improvement. The SMHQA-PS covers six domains, which include: (1) Family Partnerships (2) Services and Supports, (3) Teaming, (4) Mental Health Promotion, (5) Kindergarten Transition, and (6) Screening. There are a variety of names used to encompass early childhood care and education. This measure is best suited for early care and eductaion settings with academic programming, serving children from ages 3-5 and preparing them (cognitively and socioemotionally) for kindergarten entry; we will refer to this definition as "preschool" throughout the servey.</p>`,
        },
        mhqInstructionsDistrict: {
            title: 'Mental Health Quality Page Instructions (District)',
            message: `<h2 style="font-size: 2em">The School Mental Health Quality Assessment</h2><p>is designed for teams to assess the comprehensiveness of their school mental health system and to identify priority areas for improvement. The Quality Assessment is comprised of seven domains (Teaming, Needs Assessment/Resource Mapping, Screening, Mental Health Promotion, Early Intervention and Treatment, Funding and Sustainability, Impact). Your team will receive custom reports as you complete each domain.<p>`,
        },
        mhqInstructionsIndividual: {
            title: 'Mental Health Quality Page Instructions (Individual)',
            message: `<h2 style="font-size: 2em">The School Mental Health Quality Assessment</h2><p>is designed for teams to assess the comprehensiveness of their school mental health system and to identify priority areas for improvement. The Quality Assessment is comprised of seven domains (Teaming, Needs Assessment/Resource Mapping, Screening, Mental Health Promotion, Early Intervention and Treatment, Funding and Sustainability, Impact). Your team will receive custom reports as you complete each domain.<p>`,
        },
        mhqInstructionsEntity: {
            title: 'Mental Health Quality Page Instructions (Entity)',
            message: `<h2 style="font-size: 2em">The School Mental Health Quality Assessment</h2><p>is designed for teams to assess the comprehensiveness of their school mental health system and to identify priority areas for improvement. The Quality Assessment is comprised of seven domains (Teaming, Needs Assessment/Resource Mapping, Screening, Mental Health Promotion, Early Intervention and Treatment, Funding and Sustainability, Impact). Your team will receive custom reports as you complete each domain.<p>`,
        },
        trsInstructionsSchool: {
            title: 'Trauma Responsiveness Page Instructions (School)',
            message: `
            <h2 style="font-size: 1.9em">The Trauma Responsive Schools Implementation Assessment (TRS-IA)</h2><p>is a quality improvement tool developed by the NCTSN Treatment and Services Adaptation Center for Resilience, Hope, and Wellness in Schools and the National Center for School Mental Health. The TRS-IA is an evidence-informed self-assessment that comprises the eight key domains listed below.</p>

            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their trauma-responsive programming.</p>`,
        },
        trsInstructionsDistrict: {
            title: 'Trauma Responsiveness Page Instructions (District)',
            message: `
            <h2 style="font-size: 1.9em">The Trauma Responsive Schools Implementation Assessment (TRS-IA)</h2><p>is a quality improvement tool developed by the NCTSN Treatment and Services Adaptation Center for Resilience, Hope, and Wellness in Schools and the National Center for School Mental Health. The TRS-IA is an evidence-informed self-assessment that comprises the eight key domains listed below.</p>

            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their trauma-responsive programming.</p>`,
        },
        trsInstructionsIndividual: {
            title: 'Trauma Responsiveness Page Instructions (Individual)',
            message: `
            <h2 style="font-size: 1.9em">The Trauma Responsive Schools Implementation Assessment (TRS-IA)</h2><p>is a quality improvement tool developed by the NCTSN Treatment and Services Adaptation Center for Resilience, Hope, and Wellness in Schools and the National Center for School Mental Health. The TRS-IA is an evidence-informed self-assessment that comprises the eight key domains listed below.</p>

            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their trauma-responsive programming.</p>`,
        },
        trsInstructionsEntity: {
            title: 'Trauma Responsiveness Page Instructions (Entity)',
            message: `
            <h2 style="font-size: 1.9em">The Trauma Responsive Schools Implementation Assessment (TRS-IA)</h2><p>is a quality improvement tool developed by the NCTSN Treatment and Services Adaptation Center for Resilience, Hope, and Wellness in Schools and the National Center for School Mental Health. The TRS-IA is an evidence-informed self-assessment that comprises the eight key domains listed below.</p>
            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their trauma-responsive programming.</p>`,
        },
        owbiInstructionsSchool: {
            title: 'OWBI Page Instructions (School)',
            message: `
            <h2 style="font-size: 2em;">The Organizational Well-Being Inventory for Schools (OWBI-S)</h2><p>The OWBI-S provides an overview of the organizational well-being of your school system, often surfacing numerous strengths and opportunities for improvement or growth.</p>

            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their organizational well-being.</p>`,
        },
        owbiInstructionsDistrict: {
            title: 'OWBI Page Instructions (District)',
            message: `
            <h2 style="font-size: 2em;">The Organizational Well-Being Inventory for Schools (OWBI-S)</h2><p>The OWBI-S provides an overview of the organizational well-being of your school system, often surfacing numerous strengths and opportunities for improvement or growth.</p>

            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their organizational well-being.</p>`,
        },
        owbiInstructionsIndividual: {
            title: 'OWBI Page Instructions (Individual)',
            message: `
            <h2 style="font-size: 2em;">The Organizational Well-Being Inventory for Schools (OWBI-S)</h2><p>The OWBI-S provides an overview of the organizational well-being of your school system, often surfacing numerous strengths and opportunities for improvement or growth.</p>

            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their organizational well-being.</p>`,
        },
        owbiInstructionsEntity: {
            title: 'OWBI Page Instructions (Entity)',
            message: `
            <h2 style="font-size: 2em;">The Organizational Well-Being Inventory for Schools (OWBI-S)</h2><p>The OWBI-S provides an overview of the organizational well-being of your school system, often surfacing numerous strengths and opportunities for improvement or growth.</p>

            <p>Administration time is less than 20 minutes. Feedback reports and comprehensive guides will be generated to support schools and districts as they work to enhance their organizational well-being.</p>`,
        },
        careInstructionsSchool: {
            title: 'CARE Page Instructions (School)',
            message: `
            <h2 style="font-size: 1.5em;">The Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment</h2><p>is designed for teams to assess CARE practices and to identify priority areas for improvement. The assessment comprises five domains (Teaming and Collaboration, School Climate, School Discipline, Curricula and Teaching Practices, Professional Development) and provides examples of ways schools can create an environment that reflects values around diversity, equity, and inclusion.  Your team will receive custom reports as you complete each domain and strategic planning guides for next steps.</p>`,
        },
        careInstructionsDistrict: {
            title: 'CARE Page Instructions (District)',
            message: `
            <h2 style="font-size: 1.5em;">The Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment</h2><p>is designed for teams to assess CARE practices and to identify priority areas for improvement. The assessment comprises five domains (Teaming and Collaboration, School Climate, School Discipline, Curricula and Teaching Practices, Professional Development) and provides examples of ways schools can create an environment that reflects values around diversity, equity, and inclusion.  Your team will receive custom reports as you complete each domain and strategic planning guides for next steps.</p>`,
        },
        careInstructionsEntity: {
            title: 'CARE Page Instructions (Entity)',
            message: `
            <h2 style="font-size: 1.5em;">The Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment</h2><p>is designed for teams to assess CARE practices and to identify priority areas for improvement. The assessment comprises five domains (Teaming and Collaboration, School Climate, School Discipline, Curricula and Teaching Practices, Professional Development) and provides examples of ways schools can create an environment that reflects values around diversity, equity, and inclusion.  Your team will receive custom reports as you complete each domain and strategic planning guides for next steps.</p>`,
        },
        careInstructionsIndividual: {
            title: 'CARE Page Instructions (Individual)',
            message: `
            <h2 style="font-size: 1.5em;">The Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment</h2><p>is designed for teams to assess CARE practices and to identify priority areas for improvement. The assessment comprises five domains (Teaming and Collaboration, School Climate, School Discipline, Curricula and Teaching Practices, Professional Development) and provides examples of ways schools can create an environment that reflects values around diversity, equity, and inclusion.  Your team will receive custom reports as you complete each domain and strategic planning guides for next steps.</p>`,
        },
        mhpInstructionsSchool: {
            title: 'Mental Health Profile Page Instructions (School)',
            message: `
            <h2 style="font-size: 2em">The School Mental Health Profile</h2><p>collects information related to multi-tiered services and
            supports, staffing, financing, and data systems based on your school's mental health system. Your responses will contribute to the National School Mental Health Census, an effort to capture the status of school mental health nationally.</p>`,
        },
        mhpInstructionsDistrict: {
            title: 'Mental Health Profile Page Instructions (District)',
            message: `
            <h2 style="font-size: 2em">The School Mental Health Profile</h2><p>collects information related to multi-tiered services and
            supports, staffing, financing, and data systems based on your district's school mental health system. Your responses will contribute to the National School Mental Health Census, an effort to capture the status of school mental health nationally.`,
        },
        mhpInstructionsState: {
            title: 'Mental Health Profile Page Instructions (State/Territory)',
            message: `
            <h2 style="font-size: 2em">The State/Territory Mental Health Profile</h2><p>will help your state/territory assess several school mental health indicators including: infrastructure, technical assisstance and training, state/territory policies, financing, staffing, and emerging SMH issues (including SEL, telepsychiatry, SDH).</p>
            <p>The state/territory profile will take approximately 1-2 hours to complete as a team.</p>`,
        },
        mhpInstructionsEntity: {
            title: 'Mental Health Profile Page Instructions (Entity)',
            message: `
            <h2 style="font-size: 2em">The School Mental Health Profile</h2><p>collects information related to multi-tiered services and
            supports, staffing, financing, and data systems based on your entity's school mental health system. Your responses will contribute to the National School Mental Health Census, an effort to capture the status of school mental health nationally.</p>`,
        },
        familyEngagementInstructionsSchool: {
            title: 'Family Engagement Page Instructions (School)',
            message: `<p>Team completion of this measure is recommended. Broad and diverse participation ensures meaningful
			assessment, successful planning, and effective implementation. Your team may include school- and community-
			employed staff and other partners, including caregivers. Use your results to encourage conversation as well as to
			prioritize and plan key improvements in family engagement. Check progress by retaking the measure as needed during
			the school year!</p>`,
        },
        familyEngagementInstructionsDistrict: {
            title: 'Family Engagement Page Instructions (District)',
            message: `<p>Team completion of this measure is recommended. Broad and diverse participation ensures meaningful
			assessment, successful planning, and effective implementation. Your team may include school- and community-
			employed staff, family advocacy organization staff, and other partners (e.g. PTA members, NAMI leaders). Use your
			results to encourage conversation as well as to prioritize and plan key improvements in family engagement. Check
			progress by retaking the measure as needed during the school year!</p>`,
        },
        familyEngagementInstructionsEntity: {
            title: 'Family Engagement Page Instructions (Entity)',
            message: `<p>Team completion of this measure is recommended. Broad and diverse participation ensures meaningful
			assessment, successful planning, and effective implementation. Your team may include school- and community-
			employed staff, family advocacy organization staff, and other partners (e.g. PTA members, NAMI leaders). Use your
			results to encourage conversation as well as to prioritize and plan key improvements in family engagement. Check
			progress by retaking the measure as needed during the school year!</p>`,
        },
        familyEngagementInstructionsIndividual: {
            title: 'Family Engagement Page Instructions (Individual)',
            message: ``,
        },
    },
};
