import React from 'react';
import PropTypes from 'prop-types';
import style from '../style.css';

export default class SubHeader extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
    };

    render() {
        return (
            <div className={style.date}>
                <div>
                    Last Updated:{' '}
                    <span style={{ fontWeight: '300' }}>{this.props.lastUpdated}</span>
                </div>
            </div>
        );
    }
}
