import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import SubHeading from '../SubHeading';
import BarGraph from '../BarGraph';
import style from './style.css';

import GaugeGreen from '../images/GaugeGreen.png';
import GaugeYellow from '../images/GaugeYellow.png';
import GaugeRed from '../images/GaugeRed.png';

import _some from 'lodash/some';
import _filter from 'lodash/filter';
import _round from 'lodash/round';

const mapStateToProps = state => {
    return {
        mhqFields: state.app.params.mhqFields,
    };
};

export default connect(mapStateToProps)(
    class Screening extends React.Component {
        static propTypes = {
            domainId: PropTypes.number,
            enteredBy: PropTypes.array,
            domainName: PropTypes.string,
            pageNumber: PropTypes.number,
            totalPages: PropTypes.number,
            mhqFields: PropTypes.object,
            data: PropTypes.object,
            handle: PropTypes.string,
            flowType: PropTypes.string,
            reportType: PropTypes.string,
            getEnteredBy: PropTypes.func,
            selectedDates: PropTypes.object,
        };

        getIndicatorScores = indicatorHandle => {
            const { data, handle } = this.props;
            let indicatorScores = { score: NaN, title: 'N/A' };

            if (data.totalScores) {
                indicatorScores = data.totalScores.find(item => item.handle === handle).indicators;
                const indicatorScore = indicatorScores.find(item => item.handle === indicatorHandle);
                if (!indicatorScore) {
                    return '0';
                }
                if (!indicatorScore.score || typeof indicatorScore.score === 'object') {
                    return '0';
                }
                return indicatorScore.score;
            }
            return NaN;
        };

        getGaugeImage = score => {
            if (score < 3) {
                return GaugeRed;
            }
            if (score >= 3 && score < 5) {
                return GaugeYellow;
            }
            return GaugeGreen;
        };

        getScoreColor = score => {
            if (score < 3) {
                return style.scoreRed;
            }
            if (score >= 3 && score < 5) {
                return style.scoreYellow;
            }
            return style.scoreGreen;
        };

        getScore = () => {
            const { data, handle } = this.props;
            const scoreInfo = data.totalScores.find(item => item.handle === handle);
            return scoreInfo
                ? {
                      score: scoreInfo.score,
                      districtScore: scoreInfo.districtScore,
                      districtReportingScore: scoreInfo.districtReportingScore,
                  }
                : '*';
        };

        getDataByHandle = () => {
            const { data, handle } = this.props;
            const scoreData = data.totalScores.find(item => item.handle === handle);
            const indicatorData = { school: {}, district: {} };

            if (_some(scoreData.indicators, indicator => indicator.group === 'school')) {
                indicatorData.school = _filter(scoreData.indicators, indicator => indicator.group === 'school');
            }

            if (_some(scoreData.indicators, indicator => indicator.group === 'districtReporting')) {
                indicatorData.districtReporting = _filter(
                    scoreData.indicators,
                    indicator => indicator.group === 'districtReporting'
                );
            }
            if (_some(scoreData.indicators, indicator => indicator.group === 'district')) {
                indicatorData.district = _filter(scoreData.indicators, indicator => indicator.group === 'district');
            } else {
                indicatorData.school = scoreData ? scoreData.indicators : { score: NaN, title: 'N/A' };
            }

            return indicatorData;
        };

        render() {
            const { domainName, pageNumber, totalPages, mhqFields, flowType, reportType } = this.props;

            const districtReportingScore = this.getScore().districtReportingScore;
            const districtScore = this.getScore().districtScore;
            const bestPracticesData = this.getDataByHandle().school.find(x => x.handle === 'BestPractices');
            if (flowType === 'mhqDistrict' && Number.isNaN(districtReportingScore)) {
                return null;
            }
            if (Number.isNaN(this.getIndicatorScores('Screened'))) {
                return null;
            }

            return (
                <>
                    <Page>
                        <div className={style.content}>
                            <div className={style.header}>
                                <div className={style.headerLogo}>
                                    <div className={style.triangle} />
                                    <div className={style.logo} />
                                </div>
                                <div className={style.headerTitle}>{domainName}</div>
                            </div>
                            <SubHeading
                                getEnteredBy={this.props.getEnteredBy}
                                selectedDates={this.props.selectedDates}
                            />
                            <div className={style.reportPage}>
                                <div style={{ marginBottom: '1em' }}>
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            color: '#0b72b6',
                                            lineHeight: '1.8',
                                        }}
                                    >
                                        What is {domainName}?
                                    </span>
                                    <div className={style.description}>
                                        {flowType === 'mhqDistrict' && (
                                            <Html content={mhqFields.screeningAboutDistrict} />
                                        )}
                                        {flowType === 'mhqEntity' && <Html content={mhqFields.screeningAboutEntity} />}
                                        {!['mhqDistrict', 'mhqEntity'].includes(flowType) && (
                                            <Html content={mhqFields.screeningAboutSchool} />
                                        )}
                                    </div>
                                </div>
                                <div className={style.screeningBody}>
                                    <p>
                                        During the last school year,{' '}
                                        <strong>{this.getIndicatorScores('Screened')}</strong> students in your school
                                        were screened for a mental health concern of any type by a school-based or
                                        community-partnered mental health staff member. This is{' '}
                                        <b>
                                            {Number.isNaN(
                                                this.getIndicatorScores('Screened') /
                                                    this.getIndicatorScores('Enrolled')
                                            )
                                                ? '0'
                                                : _round(
                                                      (this.getIndicatorScores('Screened') /
                                                          this.getIndicatorScores('Enrolled')) *
                                                          100,
                                                      0
                                                  )}
                                            %
                                        </b>{' '}
                                        of your student body.
                                    </p>
                                    <div
                                        style={{
                                            fontSize: '18px',
                                            color: '#0b72b6',
                                            lineHeight: '1.8',
                                        }}
                                    >
                                        Based on screening:
                                    </div>
                                    <div style={{ marginLeft: '1em' }}>
                                        <p>
                                            <b>{this.getIndicatorScores('IdentifiedAtRisk')}</b> identified as being
                                            at-risk or already experiencing a mental health problem
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('MarginalizedGroups')}</b> of students
                                            identified were from marginalized populations (e.g., BIPOC, LGBTQ+,
                                            immigrant)
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('Referred')}</b> referred to a mental health
                                            service following identification
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('SystemLevelChanges')}</b> system-level changes
                                            (e.g., training school staff in trauma-informed practices, revising
                                            discipline policies) were implemented
                                        </p>
                                        {reportType === 'Last Completed' && (
                                            <>
                                                <>
                                                    <b>System-level changes implemented:</b>
                                                </>
                                                <p>{this.getIndicatorScores('SystemLevelChangesImplemented')} </p>
                                            </>
                                        )}
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedDepression')}</b> received depression
                                            screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedSuicide')}</b> received suicidality
                                            screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedSubstanceUse')}</b> received substance
                                            use screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedTrauma')}</b> received trauma screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedAnxiety')}</b> received anxiety
                                            screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedGeneral')}</b> received general mental
                                            health screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedWellBeing')}</b> received well-being or
                                            protective factors screening
                                        </p>
                                        <p>
                                            <b>{this.getIndicatorScores('ScreenedSocialDeterminants')}</b> received
                                            social determinants of mental health and well-being screening
                                        </p>
                                        {reportType === 'Last Completed' && (
                                            <>
                                                {!Number.isNaN(this.getIndicatorScores('ScreenedUserAdded_1')) &&
                                                    Number(this.getIndicatorScores('ScreenedUserAdded_1')) > 0 && (
                                                        <p>
                                                            <b>{this.getIndicatorScores('ScreenedUserAdded_1')}</b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('ScreenedUserAdded_1_text')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                                {!Number.isNaN(this.getIndicatorScores('ScreenedUserAdded_2')) &&
                                                    Number(this.getIndicatorScores('ScreenedUserAdded_2')) > 0 && (
                                                        <p>
                                                            <b>{this.getIndicatorScores('ScreenedUserAdded_2')}</b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('ScreenedUserAdded_2_text')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                                {!Number.isNaN(this.getIndicatorScores('ScreenedUserAdded_3')) &&
                                                    Number(this.getIndicatorScores('ScreenedUserAdded_3')) > 0 && (
                                                        <p>
                                                            <b>{this.getIndicatorScores('ScreenedUserAdded_3')}</b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('ScreenedUserAdded_3_text')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                                {!Number.isNaN(this.getIndicatorScores('ScreenedUserAdded_4')) &&
                                                    Number(this.getIndicatorScores('ScreenedUserAdded_4')) > 0 && (
                                                        <p>
                                                            <b>{this.getIndicatorScores('ScreenedUserAdded_4')}</b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('ScreenedUserAdded_4_text')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                                {!Number.isNaN(this.getIndicatorScores('ScreenedUserAdded_5')) &&
                                                    Number(this.getIndicatorScores('ScreenedUserAdded_5')) > 0 && (
                                                        <p>
                                                            <b>{this.getIndicatorScores('ScreenedUserAdded_5')}</b>{' '}
                                                            received{' '}
                                                            {this.getIndicatorScores('ScreenedUserAdded_5_text')}{' '}
                                                            screening
                                                        </p>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.footer}>
                            <div className={style.footerText}>
                                <div>
                                    This progress report was developed by the National Center for School Mental Health
                                    for the SHAPE System.
                                </div>
                                <div>
                                    Page {pageNumber} of {totalPages}
                                </div>
                            </div>
                            <div className={style.footerLogo} />
                        </div>
                    </Page>
                    {(['mhqDistrict', 'mhqEntity'].includes(flowType) || bestPracticesData) && (
                        <Page>
                            <div className={style.content}>
                                <div className={style.header}>
                                    <div className={style.headerLogo}>
                                        <div className={style.triangle} />
                                        <div className={style.logo} />
                                    </div>
                                    <div className={style.headerTitle}>{domainName}</div>
                                </div>
                                <SubHeading
                                    getEnteredBy={this.props.getEnteredBy}
                                    selectedDates={this.props.selectedDates}
                                />
                                <div className={style.reportPage}>
                                    <>
                                        <div className={style.overallScore}>
                                            <div
                                                className={style.gauge}
                                                style={{
                                                    backgroundImage: `url(${this.getGaugeImage(
                                                        this.getIndicatorScores('BestPractices')
                                                    )})`,
                                                }}
                                            />
                                            <div className={style.gaugeText}>
                                                <div>
                                                    {flowType === 'mhqDistrict' && (
                                                        <span className={style.gaugeDescription}>
                                                            For schools in your district
                                                        </span>
                                                    )}
                                                    {flowType === 'mhqEntity' && (
                                                        <span className={style.gaugeDescription}>
                                                            For schools in your entity
                                                        </span>
                                                    )}
                                                </div>
                                                <div className={style.gaugeScore}>
                                                    OVERALL COMPOSITE SCORE:{' '}
                                                    <span
                                                        className={this.getScoreColor(
                                                            this.getIndicatorScores('BestPractices')
                                                        )}
                                                    >
                                                        {this.getIndicatorScores('BestPractices')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <BarGraph metrics={[bestPracticesData]} flowType={flowType} />
                                    </>

                                    {['mhqDistrict', 'mhqEntity'].includes(flowType) && (
                                        <>
                                            <div className={style.overallScore}>
                                                <div
                                                    className={style.gauge}
                                                    style={{
                                                        backgroundImage: `url(${this.getGaugeImage(districtScore)})`,
                                                    }}
                                                />
                                                <div className={style.gaugeText}>
                                                    <div>
                                                        {flowType === 'mhqDistrict' && (
                                                            <span className={style.gaugeDescription}>
                                                                District support
                                                            </span>
                                                        )}
                                                        {flowType === 'mhqEntity' && (
                                                            <span className={style.gaugeDescription}>
                                                                Entity support
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className={style.gaugeScore}>
                                                        OVERALL COMPOSITE SCORE:{' '}
                                                        <span className={this.getScoreColor(districtScore)}>
                                                            {districtScore}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <BarGraph metrics={this.getDataByHandle().district} flowType={flowType} />
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className={style.footer}>
                                <div className={style.footerText}>
                                    <div>
                                        This progress report was developed by the National Center for School Mental
                                        Health for the SHAPE System.
                                    </div>
                                    <div>
                                        Page {pageNumber + 'b'} of {totalPages}
                                    </div>
                                </div>
                                <div className={style.footerLogo} />
                            </div>
                        </Page>
                    )}
                </>
            );
        }
    }
);
