import React from 'react';
import Modal from 'cccisd-modal';
import IconPlus from 'cccisd-icons/plus-circle2';
import style from './style.css';

const addDistrict = props => {
    return (
        <Modal
            title="Add District"
            trigger={
                <button type="button" className="btn btn-primary">
                    <IconPlus /> Add District
                </button>
            }
            ref={props.myModalRef}
        >
            <>
                <div className={style.otherFilterOptions}>
                    <div className="form-group">
                        <label>
                            State
                            <select
                                value={props.stateId}
                                onChange={props.onStateSelectChange}
                                className="form-control"
                                style={{ width: 'auto' }}
                            >
                                <option value="">-- Select a State --</option>
                                {props.states.map(option => (
                                    <option key={option.groupId} value={option.groupId}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            District
                            <select
                                value={props.districtId}
                                onChange={e => props.setDistrictId(e)}
                                className="form-control"
                                style={{ width: 'auto' }}
                            >
                                <option value="">-- Select a District --</option>
                                {props.districtList.map(option => (
                                    <option key={option.group.groupId} value={option.group.groupId}>
                                        {option.group.label}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div className={style.buttons}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={props.onAddDistrictSubmit}
                        disabled={!(props.stateId && props.districtId)}
                    >
                        Submit
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default addDistrict;
