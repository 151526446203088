import { handleActions, createAction } from 'redux-actions';
import { client as apollo } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import moment from 'moment';

// Queries
import groupQuery from './graphql/groupQuery.graphql';
import shapeUser from './graphql/shapeUser.graphql';
import userQuery from './graphql/userQuery.graphql';
import deploymentGroupPawnQuery from './graphql/deploymentGroupPawnQuery.graphql';
import deploymentProgressQuery from './graphql/deploymentProgressQuery.graphql';
import anonSchoolOWBIQuery from './graphql/anonSchoolOWBIQuery.graphql';
import anonDistrictOWBIQuery from './graphql/anonDistrictOWBIQuery.graphql';
import anonEntityOWBIQuery from './graphql/anonEntityOWBIQuery.graphql';
import anonSchoolFamilyEngagementQuery from './graphql/anonSchoolFamilyEngagementQuery.graphql';
import anonDistrictFamilyEngagementQuery from './graphql/anonDistrictFamilyEngagementQuery.graphql';
import anonEntityFamilyEngagementQuery from './graphql/anonEntityFamilyEngagementQuery.graphql';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

// Lodash
import _pick from 'lodash/pick';
import _some from 'lodash/some';
import _get from 'lodash/get';
import _mapValues from 'lodash/mapValues';
import _isPlainObject from 'lodash/isPlainObject';
import _uniqBy from 'lodash/uniqBy';

export const initialState = {
    startDate: '',
    endDate: '',
    userGroups: [],
    legacyUser: false,
    assessmentTypeSet: false,
    legacyUserSet: false,
    mhqFlowData: {},
    trsFlowData: {},
    owbiFlowData: {},
    owbiFlowAnonData: {},
    familyEngagementFlowAnonData: {},
    careFlowData: {},
    familyEngagementFlowData: {},
    mhqDataLoaded: false,
    trsDataLoaded: false,
    owbiDataLoaded: false,
    owbiAnonDataLoaded: false,
    familyEngagementAnonDataLoaded: false,
    careDataLoaded: false,
    familyEngagementDataLoaded: false,
    profileData: [],
    profileDataLoaded: false,
    groupPawn: {},
    profileContributors: [],
    reportingPeriods: [],
};

// Actions
const SET_START_DATE = 'app/shape/SET_START_DATE';
const SET_END_DATE = 'app/shape/SET_END_DATE';
const SET_USER_GROUPS = 'app/shape/SET_USER_GROUPS';
const SET_ASSESSMENT_TYPE = 'app/shape/SET_ASSESSMENT_TYPE';
const SET_LEGACY_USER = 'app/shape/SET_LEGACY_USER';
const SET_STAR_STATUS = 'app/shape/SET_STAR_STATUS';
const SET_STATE_NAME = 'app/shape/SET_STATE_NAME';
const SET_MHQ_FLOW_DATA = 'app/shape/SET_MHQ_FLOW_DATA';
const SET_TRS_FLOW_DATA = 'app/shape/SET_TRS_FLOW_DATA';
const SET_MHQ_DATA_LOADED = 'app/shape/SET_MHQ_DATA_LOADED';
const SET_TRS_DATA_LOADED = 'app/shape/SET_TRS_DATA_LOADED';
const SET_OWBI_FLOW_DATA = 'app/shape/SET_OWBI_FLOW_DATA';
const SET_OWBI_DATA_LOADED = 'app/shape/SET_OWBI_DATA_LOADED';
const SET_OWBI_FLOW_ANON_DATA = 'app/shape/SET_OWBI_FLOW_ANON_DATA';
const SET_FAMILY_ENGAGEMENT_FLOW_ANON_DATA = 'app/shape/SET_FAMILY_ENGAGEMENT_FLOW_ANON_DATA';
const SET_OWBI_ANON_DATA_LOADED = 'app/shape/SET_OWBI_ANON_DATA_LOADED';
const SET_FAMILY_ENGAGEMENT_ANON_DATA_LOADED = 'app/shape/SET_FAMILY_ENGAGEMENT_ANON_DATA_LOADED';
const SET_CARE_FLOW_DATA = 'app/shape/SET_CARE_FLOW_DATA';
const SET_CARE_DATA_LOADED = 'app/shape/SET_CARE_DATA_LOADED';
const SET_FAMILY_ENGAGEMENT_FLOW_DATA = 'app/shape/SET_FAMILY_ENGAGEMENT_FLOW_DATA';
const SET_FAMILY_ENGAGEMENT_DATA_LOADED = 'app/shape/SET_FAMILY_ENGAGEMENT_DATA_LOADED';
const SET_PROFILE_DATA = 'app/shape/SET_PROFILE_DATA';
const SET_PROFILE_DATA_LOADED = 'app/shape/SET_PROFILE_DATA_LOADED';
const SET_GROUP_PAWN = 'app/shape/SET_GROUP_PAWN';
const SET_PROFILE_CONTRIBUTOR_LIST = 'app/shape/SET_PROFILE_CONTRIBUTOR_LIST';
const SET_TOTAL_CHILD_GROUPS = 'app/shape/SET_TOTAL_CHILD_GROUPS';
const SET_REPORTING_PERIODS = 'app/shape/SET_REPORTING_PERIODS';

// Action Creators
export const setStartDate = createAction(SET_START_DATE);
export const setEndDate = createAction(SET_END_DATE);
export const setUserGroups = createAction(SET_USER_GROUPS);
export const setAssessmentType = createAction(SET_ASSESSMENT_TYPE);
export const setLegacyUser = createAction(SET_LEGACY_USER);
export const setStarStatus = createAction(SET_STAR_STATUS);
export const setStateName = createAction(SET_STATE_NAME);
export const setMhqFlowData = createAction(SET_MHQ_FLOW_DATA);
export const setTrsFlowData = createAction(SET_TRS_FLOW_DATA);
export const setMhqDataLoaded = createAction(SET_MHQ_DATA_LOADED);
export const setTrsDataLoaded = createAction(SET_TRS_DATA_LOADED);
export const setOwbiFlowData = createAction(SET_OWBI_FLOW_DATA);
export const setOwbiFlowAnonData = createAction(SET_OWBI_FLOW_ANON_DATA);
export const setOwbiDataLoaded = createAction(SET_OWBI_DATA_LOADED);
export const setOwbiAnonDataLoaded = createAction(SET_OWBI_ANON_DATA_LOADED);
export const setCareFlowData = createAction(SET_CARE_FLOW_DATA);
export const setCareDataLoaded = createAction(SET_CARE_DATA_LOADED);
export const setFamilyEngagementFlowData = createAction(SET_FAMILY_ENGAGEMENT_FLOW_DATA);
export const setFamilyEngagementDataLoaded = createAction(SET_FAMILY_ENGAGEMENT_DATA_LOADED);
export const setFamilyEngagementFlowAnonData = createAction(SET_FAMILY_ENGAGEMENT_FLOW_ANON_DATA);
export const setFamilyEngagementAnonDataLoaded = createAction(SET_FAMILY_ENGAGEMENT_ANON_DATA_LOADED);
export const setProfileData = createAction(SET_PROFILE_DATA);
export const setProfileDataLoaded = createAction(SET_PROFILE_DATA_LOADED);
export const setGroupPawn = createAction(SET_GROUP_PAWN);
export const setProfileContributorList = createAction(SET_PROFILE_CONTRIBUTOR_LIST);
export const setTotalChildGroups = createAction(SET_TOTAL_CHILD_GROUPS);
export const setReportingPeriods = createAction(SET_REPORTING_PERIODS);

// Set legacyUser, assessmentType, startDate, endDate & flowData
export const loadData = () => {
    return async (dispatch, getState) => {
        const pawnId = Fortress.user.acting.id;
        if (!getState().app.shape.assessmentTypeSet && !getState().app.shape.legacyUserSet) {
            let userResult = await apollo.query({
                query: userQuery,
                variables: {
                    pawnId,
                },
                fetchPolicy: 'network-only',
            });
            const assessmentType = userResult.data.roles.anyRole.fields.assessmentType;
            const legacyUser = userResult.data.roles.anyRole.fields.legacyUser;
            await dispatch(setAssessmentType(assessmentType));
            await dispatch(setLegacyUser(legacyUser));
        }

        if (!getState().app.shape.startDate && !getState().app.shape.endDate) {
            if (Fortress.user.acting.group) {
                const groupId = Fortress.user.acting.group.id;
                let result = await apollo.query({
                    query: groupQuery,
                    variables: {
                        groupId,
                    },
                    fetchPolicy: 'network-only',
                });
                const data = result.data.groups.anyGroup;

                const currentStartDate = data.fields.reportingPeriodStart;
                await dispatch(setStartDate(currentStartDate));

                const currentEndDate = data.fields.reportingPeriodEnd;
                await dispatch(setEndDate(currentEndDate));

                const starStatus = data.fields.starStatus;
                await dispatch(setStarStatus(starStatus));

                let reportingPeriods = data.fields.reportingPeriods;
                if (reportingPeriods) {
                    await dispatch(setReportingPeriods(reportingPeriods));
                }
            } else if (Fortress.hasRole('shapeUser')) {
                let result = await apollo.query({
                    query: shapeUser,
                    variables: {
                        pawnId,
                    },
                    fetchPolicy: 'network-only',
                });

                const data = result.data.roles.shapeUser;

                const currentStartDate = data.fields.reportingPeriodStart;
                await dispatch(setStartDate(currentStartDate));

                const currentEndDate = data.fields.reportingPeriodEnd;
                await dispatch(setEndDate(currentEndDate));

                let reportingPeriods = data.fields.reportingPeriods;
                if (reportingPeriods) {
                    await dispatch(setReportingPeriods(reportingPeriods));
                }
            }
        }
    };
};

export const loadReportingPeriods = () => {
    return async dispatch => {
        if (Fortress.user.acting.group) {
            const groupId = Fortress.user.acting.group.id;
            let result = await apollo.query({
                query: groupQuery,
                variables: {
                    groupId,
                },
                fetchPolicy: 'network-only',
            });
            const data = result.data.groups.anyGroup;
            let reportingPeriods = data.fields.reportingPeriods;
            if (reportingPeriods) {
                await dispatch(setReportingPeriods(reportingPeriods));
            }
        } else {
            const pawnId = Fortress.user.acting.id;
            let result = await apollo.query({
                query: shapeUser,
                variables: {
                    pawnId,
                },
                fetchPolicy: 'network-only',
            });
            const data = result.data.roles.shapeUser;
            let reportingPeriods = data.fields.reportingPeriods;
            if (reportingPeriods) {
                await dispatch(setReportingPeriods(reportingPeriods));
            }
        }
    };
};

export const getStarStatus = () => {
    return async dispatch => {
        if (Fortress.user.acting.group) {
            const groupId = Fortress.user.acting.group.id;
            let result = await apollo.query({
                query: groupQuery,
                variables: {
                    groupId,
                },
                fetchPolicy: 'network-only',
            });
            const data = result.data.groups.anyGroup;

            let starStatus = data.fields.starStatus;
            if (!starStatus) {
                starStatus = 'None';
            }
            await dispatch(setStarStatus(starStatus));
        }
    };
};

export const getStateName = () => {
    return async (dispatch, getState) => {
        if (
            Fortress.user.acting.group &&
            Fortress.user.acting.group.data_type === 'state' &&
            !getState().app.shape.stateName
        ) {
            const groupType = Fortress.user.acting.group.group_type.handle;
            const deploymentHandle = 'SMH_profile_' + groupType;
            // query for the deployment info and group pawn based on the role
            const response = await apollo.query({
                query: deploymentGroupPawnQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHandle,
                    groupId: Fortress.user.acting.group_id,
                },
            });

            const groupPawnType = 'data.roles.' + groupType + 'Pawn';
            const groupPawn = _get(response, groupPawnType, {});

            if (Fortress.user.acting.group && Fortress.user.acting.group.data_type === 'state') {
                const stateName = groupPawn.ancestorGroups.state.fields.stateName;
                await dispatch(setStateName(stateName));
            }
        }
    };
};

export const getUserGroups = () => {
    return async (dispatch, getState) => {
        if (!getState().app.shape.userGroups.length > 0) {
            let response = await axios.get(Boilerplate.route('all.pawn.roles'));
            const userGroupData = response.data.roles.map(role => {
                return _pick(role, ['id', 'random_hash', 'data_type', 'group']);
            });
            await dispatch(setUserGroups(userGroupData));
        }
    };
};

const _formatTimePeriods = timePeriods => {
    const formattedDates = timePeriods.map(date => {
        const month1 = date.slice(0, 2);
        const year1 = date.slice(3, 7);
        const formattedDate1 = `${year1}-${month1}`;
        const month2 = date.slice(8, 10);
        const year2 = date.slice(11, 15);
        const formattedDate2 = `${year2}-${month2}`;
        return { start: formattedDate1, end: formattedDate2 };
    });
    return formattedDates;
};

export const getShapeFlowData = (groupId, assessmentType, startDate, endDate, timePeriods) => {
    return async dispatch => {
        let url = Boilerplate.route('shape.report.data');
        let response;

        if (groupId && assessmentType) {
            if (timePeriods) {
                const formattedDates = _formatTimePeriods(timePeriods);
                url =
                    Boilerplate.route('shape.report.data') +
                    '?groupId=' +
                    groupId +
                    '&assessmentType=' +
                    assessmentType +
                    '&reportingPeriods=';

                /* eslint-disable no-return-assign */
                formattedDates.forEach(d => (url += d.start + '_' + d.end + ':'));
                url = url.slice(0, -1); // remove a trailing ':'
                response = await axios.get(url);
            } else if (startDate && endDate) {
                url =
                    Boilerplate.route('shape.report.data') +
                    '?groupId=' +
                    groupId +
                    '&assessmentType=' +
                    assessmentType +
                    '&reportingPeriods=' +
                    startDate +
                    '_' +
                    endDate;
                response = await axios.get(url);
            }
        } else if (assessmentType) {
            if (timePeriods) {
                const formattedDates = _formatTimePeriods(timePeriods);
                url =
                    Boilerplate.route('shape.report.data') + '?assessmentType=' + assessmentType + '&reportingPeriods=';

                /* eslint-disable no-return-assign */
                formattedDates.forEach(d => (url += d.start + '_' + d.end + ':'));
                url = url.slice(0, -1); // remove a trailing ':'
                response = await axios.get(url);
            } else if (startDate && endDate) {
                url =
                    Boilerplate.route('shape.report.data') +
                    '?assessmentType=' +
                    assessmentType +
                    '&reportingPeriods=' +
                    startDate +
                    '_' +
                    endDate;
                response = await axios.get(url);
            }
        }

        if (typeof response === 'object' && response.data.success) {
            const formattedData = _mapValues(response.data.data, d => {
                return {
                    status: 'success',
                    data: d,
                };
            });

            const hasErrors = _some(formattedData, item => item.status === 'error');

            if (!hasErrors) {
                if (['trs', 'trsEntity', 'trsDistrict', 'trsSchool'].includes(assessmentType)) {
                    await dispatch(setTrsFlowData(formattedData));
                    await dispatch(setTrsDataLoaded(true));
                } else if (['owbiEntity', 'owbiDistrict', 'owbiSchool'].includes(assessmentType)) {
                    await dispatch(setOwbiFlowData(formattedData));
                    await dispatch(setOwbiDataLoaded(true));
                } else if (['careDistrict', 'careSchool', 'careEntity'].includes(assessmentType)) {
                    await dispatch(setCareFlowData(formattedData));
                    await dispatch(setCareDataLoaded(true));
                } else if (
                    ['familyEngagementSchool', 'familyEngagementDistrict', 'familyEngagementEntity'].includes(
                        assessmentType
                    )
                ) {
                    await dispatch(setFamilyEngagementFlowData(formattedData));
                    await dispatch(setFamilyEngagementDataLoaded(true));
                } else {
                    await dispatch(setMhqFlowData(formattedData));
                    await dispatch(setMhqDataLoaded(true));
                }
            }
        }
    };
};

export const getShapeFlowAnonData = (groupId, assessmentType, startDate, endDate, timePeriods) => {
    return async dispatch => {
        let anonQuery = anonSchoolOWBIQuery;
        let startRP = [startDate];
        let endRP = [endDate];

        // console.log({ groupId, assessmentType, startDate, endDate });
        if (timePeriods) {
            const formattedDates = _formatTimePeriods(timePeriods);

            // get "start" dates into a new array
            startRP = formattedDates.map(item => item.start);

            // get "end" dates into a new array
            endRP = formattedDates.map(item => item.end);
        }

        if (assessmentType === 'owbiDistrict') {
            anonQuery = anonDistrictOWBIQuery;
        }
        if (assessmentType === 'owbiEntity') {
            anonQuery = anonEntityOWBIQuery;
        }

        if (assessmentType === 'familyEngagementDistrict') {
            anonQuery = anonDistrictFamilyEngagementQuery;
        }
        if (assessmentType === 'familyEngagementEntity') {
            anonQuery = anonEntityFamilyEngagementQuery;
        }
        if (assessmentType === 'familyEngagementSchool') {
            anonQuery = anonSchoolFamilyEngagementQuery;
        }

        let response = await apollo.query({
            query: anonQuery,
            variables: {
                handle: assessmentType + '_anon',
                groupId,
                startDate: startRP,
                endDate: endRP,
            },
            fetchPolicy: 'network-only',
        });

        if (assessmentType.includes('owbi')) {
            await dispatch(setOwbiFlowAnonData(response.data.flows));
            await dispatch(setOwbiAnonDataLoaded(true));
        } else if (assessmentType.includes('familyEngagement')) {
            await dispatch(setFamilyEngagementFlowAnonData(response.data.flows));
            await dispatch(setFamilyEngagementAnonDataLoaded(true));
        }
    };
};

export const getAggregateFlowData = ({ assessmentType = 'mhqSchool', reportType = 'Average', timePeriods = null }) => {
    return async dispatch => {
        let url =
            Boilerplate.route('shape.aggregated.report.job') +
            '?assessmentType=' +
            assessmentType +
            '&reportType=' +
            reportType;

        if (timePeriods) {
            const formattedDates = _formatTimePeriods(timePeriods);
            url =
                Boilerplate.route('shape.aggregated.report.job') +
                '?assessmentType=' +
                assessmentType +
                '&reportType=' +
                reportType +
                '&reportingPeriods=';

            /* eslint-disable no-return-assign */
            formattedDates.forEach(d => (url += d.start + '_' + d.end + ':'));
            url = url.slice(0, -1); // remove a trailing ':'
        }

        let response = await axios.get(url);

        if (response.status === 200) {
            const groupSize = response.data.totalChildGroups;
            await dispatch(setTotalChildGroups(groupSize));
            const formattedData = _mapValues(response.data.result, d => {
                if (_isPlainObject(d)) {
                    return {
                        status: 'success',
                        data: [d],
                    };
                }
                return {
                    status: 'success',
                    data: [],
                };
            });
            if (['trs', 'trsEntity', 'trsDistrict', 'trsSchool'].includes(assessmentType)) {
                await dispatch(setTrsFlowData(formattedData));
                await dispatch(setTrsDataLoaded(true));
            } else if (['owbiEntity', 'owbiDistrict', 'owbiSchool'].includes(assessmentType)) {
                await dispatch(setOwbiFlowData(formattedData));
                await dispatch(setOwbiDataLoaded(true));
            } else if (['careEntity', 'careDistrict', 'careSchool'].includes(assessmentType)) {
                await dispatch(setCareFlowData(formattedData));
                await dispatch(setCareDataLoaded(true));
            } else if (
                ['familyEngagementSchool', 'familyEngagementDistrict', 'familyEngagementEntity'].includes(
                    assessmentType
                )
            ) {
                await dispatch(setFamilyEngagementFlowData(formattedData));
                await dispatch(setFamilyEngagementDataLoaded(true));
            } else {
                await dispatch(setMhqFlowData(formattedData));
                await dispatch(setMhqDataLoaded(true));
            }

            if (response.data.allReportingPeriods) {
                await dispatch(setReportingPeriods(response.data.allReportingPeriods));
            }
        }
    };
};

/**
 * Get the associated group pawn for the school or district.
 * Metrics is saved against that group pawn.
 * e.g. schoolPawn, districtPawn, statePawn
 *
 * Get the deployment based on the level (school, district, state)
 *
 */
export const getProfileData = (
    flowIds,
    groupId = Fortress.user.acting.group.id,
    groupType = Fortress.user.acting.group.group_type.handle
) => {
    return async dispatch => {
        const deploymentHandle = 'SMH_profile_' + groupType;
        // query for the deployment info and group pawn based on the role
        const response = await apollo.query({
            query: deploymentGroupPawnQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId,
                deploymentHandle,
            },
        });

        const contributors = response.data.roles[groupType + 'Pawn'].ancestorGroups[groupType].fields;
        const contributorsResponse = response.data.roles[groupType + 'Pawn'].assignmentProgress
            ? response.data.roles[groupType + 'Pawn'].assignmentProgress.deployment.deploymentId
            : null;
        const groupPawnType = 'data.roles.' + groupType + 'Pawn';
        const groupPawn = _get(response, groupPawnType, {});

        // query for the flow Progress
        const result = await apollo.query({
            query: deploymentProgressQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: _get(groupPawn, 'pawn.pawnId'),
                flowId: flowIds,
                deploymentHandle,
            },
        });

        const flowProgress = _get(result, 'data.roles.anyRole.flowProgressList', {});
        flowProgress.deploymentHandle = deploymentHandle;
        flowProgress.deploymentId =
            contributorsResponse !== null ? contributorsResponse : result.data.flows.deployment.deploymentId;

        await dispatch(setGroupPawn(groupPawn.pawn));
        await dispatch(setProfileData(flowProgress));
        await dispatch(setProfileDataLoaded(true));
        await dispatch(setProfileContributorList(contributors));
    };
};

export const saveDates = (startDate, endDate, flowType) => {
    return async (dispatch, getState) => {
        let data;

        if (Fortress.user.acting.group) {
            data = {
                reportingPeriodStart: startDate,
                reportingPeriodEnd: endDate,
            };
            await axios.put(
                Boilerplate.route('api.nexus.group.update', {
                    group: Fortress.user.acting.group.id,
                }),
                data
            );
        } else {
            data = {
                reportingPeriodStart: startDate,
                reportingPeriodEnd: endDate,
                pawnHash: Fortress.user.acting.random_hash,
            };
            await axios.put(
                Boilerplate.route('api.nexus.pawn.update', {
                    pawn: Fortress.user.acting.id,
                    pawnHash: Fortress.user.acting.random_hash,
                }),
                data
            );
        }

        // Prepare all data to be re-fetched when reporting period is changed
        await dispatch(setMhqDataLoaded(false));
        await dispatch(setTrsDataLoaded(false));
        await dispatch(setFamilyEngagementDataLoaded(false));
        await dispatch(setProfileDataLoaded(false));

        await dispatch(getShapeFlowData(null, flowType, startDate, endDate));

        await dispatch(setStartDate(startDate));
        await dispatch(setEndDate(endDate));
        if (Fortress.user.acting.group) {
            const groupId = Fortress.user.acting.group.id;
            let result = await apollo.query({
                query: groupQuery,
                variables: {
                    groupId,
                },
                fetchPolicy: 'network-only',
            });
            const rpData = result.data.groups.anyGroup;
            let reportingPeriods = rpData.fields.reportingPeriods;
            if (reportingPeriods) {
                await dispatch(setReportingPeriods(reportingPeriods));
            }
        }
    };
};

export const saveReportingPeriods = reportingPeriods => {
    return async (dispatch, getState) => {
        await dispatch(setReportingPeriods(reportingPeriods));
    };
};

const _processReportingPeriods = (state, reportingPeriods) => {
    let processedReportingPeriods = [];

    if (typeof reportingPeriods === 'object') {
        reportingPeriods.forEach(rp =>
            processedReportingPeriods.push({
                start: rp.slice(0, 7),
                end: rp.slice(8),
            })
        );
    }

    if (typeof reportingPeriods === 'string') {
        reportingPeriods = JSON.parse(reportingPeriods);

        for (const [key, value] of Object.entries(reportingPeriods)) {
            for (const [k1, v1] of Object.entries(value)) {
                processedReportingPeriods.push(v1);
                key;
                k1;
            }
        }
    }

    if (state.startDate && state.endDate) {
        processedReportingPeriods.push({ start: state.startDate, end: state.endDate });
    }

    const uniqueReportingPeriods = _uniqBy(processedReportingPeriods, v => [v.start, v.end].join());
    const sortedReportingPeriods = uniqueReportingPeriods.sort((a, b) => {
        if (a.start > b.start) {
            return -1;
        }
        if (a.start < b.start) {
            return 1;
        }
        return 0;
    });

    return sortedReportingPeriods.map((rp, index) => {
        const start = moment(rp.start).format('MM/YYYY');
        const end = moment(rp.end).format('MM/YYYY');
        return {
            id: index,
            name: start + '-' + end,
            start,
            end,
        };
    });
};

// Reducers
export default handleActions(
    {
        [SET_START_DATE]: (state, action) => ({
            ...state,
            startDate: action.payload,
        }),
        [SET_END_DATE]: (state, action) => ({
            ...state,
            endDate: action.payload,
        }),
        [SET_USER_GROUPS]: (state, action) => ({
            ...state,
            userGroups: action.payload,
        }),
        [SET_ASSESSMENT_TYPE]: (state, action) => ({
            ...state,
            assessmentType: action.payload,
            assessmentTypeSet: true,
        }),
        [SET_LEGACY_USER]: (state, action) => ({
            ...state,
            legacyUser: action.payload,
            legacyUserSet: true,
        }),
        [SET_STAR_STATUS]: (state, action) => ({
            ...state,
            starStatus: action.payload,
        }),
        [SET_STATE_NAME]: (state, action) => ({
            ...state,
            stateName: action.payload,
        }),
        [SET_MHQ_FLOW_DATA]: (state, action) => ({
            ...state,
            mhqFlowData: action.payload,
        }),
        [SET_TRS_FLOW_DATA]: (state, action) => ({
            ...state,
            trsFlowData: action.payload,
        }),
        [SET_TRS_DATA_LOADED]: (state, action) => ({
            ...state,
            trsDataLoaded: action.payload,
        }),
        [SET_MHQ_DATA_LOADED]: (state, action) => ({
            ...state,
            mhqDataLoaded: action.payload,
        }),
        [SET_OWBI_FLOW_DATA]: (state, action) => ({
            ...state,
            owbiFlowData: action.payload,
        }),
        [SET_OWBI_DATA_LOADED]: (state, action) => ({
            ...state,
            owbiDataLoaded: action.payload,
        }),
        [SET_OWBI_FLOW_ANON_DATA]: (state, action) => ({
            ...state,
            owbiFlowAnonData: action.payload,
        }),
        [SET_OWBI_ANON_DATA_LOADED]: (state, action) => ({
            ...state,
            owbiAnonDataLoaded: action.payload,
        }),
        [SET_FAMILY_ENGAGEMENT_FLOW_ANON_DATA]: (state, action) => ({
            ...state,
            familyEngagementFlowAnonData: action.payload,
        }),
        [SET_FAMILY_ENGAGEMENT_ANON_DATA_LOADED]: (state, action) => ({
            ...state,
            familyEngagementAnonDataLoaded: action.payload,
        }),
        [SET_CARE_FLOW_DATA]: (state, action) => ({
            ...state,
            careFlowData: action.payload,
        }),
        [SET_CARE_DATA_LOADED]: (state, action) => ({
            ...state,
            careDataLoaded: action.payload,
        }),
        [SET_FAMILY_ENGAGEMENT_FLOW_DATA]: (state, action) => ({
            ...state,
            familyEngagementFlowData: action.payload,
        }),
        [SET_FAMILY_ENGAGEMENT_DATA_LOADED]: (state, action) => ({
            ...state,
            familyEngagementDataLoaded: action.payload,
        }),
        [SET_PROFILE_DATA]: (state, action) => ({
            ...state,
            profileData: action.payload,
        }),
        [SET_PROFILE_DATA_LOADED]: (state, action) => ({
            ...state,
            profileDataLoaded: action.payload,
        }),
        [SET_GROUP_PAWN]: (state, action) => ({
            ...state,
            groupPawn: action.payload,
        }),
        [SET_PROFILE_CONTRIBUTOR_LIST]: (state, action) => ({
            ...state,
            profileContributors: action.payload,
        }),
        [SET_TOTAL_CHILD_GROUPS]: (state, action) => ({
            ...state,
            totalChildGroups: action.payload,
        }),
        [SET_REPORTING_PERIODS]: (state, action) => ({
            ...state,
            reportingPeriods: _processReportingPeriods(state, action.payload),
        }),
    },
    initialState
);
