import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import IconCheck from 'cccisd-icons/checkmark3';
import SubHeader from './SubHeader';
import style from './style.css';
const Fortress = window.cccisd && window.cccisd.fortress;

export default class StaffingInformation extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain2: PropTypes.object,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain2 } = this.props;
        return (
            <>
                <Page>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo}>
                                <div className={style.triangle} />
                                <div className={style.logo} />
                            </div>
                            <div className={style.headerTitle}>
                                Staffing Information
                                <div className={style.headerSubtitle}>
                                    {this.props.groupName
                                        ? this.props.groupName
                                        : Fortress.user.acting.group.label}
                                </div>
                            </div>
                        </div>
                        <SubHeader lastUpdated={this.props.lastUpdated} />
                        <div className={style.reportPage}>
                            <table className={style.reportTable}>
                                <tr>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            backgroundColor: '#387ab7',
                                            color: '#fff',
                                            width: '55%',
                                        }}
                                    >
                                        STAFF MEMBER
                                    </th>
                                    <th style={{ width: '15%' }}>
                                        <span style={{ fontSize: '16px' }}>School Employed</span>
                                    </th>
                                    <th style={{ width: '15%' }}>
                                        <span style={{ fontSize: '16px' }}>Community Employed</span>
                                    </th>
                                    <th style={{ width: '15%' }}>
                                        <span style={{ fontSize: '16px' }}>Total FTE</span>
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Behavioral Specialist
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_behavioral_specialist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_behavioral_specialist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_fte_behavioral_specialist}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Case manager/care coordinator
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_case_manager ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_case_manager ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_case_manager}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Community mental health supervisor/director
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_community_supervisor ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_community_supervisor ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_fte_community_supervisor}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Cultural liaison/promotora
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_cultural_liason ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_cultural_liason ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_cultural_liason}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Family support partner (family member)
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_family_support_partner ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_family_support_partner ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_fte_family_support_partner}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Nurse / School Nurse
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_school_nurse ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_school_nurse ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_school_nurse}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Occupational therapist
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_occupational_therapist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_occupational_therapist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_fte_occupational_therapist}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Parent liaison or parent engagement coordinator
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_parent_liason ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_parent_liason ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_parent_liason}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Peer mediator
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_peer_mediator ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_peer_mediator ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_peer_mediator}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Physician (pediatrician, family medicine, etc.)
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_physician ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_physician ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_physician}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Physician assistant
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_physician_assistant ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_physician_assistant ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_physician_assistant}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Professional counselor
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_counselor ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_counselor ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_counselor}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Psychiatrist
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_phychiatrist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_phychiatrist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_phychiatrist}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Psychologist
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_phychologist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_phychologist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_phychologist}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className={style.footer}>
                        <div className={style.footerText}>
                            <div>
                                This profile was developed by the Center for School Mental Health
                                for The SHAPE System.
                            </div>
                            <div>Page 4 of {this.props.totalPages}</div>
                        </div>
                        <div className={style.footerLogo} />
                    </div>
                </Page>
                <Page>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo}>
                                <div className={style.triangle} />
                                <div className={style.logo} />
                            </div>
                            <div className={style.headerTitle}>
                                Staffing Information Cont.
                                <div className={style.headerSubtitle}>
                                    {this.props.groupName
                                        ? this.props.groupName
                                        : Fortress.user.acting.group.label}
                                </div>
                            </div>
                        </div>
                        <SubHeader lastUpdated={this.props.lastUpdated} />
                        <div className={style.reportPage}>
                            <table className={style.reportTable}>
                                <tr>
                                    <th
                                        style={{
                                            fontSize: '16px',
                                            backgroundColor: '#387ab7',
                                            color: '#fff',
                                            width: '55%',
                                        }}
                                    >
                                        STAFF MEMBER
                                    </th>
                                    <th style={{ width: '15%' }}>
                                        <span style={{ fontSize: '16px' }}>School Employed</span>
                                    </th>
                                    <th style={{ width: '15%' }}>
                                        <span style={{ fontSize: '16px' }}>Community Employed</span>
                                    </th>
                                    <th style={{ width: '15%' }}>
                                        <span style={{ fontSize: '16px' }}>Total FTE</span>
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        School counselor / Guidance counselor
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_school_counselor ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_school_counselor ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_school_counselor}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        School psychologist
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_school_psychologist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_school_psychologist ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_school_psychologist}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        School resource officer
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_school_resource_officer ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_school_resource_officer ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_fte_school_resource_officer}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        School social worker
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_school_social_worker ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_school_social_worker ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_fte_school_social_worker}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Social worker
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_social_worker ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_social_worker ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_social_worker}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Substance abuse specialist
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_substance_abuse_worker ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_substance_abuse_worker ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_fte_substance_abuse_worker}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Trainee (e.g. counseling, psychiatry, psychology, social
                                        work)
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_trainee ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_trainee ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_trainee}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                        Youth/family advocate
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_school_employed_youth_advocate ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>
                                        {domain2.mental_health_profile_community_employed_youth_advocate ===
                                            '1' && <IconCheck />}
                                    </td>
                                    <td>{domain2.mental_health_profile_fte_youth_advocate}</td>
                                </tr>
                                {(domain2.mental_health_profile_community_employed_other_1 ||
                                    domain2.mental_health_profile_school_employed_other_1) && (
                                    <tr>
                                        <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                            {
                                                domain2.mental_health_profile_staffing_row_text_other_1
                                            }
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_school_employed_other_1 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_community_employed_other_1 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>{domain2.mental_health_profile_fte_other_1}</td>
                                    </tr>
                                )}
                                {(domain2.mental_health_profile_community_employed_other_2 ||
                                    domain2.mental_health_profile_school_employed_other_2) && (
                                    <tr>
                                        <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                            {
                                                domain2.mental_health_profile_staffing_row_text_other_2
                                            }
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_school_employed_other_2 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_community_employed_other_2 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>{domain2.mental_health_profile_fte_other_2}</td>
                                    </tr>
                                )}
                                {(domain2.mental_health_profile_community_employed_other_3 ||
                                    domain2.mental_health_profile_school_employed_other_3) && (
                                    <tr>
                                        <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                            {
                                                domain2.mental_health_profile_staffing_row_text_other_3
                                            }
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_school_employed_other_3 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_community_employed_other_3 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>{domain2.mental_health_profile_fte_other_3}</td>
                                    </tr>
                                )}
                                {(domain2.mental_health_profile_community_employed_other_4 ||
                                    domain2.mental_health_profile_school_employed_other_4) && (
                                    <tr>
                                        <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                            {
                                                domain2.mental_health_profile_staffing_row_text_other_4
                                            }
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_school_employed_other_4 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_community_employed_other_4 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>{domain2.mental_health_profile_fte_other_4}</td>
                                    </tr>
                                )}
                                {(domain2.mental_health_profile_community_employed_other_5 ||
                                    domain2.mental_health_profile_school_employed_other_5) && (
                                    <tr>
                                        <td style={{ textAlign: 'left', background: '#f1f1f1' }}>
                                            {
                                                domain2.mental_health_profile_staffing_row_text_other_5
                                            }
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_school_employed_other_5 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>
                                            {domain2.mental_health_profile_community_employed_other_5 ===
                                                '1' && <IconCheck />}
                                        </td>
                                        <td>{domain2.mental_health_profile_fte_other_5}</td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                    <div className={style.footer}>
                        <div className={style.footerText}>
                            <div>
                                This profile was developed by the Center for School Mental Health
                                for The SHAPE System.
                            </div>
                            <div>Page 5 of {this.props.totalPages}</div>
                        </div>
                        <div className={style.footerLogo} />
                    </div>
                </Page>
            </>
        );
    }
}
