import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { client as apollo } from 'cccisd-apollo';
import { Report } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import { getSavedParams } from 'js/reducers/params.js';
import { getProfileData, setProfileDataLoaded } from 'js/reducers/shape.js';
import { mhProfileSchool, mhProfileDistrict, mhProfileEntity, mhProfileState } from 'js/selectors/flowData.js';
import { smhpFields } from 'js/reducers/siteFields/smhpFields.js';
import { instructionFields } from 'js/reducers/siteFields/instructionFields.js';
import DistrictReport from './DistrictReport';
import SchoolReport from './SchoolReport';
import StateReport from './StateReport';
import { compareAsc } from 'date-fns';
import _map from 'lodash/map';
import style from './style.css';

// Queries
import domain1District from './graphql/domain1District.graphql';
import domain2District from './graphql/domain2District.graphql';
import domain3District from './graphql/domain3District.graphql';
import domain1School from './graphql/domain1School.graphql';
import domain2School from './graphql/domain2School.graphql';
import domain3School from './graphql/domain3School.graphql';
import domain1Entity from './graphql/domain1Entity.graphql';
import domain2Entity from './graphql/domain2Entity.graphql';
import domain3Entity from './graphql/domain3Entity.graphql';
import domain1State from './graphql/domain1State.graphql';
import domain2State from './graphql/domain2State.graphql';
import domain3State from './graphql/domain3State.graphql';
import domain4State from './graphql/domain4State.graphql';
import domain5State from './graphql/domain5State.graphql';
import domain6State from './graphql/domain6State.graphql';
import domain7State from './graphql/domain7State.graphql';
import domain8State from './graphql/domain8State.graphql';

const mapStateToProps = state => {
    return {
        smhpFields: state.app.params.smhpFields,
        profileData: state.app.shape.profileData,
        profileDataLoaded: state.app.shape.profileDataLoaded,
        stateName: state.app.shape.stateName,
    };
};

export default connect(mapStateToProps, { getSavedParams, getProfileData, setProfileDataLoaded })(
    class SMHProfileReport extends React.Component {
        static propTypes = {
            groupId: PropTypes.number,
            groupName: PropTypes.string,
            flowType: PropTypes.string,
            getSavedParams: PropTypes.func,
            smhpFields: PropTypes.object,
            profileData: PropTypes.array,
            getProfileData: PropTypes.func,
            setProfileDataLoaded: PropTypes.func,
            stateName: PropTypes.string,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            if (!this.props.smhpFields) {
                await this.props.getSavedParams(smhpFields, 'smhp');
            }
            await this.loadProfileData();
            const flowIds = this.getFlows().map(flow => flow.flowId);
            await this.props.getProfileData(flowIds, this.props.groupId, this.getGroupType());
            this.setState({ loading: false });
        };

        getLatestProfileUpdatedDate = () => {
            const dates = _map(this.props.profileData, 'completedDate');
            const sortedDates = dates.sort(compareAsc);
            const latestDate = sortedDates.slice(-1)[0];
            return latestDate;
        };

        getGroupType = () => {
            const { flowType } = this.props;

            if (flowType === 'mhpReportDistrict') {
                return 'district';
            }
            if (flowType === 'mhpReportSchool') {
                return 'school';
            }
            if (flowType === 'mhpReportState') {
                return 'state';
            }
        };

        getFlows = () => {
            const { flowType } = this.props;
            if (flowType === 'mhpReportDistrict') {
                return mhProfileDistrict;
            }
            if (flowType === 'mhpReportSchool') {
                return mhProfileSchool;
            }
            if (flowType === 'mhpReportEntity') {
                return mhProfileEntity;
            }
            if (flowType === 'mhpReportState') {
                return mhProfileState;
            }
        };

        componentWillUnmount = async () => {
            // await this.props.setProfileDataLoaded(false);
            await this.props.getSavedParams(instructionFields, 'instructions');
        };

        loadProfileData = async () => {
            const { groupId, flowType } = this.props;
            if (flowType === 'mhpReportState') {
                const domain1 = await apollo.query({
                    query: domain1State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain2 = await apollo.query({
                    query: domain2State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain3 = await apollo.query({
                    query: domain3State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain4 = await apollo.query({
                    query: domain4State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain5 = await apollo.query({
                    query: domain5State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain6 = await apollo.query({
                    query: domain6State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain7 = await apollo.query({
                    query: domain7State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain8 = await apollo.query({
                    query: domain8State,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                await this.setState({
                    domain1: domain1.data.roles.statePawn.assignmentProgress.devTags,
                    domain2: domain2.data.roles.statePawn.assignmentProgress.devTags,
                    domain3: domain3.data.roles.statePawn.assignmentProgress.devTags,
                    domain4: domain4.data.roles.statePawn.assignmentProgress.devTags,
                    domain5: domain5.data.roles.statePawn.assignmentProgress.devTags,
                    domain6: domain6.data.roles.statePawn.assignmentProgress.devTags,
                    domain7: domain7.data.roles.statePawn.assignmentProgress.devTags,
                    domain8: domain8.data.roles.statePawn.assignmentProgress.devTags,
                });
            }
            if (flowType === 'mhpReportDistrict') {
                const domain1 = await apollo.query({
                    query: domain1District,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain2 = await apollo.query({
                    query: domain2District,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain3 = await apollo.query({
                    query: domain3District,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                await this.setState({
                    domain1: domain1.data.roles.districtPawn.assignmentProgress.devTags,
                    domain2: domain2.data.roles.districtPawn.assignmentProgress.devTags,
                    domain3: domain3.data.roles.districtPawn.assignmentProgress.devTags,
                });
            }

            if (flowType === 'mhpReportSchool') {
                const domain1 = await apollo.query({
                    query: domain1School,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain2 = await apollo.query({
                    query: domain2School,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain3 = await apollo.query({
                    query: domain3School,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                await this.setState({
                    domain1: domain1.data.roles.schoolPawn.assignmentProgress.devTags,
                    domain2: domain2.data.roles.schoolPawn.assignmentProgress.devTags,
                    domain3: domain3.data.roles.schoolPawn.assignmentProgress.devTags,
                });
            }

            if (flowType === 'mhpReportEntity') {
                const domain1 = await apollo.query({
                    query: domain1Entity,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain2 = await apollo.query({
                    query: domain2Entity,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                const domain3 = await apollo.query({
                    query: domain3Entity,
                    fetchPolicy: 'network-only',
                    variables: {
                        groupId,
                    },
                });

                await this.setState({
                    domain1: domain1.data.roles.entityPawn.assignmentProgress.devTags,
                    domain2: domain2.data.roles.entityPawn.assignmentProgress.devTags,
                    domain3: domain3.data.roles.entityPawn.assignmentProgress.devTags,
                });
            }
        };

        render() {
            const { groupName, profileData } = this.props;
            const { domain1, domain2, domain3, domain4, domain5, domain6, domain7, domain8 } = this.state;
            const { flowType } = this.props;

            if (this.state.loading) {
                return <Loader loading />;
            }
            return (
                <div className={style.reportContainer}>
                    <Report downloadFilename="Profile_Report" width="1">
                        {flowType === 'mhpReportDistrict' && (
                            <DistrictReport
                                domain1={domain1}
                                domain2={domain2}
                                domain3={domain3}
                                profileUpdatedDate={this.getLatestProfileUpdatedDate()}
                                siteFields={
                                    <Html
                                        content={
                                            flowType === 'mhpReportEntity'
                                                ? this.props.smhpFields.entitySmhpAbout
                                                : this.props.smhpFields.districtSmhpAbout
                                        }
                                    />
                                }
                                groupName={groupName}
                                totalPages="6"
                                profileData={profileData}
                                flowType={flowType}
                            />
                        )}

                        {flowType === 'mhpReportEntity' && (
                            <DistrictReport
                                domain1={domain1}
                                domain2={domain2}
                                domain3={domain3}
                                profileUpdatedDate={this.getLatestProfileUpdatedDate()}
                                siteFields={
                                    <Html
                                        content={
                                            flowType === 'mhpReportEntity'
                                                ? this.props.smhpFields.entitySmhpAbout
                                                : this.props.smhpFields.districtSmhpAbout
                                        }
                                    />
                                }
                                groupName={groupName}
                                totalPages="6"
                                profileData={profileData}
                                flowType={flowType}
                            />
                        )}

                        {flowType === 'mhpReportSchool' && (
                            <SchoolReport
                                domain1={domain1}
                                domain2={domain2}
                                domain3={domain3}
                                profileUpdatedDate={this.getLatestProfileUpdatedDate()}
                                siteFields={<Html content={this.props.smhpFields.schoolSmhpAbout} />}
                                groupName={groupName}
                                totalPages="6"
                                profileData={profileData}
                            />
                        )}
                        {flowType === 'mhpReportState' && (
                            <StateReport
                                domain1={domain1}
                                domain2={domain2}
                                domain3={domain3}
                                domain4={domain4}
                                domain5={domain5}
                                domain6={domain6}
                                domain7={domain7}
                                domain8={domain8}
                                flowType={flowType}
                                stateTitle={this.props.stateName}
                                profileUpdatedDate={this.getLatestProfileUpdatedDate()}
                                siteFields={<Html content={this.props.smhpFields.stateSmhpAbout} />}
                                siteFieldPlanningGuide={
                                    <Html content={this.props.smhpFields.stateProfileStrategicPlanningGuide} />
                                }
                                siteFieldPlanningGuide2={
                                    <Html content={this.props.smhpFields.stateProfileStrategicPlanningGuide2} />
                                }
                                siteFieldPlanningGuide3={
                                    <Html content={this.props.smhpFields.stateProfileStrategicPlanningGuide3} />
                                }
                                siteFieldPlanningGuide4={
                                    <Html content={this.props.smhpFields.stateProfileStrategicPlanningGuide4} />
                                }
                                siteFieldPlanningGuide5={
                                    <Html content={this.props.smhpFields.stateProfileStrategicPlanningGuide5} />
                                }
                                siteFieldPlanningGuide6={
                                    <Html content={this.props.smhpFields.stateProfileStrategicPlanningGuide6} />
                                }
                                stateSmhpResources={<Html content={this.props.smhpFields.stateSmhpResources} />}
                                groupName={groupName}
                                totalPages="23"
                                profileData={profileData}
                            />
                        )}
                    </Report>
                </div>
            );
        }
    }
);
