import React from 'react';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import BarGraph from '../BarGraphTRS';
import SubHeading from '../SubHeading';
import style from './style.css';

const TrsReportSection = props => {
    const {
        domainName,
        getEnteredBy,
        selectedDates,
        siteFields,
        getReportFields,
        domainId,
        getGaugeImage,
        score,
        getScoreColor,
        getDataByHandle,
        getCategories,
        getSuffix,
        pageNumber,
        totalPages,
        flowType,
    } = props;

    return (
        <Page>
            <div className={style.content}>
                <div className={style.header}>
                    <div className={style.headerLogo}>
                        <div className={style.triangle} />
                        <div className={style.logoTRS} />
                    </div>
                    <div className={style.headerTitle}>{domainName}</div>
                </div>
                <SubHeading getEnteredBy={getEnteredBy} selectedDates={selectedDates} />
                <div className={style.reportPage}>
                    <div style={{ marginBottom: '1em' }}>
                        <span
                            style={{
                                fontSize: '18px',
                                color: '#0b72b6',
                                lineHeight: '1.8',
                            }}
                        >
                            About {domainName}
                        </span>
                        <div className={style.aboutText}>
                            <Html content={siteFields[getReportFields(domainId) + 'About']} />
                        </div>
                    </div>

                    <div className={style.overallScore}>
                        <div
                            className={style.gauge}
                            style={{
                                backgroundImage: `url(${getGaugeImage(score)})`,
                            }}
                        />
                        <div className={style.gaugeText}>
                            <div className={style.gaugeScore}>
                                OVERALL COMPOSITE SCORE: <span className={getScoreColor(score)}>{score}</span>
                            </div>
                        </div>
                    </div>
                    <BarGraph
                        metrics={getDataByHandle().school}
                        categories={getCategories().schoolFlowCategories}
                        flowType={flowType}
                    />

                    <div style={{ marginBottom: '1em' }}>
                        <Html content={siteFields[getReportFields(domainId) + getSuffix(score)]} />
                    </div>
                </div>
            </div>
            <div className={style.footer}>
                <div className={style.footerTextTRS}>
                    <div>
                        This progress report was developed by the{' '}
                        {['trs', 'trsEntity', 'trsDistrict', 'trsSchool'].includes(flowType) &&
                            'Treatment and Services Adaptation Center and the'}{' '}
                        National Center for School Mental Health for the SHAPE System.
                    </div>
                    <div>
                        Page {pageNumber} of {totalPages}
                    </div>
                </div>
                <div className={style.footerLogoTRS} />
                <div className={style.footerLogo} />
            </div>
        </Page>
    );
};

export default TrsReportSection;
