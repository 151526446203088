export const familyEngagementSchool = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroSchool', 'summaryReportAdditionalText'],
        },
        {
            title: 'Engagement Activities',
            messages: [
                'activitiesNotEstablishedSchool',
                'activitiesEmergingSchool',
                'activitiesProgressingSchool',
                'activitiesAdvancingSchool',
                'activitiesAboutSchool',
            ],
        },
        {
            title: 'Caregiver Expectations',
            messages: [
                'caregiverNotEstablishedSchool',
                'caregiverEmergingSchool',
                'caregiverProgressingSchool',
                'caregiverAdvancingSchool',
                'caregiverAboutSchool',
            ],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'familyEngagementPlanningPage1School',
                'familyEngagementPlanningPage2School',
                'familyEngagementPlanningPage3School',
                'familyEngagementPlanningPage4School',
                'familyEngagementPlanningPage5School',
                'familyEngagementPlanningPage6School',
            ],
        },
    ],
    messages: {
        summaryReportIntroSchool: {
            title: 'Report instruction text (left column of Summary Report)',
            message: '<p></p>',
        },
        summaryReportAdditionalText: {
            title: 'Report additional instruction text (left column of Summary Report)',
            message: '<p></p>',
        },
        activitiesNotEstablishedSchool: {
            title: 'Engagement Activities (Not Established)',
            message: '<p></p>',
        },
        activitiesEmergingSchool: {
            title: 'Engagement Activities (Emerging)',
            message: '<p></p>',
        },
        activitiesProgressingSchool: {
            title: 'Engagement Activities (Progressing)',
            message: '<p></p>',
        },
        activitiesAdvancingSchool: {
            title: 'Engagement Activities (Advancing)',
            message: '<p></p>',
        },
        activitiesAboutSchool: {
            title: 'Engagement Activities (About)',
            message: '<p></p>',
        },
        caregiverNotEstablishedSchool: {
            title: 'Caregiver Expectations (Not Establised)',
            message: '<p></p>',
        },
        caregiverEmergingSchool: {
            title: 'Caregiver Expectations (Emerging)',
            message: '<p></p>',
        },
        caregiverProgressingSchool: {
            title: 'Caregiver Expectations (Progressing)',
            message: '<p></p>',
        },
        caregiverAdvancingSchool: {
            title: 'Caregiver Expectations (Advancing)',
            message: '<p></p>',
        },
        caregiverAboutSchool: {
            title: 'Caregiver Expectations (About)',
            message: `<p></p>`,
        },
        familyEngagementPlanningPage1School: {
            title: 'Strategic Planning Guide - Page 1',
            message: ``,
        },
        familyEngagementPlanningPage2School: {
            title: 'Strategic Planning Guide - Page 2',
            message: ``,
        },
        familyEngagementPlanningPage3School: {
            title: 'Strategic Planning Guide - Page 3',
            message: ``,
        },
        familyEngagementPlanningPage4School: {
            title: 'Strategic Planning Guide - Page 4',
            message: ``,
        },
        familyEngagementPlanningPage5School: {
            title: 'Strategic Planning Guide - Page 5',
            message: ``,
        },
        familyEngagementPlanningPage6School: {
            title: 'Strategic Planning Guide - Page 6',
            message: ``,
        },
    },
};

export const familyEngagementDistrict = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroDistrict', 'summaryReportAdditionalText'],
        },
        {
            title: 'Engagement Activities',
            messages: [
                'activitiesNotEstablishedDistrict',
                'activitiesEmergingDistrict',
                'activitiesProgressingDistrict',
                'activitiesAdvancingDistrict',
                'activitiesAboutDistrict',
            ],
        },
        {
            title: 'Caregiver Expectations',
            messages: [
                'caregiverNotEstablishedDistrict',
                'caregiverEmergingDistrict',
                'caregiverProgressingDistrict',
                'caregiverAdvancingDistrict',
                'caregiverAboutDistrict',
            ],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'familyEngagementPlanningPage1District',
                'familyEngagementPlanningPage2District',
                'familyEngagementPlanningPage3District',
                'familyEngagementPlanningPage4District',
                'familyEngagementPlanningPage5District',
                'familyEngagementPlanningPage6District',
            ],
        },
    ],
    messages: {
        summaryReportIntroDistrict: {
            title: 'Report instruction text (left column of Summary Report)',
            message: '<p></p>',
        },
        summaryReportAdditionalText: {
            title: 'Report instruction text (left column of Summary Report)',
            message: '<p></p>',
        },
        activitiesNotEstablishedDistrict: {
            title: 'Engagement Activities (Not Established)',
            message: '<p></p>',
        },
        activitiesEmergingDistrict: {
            title: 'Engagement Activities (Emerging)',
            message: '<p></p>',
        },
        activitiesProgressingDistrict: {
            title: 'Engagement Activities (Progressing)',
            message: '<p></p>',
        },
        activitiesAdvancingDistrict: {
            title: 'Engagement Activities (Advancing)',
            message: '<p></p>',
        },
        activitiesAboutDistrict: {
            title: 'Engagement Activities (About)',
            message: '<p></p>',
        },
        caregiverNotEstablishedDistrict: {
            title: 'Caregiver Expectations (Not Establised)',
            message: '<p></p>',
        },
        caregiverEmergingDistrict: {
            title: 'Caregiver Expectations (Emerging)',
            message: '<p></p>',
        },
        caregiverProgressingDistrict: {
            title: 'Caregiver Expectations (Progressing)',
            message: '<p></p>',
        },
        caregiverAdvancingDistrict: {
            title: 'Caregiver Expectations (Advancing)',
            message: '<p></p>',
        },
        caregiverAboutDistrict: {
            title: 'Caregiver Expectations (About)',
            message: `<p></p>`,
        },
        familyEngagementPlanningPage1District: {
            title: 'Strategic Planning Guide - Page 1',
            message: ``,
        },
        familyEngagementPlanningPage2District: {
            title: 'Strategic Planning Guide - Page 2',
            message: ``,
        },
        familyEngagementPlanningPage3District: {
            title: 'Strategic Planning Guide - Page 3',
            message: ``,
        },
        familyEngagementPlanningPage4District: {
            title: 'Strategic Planning Guide - Page 4',
            message: ``,
        },
        familyEngagementPlanningPage5District: {
            title: 'Strategic Planning Guide - Page 5',
            message: ``,
        },
        familyEngagementPlanningPage6District: {
            title: 'Strategic Planning Guide - Page 6',
            message: ``,
        },
    },
};

export const familyEngagementEntity = {
    domains: [
        {
            title: 'Cover Page Text',
            messages: ['summaryReportIntroEntity', 'summaryReportAdditionalText'],
        },
        {
            title: 'Engagement Activities',
            messages: [
                'activitiesNotEstablishedEntity',
                'activitiesEmergingEntity',
                'activitiesProgressingEntity',
                'activitiesAdvancingEntity',
                'activitiesAboutEntity',
            ],
        },
        {
            title: 'Caregiver Expectations',
            messages: [
                'caregiverNotEstablishedEntity',
                'caregiverEmergingEntity',
                'caregiverProgressingEntity',
                'caregiverAdvancingEntity',
                'caregiverAboutEntity',
            ],
        },
        {
            title: 'Strategic Planning Guide',
            messages: [
                'familyEngagementPlanningPage1Entity',
                'familyEngagementPlanningPage2Entity',
                'familyEngagementPlanningPage3Entity',
                'familyEngagementPlanningPage4Entity',
                'familyEngagementPlanningPage5Entity',
                'familyEngagementPlanningPage6Entity',
            ],
        },
    ],
    messages: {
        summaryReportIntroEntity: {
            title: 'Report instruction text (left column of Summary Report)',
            message: '<p></p>',
        },
        summaryReportAdditionalText: {
            title: 'Report instruction text (left column of Summary Report)',
            message: '<p></p>',
        },
        activitiesNotEstablishedEntity: {
            title: 'Engagement Activities (Not Established)',
            message: '<p></p>',
        },
        activitiesEmergingEntity: {
            title: 'Engagement Activities (Emerging)',
            message: '<p></p>',
        },
        activitiesProgressingEntity: {
            title: 'Engagement Activities (Progressing)',
            message: '<p></p>',
        },
        activitiesAdvancingEntity: {
            title: 'Engagement Activities (Advancing)',
            message: '<p></p>',
        },
        activitiesAboutEntity: {
            title: 'Engagement Activities (About)',
            message: '<p></p>',
        },
        caregiverNotEstablishedEntity: {
            title: 'Caregiver Expectations (Not Establised)',
            message: '<p></p>',
        },
        caregiverEmergingEntity: {
            title: 'Caregiver Expectations (Emerging)',
            message: '<p></p>',
        },
        caregiverProgressingEntity: {
            title: 'Caregiver Expectations (Progressing)',
            message: '<p></p>',
        },
        caregiverAdvancingEntity: {
            title: 'Caregiver Expectations (Advancing)',
            message: '<p></p>',
        },
        caregiverAboutEntity: {
            title: 'Caregiver Expectations (About)',
            message: `<p></p>`,
        },
        familyEngagementPlanningPage1Entity: {
            title: 'Strategic Planning Guide - Page 1',
            message: ``,
        },
        familyEngagementPlanningPage2Entity: {
            title: 'Strategic Planning Guide - Page 2',
            message: ``,
        },
        familyEngagementPlanningPage3Entity: {
            title: 'Strategic Planning Guide - Page 3',
            message: ``,
        },
        familyEngagementPlanningPage4Entity: {
            title: 'Strategic Planning Guide - Page 4',
            message: ``,
        },
        familyEngagementPlanningPage5Entity: {
            title: 'Strategic Planning Guide - Page 5',
            message: ``,
        },
        familyEngagementPlanningPage6Entity: {
            title: 'Strategic Planning Guide - Page 6',
            message: ``,
        },
    },
};
