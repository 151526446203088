import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'cccisd-laravel-boilerplate';
import Header from 'cccisd-header';
import { connect } from 'react-redux';
import IconUser from 'cccisd-icons/user3';
import style from './style.css';
const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const inIframe = window.location !== window.parent.location;
const getWpUrl = (path = '') => 'https://theshapesystem.com/' + path;

export default connect()(
    class Component extends React.Component {
        static propTypes = {
            className: PropTypes.string,
        };

        static defaultProps = {
            className: 'container',
        };

        getLogoClassName = () => {
            if (Fortress.user.acting.id === Fortress.user.actual.id) {
                return style.logo;
            }
            return style.logoWithoutText;
        };

        render() {
            const wpLinks = [
                <li key="home">
                    <a href={getWpUrl()} target="_top">
                        Home
                    </a>
                </li>,
                <li key="about">
                    <a href={getWpUrl('about-us')} target="_top">
                        About Us
                    </a>
                </li>,
                <li key="faq">
                    <a href={getWpUrl('faq')} target="_top">
                        FAQ
                    </a>
                </li>,
            ];

            const primaryNavLinks = [
                { url: getWpUrl(), label: 'Home' },
                { url: getWpUrl('about-us'), label: 'About Us' },
            ];

            if (Fortress.auth()) {
                primaryNavLinks.push({ url: '/rolePicker', label: 'My Account' });
            } else {
                primaryNavLinks.push({ url: '/signUp', label: 'Sign Up' });
            }

            if (inIframe) {
                return (
                    <NavBar
                        className={this.props.className}
                        logo={
                            <a href={getWpUrl()} target="_top" className={this.getLogoClassName()}>
                                {' '}
                            </a>
                        }
                        noLogoLink
                        showMenu={false}
                        allowSwitchRoles={false}
                    >
                        {wpLinks}
                        {Fortress.auth()
                            ? [
                                  <li key="myShape">
                                      <a href={Boilerplate.url('/rolePicker')} target="_top">
                                          My Account
                                      </a>
                                  </li>,
                                  <li key="username">
                                      <a href={Boilerplate.url('/rolePicker')} target="_top">
                                          <IconUser spaceRight />
                                          {Fortress.user.username}
                                      </a>
                                  </li>,
                                  <li key="logout">
                                      <a href={Boilerplate.url('/api/user/logout')} target="_top">
                                          Log out
                                      </a>
                                  </li>,
                              ]
                            : [
                                  <li key="signup">
                                      <a href={Boilerplate.url('/signUp')} target="_top">
                                          Sign Up
                                      </a>
                                  </li>,
                                  <li key="login">
                                      <a href={Boilerplate.url('/account/login')} target="_top">
                                          Log In
                                      </a>
                                  </li>,
                              ]}
                    </NavBar>
                );
            }
            if (Fortress.auth()) {
                return (
                    <Header
                        wrapperClassName={style.wrapper}
                        logoClassName={style.logo}
                        primaryNavLinks={primaryNavLinks}
                        secondaryNavLinks="mainNav"
                        containerClassName={this.props.className}
                        showAlertAction={false}
                        showHomeAction={false}
                    />
                );
            }
            return (
                <Header
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    primaryNavLinks={primaryNavLinks}
                    secondaryNavLinks="mainNav"
                    containerClassName={this.props.className}
                    showAlertAction={false}
                    showHomeAction={false}
                />
                // <NavBar
                //     className={this.props.className}
                //     noLogoLink
                //     logo={
                //         <a href={getWpUrl()} target="_top" className={this.getLogoClassName()}>
                //             {' '}
                //         </a>
                //     }
                //     allowSwitchRoles={false}
                // >
                //     {wpLinks}
                //     <li>
                //         <a href={Boilerplate.url('/signUp')} target="_top">
                //             Sign Up
                //         </a>
                //     </li>
                // </NavBar>
            );
        }
    }
);
