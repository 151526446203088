import React from 'react';
import PropTypes from 'prop-types';
import style from '../style.css';

export default class ApproveModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func,
        row: PropTypes.object,
    };

    handleYesButton = () => {
        this.props.closeModal();
    };

    handleCancelButton = () => {
        this.props.closeModal();
    };

    render() {
        const { row } = this.props;
        return (
            <div className={style.modalBody}>
                <p>
                    Reject {row['user.fullName'] ? row['user.fullName'] : 'this user'} from joining{' '}
                    {row['ancestorGroups.district.group.label']
                        ? row['ancestorGroups.district.group.label']
                        : 'your group'}
                    ?
                </p>
                <p>They will be sent an email notification.</p>
                <div className={style.modalButtons}>
                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={this.handleCancelButton}
                    >
                        Cancel
                    </button>
                    <button type="button" className="btn btn-danger" onClick={this.handleYesButton}>
                        Reject
                    </button>
                </div>
            </div>
        );
    }
}
