import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
export default class OrgAgencyInvolvement2 extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain1: PropTypes.object,
        stateTitle: PropTypes.string,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain1, stateTitle } = this.props;
        return (
            <Page>
                <div className={style.pageWrapper}>
                    <div className={style.content}>
                        <div className={style.header}>
                            <div className={style.headerLogo_organ1}>
                                <div className={style.triangleState_organiz1} />
                                {/* <div className={style.logo} /> */}
                            </div>
                            <div className={style.headerTitle_state}>
                                Organization and Agency Involvement
                            </div>
                        </div>
                        {/* <SubHeader lastUpdated={profileUpdatedDate} /> */}
                        <div className={style.reportPage}>
                            <div className={style.bodyContent}>
                                <div className={style.bodyTopState}>
                                    <p className={style.topStateContent_page3}>
                                        The organizations and agencies involved in the advancement
                                        of comprehensive school mental health services and supports
                                        in {stateTitle ? stateTitle : ''} include:
                                    </p>

                                    <div className={style.mainCartHeader_4th_Page3}>
                                        {/* <div className={style.triangletop_left_4rth} /> */}
                                        <div className={style.cartContentHeader_4th_Page3}>
                                            <h4>Advocacy and Policy</h4>
                                        </div>
                                        {/* <div className={style.header_shape_4rth_page3} /> */}
                                        <div className={style.CartContent_4rth}>
                                            <div className={style.OnOrderlist_MainContainer}>
                                                <div className={style.OnOrderlist_Block}>
                                                    <ul>
                                                        {domain1.mental_health_profile_edu_adv ===
                                                        '1' ? (
                                                            <li>Education</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_other2_adv ===
                                                        '1' ? (
                                                            <li>
                                                                {
                                                                    domain1.mental_health_profile_d1_other2_text
                                                                }
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_other1_adv ===
                                                        '1' ? (
                                                            <li>
                                                                {
                                                                    domain1.mental_health_profile_d1_other1_text
                                                                }
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_ec_adv ===
                                                        '1' ? (
                                                            <li>Early childhood </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_physhlth_adv ===
                                                        '1' ? (
                                                            <li>Physical health </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_pubhlth_adv ===
                                                        '1' ? (
                                                            <li>Public health </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_behhlth_adv ===
                                                        '1' ? (
                                                            <li>Behavioral health</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_housauth_adv ===
                                                        '1' ? (
                                                            <li>Housing authorities</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_mhhlth_adv ===
                                                        '1' ? (
                                                            <li>Mental health</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_su_adv ===
                                                        '1' ? (
                                                            <li>Substance use</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_juvjussrvc_adv ===
                                                        '1' ? (
                                                            <li>Juvenile justice services </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_chldwlfr_adv ===
                                                        '1' ? (
                                                            <li>
                                                                Child welfare/social services/human
                                                                services{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_fthorg_adv ===
                                                        '1' ? (
                                                            <li>Faith-based organizations</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_univ_adv ===
                                                        '1' ? (
                                                            <li>Universities, colleges</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_bus_adv ===
                                                        '1' ? (
                                                            <li>Businesses</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_phlthrpcfnd_adv ===
                                                        '1' ? (
                                                            <li>
                                                                National, state, and local private
                                                                philanthropic foundations{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_ythdev_adv ===
                                                        '1' ? (
                                                            <li>
                                                                Youth development/advocacy
                                                                organizations
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_fmlyldrshp_adv ===
                                                        '1' ? (
                                                            <li>
                                                                Family leadership/advocacy
                                                                organizations{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_lawenforce_adv ===
                                                        '1' ? (
                                                            <li>
                                                                Law enforcement and public safety
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_prfst_adv ===
                                                        '1' ? (
                                                            <li>
                                                                Professional association state
                                                                chapters{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={style.mainCartHeader_5th_page3}>
                                        {/* <div className={style.triangletop_left_5th} /> */}
                                        <div className={style.cartContentHeader_5th_page3}>
                                            <h4>Service Provision</h4>
                                        </div>
                                        {/* <div className={style.header_shape_5th} /> */}
                                        <div className={style.CartContent_5th}>
                                            <div className={style.OnOrderlist_MainContainer}>
                                                <div className={style.OnOrderlist_Block}>
                                                    <ul>
                                                        {domain1.mental_health_profile_edu_srvcprov ===
                                                        '1' ? (
                                                            <li>Education</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_other2_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                {
                                                                    domain1.mental_health_profile_d1_other2_text
                                                                }
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_other1_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                {
                                                                    domain1.mental_health_profile_d1_other1_text
                                                                }
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_ec_srvcprov ===
                                                        '1' ? (
                                                            <li>Early childhood </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_physhlth_srvcprov ===
                                                        '1' ? (
                                                            <li>Physical health </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_pubhlth_srvcprov ===
                                                        '1' ? (
                                                            <li>Public health </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_behhlth_srvcprov ===
                                                        '1' ? (
                                                            <li>Behavioral health</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_housauth_srvcprov ===
                                                        '1' ? (
                                                            <li>Housing authorities</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_mhhlth_srvcprov ===
                                                        '1' ? (
                                                            <li>Mental health</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_su_srvcprov ===
                                                        '1' ? (
                                                            <li>Substance use</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_juvjussrvc_srvcprov ===
                                                        '1' ? (
                                                            <li>Juvenile justice services </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_chldwlfr_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                Child welfare/social services/human
                                                                services{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_fthorg_srvcprov ===
                                                        '1' ? (
                                                            <li>Faith-based organizations</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_univ_srvcprov ===
                                                        '1' ? (
                                                            <li>Universities, colleges</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_bus_srvcprov ===
                                                        '1' ? (
                                                            <li>Businesses</li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_phlthrpcfnd_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                National, state, and local private
                                                                philanthropic foundations{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_ythdev_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                Youth development/advocacy
                                                                organizations
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_fmlyldrshp_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                Family leadership/advocacy
                                                                organizations{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_lawenforce_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                Law enforcement and public safety
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {domain1.mental_health_profile_prfst_srvcprov ===
                                                        '1' ? (
                                                            <li>
                                                                Professional association state
                                                                chapters{' '}
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.footer_page3}>
                        <div className={style.footerText_Page}>
                            This report was automatically generated by the SHAPE System based on
                            responses from {stateTitle ? stateTitle : ''} state leaders.
                        </div>
                        <div className={style.footerLogoStateReport} />
                    </div>
                    <div className={style.pageNumber}>Page 4 of {this.props.totalPages}</div>
                </div>
            </Page>
        );
    }
}
