import React from 'react';
import PropTypes from 'prop-types';
import SubHeader from './SubHeader';
import { Page } from 'cccisd-laravel-report';
import IconPlus from 'cccisd-icons/plus';
import IconSquare from 'cccisd-icons/square';
import IconCross from 'cccisd-icons/cross';
import style from './style.css';
const Fortress = window.cccisd && window.cccisd.fortress;

const shapeRed = '#a94442';
const shapeYellow = '#fcbd2b';
const shapeGreen = '#308b85';
const shapeBlue = '#154988';

export default class ServicesProvided extends React.Component {
    static propTypes = {
        lastUpdated: PropTypes.string,
        groupName: PropTypes.string,
        domain3: PropTypes.object,
        totalPages: PropTypes.string,
    };

    render() {
        const { domain3 } = this.props;

        return (
            <Page>
                <div className={style.content}>
                    <div className={style.header}>
                        <div className={style.headerLogo}>
                            <div className={style.triangle} />
                            <div className={style.logo} />
                        </div>
                        <div className={style.headerTitle}>
                            Services and Supports
                            <div className={style.headerSubtitle}>
                                {this.props.groupName
                                    ? this.props.groupName
                                    : Fortress.user.acting.group.label}
                            </div>
                        </div>
                    </div>
                    <SubHeader lastUpdated={this.props.lastUpdated} />
                    <div className={style.reportPage}>
                        <p>
                            Your{' '}
                            {Fortress.user.acting.group && Fortress.user.acting.group.data_type}{' '}
                            provided services and support to address the following student concerns
                            at each tier:
                        </p>
                        <div className={style.servicesLegend}>
                            <div className={style.pyramid} />
                            <div className={style.legendInfo}>
                                <div>
                                    <span style={{ color: shapeRed }}>Tier 3: </span>Indicated
                                    services and supports
                                </div>
                                <div>
                                    <span style={{ color: shapeYellow }}>Tier 2: </span>Selective
                                    services and supports
                                </div>
                                <div>
                                    <span style={{ color: shapeGreen }}>Tier 1: </span>Mental health
                                    promotion services and supports
                                </div>
                                <div>
                                    <span style={{ color: shapeBlue }}>
                                        <IconPlus />
                                    </span>
                                    : Referrals to community providers not in the school building
                                </div>
                                <div>
                                    <IconCross />: No services
                                </div>
                            </div>
                        </div>

                        <div className={style.servicesTable}>
                            <table>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <div className={style.icon}>
                                                {domain3.mental_health_profile_anxiety_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </div>
                                            <div className={style.icon}>
                                                {domain3.mental_health_profile_anxiety_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </div>
                                            <div className={style.icon}>
                                                {domain3.mental_health_profile_anxiety_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </div>
                                            <div className={style.icon}>
                                                {domain3.mental_health_profile_anxiety_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </div>
                                            <div className={style.icon}>
                                                {domain3.mental_health_profile_anxiety_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td>Anxiety</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_attention_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_attention_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_attention_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_attention_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_attention_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Attention/hyperactivity</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_bullying_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_bullying_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_bullying_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_bullying_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_bullying_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Bullying</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_depression_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_depression_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_depression_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_depression_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_depression_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Depression/suicidal ideation</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_eating_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_eating_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_eating_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_eating_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_eating_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Disordered eating</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_grief_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_grief_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_grief_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_grief_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_grief_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Grief/loss</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_anger_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_anger_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_anger_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_anger_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_anger_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Oppositional or conduct problems/anger management</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_psychosis_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_psychosis_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_psychosis_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_psychosis_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_psychosis_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Psychosis (hallucinations, delusions)</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_relationships_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_relationships_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_relationships_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_relationships_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_relationships_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Healthy relationships</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_se_skills_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_se_skills_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_se_skills_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_se_skills_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_se_skills_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Social and emotional skills/character development</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_substance_use_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_substance_use_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_substance_use_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_substance_use_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_substance_use_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Substance use (alcohol, tobacco, drugs)</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={style.iconGroup}>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_trauma_tier_1 ===
                                                    '1' && (
                                                    <span style={{ color: shapeGreen }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_trauma_tier_2 ===
                                                    '1' && (
                                                    <span style={{ color: shapeYellow }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_trauma_tier_3 ===
                                                    '1' && (
                                                    <span style={{ color: shapeRed }}>
                                                        <IconSquare />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_trauma_community_referrals ===
                                                    '1' && (
                                                    <span style={{ color: shapeBlue }}>
                                                        <IconPlus />
                                                    </span>
                                                )}
                                            </span>
                                            <span className={style.icon}>
                                                {domain3.mental_health_profile_trauma_no_service ===
                                                    '1' && (
                                                    <span>
                                                        <IconCross />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                    <td>Trauma/PTSD/abuse/neglect/exposure to violence</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        <div>
                            This profile was developed by the Center for School Mental Health for
                            The SHAPE System.
                        </div>
                        <div>Page 6 of {this.props.totalPages}</div>
                    </div>
                    <div className={style.footerLogoStateReport} />
                </div>
            </Page>
        );
    }
}
