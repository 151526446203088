import shape from './reducers/shape';
import paramsReducer, { persistedKeys as paramsPersistedKeys } from './reducers/params.js';

export default {
    shape: {
        reducer: shape,
    },
    params: {
        reducer: paramsReducer,
        persist: { keys: paramsPersistedKeys },
    },
};
